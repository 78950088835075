/**
 * @generated SignedSource<<36a6728201c9450d7fb6f30943e86bcd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type notesOverview_QueryFragment$data = {
  readonly Note: {
    readonly Note: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly reminder: {
            readonly __typename: string;
          } | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"noteCard_NoteFragment">;
        };
      } | null | undefined> | null | undefined;
    };
  };
  readonly " $fragmentType": "notesOverview_QueryFragment";
};
export type notesOverview_QueryFragment$key = {
  readonly " $data"?: notesOverview_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"notesOverview_QueryFragment">;
};

import notesOverview_RefetchQuery_graphql from './notesOverview_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "Note",
  "Note"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByText"
    },
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeNoteColors"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeNoteSourceKinds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "noteColorToSortBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": notesOverview_RefetchQuery_graphql
    }
  },
  "name": "notesOverview_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NoteQueries",
      "kind": "LinkedField",
      "name": "Note",
      "plural": false,
      "selections": [
        {
          "alias": "Note",
          "args": [
            {
              "kind": "Variable",
              "name": "filterByText",
              "variableName": "filterByText"
            },
            {
              "kind": "Literal",
              "name": "hasReminder",
              "value": false
            },
            {
              "kind": "Variable",
              "name": "includeNoteColors",
              "variableName": "includeNoteColors"
            },
            {
              "kind": "Variable",
              "name": "includeNoteSourceKinds",
              "variableName": "includeNoteSourceKinds"
            },
            {
              "kind": "Variable",
              "name": "noteColorToSortBy",
              "variableName": "noteColorToSortBy"
            }
          ],
          "concreteType": "NoteConnection",
          "kind": "LinkedField",
          "name": "__notesOverview_Note_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NoteEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Note",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "reminder",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "noteCard_NoteFragment"
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "70f5ebcf905239ab420d5fc5a34487e2";

export default node;
