/**
 * @generated SignedSource<<e1a7c2e338ab194e00bdb6cb40e931b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type attachmentsCollection_AttachmentsCollectionFragment$data = {
  readonly attachmentsV2: ReadonlyArray<{
    readonly file?: {
      readonly name: string;
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly title: string | null | undefined;
  }>;
  readonly indexPath: ReadonlyArray<number>;
  readonly " $fragmentType": "attachmentsCollection_AttachmentsCollectionFragment";
};
export type attachmentsCollection_AttachmentsCollectionFragment$key = {
  readonly " $data"?: attachmentsCollection_AttachmentsCollectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"attachmentsCollection_AttachmentsCollectionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "attachmentsCollection_AttachmentsCollectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "indexPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "attachmentsV2",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "file",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "FileAttachment",
          "abstractKey": "__isFileAttachment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};

(node as any).hash = "81c6161e9f09b3cfca144356bd4b21ed";

export default node;
