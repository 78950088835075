import type { Kind } from "@relay/licenseDetails_Query.graphql";

export type AccountData = ReadonlyArray<{
	readonly extension:
		| {
				readonly licenseModelKind?: Kind | null | undefined;
		  }
		| null
		| undefined;
	readonly id: string;
}>;

export const getLicenseModelKindForAccount = (
	accountData: AccountData,
	currentAccountId?: string,
) => {
	if (accountData && currentAccountId) {
		const account = accountData.find((account) => account.id === currentAccountId);
		return account?.extension?.licenseModelKind;
	}
	return undefined;
};
