import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { Skeleton } from "@thekeytechnology/epic-ui";
import { ChipSkeleton } from "@components/chip/chip.skeleton";
import { MaterialCardsCollectionSkeleton } from "@components/material-cards-collection/material-cards-collection.skeleton";
import {
	contentPathClass,
	headerWrapperClass,
	pointsWrapperClass,
	descriptionImageWrapperClass,
	wrapperClass,
} from "./content-overview.styles";
import { BranchNodeTabSkeleton } from "../branch-nodes-tab/branch-nodes-tab.skeleton";

export const ContentOverviewSkeleton = ({ showDetails }: { showDetails?: boolean }) => {
	const { isXLargeUp } = useWindowSize();
	const content = (
		<div className={wrapperClass()}>
			<div className={headerWrapperClass}>
				<div className={pointsWrapperClass}>
					<Skeleton width="4rem" />
					<ChipSkeleton />
				</div>
				<div className={contentPathClass}>
					<Skeleton width="5rem" />
				</div>
				<Skeleton height="2rem" width="80%" />
				<div className={descriptionImageWrapperClass}>
					<div className="flex flex-col gap-8 w-[60%] mt-24">
						<Skeleton width="100%" />
						<Skeleton width="90%" />
						<Skeleton width="95%" />
						<Skeleton width="85%" />
						<Skeleton width="40%" />
					</div>
				</div>
			</div>
			<MaterialCardsCollectionSkeleton />
		</div>
	);
	return isXLargeUp || showDetails ? content : <BranchNodeTabSkeleton />;
};
