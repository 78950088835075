/**
 * @generated SignedSource<<df00a21b88489e69896be2b10a7fe32d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type navbar_AuthViewerSchemaFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"avatarWithProfileMenu_AuthViewerSchemaFragment">;
  readonly " $fragmentType": "navbar_AuthViewerSchemaFragment";
};
export type navbar_AuthViewerSchemaFragment$key = {
  readonly " $data"?: navbar_AuthViewerSchemaFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"navbar_AuthViewerSchemaFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "navbar_AuthViewerSchemaFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "avatarWithProfileMenu_AuthViewerSchemaFragment"
    }
  ],
  "type": "AuthViewerSchema",
  "abstractKey": null
};

(node as any).hash = "360e15859964f65cdbc91520fdd826c7";

export default node;
