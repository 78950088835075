import { css, cva } from "@styled-system/css";

export const rowWrapperClass = cva({
	base: {
		display: "flex",
		gap: "12",
		alignItems: "center",
		cursor: "pointer",
		textAlign: "left",
		width: "full",
		_hover: {
			opacity: 0.7,
		},
	},
	variants: {
		isShort: {
			true: {
				lg: {
					maxWidth: "[8.5rem]",
				},
			},
		},
		isDualRow: {
			true: {
				justifyContent: "space-between",
				cursor: "default",
				_hover: {
					opacity: 1,
				},
			},
		},
	},
	defaultVariants: {
		isShort: false,
		isDualRow: false,
	},
});

export const childrenWrapperClass = css({
	backgroundColor: "shade.5",
	padding: "16",
	paddingLeft: "[2.1875rem]",
	borderRadius: "12",
});
