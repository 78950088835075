/**
 * @generated SignedSource<<bcd34fc2729f6dd6aaaf5750cf24458e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserExtensionKind = "Academies" | "Tka";
export type ViewerTreeStateKind = "CanBeStarted" | "CanNotBeStarted" | "IsFinished" | "IsStarted" | "NotVisible";
export type levelsBox_Query$variables = Record<PropertyKey, never>;
export type levelsBox_Query$data = {
  readonly Gamification: {
    readonly LevelDefinitions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly requiredPoints: number;
          readonly " $fragmentSpreads": FragmentRefs<"levelItem_LevelDefinitionFragment">;
        };
      } | null | undefined> | null | undefined;
    };
  };
  readonly Learnable: {
    readonly PublishedLearnables: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id?: string;
          readonly root?: {
            readonly structureDefinition: {
              readonly extension?: {
                readonly diploma?: string | null | undefined;
                readonly reducedData?: {
                  readonly isIHK: boolean;
                } | null | undefined;
              };
              readonly viewerTreeState?: {
                readonly kind: ViewerTreeStateKind;
              };
            };
          } | null | undefined;
        };
      } | null | undefined> | null | undefined;
    };
  };
  readonly Viewer: {
    readonly Auth: {
      readonly currentUser: {
        readonly user: {
          readonly extensions: ReadonlyArray<{
            readonly kind: UserExtensionKind;
            readonly level?: number;
            readonly progressInPercent?: number;
            readonly sumGamificationPoints?: number;
          }>;
        };
      } | null | undefined;
    };
  };
};
export type levelsBox_Query = {
  response: levelsBox_Query$data;
  variables: levelsBox_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredPoints",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "level",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "progressInPercent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sumGamificationPoints",
      "storageKey": null
    }
  ],
  "type": "AcademiesUserExtension",
  "abstractKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "kinds",
    "value": ([]/*: any*/)
  },
  {
    "kind": "Literal",
    "name": "tagIds",
    "value": ([]/*: any*/)
  }
],
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diploma",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReducedData",
      "kind": "LinkedField",
      "name": "reducedData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isIHK",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PublishedRootExtensionImpl",
  "abstractKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "levelsBox_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GamificationQueries",
        "kind": "LinkedField",
        "name": "Gamification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LevelDefinitionsConnection",
            "kind": "LinkedField",
            "name": "LevelDefinitions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LevelDefinitionsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LevelDefinition",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "levelItem_LevelDefinitionFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnableQueries",
        "kind": "LinkedField",
        "name": "Learnable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "PublishedLearnablesConnection",
            "kind": "LinkedField",
            "name": "PublishedLearnables",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PublishedLearnablesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LearnOpportunityV2",
                            "kind": "LinkedField",
                            "name": "root",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "structureDefinition",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "extension",
                                        "plural": false,
                                        "selections": [
                                          (v5/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "viewerTreeState",
                                        "plural": false,
                                        "selections": [
                                          (v2/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "LearnOpportunityRootStructureDefinition",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "PublishedCourseLearnable",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "PublishedLearnables(kinds:[],tagIds:[])"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "levelsBox_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GamificationQueries",
        "kind": "LinkedField",
        "name": "Gamification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LevelDefinitionsConnection",
            "kind": "LinkedField",
            "name": "LevelDefinitions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LevelDefinitionsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LevelDefinition",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shortDescription",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnableQueries",
        "kind": "LinkedField",
        "name": "Learnable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "PublishedLearnablesConnection",
            "kind": "LinkedField",
            "name": "PublishedLearnables",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PublishedLearnablesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v0/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LearnOpportunityV2",
                            "kind": "LinkedField",
                            "name": "root",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "structureDefinition",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "extension",
                                        "plural": false,
                                        "selections": [
                                          (v6/*: any*/),
                                          (v5/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "viewerTreeState",
                                        "plural": false,
                                        "selections": [
                                          (v6/*: any*/),
                                          (v2/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "LearnOpportunityRootStructureDefinition",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "PublishedCourseLearnable",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "PublishedLearnables(kinds:[],tagIds:[])"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7811cba27c805e1a2b84530a1ebab4c9",
    "id": null,
    "metadata": {},
    "name": "levelsBox_Query",
    "operationKind": "query",
    "text": "query levelsBox_Query {\n  Gamification {\n    LevelDefinitions {\n      edges {\n        node {\n          id\n          requiredPoints\n          ...levelItem_LevelDefinitionFragment\n        }\n      }\n    }\n  }\n  Viewer {\n    Auth {\n      currentUser {\n        user {\n          extensions {\n            __typename\n            kind\n            ... on AcademiesUserExtension {\n              level\n              progressInPercent\n              sumGamificationPoints\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n  Learnable {\n    PublishedLearnables(tagIds: [], kinds: []) {\n      edges {\n        node {\n          __typename\n          ... on PublishedCourseLearnable {\n            id\n            root {\n              structureDefinition {\n                __typename\n                ... on LearnOpportunityRootStructureDefinition {\n                  extension {\n                    __typename\n                    ... on PublishedRootExtensionImpl {\n                      diploma\n                      reducedData {\n                        isIHK\n                      }\n                    }\n                  }\n                  viewerTreeState {\n                    __typename\n                    kind\n                  }\n                }\n              }\n              id\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment levelItem_LevelDefinitionFragment on LevelDefinition {\n  id\n  title\n  text\n  requiredPoints\n  shortDescription\n  image {\n    id\n    name\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "70dfa8b9e24d571267ca3e08e4712712";

export default node;
