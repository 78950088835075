import { createContext, useContext, useState } from "react";
import { UserCartContextType } from "./user-cart.types";

const UserCartContext = createContext<UserCartContextType>({
	userCartOpen: false,
	setUserCartOpen: () => {},
});

export const UserCartContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [userCartOpen, setUserCartOpen] = useState(false);

	return (
		<UserCartContext.Provider value={{ userCartOpen, setUserCartOpen }}>
			{children}
		</UserCartContext.Provider>
	);
};

export const useUserCartContext = () => {
	const userCartContext = useContext(UserCartContext);
	if (!userCartContext) {
		throw new Error("useUserCartContext must be used within a UserCartContextProvider");
	}
	return userCartContext;
};
