import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { useEffect, useState } from "react";
import { BottomNavigationTemplate } from "@components/bottom-navigation-template";
import { UpsertNoteModal } from "@components/upsert-note-modal";
import { UpsertReminderModal } from "@components/upsert-reminder-modal";
import { ScreenWithNavbarAndBottomContentLayout } from "@layouts/screen-with-navbar-and-bottom-content-layout";
import { H1Span } from "@themes/font-tags";
import { INotesScreenContext, NotesScreenContext } from "./notes.context";
import { NotesScreenSkeleton } from "./notes.skeleton";
import {
	contentWrapperClass,
	header,
	headerFilterWrapperClass,
	headerInnerClass,
	noGrowWrapperClass,
} from "./notes.styles";
import { Filters } from "./parts/filters";
import { NotesTabbar } from "./parts/notes-tabbar";
import { SearchBar } from "./parts/search-bar";
import { SearchBarMobile } from "./parts/search-bar/search-bar-mobile";

const NotesScreenComponent = () => {
	const { isMediumUp, isXLargeUp } = useWindowSize();
	const [upsertNoteModalOpen, setUpsertNoteModalOpen] = useState(false);
	const [upsertReminderModalOpen, setUpsertReminderModalOpen] = useState(false);
	const [state, setState] = useState<Omit<INotesScreenContext, "helpers">>({
		filters: {
			colors: ["default", "blue", "green", "red", "yellow"],
			text: "",
		},
		activeIndex: 0,
		refetchers: [],
		filtersAreVisible: false,
		searchIsVisibleOnMobile: false,
	});

	const helpers: Pick<INotesScreenContext, "helpers">["helpers"] = {
		setText: (text) => {
			setState((state) => ({
				...state,
				filters: { ...state.filters, text },
			}));
		},
		setColors: (colors) => {
			setState((state) => ({
				...state,
				filters: { ...state.filters, colors },
			}));
		},
		toggleColor: (color) => {
			const {
				filters: { colors },
			} = { ...state };
			if (colors.includes(color)) {
				const newColors = [...colors].filter((k) => k !== color);
				setState({ ...state, filters: { ...state.filters, colors: newColors } });
			} else {
				setState({
					...state,
					filters: { ...state.filters, colors: [...state.filters.colors, color] },
				});
			}
		},
		toggleFiltersAreVisible: () => {
			setState((state) => ({ ...state, filtersAreVisible: !state.filtersAreVisible }));
		},
		toggleSearchIsVisibleOnMobile: () => {
			setState((state) => ({
				...state,
				searchIsVisibleOnMobile: !state.searchIsVisibleOnMobile,
			}));
		},
		registerRefetch: (refetch) => {
			state.refetchers.push(refetch);
			return state.refetchers.length - 1;
		},
		unregisterRefetch: (id) => {
			state.refetchers = state.refetchers.filter((_, index) => index !== id);
		},
		setActiveIndex: (index) => {
			setState((state) => ({ ...state, activeIndex: index }));
		},
		setColorToSortBy: (color) => {
			setState((state) => ({
				...state,
				filters: { ...state.filters, colorToSortBy: color },
			}));
		},
	};
	const contextValue: INotesScreenContext = { ...state, helpers };

	const handleRefetch = () => {
		state.refetchers.forEach((refetch) => refetch());
	};

	const handleClickCreateNote = () => {
		setUpsertNoteModalOpen(true);
	};

	const handleUpsertNoteModalDismiss = () => {
		setUpsertNoteModalOpen(false);
	};

	const handleNoteUpsertSuccess = () => {
		setUpsertNoteModalOpen(false);
		handleRefetch();
	};

	const handleClickCreateReminder = () => {
		setUpsertReminderModalOpen(true);
	};

	const handleUpsertReminderModalDismiss = () => {
		setUpsertReminderModalOpen(false);
	};

	const handleReminderUpsertSuccess = () => {
		setUpsertReminderModalOpen(false);
		handleRefetch();
	};

	const handleToggleOnClick = () => {
		if (isMediumUp) return;
		helpers.toggleSearchIsVisibleOnMobile();
	};

	useEffect(() => {
		if (!isMediumUp) return;
		if (state.searchIsVisibleOnMobile) {
			helpers.toggleSearchIsVisibleOnMobile();
		}
	}, [isMediumUp, state.searchIsVisibleOnMobile]);

	return (
		<NotesScreenContext.Provider value={contextValue}>
			{upsertNoteModalOpen && (
				<UpsertNoteModal
					onDismiss={handleUpsertNoteModalDismiss}
					onSuccess={handleNoteUpsertSuccess}
				/>
			)}
			{upsertReminderModalOpen && (
				<UpsertReminderModal
					onDismiss={handleUpsertReminderModalDismiss}
					onSuccess={handleReminderUpsertSuccess}
				/>
			)}
			<ScreenWithNavbarAndBottomContentLayout
				canGoBack={!isXLargeUp}
				bottomContent={
					<BottomNavigationTemplate
						actionButtonIconName="add"
						actionButtonFillParent={!isXLargeUp}
						actionButtonLabel={
							state.activeIndex === 0 ? "Neue Notiz" : "Neue Erinnerung"
						}
						onActionButtonClick={
							state.activeIndex === 0
								? handleClickCreateNote
								: handleClickCreateReminder
						}
					/>
				}
			>
				<div className={contentWrapperClass}>
					<div className={header} data-no-selection-menu>
						<div className={headerInnerClass}>
							<H1Span>Notizen & Erinnerungen</H1Span>
							<div className={headerFilterWrapperClass}>
								<SearchBar onClick={handleToggleOnClick} />
								<div className={noGrowWrapperClass}>
									<Filters />
								</div>
							</div>
						</div>
						{state.searchIsVisibleOnMobile && (
							<div>
								<SearchBarMobile />
							</div>
						)}
						<NotesTabbar />
					</div>
				</div>
			</ScreenWithNavbarAndBottomContentLayout>
		</NotesScreenContext.Provider>
	);
};

export const NotesScreen = withSuspense(NotesScreenComponent, NotesScreenSkeleton);
