/**
 * @generated SignedSource<<8773ad68f2219d6efb00ca54982ba41b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PayOrderInput = {
  clientMutationId?: string | null | undefined;
  iban?: string | null | undefined;
  orderId: string;
};
export type shoppingCartOverview_PayOrderMutation$variables = {
  input: PayOrderInput;
};
export type shoppingCartOverview_PayOrderMutation$data = {
  readonly Billing: {
    readonly payOrder: {
      readonly order: {
        readonly id: string;
      };
    } | null | undefined;
  };
};
export type shoppingCartOverview_PayOrderMutation = {
  response: shoppingCartOverview_PayOrderMutation$data;
  variables: shoppingCartOverview_PayOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BillingMutations",
    "kind": "LinkedField",
    "name": "Billing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "PayOrderPayload",
        "kind": "LinkedField",
        "name": "payOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "shoppingCartOverview_PayOrderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "shoppingCartOverview_PayOrderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9983c69fb2a5e24d5701b0d6c97c0485",
    "id": null,
    "metadata": {},
    "name": "shoppingCartOverview_PayOrderMutation",
    "operationKind": "mutation",
    "text": "mutation shoppingCartOverview_PayOrderMutation(\n  $input: PayOrderInput!\n) {\n  Billing {\n    payOrder(input: $input) {\n      order {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3614e3bb4d09f5eba726bf37dcb6d326";

export default node;
