import { Icon, Image } from "@thekeytechnology/academies-lib-webapp";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { useState } from "react";
import { useFragment } from "react-relay";
import { ReactComponent as CheckBadgeSVG } from "@assets/checkBadge.svg";
import { ReactComponent as LockSVG } from "@assets/lockPrimary.svg";
import { colorPrimary100Class, colorShade80Class } from "@themes/color-classes";
import { H2Span, H3Span, P1Span, P3Span } from "@themes/font-tags";
import { LEVEL_DEFINITION_FRAGMENT } from "./level-item.graphql";
import {
	iconWrapperClass,
	imagesWrapperClass,
	imageWrapperClass,
	modalCloseButtonClass,
	modalContentClass,
	modalWrapperClass,
	titlesWrapperClass,
	wrapperClass,
} from "./level-item.styles";
import { LevelItemProps } from "./level-item.types";

export const LevelItem = ({ levelDefinitionFragmentRef, isUnlocked }: LevelItemProps) => {
	const levelDefinition = useFragment(
		LEVEL_DEFINITION_FRAGMENT,
		levelDefinitionFragmentRef ?? null,
	);

	const [open, setOpen] = useState(false);

	const handleOnClick = () => {
		setOpen(true);
	};

	const handleDismiss = () => {
		setOpen(false);
	};

	const title = levelDefinition?.title;
	const subtitle = levelDefinition?.shortDescription;
	const text = levelDefinition?.text;
	const image = levelDefinition?.image?.url;

	return (
		<>
			<button type="button" onClick={handleOnClick} className={wrapperClass}>
				<div className={imagesWrapperClass}>
					<div
						className={imageWrapperClass({
							isUnlocked,
						})}
					>
						<Image
							src={image ?? ""}
							borderRadius="50%"
							widthRem={3.75}
							heightRem={3.75}
						/>
					</div>
					<div className={iconWrapperClass}>
						{isUnlocked ? <CheckBadgeSVG /> : <LockSVG />}
					</div>
				</div>
				<div className={titlesWrapperClass}>
					<H3Span className={colorPrimary100Class}>{title}</H3Span>
					<P3Span className={colorShade80Class}>{subtitle}</P3Span>
				</div>
			</button>
			{open && (
				<ResponsiveBottomSheetModal isVisible onDismiss={handleDismiss}>
					<button type="button" onClick={handleDismiss} className={modalCloseButtonClass}>
						<Icon icon="close" />
					</button>
					<div className={modalWrapperClass}>
						<div className={modalContentClass}>
							<div className={imagesWrapperClass}>
								<div
									className={imageWrapperClass({
										isUnlocked,
									})}
								>
									<Image
										src={image ?? ""}
										borderRadius="50%"
										widthRem={11.25}
										heightRem={11.25}
									/>
								</div>
								<div className={iconWrapperClass}>
									{isUnlocked ? <CheckBadgeSVG /> : <LockSVG />}
								</div>
							</div>
							<div className={titlesWrapperClass}>
								<H2Span className={colorPrimary100Class}>{title}</H2Span>
								{text && <P1Span className={colorShade80Class}>{text}</P1Span>}
							</div>
						</div>
					</div>
				</ResponsiveBottomSheetModal>
			)}
		</>
	);
};
