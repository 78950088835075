/**
 * @generated SignedSource<<c7615f7cea93f0a5f9b3d22ccb919430>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountHeader_QueryFragmentRefetch$variables = {
  filterByGroupIds?: ReadonlyArray<string> | null | undefined;
};
export type accountHeader_QueryFragmentRefetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"accountHeader_QueryFragment">;
};
export type accountHeader_QueryFragmentRefetch = {
  response: accountHeader_QueryFragmentRefetch$data;
  variables: accountHeader_QueryFragmentRefetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "filterByGroupIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filterByGroupIds",
    "variableName": "filterByGroupIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountHeader_QueryFragmentRefetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "accountHeader_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountHeader_QueryFragmentRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "accounts",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountGroupAssociation",
                        "kind": "LinkedField",
                        "name": "groupAssociations",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountGroup",
                            "kind": "LinkedField",
                            "name": "group",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementQueries",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AccountMembersResult",
            "kind": "LinkedField",
            "name": "AccountMembers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8f746a32bd601dde94f707bb07f022ec",
    "id": null,
    "metadata": {},
    "name": "accountHeader_QueryFragmentRefetch",
    "operationKind": "query",
    "text": "query accountHeader_QueryFragmentRefetch(\n  $filterByGroupIds: [ID!] = []\n) {\n  ...accountHeader_QueryFragment_1gDN6I\n}\n\nfragment accountHeader_QueryFragment_1gDN6I on Query {\n  Viewer {\n    Auth {\n      currentUser {\n        accounts {\n          id\n          name\n          groupAssociations {\n            group {\n              name\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n  AccountMemberManagement {\n    AccountMembers(filterByGroupIds: $filterByGroupIds) {\n      userCount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "128368b0bbc12035293abb4362ad9e3c";

export default node;
