/**
 * @generated SignedSource<<9d5456d6c095df4835dd670e0fcb29a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ItemKind = "BulkDiscount" | "Discount" | "GlobalDiscount" | "LimitedDiscount" | "Product";
export type ProductKind = "License";
import { FragmentRefs } from "relay-runtime";
export type userCart_UserCartFragment$data = {
  readonly cart: {
    readonly items: ReadonlyArray<{
      readonly amount?: number;
      readonly code?: string;
      readonly kind: ItemKind;
      readonly product?: {
        readonly data: {
          readonly licenseDefinition?: {
            readonly data: {
              readonly rootInfo?: {
                readonly image: {
                  readonly url: string | null | undefined;
                } | null | undefined;
                readonly rootId: string;
                readonly title: string;
              } | null | undefined;
            };
          } | null | undefined;
        };
        readonly id: string;
        readonly isTaxFree: boolean;
        readonly netPrice: number;
        readonly title: string;
      } | null | undefined;
      readonly title?: string;
      readonly validUntil?: string | null | undefined;
      readonly value?: number;
    }>;
    readonly otherAvailableProducts: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly data: {
            readonly kind: ProductKind;
            readonly licenseDefinition?: {
              readonly data: {
                readonly rootInfo?: {
                  readonly image: {
                    readonly url: string | null | undefined;
                  } | null | undefined;
                  readonly rootId: string;
                  readonly title: string;
                } | null | undefined;
              };
            } | null | undefined;
          };
          readonly id: string;
          readonly title: string;
        };
      } | null | undefined> | null | undefined;
    };
    readonly selection: {
      readonly selectedDiscountCodes: ReadonlyArray<string>;
      readonly selectedProducts: ReadonlyArray<{
        readonly amount: number;
        readonly product: {
          readonly data: {
            readonly licenseDefinition?: {
              readonly data: {
                readonly rootInfo?: {
                  readonly image: {
                    readonly url: string | null | undefined;
                  } | null | undefined;
                  readonly rootId: string;
                  readonly title: string;
                } | null | undefined;
              };
            } | null | undefined;
          };
          readonly id: string;
          readonly netPrice: number;
          readonly title: string;
        } | null | undefined;
      }>;
    };
    readonly totals: {
      readonly appliedDiscount: {
        readonly grossPrice: number;
      } | null | undefined;
      readonly appliedDiscountPercentage: number | null | undefined;
      readonly includingAllDiscounts: {
        readonly grossPrice: number;
        readonly netPrice: number;
      };
      readonly monthlyOptions: ReadonlyArray<{
        readonly monthlyRate: {
          readonly grossPrice: number;
        };
      }>;
      readonly withoutDiscounts: {
        readonly grossPrice: number;
        readonly netPrice: number;
        readonly taxRatePercentage: number;
      };
    };
  };
  readonly id: string;
  readonly orderId: string | null | undefined;
  readonly userId: string;
  readonly " $fragmentType": "userCart_UserCartFragment";
};
export type userCart_UserCartFragment$key = {
  readonly " $data"?: userCart_UserCartFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userCart_UserCartFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPrice",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LicenseDefinition",
      "kind": "LinkedField",
      "name": "licenseDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LicenseDefinitionRootInfo",
                  "kind": "LinkedField",
                  "name": "rootInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rootId",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "File",
                      "kind": "LinkedField",
                      "name": "image",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "url",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "TreeLicenseDefinitionData",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LicenseProductData",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v8 = [
  (v1/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grossPrice",
  "storageKey": null
},
v10 = [
  (v9/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userCart_UserCartFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Selection",
          "kind": "LinkedField",
          "name": "selection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SelectedProduct",
              "kind": "LinkedField",
              "name": "selectedProducts",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "selectedDiscountCodes",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            (v6/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": (v8/*: any*/),
              "type": "LimitedDiscountItem",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "validUntil",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v7/*: any*/)
              ],
              "type": "DiscountItem",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v8/*: any*/),
              "type": "GlobalDiscountItem",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isTaxFree",
                      "storageKey": null
                    },
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "type": "ProductItem",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Totals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "withoutDiscounts",
              "plural": false,
              "selections": [
                (v9/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "taxRatePercentage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "appliedDiscount",
              "plural": false,
              "selections": (v10/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "includingAllDiscounts",
              "plural": false,
              "selections": [
                (v9/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "appliedDiscountPercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "monthlyOptions",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "monthlyRate",
                  "plural": false,
                  "selections": (v10/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductsConnection",
          "kind": "LinkedField",
          "name": "otherAvailableProducts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "data",
                      "plural": false,
                      "selections": [
                        (v6/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserCart",
  "abstractKey": null
};
})();

(node as any).hash = "57ee2792010b233ebacb04b5ddb7f714";

export default node;
