import { Skeleton } from "@thekeytechnology/epic-ui";
import { daysWrapperClass, greenGradientClass, userDataClass } from "./learn-streak.styles";

export const LearnStreakSkeleton = () => {
	return (
		<div className={greenGradientClass}>
			<div className={userDataClass}>
				<Skeleton width="15.625rem" height="1.25rem" />
				<Skeleton width="16.875rem" height="1.25rem" />
			</div>
			<div className={daysWrapperClass}>
				{Array.from({ length: 7 }).map((_, index) => (
					<Skeleton key={index} width="2.5rem" height="2.5rem" />
				))}
			</div>
		</div>
	);
};
