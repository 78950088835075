import { graphql } from "react-relay";

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment offerCard_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		id
		description
		shortDescription
		image {
			url
		}
		structureDefinition {
			... on LearnOpportunityRootStructureDefinition {
				title
				progressPercentage
				viewerTreeState {
					kind
					... on IsStartedViewerTreeState {
						headContentId
					}
				}
				extension {
					... on PublishedRootExtensionImpl {
						reducedData {
							isIHK
						}
						tags {
							id
							kind
							data {
								name
								isClickable
								isTopic
							}
						}
						unlockInfo {
							kind
							... on UnlockInfoDemo {
								firstContentId
							}
						}
					}
				}
			}
		}
	}
`;
