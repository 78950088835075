/**
 * @generated SignedSource<<2904ed42a2b78697b294f5c9c5822f3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "enhancedText" | "file" | "markMistakes" | "matrix" | "multipleChoice" | "order" | "persolog" | "persologEvaluation" | "persologQuestion" | "podcast" | "podcastWithTimestamp" | "reminder" | "text" | "textWithPages" | "video";
import { FragmentRefs } from "relay-runtime";
export type noteSourceElementRenderer_ElementFragment$data = {
  readonly elementType: ElementTypeV2;
  readonly id: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"PodcastWithTimestamp_PodcastWithTimestampElementFragment" | "clozeTextLearnElement_ClozeTextLearnElementFragment" | "imageLearnElement_ImageLearnElementFragment" | "markMistakesLearnElement_MarkMistakesLearnElementFragment" | "matrixLearnElementFragment_MatrixLearnElementFragment" | "multipleChoiceLearnElement_MultipleChoiceLearnElementFragment" | "orderLearnElement_OrderLearnElementFragment" | "reminderLearnElement_ReminderLearnElementFragment" | "textLearnElement_EnhancedTextLearnElementFragment" | "textWithPagesLearnElement_TextWithPagesLearnElementFragment" | "videoLearnElement_VideoLearnElementFragment">;
  readonly " $fragmentType": "noteSourceElementRenderer_ElementFragment";
};
export type noteSourceElementRenderer_ElementFragment$key = {
  readonly " $data"?: noteSourceElementRenderer_ElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"noteSourceElementRenderer_ElementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "noteSourceElementRenderer_ElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "orderLearnElement_OrderLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "textLearnElement_EnhancedTextLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "textWithPagesLearnElement_TextWithPagesLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "reminderLearnElement_ReminderLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "imageLearnElement_ImageLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "videoLearnElement_VideoLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "clozeTextLearnElement_ClozeTextLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "matrixLearnElementFragment_MatrixLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "markMistakesLearnElement_MarkMistakesLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "multipleChoiceLearnElement_MultipleChoiceLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PodcastWithTimestamp_PodcastWithTimestampElementFragment"
    }
  ],
  "type": "LearnElement",
  "abstractKey": "__isLearnElement"
};

(node as any).hash = "c8e8c008c6ae10e0f32d9d224a6447c7";

export default node;
