import { css } from "@styled-system/css";

export const wrapperClass = css({
	width: "full",
	display: "flex",
	flexDirection: "column",
	gap: "24",
	marginTop: "24",
});

export const listWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "8",
});

export const listClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "8",
});

export const nextButtonWrapperClass = css({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "full",
	marginTop: "12",
});
