/**
 * @generated SignedSource<<3538f028e322572303ba7487a8779ae7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type inviteMemberModal_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"standardInviteForm_QueryFragment">;
  readonly " $fragmentType": "inviteMemberModal_QueryFragment";
};
export type inviteMemberModal_QueryFragment$key = {
  readonly " $data"?: inviteMemberModal_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"inviteMemberModal_QueryFragment">;
};

import inviteMemberModalRefetchQuery_graphql from './inviteMemberModalRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": inviteMemberModalRefetchQuery_graphql
    }
  },
  "name": "inviteMemberModal_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "standardInviteForm_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "6b5ea73d69766d274c522e3b0f90bcd3";

export default node;
