/**
 * @generated SignedSource<<7c213e5f9543e9f67d1e46cf7ed87b95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type MatrixElementCellKind = "Answer" | "Display";
import { FragmentRefs } from "relay-runtime";
export type matrixLearnElementFragment_MatrixLearnElementFragment$data = {
  readonly cells: ReadonlyArray<{
    readonly answers?: ReadonlyArray<{
      readonly content: string;
    }>;
    readonly content?: string;
    readonly kind?: MatrixElementCellKind;
    readonly xIdx: number;
    readonly yIdx: number;
  }>;
  readonly id: string;
  readonly title: string;
  readonly " $fragmentType": "matrixLearnElementFragment_MatrixLearnElementFragment";
};
export type matrixLearnElementFragment_MatrixLearnElementFragment$key = {
  readonly " $data"?: matrixLearnElementFragment_MatrixLearnElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"matrixLearnElementFragment_MatrixLearnElementFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "matrixLearnElementFragment_MatrixLearnElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "cells",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "xIdx",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "yIdx",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "type": "DisplayMatrixLearnElementCell",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MatrixLearnElementAnswer",
              "kind": "LinkedField",
              "name": "answers",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "type": "AnswerMatrixLearnElementCell",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MatrixLearnElement",
  "abstractKey": null
};
})();

(node as any).hash = "33dae4eb6d179f0595b09d75acd634a4";

export default node;
