import { css } from "@styled-system/css";

export const grid = css({
	display: "grid",
	gridTemplateColumns: "1",
	gap: "12",
	mt: "24",
	pb: "12",
	md: {
		gridTemplateColumns: "2",
	},
	lg: {
		gridTemplateColumns: "3",
	},
});

export const nextButtonWrapperClass = css({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "full",
	marginTop: "12",
});
