import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { selectIsLoggedIn } from "@thekeytechnology/academies-lib-webapp/slices";
import { PathParams } from "@thekeytechnology/epic-ui";
import { RadioButtonChangeEvent } from "primereact/radiobutton";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { gtmTrackBeginCheckout } from "@analytics/google-tag-manager";
import { BusinessClientForm } from "@components/business-client-form";
import { BusinessClientFormValues } from "@components/business-client-form/business-client-form.types";
import { InlineAuthGuard } from "@components/inline-auth-guard";
import { Message } from "@components/message";
import { PrivateClientForm } from "@components/private-client-form";
import { PrivateClientFormValues } from "@components/private-client-form/private-client-form.types";
import { RadioOption } from "@components/radio-option";
import { ShoppingCartTemplate } from "@components/shopping-cart-template";
import { ShoppingCartTemplateSkeleton } from "@components/shopping-cart-template/shopping-cart-template.skeleton";
import { shoppingCartInvoice_OrderFragment$key } from "@relay/shoppingCartInvoice_OrderFragment.graphql";
import { shoppingCartInvoice_Query } from "@relay/shoppingCartInvoice_Query.graphql";
import { shoppingCartInvoice_UpdateBusinessBillingDetailsMutation } from "@relay/shoppingCartInvoice_UpdateBusinessBillingDetailsMutation.graphql";
import { shoppingCartInvoice_UpdatePrivateBillingDetailsMutation } from "@relay/shoppingCartInvoice_UpdatePrivateBillingDetailsMutation.graphql";
import { Path, ShoppingCartPath } from "@router/paths";
import { H1Span, H2Span } from "@themes/font-tags";
import { convertToUTC } from "@utils/date-utils";
import {
	ORDER_FRAGMENT,
	QUERY,
	UPDATE_BUSINESS_BILLING_DETAILS_MUTATION,
	UPDATE_PRIVATE_BILLING_DETAILS_MUTATION,
} from "./shopping-cart-invoice.graphql";
import {
	clientTypeWrapperClass,
	dividerClass,
	headerWrapperClass,
	hideablePrivateWrapperClass,
	hideableWrapperClass,
	screenWrapperClass,
} from "./shopping-cart-invoice.styles";
import {
	getCartDiscountItems,
	getCartLimitedDiscountItems,
	getProductItems,
} from "./shopping-cart-invoice.utils";

//TODO: add-translations
export const ShoppingCartInvoiceScreenComponent = () => {
	const { shoppingCartId } = useParams<PathParams<typeof ShoppingCartPath>>();
	const isLoggedIn = useSelector(selectIsLoggedIn);

	const {
		node,
		Viewer: { Auth },
		AccountBaseData: AccountBaseDataOpt,
	} = useLazyLoadQuery<shoppingCartInvoice_Query>(
		QUERY,
		{
			orderId: shoppingCartId ?? "",
			skip: !shoppingCartId,
			isNotLoggedIn: !isLoggedIn,
		},
		{
			fetchPolicy: "network-only",
		},
	);

	const AccountBaseData = AccountBaseDataOpt?.AccountBaseData;

	const order = useFragment<shoppingCartInvoice_OrderFragment$key>(ORDER_FRAGMENT, node!);

	const [updatePrivateBillingDetails, updatingPrivateDetails] =
		useMutation<shoppingCartInvoice_UpdatePrivateBillingDetailsMutation>(
			UPDATE_PRIVATE_BILLING_DETAILS_MUTATION,
		);

	const [updateBusinessBillingDetails, updatingBusinessDetails] =
		useMutation<shoppingCartInvoice_UpdateBusinessBillingDetailsMutation>(
			UPDATE_BUSINESS_BILLING_DETAILS_MUTATION,
		);

	const navigate = useNavigate();

	const privateFormRef = useRef<PrivateClientForm>(null);
	const businessFormRef = useRef<BusinessClientForm>(null);
	const [isBusiness, setIsBusiness] = useState(
		order?.customerDetails?.data?.kind === "Business" ||
			AccountBaseData?.__typename === "BusinessBaseData",
	);

	const hanldeClientTypeOnChange = (event: RadioButtonChangeEvent) => {
		setIsBusiness(event.value === "business");
	};

	const handleNextOnClick = () => {
		if (isBusiness) {
			businessFormRef.current?.submit();
		} else {
			privateFormRef.current?.submit();
		}
	};

	const handlePreviousOnClick = () => {
		navigate(-1);
	};

	const handleOnCompleted = () => {
		shoppingCartId && navigate(Path.shoppingCart.withId(shoppingCartId).paymentMethod.path);
	};

	const handlePrivateOnSubmit = (values: PrivateClientFormValues) => {
		if (!shoppingCartId) return;
		const utcDate = convertToUTC(values.dateOfBirth);
		updatePrivateBillingDetails({
			variables: {
				input: {
					orderId: shoppingCartId,
					salutation: values.salutation,
					title: values.title,
					invoiceEmail: order?.customerDetails?.invoiceEmail ?? "",
					invoiceAddress: {
						firstName: values.firstName,
						lastName: values.lastName,
						street: values.street,
						houseNumber: values.houseNumber,
						postalCode: values.postCode,
						city: values.city,
						country: "Deutschland",
						companyName: order?.customerDetails?.data?.company ?? "",
						companyDetails: order?.customerDetails?.data?.companyDetails ?? "",
					},
					firstName: values.firstName,
					lastName: values.lastName,
					street: values.street,
					houseNumber: values.houseNumber,
					postalCode: values.postCode,
					city: values.city,
					country: order?.customerDetails?.country ?? "Deutschland",
					phoneNumber: values.phoneNumber,
					dateOfBirth: utcDate.toISOString(),
				},
			},
			onCompleted: handleOnCompleted,
		});
	};

	const handleBusinessOnSubmit = (values: BusinessClientFormValues) => {
		if (!shoppingCartId) return;
		updateBusinessBillingDetails({
			variables: {
				input: {
					orderId: shoppingCartId,
					company: values.company,
					companyDetails: values.recipient,
					invoiceEmail: values.email,
					street: values.street,
					houseNumber: values.houseNumber,
					postalCode: values.postCode,
					city: values.city,
					country: "Deutschland",
					phoneNumber: values.phoneNumber,
					companyType: values.businessType,
				},
			},
			onCompleted: handleOnCompleted,
		});
	};

	useEffect(() => {
		const cartDiscounts = getCartDiscountItems(order.cart) ?? [];
		const cartLimitedDiscounts = getCartLimitedDiscountItems(order.cart) ?? [];
		const discounts = [...cartLimitedDiscounts, ...cartDiscounts];

		const discountsString = discounts
			.map((discount) => {
				return `${discount.code}_${discount.value}%`;
			})
			.join(",");

		gtmTrackBeginCheckout({
			currency: "EUR",
			value: order.cart?.totals.includingAllDiscounts.grossPrice ?? 0,
			coupon: discountsString,
			items:
				getProductItems(order.cart)?.map((item) => {
					const rootInfo = item?.product?.data?.licenseDefinition?.data?.rootInfo;

					return {
						itemId: rootInfo?.rootId!,
						itemName: rootInfo?.title ?? "",
						itemCategory: "",
						price: item?.product?.netPrice ?? 0,
						quantity: item?.amount ?? 0,
					};
				}) ?? [],
		});
	}, [order.cart]);

	const disableNext = updatingPrivateDetails || updatingBusinessDetails || !isLoggedIn;

	return (
		<ShoppingCartTemplate
			disableNext={disableNext}
			onNext={handleNextOnClick}
			onPrevious={handlePreviousOnClick}
			authViewerSchemaFragmentRef={Auth}
			orderFragmentRef={order}
		>
			<div data-no-selection-menu className={screenWrapperClass}>
				<div className={headerWrapperClass}>
					<H1Span>Rechnungsdaten</H1Span>
					<Message
						highlighted
						severity="success"
						summary="Auslandsbestellungen"
						detail="Du möchtest außerhalb von Deutschland bestellen? Dann wende dich bitte an info@thekey.academy."
					/>
				</div>
				<InlineAuthGuard authViewerSchemaFragmentRef={Auth}>
					<div className={clientTypeWrapperClass}>
						<RadioOption
							value="private"
							checked={!isBusiness}
							label="Privatkunde"
							onChange={hanldeClientTypeOnChange}
						/>
						<RadioOption
							value="business"
							checked={isBusiness}
							label="Geschäftskunde"
							onChange={hanldeClientTypeOnChange}
						/>
					</div>
					<div className={dividerClass} />
					<div className={hideablePrivateWrapperClass({ hidden: isBusiness })}>
						{AccountBaseData && (
							<PrivateClientForm
								ref={privateFormRef}
								baseDataFragmentRef={AccountBaseData}
								onSubmit={handlePrivateOnSubmit}
							/>
						)}
					</div>
					<div className={hideableWrapperClass({ hidden: !isBusiness })}>
						<H2Span>Unternehmen</H2Span>
						{AccountBaseData && (
							<BusinessClientForm
								ref={businessFormRef}
								baseDataFragmentRef={AccountBaseData}
								onSubmit={handleBusinessOnSubmit}
							/>
						)}
					</div>
				</InlineAuthGuard>
			</div>
		</ShoppingCartTemplate>
	);
};

export const ShoppingCartInvoiceScreen = withSuspense(
	ShoppingCartInvoiceScreenComponent,
	ShoppingCartTemplateSkeleton,
);
