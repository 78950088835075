import { parseHtml } from "@thekeytechnology/academies-lib-webapp/utils/parse-html";
import { P2Span, H2Span } from "@themes/font-tags";
import { TextParagraphProps } from "./text-paragraph.interface";
import { headlineWrapperClass, textClass, textWrapperClass } from "./text-paragraph.styles";

export const TextParagraph = ({ title, html, headline, listIsCheckIcons }: TextParagraphProps) => {
	return (
		<div>
			{headline && (
				<div className={headlineWrapperClass}>
					<P2Span>{headline}</P2Span>
				</div>
			)}
			<H2Span>{title}</H2Span>
			<div className={textWrapperClass}>
				<P2Span
					className={textClass({
						listIsCheckIcons,
					})}
				>
					{parseHtml(html)}
				</P2Span>
			</div>
		</div>
	);
};
