import { useState, createContext, type PropsWithChildren, Dispatch, SetStateAction } from "react";

export type IReportModalFlowContext = {
	setReferenceNumber: Dispatch<SetStateAction<string | undefined>>;
	referenceNumber?: string;
};

export const ReportModalFlowContext = createContext<IReportModalFlowContext>({
	referenceNumber: "",
	setReferenceNumber: () => {},
});

export const ReportModalFlowContextProvider = (props: PropsWithChildren) => {
	const [referenceNumber, setReferenceNumber] = useState<string | undefined>(undefined);

	return (
		<ReportModalFlowContext.Provider
			value={{
				referenceNumber,
				setReferenceNumber,
			}}
		>
			{props.children}
		</ReportModalFlowContext.Provider>
	);
};
