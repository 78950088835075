import { css, cva } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "8",
	py: "4",
	justifyContent: "center",
	alignItems: "center",
	textAlign: "center",
	xl: {
		w: "[4.5rem]",
		h: "[4.5rem]",
	},
	_hover: {
		cursor: "pointer",
	},
});

export const linkTextClass = cva({
	variants: {
		isActive: {
			true: {
				fontWeight: "bold",
				color: "shade.100",
				lg: {
					color: "shade.0",
				},
			},
			false: {
				color: "shade.100",
				xl: {
					color: "shade.0",
				},
			},
		},
	},
});
