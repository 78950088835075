import { LabelSkeleton } from "@thekeytechnology/academies-lib-webapp/components/label";
import { labelWrapperClass, wrapperClass } from "./tags.styles";

export const TagsSkeleton = () => {
	return (
		<div className={wrapperClass({ isCollapsed: true })}>
			<div className={labelWrapperClass}>
				<LabelSkeleton size="big" />
			</div>
			<div className={labelWrapperClass}>
				<LabelSkeleton size="big" />
			</div>
			<div className={labelWrapperClass}>
				<LabelSkeleton size="big" />
			</div>
		</div>
	);
};
