import { Card } from "@thekeytechnology/academies-lib-webapp/components/card";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { H3Span, P2Span } from "@themes/font-tags";
import { useTheme } from "styled-components";
import { flexClass, flexColumnClass } from "./material-card.styles";
import { type MaterialCardProps } from "./material-card.types";
import { MaterialIcon } from "../material-icon";

export const MaterialCard = ({
	icon,
	title,
	description,
	disabled,
	isExpanded,
	bodyPadding,
	noBorder,
	fileUrl,
	fileName,
	onClick,
	deleteIcon,
	deleteOnClick,
}: MaterialCardProps) => {
	const theme = useTheme();

	const DownloadIcon =
		isExpanded &&
		(disabled ? (
			<Icon icon="lock" />
		) : (
			<MaterialIcon icon={"downloadBottom"} showBorder={false} hasCursorPointer={true} />
		));

	const Title = isExpanded ? P2Span : H3Span;
	const Description = isExpanded ? H3Span : P2Span;

	const handleOnClick = () => {
		onClick?.();
	};

	return (
		<Card
			showBorder={isExpanded && !noBorder}
			bodyPaddingInRem={bodyPadding ?? 0.75}
			onClick={!disabled ? handleOnClick : undefined}
			hasCursorPointer={!disabled && !!onClick}
		>
			<div className={flexClass} aria-disabled={disabled}>
				<MaterialIcon
					icon={icon}
					showBorder={!isExpanded}
					{...(isExpanded ? { backgroundColor: theme.colors.shade5 } : {})}
				/>
				<div className={flexColumnClass}>
					<Title>{title}</Title>
					<Description>{description}</Description>
				</div>
				{fileUrl && !disabled ? (
					<a
						href={fileUrl ?? ""}
						target="_blank"
						rel="noopener noreferrer"
						download={fileName}
					>
						{DownloadIcon}
					</a>
				) : deleteIcon ? (
					<div onClick={deleteOnClick}>
						<MaterialIcon showBorder={false} icon={"trash"} />
					</div>
				) : (
					DownloadIcon
				)}
			</div>
		</Card>
	);
};
