import { flex, grid, stack } from "@styled-system/patterns";

export const listWrapperClass = grid({
	gap: "8",
	cursor: "pointer",
});

export const mainWrapperClass = flex({
	alignItems: "center",
	gap: "8",
});

export const textWrapperClass = stack({
	gap: "0",
	flex: "[1 1 0%]",
	textAlign: "left",
});

export const iconWrapperClass = stack({
	gap: "0",
	alignItems: "center",
	paddingRight: "12",
});
