import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { match, P } from "ts-pattern";
import { ReactComponent as KeyMedalPointsBigSvg } from "@assets/key-medal-points-big.svg";
import { ReactComponent as BronzeMedal } from "@assets/medals/bronze.svg";
import { ReactComponent as GoldMedal } from "@assets/medals/gold.svg";
import { ReactComponent as SilverMedal } from "@assets/medals/silver.svg";
import { ReactComponent as WoodMedal } from "@assets/medals/wood.svg";
import { SideMenuContentPadding } from "@containers/side-menu-content-padding";
import { contentFinished_RestartContentNodeAfterFailedMutation } from "@relay/contentFinished_RestartContentNodeAfterFailedMutation.graphql";
import { contentFinished_RestartContentNodeAfterPassedMutation } from "@relay/contentFinished_RestartContentNodeAfterPassedMutation.graphql";
import { Path } from "@router/paths";
import { colorWarning100Class } from "@themes/color-classes";
import { H1Span, H2Span, H3Span, P2Span } from "@themes/font-tags";
import {
	CONTENT_SUBMISSION_FRAGMENT,
	RESTART_CONTENT_NODE_AFTER_FAILED_MUTATION,
	RESTART_CONTENT_NODE_AFTER_PASSED_MUTATION,
} from "./content-finished.graphql";
import {
	contentWrapperClass,
	headerSubtitleClass,
	headerTitleClass,
	headerWrapperClass,
	levelRewardProgressClass,
	levelRewardProgressWrapperClass,
	levelRewardProgressWrapperInnerClass,
	medalsPercentageCorrectClass,
	medalsWrapperClass,
	rewardListClass,
	wrapperClass,
} from "./content-finished.styles";
import { ContentFinishedProps } from "./content-finished.types";
import { VOCAB_CONTENT_NODE_ID } from "../../../../consts";
import { ContentFinishedConfetti } from "../content-finished-confetti";
import { ContentFinishedRewardItemComponent } from "../content-finished-reward-item";

//TODO: add-translations
export const ContentFinished = ({ contentSubmissionFragmentRef }: ContentFinishedProps) => {
	const [showConfetti, setShowConfetti] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);

	const contentSubmission = useFragment(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const [restartContentNodeAfterPassed] =
		useMutation<contentFinished_RestartContentNodeAfterPassedMutation>(
			RESTART_CONTENT_NODE_AFTER_PASSED_MUTATION,
		);

	const [restartContentNodeAfterFailed] =
		useMutation<contentFinished_RestartContentNodeAfterFailedMutation>(
			RESTART_CONTENT_NODE_AFTER_FAILED_MUTATION,
		);

	const navigate = useNavigate();

	const contentId = contentSubmission.learnOpportunity?.id;

	const isFinishedRootState =
		contentSubmission.learnOpportunity?.root?.structureDefinition.viewerTreeState?.kind ===
		"IsFinished";

	const rewards = contentSubmission.definition.rewardResults;
	const receivedPoints =
		rewards?.reduce((acc, cur) => {
			if (cur.kind === "GamificationPoints_PointsReceived") {
				return acc + (cur.points?.num ?? 0);
			}
			return acc;
		}, 0) ?? 0;

	const nextContentNode = contentSubmission.learnOpportunity?.nextContentNodeId;
	const hasNextContentNode = !!nextContentNode;
	const contentNodeStatus =
		contentSubmission.learnOpportunity?.typeDefinition.contentNodeAdvancementResult?.status;
	const canBeRestartedAfterFailed = contentNodeStatus === "CanBeRestartedAfterFailed";
	const canBeRestartedAfterPassed = contentNodeStatus === "CanBeRestartedAfterPassed";
	const canBeRestartet = canBeRestartedAfterFailed || canBeRestartedAfterPassed;
	const passed = contentSubmission.definition.status === "passed";
	const isRootFinish = !hasNextContentNode && isFinishedRootState;
	const percentageCorrect = contentSubmission.definition.relativeProgress?.percentageCorrect ?? 0;

	const receivedGamificationPoints =
		contentSubmission?.learnOpportunity?.typeDefinition.extension?.receivedGamificationPoints ??
		0;
	const receivableGamificationPoints =
		contentSubmission?.learnOpportunity?.typeDefinition.extension
			?.receivableGamificationPoints ?? 0;
	const progressInPercent = (receivedGamificationPoints / receivableGamificationPoints) * 100;

	const isVocabContent = contentSubmission.learnOpportunity?.id === VOCAB_CONTENT_NODE_ID;

	const medal = match(percentageCorrect)
		.with(P.number.gte(95), () => <GoldMedal />)
		.with(P.number.gte(75).and(P.number.lte(94)), () => <SilverMedal />)
		.with(P.number.gte(50).and(P.number.lte(74)), () => <BronzeMedal />)
		.otherwise(() => <WoodMedal />);
	const isWoodMedal = percentageCorrect <= 49;
	const needsRestartButton = isWoodMedal;

	const handleRepeatOnClick = () => {
		if (!contentId) return;
		if (canBeRestartedAfterFailed) {
			restartContentNodeAfterFailed({
				variables: {
					input: {
						contentNodeId: contentId,
					},
				},
				onCompleted: (response) => {
					const id = response.LearnV2.restartContentNodeAfterFailed?.contentSubmission.id;
					id && navigate(Path.contentSubmission.withId(id).path);
				},
			});
		} else if (canBeRestartedAfterPassed) {
			restartContentNodeAfterPassed({
				variables: {
					input: {
						contentNodeId: contentId,
					},
				},
				onCompleted: (response) => {
					const id = response.LearnV2.restartContentNodeAfterPassed?.contentSubmission.id;
					id && navigate(Path.contentSubmission.withId(id).path);
				},
			});
		}
	};

	const title = passed ? "Geschafft!" : "Fast geschaft ...";
	const repeatContentSubTitle = canBeRestartet
		? "Versuche es noch einmal, um die Lektion erfolgreich abzuschließen."
		: "Du konntest diese Lektion leider nicht erfolgreich abschließen.";
	const contentFinishedSubtitle = isVocabContent
		? "Du hast deine Lernsession erfolgreich abgeschlossen. Gratulation!"
		: passed
		? "Du hast diese Lektion erfolgreich abgeschlossen. Gratulation!"
		: repeatContentSubTitle;
	const subtitle = isRootFinish
		? "Du hast diesen Kurs erfolgreich abgeschlossen. Gratulation!"
		: contentFinishedSubtitle;

	useEffect(() => {
		if (passed && percentageCorrect >= 50) {
			setShowConfetti(true);

			return () => {
				setShowConfetti(false);
			};
		}
	}, [passed]);

	const levelRewardFragmentRef = rewards?.find(
		(reward) => reward.kind === "GamificationPoints_PointsReceived",
	);
	const certificateFragmentRef = rewards?.find(
		(reward) => reward.kind === "IHKCertificate_ReadyToOrder",
	);

	return (
		<SideMenuContentPadding className={wrapperClass} ref={wrapperRef}>
			{showConfetti && passed && (
				<ContentFinishedConfetti
					source={{
						x: wrapperRef.current?.offsetLeft ?? 0,
						y: wrapperRef.current?.offsetTop ?? 0,
						w: wrapperRef.current?.offsetWidth ?? 0,
						h: wrapperRef.current?.offsetHeight ?? 0,
					}}
					onComplete={(c) => {
						c?.stop();
						setShowConfetti(false);
					}}
				/>
			)}
			<div className={contentWrapperClass}>
				<div className={medalsWrapperClass}>
					{medal}
					<H3Span
						className={medalsPercentageCorrectClass({
							isWoodMedal,
						})}
					>
						{progressInPercent.toFixed(0)} %
					</H3Span>
				</div>
				<div className={headerWrapperClass}>
					<H1Span className={headerTitleClass}>{title}</H1Span>
					<P2Span className={headerSubtitleClass}>{subtitle}</P2Span>
				</div>

				{needsRestartButton && (
					<div>
						<Button
							colorVersion="tertiary"
							label="Lektion neu starten"
							onClick={handleRepeatOnClick}
						/>
					</div>
				)}

				<div className={rewardListClass}>
					{receivedPoints > 0 && (
						<ContentFinishedRewardItemComponent
							title="Schlüssel"
							iconRenderer={<KeyMedalPointsBigSvg />}
							value={receivedGamificationPoints.toString()}
							valueExtensionElement={
								<div className={levelRewardProgressClass}>
									<div className={levelRewardProgressWrapperClass}>
										<motion.div
											className={levelRewardProgressWrapperInnerClass}
											animate={{
												width: `${progressInPercent}%`,
											}}
											transition={{
												duration: 0.5,
												ease: "easeInOut",
											}}
										/>
									</div>
									<H2Span className={colorWarning100Class}>
										+{receivedPoints}
									</H2Span>
								</div>
							}
							rewardFragmentRef={levelRewardFragmentRef}
						/>
					)}
					{certificateFragmentRef && (
						<ContentFinishedRewardItemComponent
							title="Erhalten"
							icon="certifiedCertificate"
							iconVariant="green"
							value="IHK Zertifikat"
							rewardFragmentRef={certificateFragmentRef}
						/>
					)}
				</div>
			</div>
		</SideMenuContentPadding>
	);
};
