import { BottomNavigationTemplateSkeleton } from "@components/bottom-navigation-template/bottom-navigation-template.skeleton";
import { ScreenWithNavbarAndBottomContentLayoutSkeleton } from "@layouts/screen-with-navbar-and-bottom-content-layout/screen-with-navbar-and-bottom-content-layout.skeleton";
import { H1Span } from "@themes/font-tags";
import { contentWrapperClass, header } from "./notes.styles";

export const NotesScreenSkeleton = () => {
	return (
		<ScreenWithNavbarAndBottomContentLayoutSkeleton
			bottomContent={<BottomNavigationTemplateSkeleton />}
		>
			<div className={contentWrapperClass}>
				<div className={header} data-no-selection-menu>
					<H1Span>Notizen & Erinnerungen</H1Span>
				</div>
			</div>
		</ScreenWithNavbarAndBottomContentLayoutSkeleton>
	);
};
