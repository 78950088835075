import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.spacing32};
`;

export const TextCenter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: ${({ theme }) => theme.spacing.spacing32};
`;
