/**
 * @generated SignedSource<<226464b9bb59198c80e6cc3a0d4fa96d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AsyncElementKind = "Upload";
export type AsyncElementTaskStatus = "Evaluated" | "Fresh";
export type UnlockInfoKind = "Demo" | "ErrorOccurred" | "FullAccess" | "WallNotFound";
export type ViewerTreeStateKind = "CanBeStarted" | "CanNotBeStarted" | "IsFinished" | "IsStarted" | "NotVisible";
import { FragmentRefs } from "relay-runtime";
export type asyncContentOverview_LearnOpportunityV2Fragment$data = {
  readonly attachmentsV2: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly indexPath: ReadonlyArray<number>;
  readonly root: {
    readonly structureDefinition: {
      readonly extension?: {
        readonly unlockInfo?: {
          readonly firstContentId?: string;
          readonly kind: UnlockInfoKind;
        };
      };
      readonly viewerTreeState?: {
        readonly headContentId?: string;
        readonly kind: ViewerTreeStateKind;
      };
    };
    readonly typeDefinition: {
      readonly children?: ReadonlyArray<{
        readonly typeDefinition: {
          readonly children?: ReadonlyArray<{
            readonly id: string;
            readonly indexPath: ReadonlyArray<number>;
          }>;
        };
      }>;
    };
  } | null | undefined;
  readonly typeDefinition: {
    readonly task?: {
      readonly elementKind: AsyncElementKind;
      readonly status: AsyncElementTaskStatus;
      readonly " $fragmentSpreads": FragmentRefs<"UploadAsyncContentOverview_AsyncElementTaskFragment">;
    } | null | undefined;
    readonly taskDescription?: string | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"attachmentsCollection_AttachmentsCollectionFragment">;
  readonly " $fragmentType": "asyncContentOverview_LearnOpportunityV2Fragment";
};
export type asyncContentOverview_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: asyncContentOverview_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"asyncContentOverview_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "indexPath",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "asyncContentOverview_LearnOpportunityV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "task",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "elementKind",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UploadAsyncContentOverview_AsyncElementTaskFragment"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taskDescription",
              "storageKey": null
            }
          ],
          "type": "LearnAsyncContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "attachmentsV2",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "structureDefinition",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "viewerTreeState",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "headContentId",
                          "storageKey": null
                        }
                      ],
                      "type": "IsStartedViewerTreeState",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "extension",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "unlockInfo",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "firstContentId",
                                  "storageKey": null
                                }
                              ],
                              "type": "UnlockInfoDemo",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "PublishedRootExtensionImpl",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityRootStructureDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "typeDefinition",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LearnOpportunityV2",
                  "kind": "LinkedField",
                  "name": "children",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "typeDefinition",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "LearnOpportunityV2",
                              "kind": "LinkedField",
                              "name": "children",
                              "plural": true,
                              "selections": [
                                (v0/*: any*/),
                                (v1/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "LearnOpportunityBranchTypeDefinition",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityBranchTypeDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "attachmentsCollection_AttachmentsCollectionFragment"
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "204b43d9a2ddee455feedd4232138e1a";

export default node;
