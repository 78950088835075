/**
 * @generated SignedSource<<64a2ad79790c7364e9e5af5ad0f8ebef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountHeader_QueryFragment$data = {
  readonly AccountMemberManagement: {
    readonly AccountMembers: {
      readonly userCount: any;
    };
  };
  readonly Viewer: {
    readonly Auth: {
      readonly currentUser: {
        readonly accounts: ReadonlyArray<{
          readonly groupAssociations: ReadonlyArray<{
            readonly group: {
              readonly name: string;
            } | null | undefined;
          }>;
          readonly id: string;
          readonly name: string;
        }>;
      } | null | undefined;
    };
  };
  readonly " $fragmentType": "accountHeader_QueryFragment";
};
export type accountHeader_QueryFragment$key = {
  readonly " $data"?: accountHeader_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"accountHeader_QueryFragment">;
};

import accountHeader_QueryFragmentRefetch_graphql from './accountHeader_QueryFragmentRefetch.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "filterByGroupIds"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": accountHeader_QueryFragmentRefetch_graphql
    }
  },
  "name": "accountHeader_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerQueryType",
      "kind": "LinkedField",
      "name": "Viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuthViewerSchema",
          "kind": "LinkedField",
          "name": "Auth",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CurrentUser",
              "kind": "LinkedField",
              "name": "currentUser",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Account",
                  "kind": "LinkedField",
                  "name": "accounts",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AccountGroupAssociation",
                      "kind": "LinkedField",
                      "name": "groupAssociations",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AccountGroup",
                          "kind": "LinkedField",
                          "name": "group",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountMemberManagementQueries",
      "kind": "LinkedField",
      "name": "AccountMemberManagement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filterByGroupIds",
              "variableName": "filterByGroupIds"
            }
          ],
          "concreteType": "AccountMembersResult",
          "kind": "LinkedField",
          "name": "AccountMembers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "128368b0bbc12035293abb4362ad9e3c";

export default node;
