import { OfferCardSkeleton } from "@thekeytechnology/academies-lib-webapp/components/offer-card";
import { gridClass, gridItemClass, wrapperClass } from "./grid-view.styles";
import { TagsSkeleton } from "../tags/tags.skeleton";

export const GridViewSkeleton = () => {
	return (
		<div className={wrapperClass}>
			<TagsSkeleton />
			<div className={gridClass}>
				<div className={gridItemClass}>
					<OfferCardSkeleton />
				</div>
				<div className={gridItemClass}>
					<OfferCardSkeleton />
				</div>
				<div className={gridItemClass}>
					<OfferCardSkeleton />
				</div>
				<div className={gridItemClass}>
					<OfferCardSkeleton />
				</div>
			</div>
		</div>
	);
};
