import { graphql } from "react-relay";

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment contentCard_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		id
		image {
			url
		}
		shortDescription
		structureDefinition {
			title
		}
		indexPath
		lengthInSecondsV2
		typeDefinition {
			... on LearnAsyncContentTypeDefinition {
				contentKind
				extension {
					... on LearnContentExtensionImpl {
						receivableGamificationPoints
						receivedGamificationPoints
						isUnlockNecessaryToStart
					}
				}
				task {
					status
				}
			}
			... on LearnOpportunityELearningContentTypeDefinition {
				contentKind
				extension {
					... on LearnContentExtensionImpl {
						receivableGamificationPoints
						receivedGamificationPoints
						isUnlockNecessaryToStart
					}
				}
			}
		}
	}
`;
