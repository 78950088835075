import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { formatCurrency } from "@thekeytechnology/academies-lib-webapp/utils";
import { InputText, InputTextStatus, PathParams } from "@thekeytechnology/epic-ui";
import { useEffect, useState } from "react";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import {
	gtmTrackAddToCart,
	gtmTrackRemoveFromCart,
	gtmTrackViewCart,
} from "@analytics/google-tag-manager";
import { ControlButton } from "@components/control-button";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { RootCard } from "@components/root-card";
import {
	ShoppingCartTemplate,
	ShoppingCartTemplateSkeleton,
} from "@components/shopping-cart-template";
import { shoppingCartProducts_OrderFragment$key } from "@relay/shoppingCartProducts_OrderFragment.graphql";
import { shoppingCartProducts_Query } from "@relay/shoppingCartProducts_Query.graphql";
import {
	SelectionInput,
	shoppingCartProducts_UpdateCartMutation,
} from "@relay/shoppingCartProducts_UpdateCartMutation.graphql";
import { Path, ShoppingCartPath } from "@router/paths";
import { colorShade80Class, colorSuccess100Class } from "@themes/color-classes";
import { shade20 } from "@themes/colors";
import { H1Span, H2Span, P1Span, P2Span, P3Span } from "@themes/font-tags";
import { formatDateTime } from "@utils/date-utils";
import { ORDER_FRAGMENT, QUERY, UPDATE_CART_MUTATION } from "./shopping-cart-products.graphql";
import {
	controlsPriceWrapperClass,
	countControlsClass,
	discountCodeWrapperClass,
	discountPercentWrapperClass,
	discountTitleTagWrapperClass,
	discountTitleWrapperClass,
	discountWrapperClass,
	dividerClass,
	headerWrapperClass,
	itemPriceInnerWrapperClass,
	itemPriceWrapperClass,
	moreProductsWrapperClass,
	priceLeftGroupWrapperClass,
	priceMonthlyWrapperClass,
	priceRightGroupWrapperClass,
	priceRowsWrapperClass,
	priceWrapperClass,
	productCardWrapperClass,
	productItemClass,
	productsListClass,
	screenWrapperClass,
} from "./shopping-cart-products.styles";
import {
	cloneCartSelection,
	getBulkDiscountItems,
	getCartDiscountItems,
	getCartLimitedDiscountItems,
	getDiscountString,
	getGlobalDiscountItems,
	getProductItems,
} from "./shopping-cart-products.utils";

//TODO: add-translations
export const ShoppingCartProductsScreenComponent = () => {
	const { shoppingCartId } = useParams<PathParams<typeof ShoppingCartPath>>();

	const {
		node,
		Viewer: { Auth },
	} = useLazyLoadQuery<shoppingCartProducts_Query>(QUERY, {
		orderId: shoppingCartId ?? "",
		skip: !shoppingCartId,
	});

	const order = useFragment<shoppingCartProducts_OrderFragment$key>(ORDER_FRAGMENT, node!);

	const [updateCart] = useMutation<shoppingCartProducts_UpdateCartMutation>(UPDATE_CART_MUTATION);
	const navigate = useNavigate();

	const [discountCodeInput, setDiscountCodeInput] = useState("");
	const [hasDiscountCodeError, setHasDiscountCodeError] = useState(false);

	const cart = order?.cart;
	const products = getProductItems(cart);
	const cartDiscounts = getCartDiscountItems(cart) ?? [];
	const bulkDiscounts = getBulkDiscountItems(cart) ?? [];
	const globalDiscounts = getGlobalDiscountItems(cart) ?? [];
	const cartLimitedDiscounts = getCartLimitedDiscountItems(cart) ?? [];
	const discounts = [...cartLimitedDiscounts, ...cartDiscounts];
	const moreProducts = cart?.otherAvailableProducts.edges ?? [];

	const hasDiscounts =
		discounts.length > 0 || bulkDiscounts.length > 0 || globalDiscounts.length > 0;
	const hasProducts = products?.length != 0;
	const hasMoreProducts = moreProducts.length > 0;
	const hasProductWithTaxes = products && products.some((product) => !product.product?.isTaxFree);

	const taxes = order?.cart?.totals?.withoutDiscounts
		? order.cart.totals.withoutDiscounts.grossPrice -
		  order.cart.totals.withoutDiscounts.netPrice
		: undefined;

	const handleGoToOffers = () => {
		navigate(Path.progression.path);
	};

	const handleUpdateCart = (selection: SelectionInput) => {
		updateCart({
			variables: {
				input: {
					orderId: order.id,
					selection,
				},
			},
			onCompleted: (response) => {
				const hasAllDiscountCodes = selection.selectedDiscountCodes.every(
					(code) =>
						response.Billing.updateCart?.order.cart?.items.find(
							(item) => item?.kind === "Discount" && item.code === code,
						) !== undefined,
				);
				setHasDiscountCodeError(!hasAllDiscountCodes);
			},
		});
	};

	const createIncreaseProductHandler = (productId?: string) => () => {
		if (!productId) return;
		const cart = cloneCartSelection(order?.cart);
		cart.selectedProducts.forEach((item) => {
			if (item.productId === productId) {
				item.amount++;
			}
		});

		const product = order?.cart?.selection.selectedProducts.find(
			(i) => i.product?.id === productId,
		);
		if (product) {
			const rootInfo = product.product?.data.licenseDefinition?.data.rootInfo;
			gtmTrackAddToCart({
				currency: "EUR",
				value: product.product?.netPrice ?? 0,
				items: [
					{
						itemId: rootInfo?.rootId!,
						itemName: rootInfo?.title ?? "",
						itemCategory: "",
						price: product.product?.netPrice ?? 0,
						quantity: 1,
					},
				],
			});
		}

		handleUpdateCart(cart);
	};

	const createDecreaseProductHandler = (productId?: string) => () => {
		if (!productId) return;
		const cart = cloneCartSelection(order?.cart);
		cart.selectedProducts.forEach((item) => {
			if (item.productId === productId) {
				item.amount--;
			}
		});

		const product = order?.cart?.selection.selectedProducts.find(
			(i) => i.product?.id === productId,
		);
		if (product) {
			const rootInfo = product.product?.data.licenseDefinition?.data.rootInfo;

			gtmTrackRemoveFromCart({
				currency: "EUR",
				value: product.product?.netPrice ?? 0,
				items: [
					{
						itemId: rootInfo?.rootId!,
						itemName: rootInfo?.title ?? "",
						itemCategory: "",
						price: product.product?.netPrice ?? 0,
						quantity: 1,
					},
				],
			});
		}

		handleUpdateCart(cart);
	};

	const handleDiscountCodeOnChange = (text?: string) => {
		setDiscountCodeInput(text ?? "");
		setHasDiscountCodeError(false);
	};

	const handleSubmitDiscountCodeOnClick = () => {
		const cart = cloneCartSelection(order?.cart);
		cart.selectedDiscountCodes = [
			...cart.selectedDiscountCodes.filter((code) => code !== discountCodeInput),
			discountCodeInput,
		];
		handleUpdateCart(cart);
		setDiscountCodeInput("");
	};

	const createAddProductOnClickHandler = (productId?: string) => () => {
		if (!productId) return;
		const cart = cloneCartSelection(order?.cart);
		cart.selectedProducts.push({
			amount: 1,
			productId,
		});

		const product = order?.cart?.otherAvailableProducts?.edges?.find(
			(i) => i?.node?.id === productId,
		);
		if (product) {
			const rootInfo = product.node?.data.licenseDefinition?.data.rootInfo;
			gtmTrackAddToCart({
				currency: "EUR",
				value: product.node?.netPrice ?? 0,
				items: [
					{
						itemId: rootInfo?.rootId!,
						itemName: rootInfo?.title ?? "",
						itemCategory: "",
						price: product.node?.netPrice ?? 0,
						quantity: 1,
					},
				],
			});
		}

		handleUpdateCart(cart);
	};

	const createRemoveProductOnClickHandler = (productId?: string) => () => {
		if (!productId) return;
		const cart = cloneCartSelection(order?.cart);
		const amount = cart.selectedProducts.find((i) => i.productId === productId)?.amount ?? 1;
		cart.selectedProducts = cart.selectedProducts.filter(
			(item) => item.productId !== productId,
		);

		const product = order?.cart?.items.find((i) => i.product?.id === productId);
		if (product) {
			const rootInfo = product.product?.data.licenseDefinition?.data.rootInfo;

			gtmTrackRemoveFromCart({
				currency: "EUR",
				value: (product.product?.netPrice ?? 0) * amount,
				items: [
					{
						itemId: rootInfo?.rootId!,
						itemName: rootInfo?.title ?? "",
						itemCategory: "",
						price: product.product?.netPrice ?? 0,
						quantity: amount,
					},
				],
			});
		}

		handleUpdateCart(cart);
	};

	const createDeleteDiscountCodeOnClickHandler = (discountCode: string) => () => {
		const cart = cloneCartSelection(order?.cart);
		cart.selectedDiscountCodes = cart.selectedDiscountCodes.filter(
			(item) => item !== discountCode,
		);
		handleUpdateCart(cart);
	};

	const handleNextOnClick = () => {
		navigate(Path.shoppingCart.withId(order.id).invoice.path);
	};

	const getRemoveControlDisabled = (amount?: number) => (amount ?? 1) <= 1;

	const trackViewCart = () => {
		const cartDiscounts = getCartDiscountItems(order.cart) ?? [];
		const cartLimitedDiscounts = getCartLimitedDiscountItems(order.cart) ?? [];
		const discounts = [...cartLimitedDiscounts, ...cartDiscounts];
		const discountsString = getDiscountString(discounts);

		gtmTrackViewCart({
			currency: "EUR",
			value: order.cart?.totals.includingAllDiscounts.grossPrice ?? 0,
			coupon: discountsString,
			items:
				getProductItems(order.cart)?.map((item) => {
					const rootInfo = item?.product?.data?.licenseDefinition?.data?.rootInfo;

					return {
						itemId: rootInfo?.rootId!,
						itemName: rootInfo?.title ?? "",
						itemCategory: "",
						price: item?.product?.netPrice ?? 0,
						quantity: item?.amount ?? 0,
					};
				}) ?? [],
		});
	};

	useEffect(() => {
		if (!order.cart) return;
		trackViewCart();
	}, [order.cart]);

	const discountGross = order.cart?.totals.appliedDiscount?.grossPrice;
	const smallestMonthlyRate = order.cart?.totals.monthlyOptions.length
		? order.cart?.totals.monthlyOptions
				.slice()
				.sort((a, b) => a.monthlyRate.grossPrice - b.monthlyRate.grossPrice)[0].monthlyRate
				.grossPrice
		: undefined;

	const discountCodeInputStatus = hasDiscountCodeError ? InputTextStatus.Error : undefined;
	const discountCodeError = hasDiscountCodeError ? "Rabattcode nicht gültig" : undefined;

	return (
		<ShoppingCartTemplate
			onNext={handleNextOnClick}
			disableNext={!hasProducts}
			authViewerSchemaFragmentRef={Auth}
			orderFragmentRef={order}
		>
			<div data-no-selection-menu className={screenWrapperClass}>
				<div className={headerWrapperClass}>
					<H1Span>Warenkorb</H1Span>
					{hasProducts && (
						<P2Span>
							Wähle weitere Produkte aus oder füge einen Rabatt-Code hinzu.
						</P2Span>
					)}
				</div>
				<EmptyPlaceholder
					isVisible={!hasProducts}
					iconName="shoppingBasketSad1"
					title="Hier sieht es aber leer aus ..."
					subtitle="Schau dich doch bei unseren Kursen um und finde das passende Angebot!"
					buttonLabel={"Zu den Kursen"}
					onClick={handleGoToOffers}
				>
					{products?.map(({ product, amount }) => {
						return (
							<div key={product?.id} className={productItemClass}>
								<div className={productCardWrapperClass}>
									<RootCard
										title={product?.title ?? ""}
										imageUrl={
											product?.data?.licenseDefinition?.data.rootInfo?.image
												?.url
										}
									/>
								</div>
								<div className={controlsPriceWrapperClass}>
									<div className={countControlsClass}>
										<ControlButton
											iconName="remove"
											disabled={getRemoveControlDisabled(amount)}
											onClick={createDecreaseProductHandler(product?.id)}
										/>
										<H2Span>{amount}</H2Span>
										<ControlButton
											iconName="add"
											onClick={createIncreaseProductHandler(product?.id)}
										/>
									</div>
									<div className={itemPriceWrapperClass}>
										<Icon
											color={shade20}
											icon="trash"
											onClick={createRemoveProductOnClickHandler(product?.id)}
										/>
										<div className={itemPriceInnerWrapperClass}>
											<H2Span>{formatCurrency(product?.netPrice)}</H2Span>
											{product?.isTaxFree && (
												<P3Span className={colorShade80Class}>
													(MwSt. befreit)
												</P3Span>
											)}
										</div>
									</div>
								</div>
							</div>
						);
					})}

					<div className={discountCodeWrapperClass}>
						<InputText
							icon="discountBubble1"
							placeholder="Rabattcode"
							value={discountCodeInput}
							onChange={handleDiscountCodeOnChange}
							status={discountCodeInputStatus}
							bottomLabel={discountCodeError}
						/>
						<Button
							disabled={discountCodeInput.length === 0}
							label="Bestätigen"
							colorVersion="tertiary"
							onClick={handleSubmitDiscountCodeOnClick}
						/>
					</div>

					{hasDiscounts && <div className={dividerClass} />}
					{globalDiscounts.map((globalDiscount) => (
						<div key={globalDiscount.title} className={discountWrapperClass}>
							<div className={discountTitleTagWrapperClass}>
								<div className={discountTitleWrapperClass}>
									<H2Span>{globalDiscount.title ?? "Rabatt"}</H2Span>
								</div>
							</div>
							<div className={discountPercentWrapperClass}>
								<H2Span>-{globalDiscount.value}%</H2Span>
							</div>
						</div>
					))}
					{bulkDiscounts.map((bulkDiscount) => (
						<div key={bulkDiscount.discountPercentage} className={discountWrapperClass}>
							<div className={discountTitleTagWrapperClass}>
								<div className={discountTitleWrapperClass}>
									<H2Span>Mengenrabatt</H2Span>
								</div>
							</div>
							<div className={discountPercentWrapperClass}>
								<H2Span>-{bulkDiscount.discountPercentage}%</H2Span>
							</div>
						</div>
					))}
					{discounts?.map((discount) => (
						<div key={discount.code} className={discountWrapperClass}>
							<div className={discountTitleTagWrapperClass}>
								{discount.validUntil && (
									<Label
										label={`Noch bis ${formatDateTime(discount.validUntil)}`}
										severity="info"
									/>
								)}
								<div className={discountTitleWrapperClass}>
									<H2Span>{discount.title ?? "Rabatt"}</H2Span>
									<P1Span>{discount.code ?? "Rabatt Aktion"}</P1Span>
								</div>
							</div>
							<div className={discountPercentWrapperClass}>
								{discount.kind === "Discount" && discount.code && (
									<Icon
										color={shade20}
										icon="trash"
										onClick={createDeleteDiscountCodeOnClickHandler(
											discount.code,
										)}
									/>
								)}
								<H2Span>-{discount.value}%</H2Span>
							</div>
						</div>
					))}
					<div className={dividerClass} />
					<div className={priceWrapperClass}>
						<div className={priceRowsWrapperClass}>
							<div className={priceLeftGroupWrapperClass}>
								<P1Span>Zwischensumme</P1Span>
								{hasProductWithTaxes && (
									<P1Span>
										Mehrwertsteuer{" "}
										{order.cart?.totals?.withoutDiscounts.taxRatePercentage}%
									</P1Span>
								)}
								{discountGross !== undefined && discountGross > 0 && (
									<P1Span>
										Totaler Rabatt -
										{order.cart?.totals.appliedDiscountPercentage}%
									</P1Span>
								)}
								<H2Span>Gesamtpreis</H2Span>
							</div>
							<div className={priceRightGroupWrapperClass}>
								<P1Span>
									{formatCurrency(order.cart?.totals.withoutDiscounts.netPrice)}
								</P1Span>
								{hasProductWithTaxes && <P1Span>{formatCurrency(taxes)}</P1Span>}
								{discountGross !== undefined && discountGross > 0 && (
									<P1Span className={colorSuccess100Class}>
										-&nbsp;
										{formatCurrency(discountGross)}
									</P1Span>
								)}
								<H2Span>
									{formatCurrency(
										order.cart?.totals.includingAllDiscounts.grossPrice,
									)}
								</H2Span>
							</div>
						</div>
						<div className={priceMonthlyWrapperClass}>
							<P1Span>
								Oder monatlich <wbr />
								<strong>ab {formatCurrency(smallestMonthlyRate)}</strong>
							</P1Span>
						</div>
					</div>
				</EmptyPlaceholder>
				{hasMoreProducts && (
					<>
						<div className={dividerClass} />
						<div className={moreProductsWrapperClass}>
							<H2Span>Weitere Produkte</H2Span>
							<div className={productsListClass}>
								{moreProducts.map((item, index) => {
									return (
										<RootCard
											key={`${item?.node.id}_${index}`}
											title={item?.node.title ?? ""}
											imageUrl={
												item?.node?.data?.licenseDefinition?.data.rootInfo
													?.image?.url
											}
											onClick={createAddProductOnClickHandler(item?.node.id)}
										/>
									);
								})}
							</div>
						</div>
					</>
				)}
			</div>
		</ShoppingCartTemplate>
	);
};

export const ShoppingCartProductsScreen = withSuspense(
	ShoppingCartProductsScreenComponent,
	ShoppingCartTemplateSkeleton,
);
