export const CONFETTI_COLORS = [
	"#FFF48C",
	"#FF8A14",
	"#BF8DF2",
	"#FF6196",
	"#4AEFF7",
	"#FF6242",
	"#00DBA8",
];

export const CONFETTI_NUMBER_OF_PIECES = 300;
