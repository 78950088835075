import { css, cva } from "@styled-system/css";

export const permanentActionsButtonClass = css({
	display: "flex",
	alignItems: "center",
	gap: "8",
});

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	paddingTop: "20",
	paddingBottom: "16",
	md: {
		paddingTop: "0",
		paddingBottom: "0",
	},
});

export const optionsWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "8",
});

export const itemWrapperClass = cva({
	base: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: "12",
		py: "8",
		px: "16",
		_hover: {
			opacity: 0.6,
			cursor: "pointer",
		},
		md: {
			gap: "8",
		},
	},
	variants: {
		dangerous: {
			true: {
				color: "error.100",
			},
		},
	},
	defaultVariants: {
		dangerous: false,
	},
});
