import {
	Checkbox,
	CheckboxStatus,
} from "@thekeytechnology/academies-lib-webapp/components/checkbox";
import { DialogTemplate } from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import { Divider } from "@thekeytechnology/academies-lib-webapp/components/divider";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { ValidatedDropdown } from "@thekeytechnology/academies-lib-webapp/components/validated-dropdown";
import { ValidatedInputText } from "@thekeytechnology/academies-lib-webapp/components/validated-input-text";
import { useFormik } from "formik";
import { Suspense, useContext, useMemo } from "react";
import { useMutation } from "react-relay";
import * as Yup from "yup";
import { type createReportModal_createReportMutation } from "@relay/createReportModal_createReportMutation.graphql";
import { cx } from "@styled-system/css";
import { colorError100Class, colorShade100Class, colorShade80Class } from "@themes/color-classes";
import { success100 } from "@themes/colors";
import { H1Span, H2Span, P2Anchor, P2Span, P3Span } from "@themes/font-tags";
import { CREATE_REPORT_MUTATION } from "./create-report-modal.graphql";
import {
	centerWrapperClass,
	checkboxWrapperClass,
	hrClass,
	iconWrapperClass,
	iconWrapperWrapperClass,
	inputsWrapperClass,
	rowSpan12Class,
	rowSpan6Class,
	titleWrapperClass,
	wrapperClass,
} from "./create-report-modal.styles";
import {
	type CreateReportModalFormState,
	type CreateReportModalProps,
} from "./create-report-modal.types";
import { getProblemTypeOptions, getReportTypeOptions } from "./create-report-modal.utils";
import { DATA_PROTECTION_URL, DSA_REPORT_EMAIL_FALLBACK } from "../../../../consts";
import { ReportModalFlowContext } from "../../report-modal-flow.context";
import { SystemEmail } from "../system-email";

export const CreateReportModal = ({ isVisible, onDismiss, onSuccess }: CreateReportModalProps) => {
	const [createReport, isCreating] =
		useMutation<createReportModal_createReportMutation>(CREATE_REPORT_MUTATION);
	const { setReferenceNumber } = useContext(ReportModalFlowContext);

	const handleOnDismiss = () => {
		setReferenceNumber("");
		onDismiss?.();
	};

	const form = useFormik<Partial<CreateReportModalFormState>>({
		initialValues: {
			firstName: undefined,
			lastName: undefined,
			email: undefined,
			url: window.location.href,
			extra: undefined,
			reportType: "Feedback",
			problemType: "IllegalContents",
			isChecked: false,
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			reportType: Yup.string().required("Bitte einen Meldungs-Typ auswählen."),
			problemType: Yup.string().required("Bitte einen Problem-Typ auswählen."),
			email: Yup.string().email("Bitte nutze ein valide E-Mail-Adresse."),
			isChecked: Yup.boolean()
				.test(
					"report-modal-isCecked",
					"Die AGBs müssen akzeptiert werden.",
					(value, context) => {
						if (form.touched.isChecked && value === false) return context.createError();
						return true;
					},
				)
				.required(),
		}),
		onSubmit: (values, formikHelpers) => {
			if (!values.isChecked) {
				formikHelpers.setFieldError("isChecked", "Du musst die AGB akzeptieren.");
				return;
			}
			createReport({
				variables: {
					input: {
						data: {
							url: values.url,
							firstName: values.firstName,
							lastName: values.lastName,
							extra: values.extra,
							email: values.email,
							reportType: values.reportType!,
							problemType: values.problemType!,
						},
					},
				},
				onCompleted: (response) => {
					setReferenceNumber(response.Reports.createReport?.referenceNumber ?? "");
					onSuccess?.();
				},
			});
		},
	});

	const handleSubmitOnClick = () => {
		form.submitForm();
	};

	const checkboxStatus = useMemo(
		() => (form.isValid ? CheckboxStatus.Default : CheckboxStatus.Error),
		[form.values, form.touched, form.isValid, form.isValidating],
	);

	return (
		<ResponsiveBottomSheetModal
			isVisible={isVisible}
			blocking={false}
			dismissable={true}
			onDismiss={handleOnDismiss}
		>
			<DialogTemplate
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={handleOnDismiss}
				primaryButtonIconName="arrowRight"
				primaryButtonLabel="Meldung senden"
				primaryButtonLoading={isCreating}
				onPrimaryButtonClick={handleSubmitOnClick}
				maxWidthRem={50}
				primaryButtonStretch={false}
				secondaryButtonStretch={false}
			>
				<div className={wrapperClass} data-report-modal-flow-container>
					<div className={iconWrapperWrapperClass}>
						<div className={iconWrapperClass}>
							<Icon icon="feedback2" sizeRem={2.5} color={success100} />
						</div>
					</div>

					<div className={titleWrapperClass}>
						<H1Span className={colorShade100Class}>
							Wir freuen uns über deine Meldung!
						</H1Span>
						<br />
						<P3Span className={colorShade80Class}>
							In der Regel antworten wir innerhalb von 5 Werktagen.
						</P3Span>
					</div>
					<P2Span className={colorShade80Class}>
						Wir bearbeiten deine Anfrage so schnell wie möglich. In der Regel erhältst
						du innerhalb von 5 Werktagen eine Antwort von uns. Deine persönlichen Daten
						sind optional und können hilfreich sein, um dich auf dem Laufenden zu halten
						oder uns zu ermöglichen, bei Fragen auf dich zuzukommen.
					</P2Span>
					<form onSubmit={form.handleSubmit}>
						<div className={inputsWrapperClass}>
							<div className={rowSpan6Class}>
								<ValidatedInputText
									formikConfig={form}
									name="firstName"
									label={"Name"}
									placeholder={"Dein Name, für Nachfragen..."}
								/>
							</div>
							<div className={rowSpan6Class}>
								<ValidatedInputText
									formikConfig={form}
									name="lastName"
									label={"Nachname"}
									placeholder={"Dein Nachname, für Nachfragen..."}
								/>
							</div>
							<div className={rowSpan12Class}>
								<ValidatedInputText
									formikConfig={form}
									name="email"
									label={"E-Mail"}
									placeholder={"Deine E-Mail, für Nachfragen (optional)..."}
								/>
							</div>
							<hr className={hrClass} />
							<div className={rowSpan12Class}>
								<H2Span>Wie können wir dir helfen?</H2Span>
							</div>
							<div className={rowSpan12Class}>
								<ValidatedDropdown
									formikConfig={form}
									name="reportType"
									options={getReportTypeOptions()}
									label="Art der Meldung*"
								/>
							</div>
							<div className={rowSpan12Class}>
								<ValidatedInputText
									formikConfig={form}
									name={"url"}
									label={"URL der Meldung"}
									placeholder={"Url der Meldung, für Nachfragen..."}
								/>
							</div>
							<hr className={hrClass} />

							<div className={rowSpan12Class}>
								<ValidatedDropdown
									formikConfig={form}
									name="problemType"
									options={getProblemTypeOptions()}
									label="Problem*"
								/>
							</div>

							<div className={rowSpan12Class}>
								<ValidatedInputText
									formikConfig={form}
									name="extra"
									label={"Erzähl uns mehr über dein Anliegen"}
									placeholder={
										"Bitte beschreibe dein Anliegen so detailliert wie möglich, damit wir dir bestmöglich weiterhelfen können ..."
									}
								/>
							</div>

							<div className={rowSpan12Class}>
								<div className={checkboxWrapperClass}>
									<Checkbox
										checked={form.values.isChecked ?? false}
										status={checkboxStatus}
										onChange={(e) => {
											form.setFieldValue("isChecked", e.checked);
										}}
									/>
									<P2Span className={colorShade100Class}>
										Ich stimme zu, dass meine angegebenen Daten im Einklang mit
										der{" "}
										<P2Anchor href={DATA_PROTECTION_URL} target="_blank">
											Datenschutzrichtlinie
										</P2Anchor>{" "}
										verarbeitet werden
										{checkboxStatus === CheckboxStatus.Error && (
											<>
												<br />
												<div>
													<P3Span className={colorError100Class}>
														{form.errors?.isChecked}
													</P3Span>
												</div>
											</>
										)}
									</P2Span>
								</div>
							</div>
							<div className={cx(rowSpan12Class, centerWrapperClass)}>
								<P3Span className={colorShade100Class}>
									Für Hilfe und Unterstützung kontaktiere bitte{" "}
									<Suspense fallback={DSA_REPORT_EMAIL_FALLBACK}>
										<SystemEmail />
									</Suspense>
								</P3Span>
							</div>
						</div>
					</form>
				</div>
				<Divider />
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
