import { graphql } from "react-relay";

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment trialProgressTrackerItem_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		id
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on PublishedRootExtensionImpl {
						abbreviation
						product {
							id
						}
						tags {
							id
							data {
								name
								isTopic
							}
						}
						unlockInfo {
							kind
							... on UnlockInfoDemo {
								firstContentId
								firstContentCoordinatesIndexPath
							}
						}
						licenseAvailability {
							kind
							... on LicenseAvailabilityFreeLicensesAvailable {
								numAvailable
							}
						}
					}
				}
				viewerTreeState {
					kind
					... on IsStartedViewerTreeState {
						headContentId
					}
				}
			}
		}
		typeDefinition {
			... on LearnOpportunityBranchTypeDefinition {
				children {
					typeDefinition {
						... on LearnOpportunityBranchTypeDefinition {
							children {
								id
								indexPath
							}
						}
					}
				}
			}
		}
	}
`;
