import { css } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	border: "1px solid",
	borderColor: "shade.20",
	borderRadius: "12",
	boxShadow: "card",
	width: "full",
	height: "full",
});

export const headerClass = css({
	width: "full",
});

export const imageWrapperClass = css({
	position: "relative",
	width: "full",
	height: "full",
	overflow: "hidden",
	borderTopRadius: "12",
});

export const imageClass = css({
	objectFit: "cover",
	width: "full",
	height: "full",
	minWidth: "full",
	minHeight: "[200px]",
	maxHeight: "[200px]",
});

export const imageOverlayClass = css({
	position: "absolute",
	left: "16",
	bottom: "16",
});

export const contentClass = stack({
	gap: "8",
	padding: "16",
	width: "full",
	textAlign: "left",
});

export const contentHeaderClass = stack({
	gap: "0",
	textAlign: "left",
});

export const contentTextClass = css({
	maxHeight: "[100px]",
	overflow: "hidden",
});
