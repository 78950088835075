import type { MultiSelectChangeEvent } from "primereact/multiselect";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { MultiSelect } from "@components/multi-select";
import { type licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation } from "@relay/licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation.graphql";
import { colorSuccess100Class } from "@themes/color-classes";
import { P2Span } from "@themes/font-tags";
import {
	CREATE_NEW_SINGLE_USER_ASSIGNMENT_RULE_MUTATION,
	QUERY_FRAGMENT,
} from "./licenses-new-single-user-assignment-form.graphql";
import { userListClass, wrapperClass } from "./licenses-new-single-user-assignment-form.styles";
import {
	type SelectedUser,
	type LicensesNewSingleUserAssignmentFormProps,
	type LicensesNewSingleUserAssignmentFormRef,
} from "./licenses-new-single-user-assignment-form.types";
import { LicensesSingleUserAssignmentCard } from "../licenses-single-user-assignment-card";
import { Message } from "../message";

export const LicensesNewSingleUserAssignmentForm = forwardRef<
	LicensesNewSingleUserAssignmentFormRef,
	LicensesNewSingleUserAssignmentFormProps
>(function LicensesNewSingleUserAssignmentForm(
	{ licenseGroupingId, queryFragmentRef, freeLicenseCount, onSuccess },
	ref,
) {
	const data = useFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);
	const [createLicenseRule] =
		useMutation<licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation>(
			CREATE_NEW_SINGLE_USER_ASSIGNMENT_RULE_MUTATION,
		);

	const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);
	const licensesAvailableToAssign =
		freeLicenseCount - selectedUsers.reduce((acc, val) => acc + val.licenseCount, 0);
	const canAssignLicenses = licensesAvailableToAssign > 0;

	const handleSubmit = () => {
		selectedUsers.forEach((user) => {
			if (!user.userId) return;
			createLicenseRule({
				variables: {
					input: {
						licenseGroupingId,
						userId: user.userId,
						maxLicenseAmount: user.licenseCount,
					},
				},
				onCompleted: () => {
					onSuccess?.();
				},
			});
		});
	};

	useImperativeHandle(ref, () => ({
		submit: () => {
			handleSubmit();
		},
	}));

	const options = useMemo(() => {
		return (
			data?.AccountMemberManagement.AccountMembers.accountMembers.edges
				?.map((edge) => {
					if (!edge) return null;
					const data = edge.node;

					if (data.kind !== "UserInAccount") return false;
					return {
						id: data.id,
						name: data.name,
						licenseCount: 1,
						userId: data?.user?.user.id,
						userRef: data,
					};
				})
				.filter(Boolean) ?? []
		);
	}, [data]);

	const handleRemoveUser = (id?: string) => {
		if (id) {
			setSelectedUsers((prev) => prev.filter((user) => user.id !== id));
		}
	};

	const createOnDeleteUserHandler = (id: string) => () => {
		handleRemoveUser(id);
	};

	const handleMultiSelectOnChange = (event: MultiSelectChangeEvent) => {
		if (!canAssignLicenses) return;
		setSelectedUsers(
			(event.value as SelectedUser[]).map((user) => ({ ...user, licenseCount: 1 })),
		);
	};

	const createLicenseCountChangeHandler = (id: string) => (newLicenseCount: number) => {
		setSelectedUsers((prev) =>
			prev.map((user) =>
				user.id === id ? { ...user, licenseCount: newLicenseCount } : user,
			),
		);
	};

	return (
		<form className={wrapperClass}>
			<Message
				highlighted
				severity="success"
				content={
					<P2Span className={colorSuccess100Class}>
						Einzelzuweisungen gewähren Zugriff auf X Lizenzen in dem Lizenz-Pool. Dieser
						kann nur so lange ausgeschöpft werden, wie sich darin Lizenzen befinden.
					</P2Span>
				}
			/>

			<MultiSelect
				label="Nutzer"
				placeholder="Nutzer auswählen..."
				options={options}
				optionLabel="name"
				value={selectedUsers}
				onChange={handleMultiSelectOnChange}
				onCloseClick={handleRemoveUser}
				disabled={!canAssignLicenses}
			/>
			<div className={userListClass}>
				{selectedUsers.map((user) => {
					return (
						<LicensesSingleUserAssignmentCard
							key={user.id}
							userRef={user.userRef}
							licenseCount={user.licenseCount}
							onDelete={createOnDeleteUserHandler(user.id)}
							onLicenseCountChange={createLicenseCountChangeHandler(user.id)}
							showSubmitButton={false}
							showUserIcon={false}
							showModalOnDelete={false}
							showGroups={false}
							canIncrease={canAssignLicenses}
						/>
					);
				})}
			</div>
		</form>
	);
});
