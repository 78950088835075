import { Cookie } from "@hooks/use-cookies";

export const COOKIE_MAX_AGE_SECONDS = 7884000;
export const COOKIE_DOMAIN =
	process.env.NODE_ENV === "development"
		? "localhost"
		: window.location.hostname.includes("onrender.com")
		? "thekey-academy-lms-webapp-production.onrender.com"
		: "app.thekey.academy";

export const TKAOptinCookie = Cookie.TKAOptin;
