import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { EpicIcons } from "@thekeytechnology/epic-ui";
import { Carousel } from "primereact/carousel";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { NewsCard } from "@components/news-card";
import { UserHeader } from "@components/user-header";
import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";
import { Path } from "@router/paths";
import { brandStrong120, info100 } from "@themes/colors";
import { H1Span, H2Span } from "@themes/font-tags";
import {
	carouselContainerClass,
	carouselContentClass,
	carouselIndicatorButtonClass,
	carouselIndicatorClass,
	carouselIndicatorContainerClass,
	gridItemClass,
	headerClass,
	newsCarouselItemClass,
	sectionClass,
	sectionHeaderClass,
} from "./dashboard.styles";
import { News } from "./dashboard.types";
import { getNews } from "./dashboard.utils";
import { ActiveRootsCarousel } from "./parts/active-roots-carousel";
import { GamificationPoints } from "./parts/gamification-points";
import { LearnStreak } from "./parts/learn-streak/learn-streak.component";
import { LearnablesGrid } from "./parts/learnables-grid";

export const DashboardComponent = () => {
	const { isMediumUp, isXLargeUp } = useWindowSize();
	const navigate = useNavigate();
	const [news, setNews] = useState<News[]>([]);

	const handleOnNewsClick = () => {
		window.open("https://www.thekey.academy/blog/");
	};

	const handleOnUserHeaderClick = () => {
		navigate(Path.profileMenu.profile.path);
	};

	useEffect(() => {
		const controller = new AbortController();
		getNews(controller).then(setNews);

		return () => {
			controller.abort();
		};
	}, []);

	return (
		<ScreenWithNavbarLayout>
			<div className={headerClass}>
				<H1Span>Mein Bereich</H1Span>
				<GamificationPoints />
			</div>
			<UserHeader onClick={handleOnUserHeaderClick} />
			<LearnStreak />
			<ActiveRootsCarousel />
			<LearnablesGrid />
			<section className={sectionClass}>
				<div className={sectionHeaderClass}>
					<H2Span>Neuigkeiten</H2Span>
					<Button
						label="Zu den Neuigkeiten"
						colorVersion="textlink"
						textLinkColor={brandStrong120}
						onClick={handleOnNewsClick}
					/>
				</div>
				<EmptyPlaceholder
					isVisible={news.length === 0}
					icon={<Icon icon={EpicIcons.ENVELOPE} sizeRem={2.5} color={info100} />}
					iconBackgroundColor="onPrimary"
					title="Hier findest du die aktuellen Neuigkeiten"
					subtitle="Du kannst jederzeit Neuigkeiten erleben. Beispielsweise die neuesten Updates zum Thema der Kurse."
				>
					<Carousel
						value={news}
						page={0}
						numVisible={isMediumUp ? 4 : isXLargeUp ? 4 : 1}
						numScroll={1}
						showNavigators={false}
						pt={{
							content: {
								className: carouselContentClass,
							},
							container: {
								className: carouselContainerClass,
							},
							itemsContainer: {
								className: carouselContainerClass,
							},
							item: {
								className: newsCarouselItemClass,
							},
							indicators: {
								className: carouselIndicatorContainerClass,
							},
							indicator: {
								className: carouselIndicatorClass,
							},
							indicatorButton: {
								className: carouselIndicatorButtonClass,
							},
						}}
						itemTemplate={(news: News) => (
							<div key={news.id} className={gridItemClass}>
								<NewsCard news={news} />
							</div>
						)}
					/>
				</EmptyPlaceholder>
			</section>
		</ScreenWithNavbarLayout>
	);
};

export const DashboardScreen = DashboardComponent;
