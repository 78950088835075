import { useMemo } from "react";
import { useFragment } from "react-relay";
import { AttachmentsCollection } from "@components/attachments-collection";
import { ContentElementsCollection } from "@components/content-elements-collection";
import { InstructorsComponent } from "@components/instructors-component";
import { isAfterIndexPath, isEqualIndexPath } from "@utils/index-path.util";
import { LEARN_OPPORTUNITY_FRAGMENT } from "./e-learning-content-overview.graphql";
import {
	attachmentsWrapperClass,
	contentCardsWrapperClass,
	instructorsClass,
	wrapperClass,
} from "./e-learning-content-overview.styles";
import { ELearningContentOverviewProps } from "./e-learning-content-overview.types";

export const ELearningContentOverview = ({
	learnOpportunityV2FragmentRef,
}: ELearningContentOverviewProps) => {
	const node = useFragment(LEARN_OPPORTUNITY_FRAGMENT, learnOpportunityV2FragmentRef);

	const headContentId = node.root?.structureDefinition.viewerTreeState?.headContentId;
	const firstLockedContentId =
		node.root?.structureDefinition.extension?.unlockInfo?.firstContentId;
	const unlockInfoKind = node.root?.structureDefinition.extension?.unlockInfo?.kind;
	const noDemo = unlockInfoKind !== "Demo";
	const hasFullAccess = unlockInfoKind === "FullAccess" || unlockInfoKind === "WallNotFound";

	const isStarted = node.root?.structureDefinition.viewerTreeState?.kind === "IsStarted";
	const isFinished = node.root?.structureDefinition.viewerTreeState?.kind === "IsFinished";

	const headIndexPath: readonly number[] | undefined = useMemo(() => {
		let indexPath: readonly number[] | undefined = undefined;
		node.root?.typeDefinition.children?.forEach((branch) => {
			const foundContent = branch.typeDefinition?.children?.find(
				(content) => content.id === headContentId,
			);
			if (foundContent) {
				indexPath = foundContent.indexPath;
			}
		});
		return indexPath;
	}, [headContentId, node.root?.typeDefinition.children]);

	const isUnlocked = useMemo(
		() =>
			hasFullAccess &&
			((isStarted &&
				(isAfterIndexPath(node.indexPath, headIndexPath) ||
					(isEqualIndexPath(node.indexPath, headIndexPath) &&
						(headContentId !== firstLockedContentId || noDemo)))) ||
				isFinished),
		[
			hasFullAccess,
			isStarted,
			isFinished,
			node.indexPath,
			headIndexPath,
			headContentId,
			firstLockedContentId,
			noDemo,
		],
	);

	const hasAttachments = node.attachmentsV2.length > 0;
	return (
		<div className={wrapperClass}>
			{node && (
				<div className={contentCardsWrapperClass}>
					<ContentElementsCollection learnOpportunityV2FragmentRef={node} />
				</div>
			)}
			{hasAttachments && node && (
				<div className={attachmentsWrapperClass}>
					<AttachmentsCollection
						isUnlocked={isUnlocked}
						attachmentsCollectionFragmentRef={node}
					/>
				</div>
			)}
			{node?.instructorsV2 && (
				<div className={instructorsClass}>
					<InstructorsComponent instructorsConnectionFragmentRef={node?.instructorsV2} />
				</div>
			)}
		</div>
	);
};
