import { flex, stack } from "@styled-system/patterns";

export const wrapperClass = flex({
	alignItems: "center",
});

export const cardClass = stack({
	alignItems: "center",
	textAlign: "center",
	flex: "[1 1 0%]",
	paddingX: "16",
	paddingY: "20",
	gap: "8",
	borderRadius: "12",
	border: "0.5px solid",
	borderColor: "primary.100",
	backgroundColor: "primary.20",
});
