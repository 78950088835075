/**
 * @generated SignedSource<<ca0cb5c9a39030b7d4a9f54f4aafb204>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type loginSteps_InvitationByTokenQuery$variables = {
  skip: boolean;
  token: string;
};
export type loginSteps_InvitationByTokenQuery$data = {
  readonly Auth: {
    readonly InvitationByToken?: {
      readonly accountName: string | null | undefined;
      readonly invitingUserName: string | null | undefined;
    } | null | undefined;
  };
};
export type loginSteps_InvitationByTokenQuery = {
  response: loginSteps_InvitationByTokenQuery$data;
  variables: loginSteps_InvitationByTokenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v2 = [
  {
    "kind": "Variable",
    "name": "token",
    "variableName": "token"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invitingUserName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "loginSteps_InvitationByTokenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthQueries",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "condition": "skip",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "Invitation",
                "kind": "LinkedField",
                "name": "InvitationByToken",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "loginSteps_InvitationByTokenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthQueries",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "condition": "skip",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "Invitation",
                "kind": "LinkedField",
                "name": "InvitationByToken",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4ecdf91dbd1a074bc5a0b908a7d84453",
    "id": null,
    "metadata": {},
    "name": "loginSteps_InvitationByTokenQuery",
    "operationKind": "query",
    "text": "query loginSteps_InvitationByTokenQuery(\n  $token: String!\n  $skip: Boolean!\n) {\n  Auth {\n    InvitationByToken(token: $token) @skip(if: $skip) {\n      invitingUserName\n      accountName\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "552e0f58d2ec7e5503d2883eff25f591";

export default node;
