import { useMemo } from "react";
import { MaterialCard } from "@components/material-card";
import { mainWrapperClass } from "./material-cards-collection.styles";
import { MainWrapperStyle, MaterialCardsCollectionProps } from "./material-cards-collection.types";

export const MaterialCardsCollection = ({ cards, rows = 2 }: MaterialCardsCollectionProps) => {
	const cardsList = useMemo(
		() =>
			cards.map((card) => (
				<MaterialCard key={`${card.title}_${card.description}`} {...card} bodyPadding={0} />
			)),
		[cards],
	);

	return (
		<div
			className={mainWrapperClass}
			style={
				{
					"--rows": rows,
				} as MainWrapperStyle
			}
		>
			{cardsList}
		</div>
	);
};
