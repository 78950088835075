/**
 * @generated SignedSource<<ea9a52d8006f6d8b65e95bc42b1833ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type activeRootsCarousel_Query$variables = Record<PropertyKey, never>;
export type activeRootsCarousel_Query$data = {
  readonly Viewer: {
    readonly Learn: {
      readonly getActiveRoots: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"offerCard_LearnOpportunityV2Fragment">;
      }>;
    };
  };
};
export type activeRootsCarousel_Query = {
  response: activeRootsCarousel_Query$data;
  variables: activeRootsCarousel_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "activeRootsCarousel_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnViewerSchema",
            "kind": "LinkedField",
            "name": "Learn",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnOpportunityV2",
                "kind": "LinkedField",
                "name": "getActiveRoots",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "offerCard_LearnOpportunityV2Fragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "activeRootsCarousel_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnViewerSchema",
            "kind": "LinkedField",
            "name": "Learn",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnOpportunityV2",
                "kind": "LinkedField",
                "name": "getActiveRoots",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "structureDefinition",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "progressPercentage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "viewerTreeState",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "headContentId",
                                    "storageKey": null
                                  }
                                ],
                                "type": "IsStartedViewerTreeState",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "extension",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ReducedData",
                                    "kind": "LinkedField",
                                    "name": "reducedData",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "isIHK",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "tags",
                                    "plural": true,
                                    "selections": [
                                      (v1/*: any*/),
                                      (v0/*: any*/),
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AcademiesTagData",
                                        "kind": "LinkedField",
                                        "name": "data",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isClickable",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isTopic",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "unlockInfo",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      (v2/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "firstContentId",
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "UnlockInfoDemo",
                                        "abstractKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "PublishedRootExtensionImpl",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "LearnOpportunityRootStructureDefinition",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e4d40ae3e6d6bb304091eea0d0f89f1f",
    "id": null,
    "metadata": {},
    "name": "activeRootsCarousel_Query",
    "operationKind": "query",
    "text": "query activeRootsCarousel_Query {\n  Viewer {\n    Learn {\n      getActiveRoots {\n        id\n        ...offerCard_LearnOpportunityV2Fragment\n      }\n    }\n  }\n}\n\nfragment offerCard_LearnOpportunityV2Fragment on LearnOpportunityV2 {\n  id\n  description\n  shortDescription\n  image {\n    url\n    id\n  }\n  structureDefinition {\n    __typename\n    ... on LearnOpportunityRootStructureDefinition {\n      title\n      progressPercentage\n      viewerTreeState {\n        __typename\n        kind\n        ... on IsStartedViewerTreeState {\n          headContentId\n        }\n      }\n      extension {\n        __typename\n        ... on PublishedRootExtensionImpl {\n          reducedData {\n            isIHK\n          }\n          tags {\n            __typename\n            id\n            kind\n            data {\n              name\n              isClickable\n              isTopic\n            }\n          }\n          unlockInfo {\n            __typename\n            kind\n            ... on UnlockInfoDemo {\n              firstContentId\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "21727b6cfcda5a46c183bece6fd6b13a";

export default node;
