import { css } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	gap: "12",
	p: "24",
	pb: "8",
	bg: "shade.10",
});

export const scrollPanelClass = css({
	"&.p-scrollpanel .p-scrollpanel-content": {
		display: "flex",
		flexDirection: "row!",
		flexWrap: "nowrap!",
		alignItems: "flex-start!",
		gap: "8!",
		scrollbarWidth: "none!",
		"&::-webkit-scrollbar": {
			display: "none!",
		},
	},
});
