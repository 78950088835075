import { ZonedDateTime } from "@js-joda/core";
import { useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import { useLocation } from "react-router-dom";
import { MessageModal } from "@components/message-modal";
import { PATHS_PROTECTED_FROM_CUSTOMER_MESSAGE_MODAL } from "@components/message-modal-injector/message-modal-injector.consts";
import { MESSAGE_QUERY } from "@components/message-modal-injector/message-modal-injector.graphql";
import { messageModalInjector_LastUnseenMessageQuery } from "@relay/messageModalInjector_LastUnseenMessageQuery.graphql";

export const MessageModalInjector = () => {
	const data = useLazyLoadQuery<messageModalInjector_LastUnseenMessageQuery>(
		MESSAGE_QUERY,
		{
			skip: false,
		},
		{ fetchPolicy: "store-or-network" },
	);
	const location = useLocation();
	const currentPath = location.pathname;
	const isCoursePage = PATHS_PROTECTED_FROM_CUSTOMER_MESSAGE_MODAL.includes(currentPath);

	const message = data?.Viewer?.Message?.LastUnseenMessage;
	const hasSeen = data.Viewer.Message.LastUnseenMessage?.hasSeen;
	const showUntilNotYetPassed: boolean =
		message !== undefined &&
		message !== null &&
		message.messageDefinition?.showUntil !== undefined &&
		message.messageDefinition?.showUntil !== null &&
		message.messageDefinition?.showUntil !== "" &&
		ZonedDateTime.parse(message.messageDefinition.showUntil).isAfter(ZonedDateTime.now());

	const [isVisible, setIsVisible] = useState(!isCoursePage && !hasSeen && showUntilNotYetPassed);

	const handleOnHide = () => {
		setIsVisible(false);
	};

	if (!message) return null;

	return (
		<MessageModal isVisible={isVisible} messageFragmentRef={message} onHide={handleOnHide} />
	);
};
