import { cva } from "@styled-system/css";
import { flex, stack } from "@styled-system/patterns";

export const multiSelectClass = cva({
	base: {
		"&.p-multiselect.p-multiselect.p-multiselect": {
			height: "[40px!important]",
			display: "flex!",
			alignItems: "center!",
		},
		"&.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label": {
			display: "flex!",
			gap: "8!",
		},
	},
	variants: {
		hasError: {
			true: {
				"&.p-multiselect.p-multiselect.p-multiselect": {
					borderColor: "error.100!",
				},
			},
			false: {
				"&.p-multiselect.p-multiselect.p-multiselect": {
					borderColor: "shade.20!",
				},
			},
		},
	},
	defaultVariants: {
		hasError: false,
	},
});

export const wrapperClass = stack({
	gap: "4",
});

export const selectedItemTemplateWrapperClass = flex({
	width: "[min-content]",
});

export const selectedItemLabelWrapperClass = cva({
	base: {
		display: "flex",
		alignItems: "center",
		paddingX: "10",
		minHeight: "24",
		backgroundColor: "primary.20",
		borderLeftRadius: "4",
	},
	variants: {
		isCloseable: {
			false: {
				borderRadius: "4",
			},
		},
	},
});

export const selectedItemButtonClass = flex({
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: "primary.80",
	paddingX: "4",
	borderRightRadius: "4",
	_hover: {
		backgroundColor: "primary.40",
	},
});
