import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { TabBar } from "@thekeytechnology/academies-lib-webapp/components/tab-bar";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { TabPanel, TabViewTabChangeEvent } from "primereact/tabview";
import { Suspense, useCallback, useEffect, useState } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { gtmTrackAddToCart, gtmTrackViewItem } from "@analytics/google-tag-manager";
import { AdvanceTreeNavigation } from "@components/advance-tree-navigation";
import { useUserCartContext } from "@components/user-cart/user-cart.context";
import { useCurrentPath } from "@hooks/use-current-path";
import { root_AddItemToUserCartMutation } from "@relay/root_AddItemToUserCartMutation.graphql";
import { root_GetRootQuery } from "@relay/root_GetRootQuery.graphql";
import { BranchNodesPath, Path } from "@router/index";
import { colorShade80Class, colorWarning100Class } from "@themes/color-classes";
import { B1Span, H1Span } from "@themes/font-tags";
import { BranchNodeTab } from "./branch-nodes-tab";
import { RootContainerId } from "./root-overview/root-overview.const";
import { RootScreenContext } from "./root.context";
import { ADD_ITEM_TO_USER_CART_MUTATION, QUERY } from "./root.graphql";
import { RootScreenSkeleton } from "./root.skeleton";
import {
	licenseWrapperClass,
	needsLicenseWrapperClass,
	paddingWrapperClass,
	receiverGamificationPointsWrapperClass,
	sideMenuWrapperClass,
	subpathBackgroundClass,
	wrapperClass,
} from "./root.styles";
import { RootScreenProps } from "./root.types";
import { getTabIndexForPath, useRootNavigation } from "./root.util";
import { ReactComponent as KeyMedalSvg } from "../../assets/key-medal-points.svg";
import { CertificateCard } from "../../components/certificate-card";
import { RootProgress } from "../../components/root-progress";
import { RouterContentMenu } from "../../components/router-content-menu";
import { ScreenWithNavbarSidemenuLayout } from "../../layouts/screen-with-navbar-sidemenu-layout";

export const RootScreenWithRootId = ({ rootId }: RootScreenProps) => {
	const { t } = useTkaTranslation("courseScreen");

	const navigate = useNavigate();
	const currentPath = useCurrentPath();
	const { navigateToTab } = useRootNavigation();
	const { isXLargeUp } = useWindowSize();
	const { setUserCartOpen } = useUserCartContext();

	const [activeIndex, setActiveIndex] = useState(getTabIndexForPath(currentPath?.route.path));

	const query = useLazyLoadQuery<root_GetRootQuery>(
		QUERY,
		{ id: rootId },
		{ fetchPolicy: "store-and-network" },
	);
	const [addItemToUserCart] = useMutation<root_AddItemToUserCartMutation>(
		ADD_ITEM_TO_USER_CART_MUTATION,
	);

	const isRootPath = currentPath?.route.path === Path.root.withIdPlaceholder().path;
	const isRootOverviewPath = currentPath?.route.path === "overview";

	const userLevel = query?.Viewer?.Auth?.currentUser?.user?.extension?.level ?? 0;
	const rootName = query.node?.root?.structureDefinition?.title;
	const diploma = query.node?.root?.structureDefinition?.extension?.diploma ?? "";
	const isIHK = query?.node?.root?.structureDefinition?.extension?.reducedData?.isIHK;
	const needLicense =
		query?.node?.root?.structureDefinition?.extension?.unlockInfo?.kind === "Demo";
	const availableLicensesCount =
		query?.node?.root?.structureDefinition?.extension?.licenseAvailability?.numAvailable ?? 0;
	const receivableGamificationPoints =
		query?.node?.root?.structureDefinition?.extension?.reducedData
			?.receivableGamificationPoints;
	const canOrderIHK =
		query?.node?.root?.structureDefinition?.extension?.ihkState === "ReadyToOrder";
	const hasProduct = query?.node?.root?.structureDefinition?.extension?.product;

	useEffect(() => {
		if (isRootPath) {
			navigate(Path.root.withId(rootId).overview.path, { replace: true });
		}
	}, [isRootPath]);

	const isContentBasePath = currentPath?.route.path === BranchNodesPath.pathName;

	useEffect(() => {
		if (isContentBasePath) {
			const nextContentId =
				query.node?.root?.structureDefinition.viewerTreeState?.headContentId ??
				query.node?.root?.typeDefinition?.children?.[0]?.typeDefinition.children?.[0]?.id;
			nextContentId &&
				navigate(Path.root.withId(rootId).branchNodes.withId(nextContentId).path, {
					replace: true,
				});
		}
	}, [isContentBasePath]);

	useEffect(() => {
		const pathIndex = getTabIndexForPath(currentPath?.route.path);
		if (pathIndex !== activeIndex) {
			setActiveIndex(pathIndex);
		}
	}, [currentPath?.route.path, activeIndex]);

	useEffect(() => {
		if (!query.node?.root) return;
		const productId = query.node.root.structureDefinition.extension?.product?.id;
		if ((!needLicense && availableLicensesCount === 0) || !productId) return;

		const category = query.node.root.structureDefinition.extension?.tags?.find(
			(t) => t.data.isTopic,
		);

		gtmTrackViewItem({
			currency: "EUR",
			value: query.node?.root?.structureDefinition?.extension?.product?.netPrice ?? 0,
			items: [
				{
					itemId: query.node.root.id!,
					itemName: query.node.root.structureDefinition.title,
					itemCategory: category?.data.name ?? "",
					price: query.node?.root?.structureDefinition?.extension?.product?.netPrice ?? 0,
					quantity: 1,
				},
			],
		});
	}, []);

	const handleOnTabChange = useCallback(
		(params: TabViewTabChangeEvent) => {
			navigateToTab(params.index);
		},
		[navigateToTab],
	);

	const handleOnCartClickout = useCallback(() => {
		const productId = query.node?.root?.structureDefinition?.extension?.product?.id;
		if (!productId) return;

		const structureDefinition = query?.node?.root?.structureDefinition;
		const rootExtension = structureDefinition.extension;
		const rootCategory = structureDefinition?.extension?.tags?.find((t) => t.data.isTopic);
		gtmTrackAddToCart({
			currency: "EUR",
			value: rootExtension?.product?.netPrice ?? 0,
			items: [
				{
					itemId: rootId,
					itemName: structureDefinition?.title ?? "",
					itemCategory: rootCategory?.data.name ?? "",
					price: rootExtension?.product?.netPrice ?? 0,
					quantity: 1,
				},
			],
		});
		addItemToUserCart({
			variables: {
				input: {
					productId,
				},
			},
			onCompleted: () => {
				setUserCartOpen(true);
			},
		});
	}, [query, navigate]);

	const tabBar = (
		<TabBar activeIndex={activeIndex} onTabChange={handleOnTabChange}>
			<TabPanel header={t("course_screen.tabOverview")} />
			<TabPanel header={t("course_screen.tabModule")} />
			<TabPanel header={t("course_screen.tabMaterial")} />
		</TabBar>
	);

	return (
		<ScreenWithNavbarSidemenuLayout
			noPadding
			noSideMenuPadding
			canGoBack={!isXLargeUp}
			contentContainerId={RootContainerId}
			isContentSubmission
			sideMenu={
				<div className={sideMenuWrapperClass}>
					<RouterContentMenu
						items={[
							{
								id: "overview",
								label: t("course_screen.tabOverview"),
							},
							{
								id: "materials",
								label: t("course_screen.tabMaterial"),
							},
							{
								id: "modules",
								label: t("course_screen.tabModule"),
								children: query.node?.root && (
									<BranchNodeTab
										userLevel={userLevel}
										learnOpportunityV2FragmentRef={query.node.root}
									/>
								),
							},
						]}
					/>
					{((isIHK && rootName) || needLicense) && (
						<div className={licenseWrapperClass}>
							{((isIHK && rootName) || receivableGamificationPoints) && (
								<B1Span className={colorShade80Class}>
									<strong>Was du erhältst:</strong>
								</B1Span>
							)}
							{isIHK && rootName && (
								<CertificateCard
									rootName={rootName}
									diploma={diploma}
									canOrder={canOrderIHK}
									rootId={rootId}
								/>
							)}
							{receivableGamificationPoints && (
								<div className={receiverGamificationPointsWrapperClass}>
									<H1Span className={colorWarning100Class}>
										<strong>+{receivableGamificationPoints}</strong>
									</H1Span>
									<KeyMedalSvg width={22} height={22} />
								</div>
							)}
							{hasProduct && (
								<div className={needsLicenseWrapperClass}>
									<B1Span className={colorShade80Class}>
										{availableLicensesCount === 0 && (
											<strong>Keine Lizenz im Besitz</strong>
										)}
										{availableLicensesCount > 0 && (
											<strong>
												{availableLicensesCount} Lizenzen verfügbar
											</strong>
										)}
									</B1Span>
									<Button
										label="Lizenz zum Warenkorb hinzufügen"
										colorVersion="outline"
										className="w-full"
										iconName="shoppingBasket"
										onClick={handleOnCartClickout}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			}
			bottomContent={<AdvanceTreeNavigation>{!isXLargeUp && tabBar}</AdvanceTreeNavigation>}
		>
			<div className={wrapperClass}>
				<div className={paddingWrapperClass}>
					{query.node?.root && (
						<RootProgress
							hideImage={!isRootOverviewPath}
							learnOpportunityV2FragmentRef={query.node.root}
						/>
					)}
					{/* {!isXLargeUp && <StickyWrapper>{tabBar}</StickyWrapper>} */}
				</div>
				{!isRootOverviewPath ? (
					<div className={subpathBackgroundClass}>
						<Outlet />
					</div>
				) : (
					<div className={paddingWrapperClass}>
						<Outlet />
					</div>
				)}
			</div>
		</ScreenWithNavbarSidemenuLayout>
	);
};

export const RootScreen = () => {
	const { rootId } = useParams();
	const [spyEnabled, setSpyEnabled] = useState(false);
	const [lastContentId, setLastContentId] = useState<string>();

	return rootId ? (
		<RootScreenContext.Provider
			value={{ spyEnabled, setSpyEnabled, lastContentId, setLastContentId }}
		>
			<Suspense fallback={<RootScreenSkeleton />}>
				<RootScreenWithRootId rootId={rootId} />
			</Suspense>
		</RootScreenContext.Provider>
	) : null;
};
