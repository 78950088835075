/**
 * @generated SignedSource<<1b55a9cd1ff5ab09a9a4b09abf2c0822>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NoteReminderKind = "NonRecurring" | "Recurring";
import { FragmentRefs } from "relay-runtime";
export type reminderOverview_QueryFragment$data = {
  readonly Note: {
    readonly Note: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly id: string;
          readonly reminder: {
            readonly isCompleted: boolean;
            readonly kind: NoteReminderKind;
            readonly remindAt: string;
          } | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"reminderCard_ReminderNoteFragment">;
        };
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly endCursor: string | null | undefined;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null | undefined;
      };
    };
  };
  readonly " $fragmentType": "reminderOverview_QueryFragment";
};
export type reminderOverview_QueryFragment$key = {
  readonly " $data"?: reminderOverview_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"reminderOverview_QueryFragment">;
};

import reminderOverview_RefetchQuery_graphql from './reminderOverview_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "Note",
  "Note"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByText"
    },
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeNoteColors"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeNoteSourceKinds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "noteColorToSortBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": reminderOverview_RefetchQuery_graphql
    }
  },
  "name": "reminderOverview_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NoteQueries",
      "kind": "LinkedField",
      "name": "Note",
      "plural": false,
      "selections": [
        {
          "alias": "Note",
          "args": [
            {
              "kind": "Variable",
              "name": "filterByText",
              "variableName": "filterByText"
            },
            {
              "kind": "Literal",
              "name": "hasReminder",
              "value": true
            },
            {
              "kind": "Variable",
              "name": "includeNoteColors",
              "variableName": "includeNoteColors"
            },
            {
              "kind": "Variable",
              "name": "includeNoteSourceKinds",
              "variableName": "includeNoteSourceKinds"
            },
            {
              "kind": "Variable",
              "name": "noteColorToSortBy",
              "variableName": "noteColorToSortBy"
            }
          ],
          "concreteType": "NoteConnection",
          "kind": "LinkedField",
          "name": "__reminderOverview_Note_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPreviousPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "NoteEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Note",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "reminder",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "kind",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isCompleted",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remindAt",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "reminderCard_ReminderNoteFragment"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "329f19d54eb41473c4d9651ebba214fb";

export default node;
