import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment licenseSettings_QueryFragment on Query
	@refetchable(queryName: "licenseSettings_QueryFragmentRefetch") {
		Viewer {
			Auth {
				currentUser {
					accounts {
						id
						extension(accountExtensionKind: Tka) {
							kind
							... on TkaAccountExtension {
								licenseModelKind
							}
						}
					}
				}
			}
		}
	}
`;

export const EDIT_LICENSE_MODEL_MUTATION = graphql`
	mutation licenseSettings_EditLicenseModelMutation($input: EditLicenseModelInput!) {
		LicenseModel {
			editLicenseModel(input: $input) {
				clientMutationId
			}
		}
	}
`;
