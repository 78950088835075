import { css, cva } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	gap: "10",
	padding: "[5px]",
});

export const imagesWrapperClass = css({
	position: "relative",
	mb: "8",
});

export const imageWrapperClass = cva({
	base: {
		borderRadius: "full",
	},
	variants: {
		isUnlocked: {
			false: {
				filter: "auto",
				blur: "4px",
			},
		},
	},
});

export const iconWrapperClass = css({
	position: "absolute",
	width: "[1.75rem]",
	height: "[1.75rem]",
	bottom: "0",
	left: "50%",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	transform: "translate(-50%, 50%)",
});

export const titlesWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
});

export const modalWrapperClass = css({
	position: "relative",
	display: "flex",
	flexDirection: "column",
	maxHeight: "[25.625rem]",
	minHeight: "[25.625rem]",
	overflowY: "auto",
	md: {
		minWidth: "[25rem]",
		maxWidth: "[25rem]",
		maxHeight: "auto",
		minHeight: "auto",
	},
});

export const modalCloseButtonClass = css({
	position: "absolute",
	zIndex: "10",
	top: "16",
	right: "16",
	width: "16",
	height: "16",
	display: "none",
	md: {
		display: "block",
	},
});

export const modalContentClass = css({
	flex: "1",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	gap: "16",
	width: "full",
	height: "full",
	textAlign: "center",
});
