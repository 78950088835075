import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { ImageCardAtom } from "@thekeytechnology/academies-lib-webapp/components/image-card-atom";
import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { H3Span, P3Span } from "@thekeytechnology/epic-ui";
import { useCallback, useState } from "react";
import { useTheme } from "styled-components";
import { match } from "ts-pattern";
import { ReactComponent as KeyMedalSvg } from "@assets/key-medal-points-dark.svg";
import { shade40 } from "@themes/colors";
import { decodeIndexPath } from "@utils/index-path.util";
import { ImageWidth } from "./branch-node-card.const";
import {
	iconsWrapperClass,
	infoWrapperClass,
	listWrapperClass,
	mainWrapperClass,
	pointsWrapperClass,
} from "./branch-node-card.styles";
import { type BranchNodeCardProps, BranchNodeStatus } from "./branch-node-card.types";

export const BranchNodeCard = ({
	title,
	imageUrl,
	showImage = false,
	moduleNumber,
	indexPath,
	headIndexPath,
	contentsCount,
	isFinished,
	status,
	receivedPoints,
	totalPoints,
	hasMarginLeft,
	children,
}: BranchNodeCardProps) => {
	const { t } = useTkaTranslation("modulesAndLessons");
	const theme = useTheme();

	const [isExpanded, setIsExpanded] = useState(false);

	const { branchNumber } = decodeIndexPath(indexPath);
	const { branchNumber: headBranchNumber, contentNumber: headContentNumber } = decodeIndexPath(
		headIndexPath ?? [],
	);

	const isHeadAtPreviousBranch =
		status !== BranchNodeStatus.done && headBranchNumber < branchNumber;
	const isHeadAtNextBranch = status !== BranchNodeStatus.done && headBranchNumber > branchNumber;

	const handleOnExpandClick = useCallback(() => {
		setIsExpanded(!isExpanded);
	}, [isExpanded]);

	const progressLabel = match({ isHeadAtPreviousBranch, isHeadAtNextBranch, isFinished })
		.with({ isHeadAtPreviousBranch: true }, () => (
			<Label
				backgroundColor={shade40.hexValue()}
				label={`0/${contentsCount}`}
				closeIconName="lock"
			/>
		))
		.with({ isFinished: false, isHeadAtNextBranch: true }, () => (
			<Label label={`${contentsCount}/${contentsCount}`} closeIconName="tick" />
		))
		.with({ isFinished: true }, () => (
			<Label label={`${contentsCount}/${contentsCount}`} closeIconName="tick" />
		))
		.otherwise(() => <Label label={`${headContentNumber}/${contentsCount}`} />);

	return (
		<div
			className={listWrapperClass({
				isLocked: status === BranchNodeStatus.locked,
				hasMarginLeft,
			})}
			onClick={handleOnExpandClick}
		>
			<div
				className={mainWrapperClass({
					isEnabled: status !== BranchNodeStatus.locked,
				})}
			>
				{showImage && imageUrl && (
					<ImageCardAtom imageUrl={imageUrl} imageWidth={`${ImageWidth}rem`} />
				)}
				<div className={infoWrapperClass}>
					<P3Span $color={theme.colors.shade80}>
						{t("modules_and_lessons.module")} {moduleNumber}
					</P3Span>
					<H3Span $color={theme.colors.shade100}>{title}</H3Span>
					{progressLabel}
				</div>
				<div className={iconsWrapperClass}>
					<Icon icon={isExpanded ? "arrowUp" : "arrowDown"} sizeRem={1} />
					<div
						className={pointsWrapperClass({
							isHeadAtPreviousBranch,
						})}
					>
						<P3Span>
							{receivedPoints}/{totalPoints}
						</P3Span>
						<KeyMedalSvg />
					</div>
				</div>
			</div>
			{isExpanded && children}
		</div>
	);
};
