import { graphql } from "react-relay";

export const SINGLE_USER_ASSIGNMENT_RULE_FRAGMENT = graphql`
	fragment licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment on SingleUserAssignmentRule {
		__typename
		id
		userId
		licenseGroupingId
		licensesUsedByUser
		maxLicenseAmount
	}
`;

export const ACCOUNT_MEMBER_FRAGMENT = graphql`
	fragment licensesSingleUserAssignmentCard_AccountMemberFragment on AccountMember {
		id
		name
		isManager
		kind
		... on UserInAccountWrapper {
			user {
				id
				user {
					id
				}
				groups {
					id
					name
					isBuiltIn
				}
			}
		}
	}
`;

export const EDIT_SINGLE_USER_ASSIGNMENT_RULE_MUTATION = graphql`
	mutation licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation(
		$input: EditSingleUserAssignmentRuleInput!
	) {
		LicenseManagement {
			editSingleUserAssignmentRule(input: $input) {
				assignmentRule {
					...licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment
				}
			}
		}
	}
`;

export const DELETE_SINGLE_USER_ASSIGNMENT_RULE_MUTATION = graphql`
	mutation licensesSingleUserAssignmentCard_deleteSingleUserAssignmentRuleMutation(
		$input: DeleteAssignmentRuleInput!
	) {
		LicenseManagement {
			deleteAssignmentRule(input: $input) {
				clientMutationId
			}
		}
	}
`;
