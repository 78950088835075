import { css } from "@styled-system/css";

export const sectionClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "8",
	marginTop: "24",
});

export const sectionHeaderClass = css({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
});

export const gridClass = css({
	display: "grid",
	gap: "24",
	gridTemplateColumns: "1",
	gridAutoRows: "fr",
	md: {
		gridTemplateColumns: "2",
	},
	lg: {
		gridTemplateColumns: "3",
	},
	"2xl": {
		gridTemplateColumns: "4",
	},
});

export const gridItemClass = css({
	flexBasis: "100%",
	width: "full",
	height: "full",
	pb: "8",
});

export const nextButtonWrapperClass = css({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "full",
	marginTop: "12",
});
