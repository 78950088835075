import { graphql } from "react-relay";

export const INVITATION_QUERY = graphql`
	query acceptInvitation_InvitationQuery($token: String!) {
		Auth {
			InvitationByToken(token: $token) {
				validUntil
				accountName
				invitingUserName
			}
		}
	}
`;
