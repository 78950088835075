/**
 * @generated SignedSource<<1cfc2544a1820f6bf6831bd2005257e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type profileMenu_AuthViewerSchemaFragment$data = {
  readonly currentUser: {
    readonly accounts: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly user: {
      readonly extension: {
        readonly avatar?: {
          readonly url: string | null | undefined;
        } | null | undefined;
        readonly sumGamificationPoints?: number;
      } | null | undefined;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"userAvatar_UserFragment">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"accountsDropdown_CurrentUserFragment">;
  } | null | undefined;
  readonly " $fragmentType": "profileMenu_AuthViewerSchemaFragment";
};
export type profileMenu_AuthViewerSchemaFragment$key = {
  readonly " $data"?: profileMenu_AuthViewerSchemaFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"profileMenu_AuthViewerSchemaFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "profileMenu_AuthViewerSchemaFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUser",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "userExtensionKind",
                  "value": "Academies"
                }
              ],
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "File",
                      "kind": "LinkedField",
                      "name": "avatar",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "url",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sumGamificationPoints",
                      "storageKey": null
                    }
                  ],
                  "type": "AcademiesUserExtension",
                  "abstractKey": null
                }
              ],
              "storageKey": "extension(userExtensionKind:\"Academies\")"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "userAvatar_UserFragment"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "accounts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "accountsDropdown_CurrentUserFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AuthViewerSchema",
  "abstractKey": null
};

(node as any).hash = "75e4c7640b61d3c74b49ba5c4e84af92";

export default node;
