import { createContext, Dispatch, SetStateAction, useContext, useState } from "react";

type SelectionMenuContext = {
	upsertNoteModalOpen: boolean;
	setUpsertNoteModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const SelectionMenuContext = createContext<SelectionMenuContext>({
	upsertNoteModalOpen: false,
	setUpsertNoteModalOpen: () => {},
});

export const SelectionMenuContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [upsertNoteModalOpen, setUpsertNoteModalOpen] = useState(false);

	return (
		<SelectionMenuContext.Provider
			value={{
				upsertNoteModalOpen,
				setUpsertNoteModalOpen,
			}}
		>
			{children}
		</SelectionMenuContext.Provider>
	);
};

export const useSelectionMenuContext = () => {
	const context = useContext(SelectionMenuContext);
	if (!context) {
		throw new Error("useSelectionMenuContext needs to be in SelectionContextMenuProvider.");
	}
	return context;
};
