import { graphql } from "react-relay";

export const QUERY = graphql`
	query systemEmail_Query {
		Viewer {
			Reports {
				SystemEmail
			}
		}
	}
`;
