import { createContext, useContext, useState } from "react";
import { STORAGE_KEY_TRIAL_LESSON_PROGRESS } from "../../consts";
import { LessonProgress, TrialProgressTrackerContextType } from "./trial-progress-tracker.types";

export const TrialProgressTrackerContext = createContext<TrialProgressTrackerContextType>({
	lessonProgress: [],
	setLessonProgress: () => {},
	removeLessonProgress: () => false,
});

export const useTrialProgressTrackerContext = () => {
	const context = useContext(TrialProgressTrackerContext);
	if (!context) {
		throw new Error(
			"useTrialProgressTrackerContext must be used within a TrialProgressTrackerProvider",
		);
	}
	return context;
};

export const TrialProgressTrackerProvider = ({ children }: { children: React.ReactNode }) => {
	const [lessonProgress, setLessonProgress] = useState<LessonProgress[]>([]);

	const handleSetLessonProgress = (toAddLessonProgress: LessonProgress) => {
		const oldLessonProgress = lessonProgress.find(
			({ abbreviation }) => abbreviation === toAddLessonProgress.abbreviation,
		);
		const newLessonProgress = [...lessonProgress];
		if (!oldLessonProgress) {
			newLessonProgress.push(toAddLessonProgress);
		} else {
			newLessonProgress[newLessonProgress.indexOf(oldLessonProgress)] = toAddLessonProgress;
		}
		setLessonProgress(newLessonProgress);
		localStorage.setItem(
			STORAGE_KEY_TRIAL_LESSON_PROGRESS,
			newLessonProgress
				.map(({ abbreviation, progress }) => `${abbreviation}_${progress}`)
				.join(","),
		);
	};

	const handleRemoveLessonProgress = (abbreviation: string) => {
		let hadToRemove = false;
		const newLessonProgress = lessonProgress.filter(({ abbreviation: item }) => {
			if (item === abbreviation) {
				hadToRemove = true;
				return false;
			}
			return true;
		});

		setLessonProgress(newLessonProgress);
		localStorage.setItem(
			STORAGE_KEY_TRIAL_LESSON_PROGRESS,
			newLessonProgress
				.map(({ abbreviation, progress }) => `${abbreviation}_${progress}`)
				.join(","),
		);

		return hadToRemove;
	};

	return (
		<TrialProgressTrackerContext.Provider
			value={{
				lessonProgress,
				setLessonProgress: handleSetLessonProgress,
				removeLessonProgress: handleRemoveLessonProgress,
			}}
		>
			{children}
		</TrialProgressTrackerContext.Provider>
	);
};
