/**
 * @generated SignedSource<<e6a637ff9ad990c7158d7df462474351>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentNodeAdvancementStatus = "BlockedByTree" | "CanBeRestartedAfterFailed" | "CanBeRestartedAfterPassed" | "CanBeStarted" | "CanNotBeRestartedAfterFailed" | "CanNotBeRestartedAfterPassed" | "CanNotBeStarted" | "Continue" | "NotContentNode" | "ViolatesTreeFlow";
export type ContentSubmissionStatus = "active" | "failed" | "passed";
export type RewardKind = "CrmTreeHeadUpdater" | "ExternalLicense" | "GamificationPoints" | "IHKCertificate" | "LearnStreak";
export type RewardResultKind = "CrmTreeHeadUpdater_ErrorOccurred" | "CrmTreeHeadUpdater_HeadUpdated" | "CrmTreeHeadUpdater_NotPassed" | "ExternalLicense_ErrorOccurred" | "ExternalLicense_IssuedToUser" | "ExternalLicense_NotPassed" | "GamificationPoints_AlreadyReceivedPoints" | "GamificationPoints_ErrorOccurred" | "GamificationPoints_NoPointsReceived" | "GamificationPoints_NotPassedIHKTestFlow" | "GamificationPoints_PointsReceived" | "IHKCertificate_ErrorOccurred" | "IHKCertificate_ReadyToOrder" | "LearnStreak_ErrorOccurred" | "LearnStreak_LearnStreakUpdated";
export type ViewerTreeStateKind = "CanBeStarted" | "CanNotBeStarted" | "IsFinished" | "IsStarted" | "NotVisible";
import { FragmentRefs } from "relay-runtime";
export type contentFinished_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly relativeProgress?: {
      readonly percentageCorrect: number;
    };
    readonly rewardResults?: ReadonlyArray<{
      readonly kind?: RewardResultKind;
      readonly points?: {
        readonly num: number;
      } | null | undefined;
      readonly rewardKind?: RewardKind;
      readonly " $fragmentSpreads": FragmentRefs<"contentFinishedRewardItem_RewardResultFragment">;
    }>;
    readonly status?: ContentSubmissionStatus;
  };
  readonly id: string;
  readonly learnOpportunity: {
    readonly id: string;
    readonly nextContentNodeId: string | null | undefined;
    readonly root: {
      readonly id: string;
      readonly structureDefinition: {
        readonly viewerTreeState?: {
          readonly kind: ViewerTreeStateKind;
        };
      };
    } | null | undefined;
    readonly typeDefinition: {
      readonly contentNodeAdvancementResult?: {
        readonly status: ContentNodeAdvancementStatus;
      };
      readonly extension?: {
        readonly receivableGamificationPoints?: number;
        readonly receivedGamificationPoints?: number;
      };
    };
  } | null | undefined;
  readonly " $fragmentType": "contentFinished_ContentSubmissionFragment";
};
export type contentFinished_ContentSubmissionFragment$key = {
  readonly " $data"?: contentFinished_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentFinished_ContentSubmissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "rewardResults",
  "plural": true,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rewardKind",
          "storageKey": null
        }
      ],
      "type": "IHKCertificate_ReadyToOrderRewardResult",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GamificationPointsReceivedByContent",
          "kind": "LinkedField",
          "name": "points",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "num",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "GamificationPoints_PointsReceivedRewardResult",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentFinishedRewardItem_RewardResultFragment"
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentFinished_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "ELearningContentSubmissionDefinition",
          "abstractKey": "__isELearningContentSubmissionDefinition"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ELearningContentSubmissionRelativeProgress",
              "kind": "LinkedField",
              "name": "relativeProgress",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "percentageCorrect",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "type": "PassedELearningContentSubmissionDefinition",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v3/*: any*/)
          ],
          "type": "FailedELearningContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "learnOpportunity",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnOpportunityV2",
          "kind": "LinkedField",
          "name": "root",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "structureDefinition",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "viewerTreeState",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LearnOpportunityRootStructureDefinition",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "typeDefinition",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "contentNodeAdvancementResult",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityContentTypeDefinition",
              "abstractKey": "__isLearnOpportunityContentTypeDefinition"
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "extension",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "receivableGamificationPoints",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "receivedGamificationPoints",
                          "storageKey": null
                        }
                      ],
                      "type": "LearnContentExtensionImpl",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityELearningContentTypeDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nextContentNodeId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "48f2ab0f430efcb7c0edc1be69f3768a";

export default node;
