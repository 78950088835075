import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { parseHtml } from "@thekeytechnology/academies-lib-webapp/utils/parse-html";
import { Icon } from "@thekeytechnology/epic-ui";
import { useRef } from "react";
import { useFragment } from "react-relay";
import { ReminderForm, ReminderFormRef } from "@components/reminder-form";
import { useToast } from "@hooks/useToast";
import { L1Span } from "@themes/font-tags";
import { REMINDER_LEARN_ELEMENT_FRAGMENT } from "./reminder-learn-element.graphql";
import {
	buttonClass,
	reminderLocationClass,
	reminderWrapperClass,
	wrapperClass,
} from "./reminder-learn-element.styles";
import { ReminderLearnElementProps } from "./reminder-learn-element.types";

export const ReminderLearnElement = ({ learnElementFragmentRef }: ReminderLearnElementProps) => {
	const element = useFragment(REMINDER_LEARN_ELEMENT_FRAGMENT, learnElementFragmentRef ?? null);
	const reminderFormRef = useRef<ReminderFormRef>(null);
	const toast = useToast();

	const handleReminderFormOnSubmit = async () => {
		const isValid = await reminderFormRef.current?.validate();
		if (!isValid) return;
		await reminderFormRef.current?.submit();
	};

	const handleReminderFormOnSuccess = () => {
		toast.showSuccess({
			summary: "Erinnerung wurde erfolgreich erstellt!",
		});
	};

	return (
		<div className={wrapperClass}>
			{element?.text && parseHtml(element.text)}
			<div className={reminderWrapperClass}>
				<ReminderForm ref={reminderFormRef} onSuccess={handleReminderFormOnSuccess} />
				<Button
					label="Erinnerung erstellen"
					className={buttonClass}
					onClick={handleReminderFormOnSubmit}
				/>
			</div>
			<div className={reminderLocationClass}>
				<Icon icon="question" sizeRem={0.875} />
				<L1Span>Du findest deine Erinnerungen in deinem Profil</L1Span>
			</div>
		</div>
	);
};
