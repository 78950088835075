import { Skeleton } from "@thekeytechnology/epic-ui";
import { H2Span } from "@themes/font-tags";
import {
	gridClass,
	gridItemClass,
	sectionClass,
	sectionHeaderClass,
} from "./learnables-grid.styles";

export const LearnablesGridSkeleton = () => {
	return (
		<section className={sectionClass}>
			<div className={sectionHeaderClass}>
				<H2Span>Deine Weiterentwicklung</H2Span>
			</div>

			<div className={gridClass}>
				{Array.from({ length: 10 }).map((_, index) => (
					<div key={index} className={gridItemClass}>
						<Skeleton width="100%" height="100%" />
					</div>
				))}
			</div>
		</section>
	);
};
