import { css, cva } from "@styled-system/css";

export const headlineWrapperClass = css({
	mb: "4",
});

export const textWrapperClass = css({
	mt: "12",
	color: "shade.80",
});

export const textClass = cva({
	base: {
		color: "shade.100",
	},
	variants: {
		listIsCheckIcons: {
			true: {
				"& li": {
					position: "relative",
					listStyleType: "none",
					_before: {
						content: "''",
						display: "inline-block",
						width: "1.3125rem",
						height: "1.25rem",
						left: "0",
						transform: "translateY(20%)",
						marginRight: "0.5rem",
						marginLeft: "-1.8rem",
						backgroundImage: "url(/check-goal.svg)",
					},
				},
			},
		},
	},
});
