import { BusinessTypeOptions } from "./business-type-dropdown.consts";
import { BusinessType, BusinessTypeOptionsRecord } from "./business-type-dropdown.types";

export const getBusinessTypeOptions = () => {
	const translations: BusinessTypeOptionsRecord = {
		[BusinessTypeOptions.Ag]: "AG",
		[BusinessTypeOptions.Ev]: "Ev",
		[BusinessTypeOptions.Einzelunternehmen]: "Einzelunternehmen",
		[BusinessTypeOptions.Gmbh]: "GmbH",
		[BusinessTypeOptions.Gbr]: "GbR",
		[BusinessTypeOptions.Kg]: "KG",
		[BusinessTypeOptions.Ohg]: "OHG",
		[BusinessTypeOptions.Ug]: "UG",
		[BusinessTypeOptions.OeffentlicheEinrichtung]: "Öffentliche Einrichtung",
		[BusinessTypeOptions.GmbhCoKg]: "GmbH & Co. KG",
		[BusinessTypeOptions.PartmbH]: "Partnerschaftsgesellschaft mit beschränkter Berufshaftung",
		[BusinessTypeOptions.Stiftung]: "Stiftung",
		[BusinessTypeOptions.Unknown]: "Unbekannt",
	};
	return Object.keys(translations)
		.sort()
		.map((key) => ({
			value: key,
			label: translations[key as unknown as BusinessType],
		}));
};

export const getAvailableBusinessType = (companyType?: string): BusinessType | undefined => {
	if (
		companyType &&
		Object.values(BusinessTypeOptions).includes(companyType as BusinessTypeOptions)
	)
		return companyType as BusinessType;
	return undefined;
};
