import { TabPanel, type TabViewTabChangeEvent } from "primereact/tabview";
import { useContext } from "react";
import { TabBar } from "@components/tab-bar";
import { NotesScreenContext } from "@screens/notes/notes.context";
import { tabPanelClass, tabPanelContainerClass } from "./notes-tabbar.styles";
import { NotesOverview } from "../notes-overview";
import { ReminderOverviewComponent } from "../reminder-overview";

export const NotesTabbar = () => {
	const {
		activeIndex,
		helpers: { setActiveIndex },
	} = useContext(NotesScreenContext);

	const handleOnTabChange = (event: TabViewTabChangeEvent) => {
		setActiveIndex(event.index);
	};

	return (
		<TabBar
			className={tabPanelContainerClass}
			panelContainerClassName={tabPanelContainerClass}
			activeIndex={activeIndex}
			onTabChange={handleOnTabChange}
			disableMaxParentHeight
		>
			<TabPanel header="Notizen" className={tabPanelClass}>
				<NotesOverview />
			</TabPanel>
			<TabPanel header="Erinnerungen" className={tabPanelClass}>
				<ReminderOverviewComponent />
			</TabPanel>
		</TabBar>
	);
};
