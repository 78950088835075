import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { OfferCard } from "@components/offer-card";
import { TrialProgressTracker } from "@components/trial-progress-tracker";
import { learnablesGrid_Query } from "@relay/learnablesGrid_Query.graphql";
import { learnablesGrid_QueryFragment$key } from "@relay/learnablesGrid_QueryFragment.graphql";
import { learnablesGrid_RefetchQuery } from "@relay/learnablesGrid_RefetchQuery.graphql";
import { Path } from "@router/paths";
import { brandStrong120 } from "@themes/colors";
import { H2Span } from "@themes/font-tags";
import { QUERY, QUERY_FRAGMENT } from "./learnables-grid.graphql";
import { LearnablesGridSkeleton } from "./learnables-grid.skeleton";
import {
	gridClass,
	gridItemClass,
	nextButtonWrapperClass,
	sectionClass,
	sectionHeaderClass,
} from "./learnables-grid.styles";

const LearnablesGridComponent = () => {
	const navigate = useNavigate();

	const query = useLazyLoadQuery<learnablesGrid_Query>(
		QUERY,
		{ kinds: [], tagIds: [], first: 8 },
		{ fetchPolicy: "store-and-network" },
	);

	const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment<
		learnablesGrid_RefetchQuery,
		learnablesGrid_QueryFragment$key
	>(QUERY_FRAGMENT, query);

	const learnables =
		data?.Learnable.PublishedLearnables.edges?.map((edge) => edge?.node).filter(Boolean) ?? [];

	const handleCardOnClick = (learnableId: string) => {
		navigate(Path.root.withId(learnableId).overview.path);
	};

	const handleLoadNext = () => {
		loadNext(8);
	};

	const handleOnYourOffersClick = () => {
		navigate(Path.progression.path);
	};

	return (
		<>
			<TrialProgressTracker
				publishedLearnablesConnectionFragmentRef={data?.Learnable.PublishedLearnables}
			/>

			<section className={sectionClass}>
				<div className={sectionHeaderClass}>
					<H2Span>Deine Weiterentwicklung</H2Span>
					<Button
						label="Deine Angebote"
						colorVersion="textlink"
						textLinkColor={brandStrong120}
						onClick={handleOnYourOffersClick}
					/>
				</div>
				<EmptyPlaceholder
					isVisible={learnables.length === 0}
					title="Keine Weiterbildungen gefunden"
					subtitle="Du hast aktuell keine Weiterbildungen in deinem Konto."
					buttonLabel="Deine Angebote"
					onClick={handleOnYourOffersClick}
				>
					<div className={gridClass}>
						{learnables.map((learnable) => (
							<div key={learnable.id} className={gridItemClass}>
								{learnable.kind === "Course" && (
									<OfferCard
										onClick={handleCardOnClick}
										publishedLearnOpportunityV2FragmentRef={learnable.root}
									/>
								)}
							</div>
						))}
					</div>
					{hasNext && (
						<div className={nextButtonWrapperClass}>
							<Button
								type="button"
								onClick={handleLoadNext}
								loading={isLoadingNext}
								label="Weitere Kurse laden"
							/>
						</div>
					)}
				</EmptyPlaceholder>
			</section>
		</>
	);
};

export const LearnablesGrid = withSuspense(LearnablesGridComponent, LearnablesGridSkeleton);
