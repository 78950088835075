/**
 * @generated SignedSource<<b0718e4c8231e65cddbcf6b0b1e035ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type membersFilter_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"filterModal_QueryFragment">;
  readonly " $fragmentType": "membersFilter_QueryFragment";
};
export type membersFilter_QueryFragment$key = {
  readonly " $data"?: membersFilter_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"membersFilter_QueryFragment">;
};

import membersFilterRefetchQuery_graphql from './membersFilterRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": membersFilterRefetchQuery_graphql
    }
  },
  "name": "membersFilter_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "filterModal_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "223489280f27434abc583fa1996b358a";

export default node;
