export type CookieManager<T = string> = {
	cookie: T;
	cookieExists: boolean;
	setCookie: (value: T, options?: CookieSetOptions) => void;
};

export enum Cookie {
	TKAOptin = "tka-optin",
}

export interface CookieSetOptions {
	path?: string;
	expires?: Date;
	maxAge?: number;
	domain?: string;
	secure?: boolean;
	httpOnly?: boolean;
	sameSite?: boolean | "none" | "lax" | "strict";
}
