import { CardElement } from "@stripe/react-stripe-js";
import { useFormik } from "formik";
import { ForwardedRef, forwardRef, useImperativeHandle } from "react";
import { CreditcardFormClass, CreditcardFormProps } from "./creditcard-form.types";

//TODO: add-translations
const CreditcardFormComponent = ({ onSubmit }: CreditcardFormProps, ref: ForwardedRef<any>) => {
	const form = useFormik({
		initialValues: {},
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: (values) => {
			onSubmit?.(values);
		},
	});

	useImperativeHandle(ref, () => ({
		submit: form.submitForm,
	}));

	return (
		<form onSubmit={form.handleSubmit}>
			<CardElement id="card-element" />
		</form>
	);
};

export const CreditcardForm = forwardRef(CreditcardFormComponent);
export type CreditcardForm = CreditcardFormClass;
