import { Skeleton } from "@thekeytechnology/epic-ui";
import { Element as ReactScrollElement } from "react-scroll";
import { useTheme } from "styled-components";
import { ChipSkeleton } from "@components/chip/chip.skeleton";
import { TextParagraphSkeleton } from "@components/text-paragraph/text-paragraph.skeleton";
import { TrailerSectionId } from "./root-overview.const";
import {
	wrapperClass,
	trailerSectionClass,
	titlePointsWrapperClass,
	textParagraphWrapperClass,
} from "./root-overview.styles";

export const RootOverviewSkeleton = () => {
	const theme = useTheme();

	return (
		<div className={wrapperClass}>
			<div className={titlePointsWrapperClass}>
				<Skeleton height="2rem" width="20rem" />
				<ChipSkeleton />
			</div>
			<div className={textParagraphWrapperClass}>
				<TextParagraphSkeleton />
			</div>
			<ReactScrollElement
				name={TrailerSectionId}
				id={TrailerSectionId}
				className={trailerSectionClass}
			>
				<Skeleton
					width="100%"
					height="20rem"
					borderRadius={theme.borderRadius?.borderRadius12}
				/>
			</ReactScrollElement>
		</div>
	);
};
