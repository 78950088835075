import { graphql } from "react-relay";

export const QUERY = graphql`
	query intercom_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						id
						name
						email
						extensions {
							kind
							... on TkaUserExtension {
								intercomUserWebHash
							}
						}
					}
				}
			}
		}
	}
`;
