import { graphql } from "react-relay";

export const QUERY = graphql`
	query userHeader_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						id
						name
						extensions {
							kind
							... on AcademiesUserExtension {
								firstName
								lastName
								level
								progressInPercent
								sumGamificationPoints
							}
							... on TkaUserExtension {
								levelDefinition {
									id
									title
								}
							}
						}
						...userAvatar_UserFragment
					}
				}
			}
		}
	}
`;
