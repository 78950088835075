import { css } from "@styled-system/css";
import { flex, stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	gap: "16",
	padding: "16",
});

export const listWrapperClass = stack({
	gap: "12",
});

export const listItemClass = flex({
	gap: "8",
	alignItems: "flex-start",
});

export const referenceNumberWrapperClass = css({
	color: "secondary.120",
	fontWeight: "bold",
});

export const centerWrapperClass = flex({
	alignItems: "center",
	justifyContent: "center",
});
