import { Slider } from "@thekeytechnology/academies-lib-webapp/components/slider";
import { Skeleton } from "@thekeytechnology/epic-ui";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import styled, { css } from "styled-components";

const FullScreenCSS = css`
	position: fixed;
	inset: 0px;
	background-color: ${({ theme }) => theme.colors.shade100};
`;

export const Wrapper = styled.div<{ fullScreen: boolean }>`
	display: flex;
	max-height: 100%;
	max-width: 100%;
	aspect-ratio: 16 / 9;
	${({ fullScreen }) => fullScreen && !screenfull.isEnabled && FullScreenCSS}
	${({ fullScreen }) =>
		fullScreen &&
		`
		justify-content: center;
		align-items: center;
	`}
`;

export const CenterWrapper = styled.div<{ fullScreen: boolean }>`
	position: relative;
	max-height: 100%;
	flex-grow: 1;
	border-radius: ${({ theme }) => theme.spacing.spacing10.rem()};
	overflow: hidden;
	background-color: transparent;
	z-index: 10;
	max-width: ${({ fullScreen }) => (fullScreen ? "calc( 100dvh * 16/9 )" : "100%")};
	${({ fullScreen }) => fullScreen && "border-radius: 0;"}
`;

export const StyledReactPlayer = styled(ReactPlayer)`
	aspect-ratio: 16 / 9;
	height: auto !important;
	max-height: 100%;
`;

export const Backdrop = styled.div<{ hidden?: boolean }>`
	position: absolute;
	display: flex;
	inset: 0px;
	background-color: ${({ theme }) => theme.colors.shade80};
	opacity: 0.6;
	transition: opacity 200ms ease-out;
	${({ hidden }) => hidden && "opacity: 0;"}
`;

export const Overlay = styled.div<{ hidden?: boolean; showBottomControls?: boolean }>`
	position: absolute;
	display: flex;
	flex-direction: column;
	inset: 0px;
	transition: opacity 100ms ease-out;

	${({ hidden }) => hidden && "display: none;"}
	&.tka-video-controls .tka-bottom-controls {
		${({ showBottomControls }) => !showBottomControls && "display: none;"}
		@media (min-width: ${({ theme }) => theme.breakpoints.large}px) {
			display: none;
		}
	}
	&.tka-video-controls:hover .tka-bottom-controls {
		@media (min-width: ${({ theme }) => theme.breakpoints.large}px) {
			display: block;
		}
	}
`;

export const TopControls = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-height: 0;
	gap: ${({ theme }) => theme.spacing.spacing24.rem()};
	padding-left: ${({ theme }) => theme.spacing.spacing12.rem()};
	padding-right: ${({ theme }) => theme.spacing.spacing12.rem()};
	padding-top: ${({ theme }) => theme.spacing.spacing12.rem()};
	z-index: 10;
	/* landscape:pl-20
	landscape:pr-20
	landscape:pt-16 */
`;

export const TopShadow = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 3rem;
	z-index: -1;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 98.48%);

	@media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
		display: none;
	}
`;

export const TranscriptsWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: center;
`;

export const TopRightControls = styled.div<{ showControls?: boolean }>`
	display: flex;
	flex-direction: row;
	gap: ${({ theme }) => theme.spacing.spacing24.rem()};

	@media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
		display: none;
	}

	${({ showControls }) => !showControls && "display: none;"}
`;

export const CenterControls = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
	min-height: 0;
	padding: ${({ theme }) => theme.spacing.spacing12.rem()};

	@media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
		padding: ${({ theme }) => theme.spacing.spacing20.rem()};
	}
`;

export const CenterPlayControls = styled.div<{ hidden?: boolean }>`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.spacing24.rem()};
	${({ hidden }) => hidden && "display: none;"}
	&>* {
		cursor: pointer;
	}
`;

export const InitialPlayButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3.5rem;
	height: 3.5rem;
	background-color: ${({ theme }) => theme.colors.secondary100};
	border-radius: 1.75rem;
	padding-left: ${({ theme }) => theme.spacing.spacing4.rem()};

	@media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
		width: 6.5rem;
		height: 6.5rem;
		border-radius: 3.75rem;
		padding-left: ${({ theme }) => theme.spacing.spacing8.rem()};
	}
`;

export const CenterPlayBackground = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3.5rem;
	height: 3.5rem;
	background-color: ${({ theme }) => theme.colors.shade100.withAlpha(0.5)};
	border-radius: 1.75rem;
`;

export const CenterRewindBackground = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.75rem;
	height: 2.75rem;
	background-color: ${({ theme }) => theme.colors.shade100.withAlpha(0.5)};
	border-radius: 1.375rem;
`;

export const BottomControls = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	padding-left: ${({ theme }) => theme.spacing.spacing12.rem()};
	padding-right: ${({ theme }) => theme.spacing.spacing12.rem()};
	padding-bottom: ${({ theme }) => theme.spacing.spacing12.rem()};
	z-index: 10;

	@media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
		padding-left: ${({ theme }) => theme.spacing.spacing20.rem()};
		padding-right: ${({ theme }) => theme.spacing.spacing20.rem()};
		padding-bottom: ${({ theme }) => theme.spacing.spacing20.rem()};
	}
`;

export const BottomShadow = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 3.5rem;
	background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 98.48%);
	z-index: -1;

	@media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
		height: 5.5rem;
	}
`;

export const MenuWrapperAnchor = styled.div`
	position: relative;
`;

export const MenuWrapper = styled.div`
	position: absolute;
	display: flex;
	bottom: 0;
	right: 0;
	max-height: 100%;
	z-index: 10;
`;

export const SplitControlsWrapper = styled.div`
	display: none;
	flex-direction: row;
	justify-content: space-between;
	padding-top: ${({ theme }) => theme.spacing.spacing16.rem()};

	@media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
		display: flex;
	}
`;

export const ControlsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${({ theme }) => theme.spacing.spacing24.rem()};
	align-items: center;
`;

export const SmallVideoTime = styled.div`
	padding-bottom: ${({ theme }) => theme.spacing.spacing12.rem()};
`;

export const VolumeWrapper = styled.div`
	position: relative;
`;

export const VolumeSliderWrapper = styled.div`
	position: absolute;
	bottom: ${({ theme }) => theme.spacing.spacing64.rem()};
	left: -1rem;
	background-color: ${({ theme }) => theme.colors.primary100.withAlpha(0.9)};
	border-radius: ${({ theme }) => theme.spacing.spacing10.rem()};
	padding: ${({ theme }) => theme.spacing.spacing24.rem()};
`;

export const VolumeSlider = styled(Slider)`
	height: 7rem;
`;

export const LoadinAnimation = styled(Skeleton)`
	position: absolute;
	inset: 0px;
	z-index: -10;
`;

export const CenterPlayWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: ${({ theme }) => theme.spacing.spacing16.rem()};
`;
