import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { EpicH2Span } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { useTheme } from "styled-components";
import { DynamicSlideMenuContext } from "@components/dynamic-slide-menu-provider";
import { type DynamicSlideMenuParentProps } from "./dynamic-slide-menu-parent.interface";
import { BackWrapper, HeaderWrapper, ParentWrapper } from "./dynamic-slide-menu-parent.styles";

export const DynamicSlideMenuParent = ({
	title,
	width,
	path,
	children,
}: DynamicSlideMenuParentProps) => {
	const theme = useTheme();
	const context = useContext(DynamicSlideMenuContext);
	const { path: currentPath, goBack, depth } = context;

	const canGoBack = currentPath.length > 1;

	if (!currentPath.includes(path)) return null;

	return currentPath.includes(path) && depth === currentPath.length ? (
		<ParentWrapper
			width={width}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			{(canGoBack || title) && (
				<HeaderWrapper>
					{canGoBack && (
						<BackWrapper>
							<Icon
								icon={"arrowLeft2"}
								color={theme.colors.shade0}
								sizeRem={0.75}
								onClick={goBack}
							/>
						</BackWrapper>
					)}
					{title && <EpicH2Span $color={theme.colors.shade0}>{title}</EpicH2Span>}
				</HeaderWrapper>
			)}
			{children}
		</ParentWrapper>
	) : (
		<>{children}</>
	);
};
