import { css } from "@styled-system/css";

export const wrapperClass = css({
	width: "full",
});

export const videoWrapperClass = css({
	borderRadius: "10",
	overflow: "hidden",
	marginTop: "12",
});
