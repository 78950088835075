import { css } from "@styled-system/css";
import { grid, stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	gap: "24",
});

export const gridClass = grid({
	gap: "24",
	gridTemplateColumns: "12",
	gridAutoRows: "[minmax(0, 1fr)]",
});

export const gapClass = css({
	height: "24",
});

export const gridItemClass = css({
	gridColumn: "full",
	rowGap: "16",
	md: {
		gridColumn: "6",
	},
	lg: {
		gridColumn: "4",
	},
	"2xl": {
		gridColumn: "3",
	},
});

export const nextButtonWrapperClass = css({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "full",
	marginTop: "24",
});
