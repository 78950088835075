import { DialogTemplate } from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { colorShade100Class } from "@themes/color-classes";
import { shade0 } from "@themes/colors";
import { H1Span, P2Span } from "@themes/font-tags";
import {
	contentWrapperClass,
	iconCircleClass,
	wrapperClass,
} from "./licenses-remove-rule-modal.styles";
import { LicensesRemoveRuleModalProps } from "./licenses-remove-rule-modal.types";

export const LicensesRemoveRuleModal = ({
	isVisible,
	content,
	onCancel,
	onSubmit,
}: LicensesRemoveRuleModalProps) => {
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onCancel}>
			<DialogTemplate
				primaryButtonStretch={true}
				primaryButtonLabel="Zuweisung löschen"
				primaryButtonColorVersion="error"
				onPrimaryButtonClick={onSubmit}
				secondaryButtonLabel="Abbrechen"
				secondaryButtonStretch={false}
				onSecondaryButtonClick={onCancel}
			>
				<div className={wrapperClass}>
					<H1Span className={colorShade100Class}>Zuweisung wirklich löschen?</H1Span>
					<P2Span>
						Durch das Löschen der Zuweisung gehen dir die folgenden Daten für immer
						verloren und können nicht wiederhergestellt werden:
					</P2Span>
					<div className={contentWrapperClass}>
						<div className={iconCircleClass}>
							<Icon icon="close" sizeRem={0.75} color={shade0} />
						</div>
						{content}
					</div>
				</div>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
