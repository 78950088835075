import { css } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const wrapperClass = css({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	width: "full",
	border: "1px solid",
	borderColor: "shade.20",
	borderRadius: "12",
	padding: "12",
	paddingRight: "24",
	boxShadow: "card",
});

export const dataWrapperClass = css({
	display: "flex",
	alignItems: "center",
	gap: "12",
});

export const iconWrapperClass = css({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	padding: "8",
	borderRadius: "12",
	backgroundColor: "shade.5",
});

export const textWrapperClass = stack({
	gap: "0",
});
