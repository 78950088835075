import { css } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	height: "full",
});

export const headerClass = css({
	display: "flex",
	justifyContent: "space-between",
	width: "full",
	marginBottom: "24",
});

export const purpleGradientClass = css({
	position: "relative",
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	justifyContent: "space-between",
	padding: "32",
	borderRadius: "12",
	width: "full",
	background: "[rgba(83, 39, 230, 0.60)]",
	boxShadow:
		"[0px 1px 1px 0px rgba(16, 24, 32, 0.04), 0px 2px 5px 0px rgba(16, 24, 32, 0.02), 0px 4px 0px 0px rgba(41, 19, 113, 0.11)]",
	xl: {
		flexDirection: "row",
	},
	_before: {
		content: "''",
		position: "absolute",
		top: "0",
		left: "0",
		right: "0",
		bottom: "0",
		zIndex: "1",
		borderRadius: "12",
		background: "[radial-gradient(150% 417.55% at 5.45% 43.49%, #8E73EB 0%, #5327E6 60%)]",
	},
});

export const userEditButtonClass = css({
	position: "absolute",
	zIndex: "10",
	top: "12",
	right: "12",
	display: "flex",
	alignItems: "center",
	gap: "4",
	paddingX: "8",
	paddingY: "[3px]",
	backgroundColor: "shade.0",
	borderRadius: "4",
});

export const carouselContentClass = css({
	display: "flex",
});

export const carouselContainerClass = css({
	md: {
		gap: "16",
	},
});

export const carouselItemClass = css({
	width: "full",
	flex: "[1 0 100%]",
	md: {
		flex: "[1 0 calc(100% / 2 - 8px)!important]",
	},
	lg: {
		flex: "[1 0 calc(100% / 3 - 12px)!important]",
	},
});

export const newsCarouselItemClass = css({
	width: "full",
	flex: "[1 0 100%]",
	md: {
		flex: "[1 0 calc(100% / 3 - 8px)!important]",
	},
	lg: {
		flex: "[1 0 calc(100% / 4 - 12px)!important]",
	},
});

export const carouselIndicatorContainerClass = css({
	display: "flex",
	alignItems: "center",
	gap: "[6px]",
	paddingBottom: "4!",
});

export const carouselIndicatorClass = css({
	width: "[6px]",
	height: "[6px]",
	marginRight: "0!",
	marginBottom: "0!",
	borderRadius: "full",
	bg: "shade.10",
	"& > *": {
		width: "[6px!]",
		height: "[6px!]",
	},
	"&.p-highlight": {
		width: "12",
		height: "12",
		bg: "primary.100",
		"& > *": {
			width: "12!",
			height: "12!",
		},
	},
});

export const carouselIndicatorButtonClass = css({
	borderRadius: "full!",
	bg: "transparent!",
});

export const sectionClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "8",
	marginTop: "24",
});

export const sectionHeaderClass = css({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
});

export const gridClass = css({
	display: "grid",
	gap: "24",
	gridTemplateColumns: "1",
	gridAutoRows: "fr",
	md: {
		gridTemplateColumns: "2",
	},
	lg: {
		gridTemplateColumns: "3",
	},
	"2xl": {
		gridTemplateColumns: "4",
	},
});

export const gapClass = css({
	height: "[1.5rem]",
});

export const gridItemClass = css({
	flexBasis: "100%",
	width: "full",
	height: "full",
	pb: "8",
});

export const nextButtonWrapperClass = css({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "full",
	marginTop: "12",
});
