import { flex, stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	gap: "12",
});

export const contentWrapperClass = flex({
	alignItems: "center",
	gap: "8",
});

export const iconCircleClass = flex({
	width: "20",
	height: "20",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: "error.100",
	borderRadius: "full",
	border: "none",
});
