import { type PropsWithChildren } from "react";

export type BranchNodeCardProps = PropsWithChildren<{
	title: string;
	imageUrl?: string;
	moduleNumber: number;
	status: BranchNodeStatus;
	indexPath: readonly number[];
	headIndexPath?: readonly number[] | null;
	contentsCount: number;
	isFinished: boolean;
	totalPoints?: number;
	receivedPoints?: number;
	showImage?: boolean;
	hasMarginLeft?: boolean;
}>;

export enum BranchNodeStatus {
	locked = "locked",
	unlocked = "unlocked",
	done = "done",
}
