import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	direction: "column",
	minHeight: "screen",
	maxWidth: "screen",
	overflowX: "hidden",
});

export const contentWrapperClass = css({
	flex: "1",
});
