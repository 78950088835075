import { News } from "./dashboard.types";

function formatSlug(slug: string) {
	return slug
		.split("-")
		.map((word) => word.replace("ae", "ä").replace("oe", "ö").replace("ue", "ü"))
		.map((word, i) => (i !== 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
		.join(" ");
}

export function getNews(controller: AbortController): Promise<News[]> {
	return fetch("https://www.thekey.academy/wp-json/wp/v2/posts?per_page=5&_embed", {
		signal: controller.signal,
	})
		.then((response) => response.json())
		.then((response) => {
			const responseNews = response as any[];
			const list = responseNews
				.map(
					(raw: {
						id: string;
						date: string;
						slug?: string;
						title: { rendered: string };
						excerpt: { rendered: string };
						link: string;
						_embedded: {
							"wp:featuredmedia": any;
							"wp:term": {
								id: number;
								name: string;
							}[][];
						};
					}) => {
						try {
							const featuredMedia = raw._embedded[`wp:featuredmedia`];
							const firstFeaturesImage =
								featuredMedia.length > 0 ? featuredMedia[0] : undefined;
							const sizes = firstFeaturesImage?.media_details.sizes;
							const size =
								sizes.large ?? sizes.medium_large ?? sizes.medium ?? sizes.full;

							return {
								id: raw.id,
								date: new Date(raw.date),
								slug: formatSlug(raw.slug ?? ""),
								term: raw._embedded["wp:term"][0][0].name,
								title: raw.title.rendered,
								content: raw.title.rendered,
								excerpt: raw.excerpt.rendered,
								permalink: raw.link,
								image: size?.source_url,
							} as News;
						} catch (e) {
							return undefined;
						}
					},
				)
				.filter((e) => e !== undefined) as unknown as News[];
			return list;
		})
		.catch(() => []);
}
