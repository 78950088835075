import { stripHtml } from "@thekeytechnology/academies-lib-webapp/utils";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { graphql, useFragment, useMutation } from "react-relay";
import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider/content-submission-modal-context-provider.context";
import { OrderLearnElement } from "@components/order-learn-element";
import { OrderElement_ContentSubmissionFragment$key } from "@relay/OrderElement_ContentSubmissionFragment.graphql";
import { OrderElement_SubmitOrderElementMutation } from "@relay/OrderElement_SubmitOrderElementMutation.graphql";
import { shade100 } from "@themes/colors";
import { TkaH1Span } from "@themes/font-tags";
import { OrderElementProps } from "./order-element.interface";
import { ElementWrapper, Subtitle } from "./order-element.styles";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment OrderElement_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					kind
					... on InputAndIsCorrectElementState {
						isCorrect
						inputElementState {
							... on OrderInputElementState {
								selectedOrder
							}
						}
					}
					element {
						title
						id
						... on OrderLearnElement {
							id
							title
							answeringTypeText
							orderItems {
								id
								text
							}
						}
						...orderLearnElement_OrderLearnElementFragment
					}
				}
			}
		}
	}
`;

const SUBMIT_ORDER_ELEMENT_MUTATION = graphql`
	mutation OrderElement_SubmitOrderElementMutation($input: SubmitOrderElementInput!) {
		LearnV2 {
			submitOrderElement(input: $input) {
				clientMutationId
				contentSubmission {
					...ContentSubmissionScreen_ContentSubmissionFragment
					definition {
						... on ActiveELearningContentSubmissionDefinition {
							currentElementState {
								kind
								... on InputAndIsCorrectElementState {
									isCorrect
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const OrderElement = ({ contentSubmissionFragmentRef }: OrderElementProps) => {
	const contentSubmission = useFragment<OrderElement_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const [submitOrderElement] = useMutation<OrderElement_SubmitOrderElementMutation>(
		SUBMIT_ORDER_ELEMENT_MUTATION,
	);

	const elementState = contentSubmission?.definition?.currentElementState;
	const element = elementState?.element;

	const currentElementStateKind =
		contentSubmission?.definition?.currentElementState?.kind || "Untouched";
	const isInputCorrect = currentElementStateKind === "InputAndIsCorrect";
	const isUntouched =
		currentElementStateKind === "Untouched" ||
		currentElementStateKind === "UntouchedAndPreviouslyIncorrect";

	const isCorrect = elementState?.isCorrect;

	const {
		isModalVisible,
		setCanGoNext,
		addGoToNextOnClickListener,
		setFailureModalVisible,
		setSuccessModalVisible,
		setLoading,
	} = useContext(ContentSubmissionModalContext);

	const [orderElementsId, setOrderElementsId] = useState<string[]>([]);

	const dispatch = useDispatch();

	const handleSubmitClick = useCallback(() => {
		setLoading(true);
		submitOrderElement({
			variables: {
				input: {
					contentSubmissionId: contentSubmission.id!,
					orderElementId: element?.id!,
					order: orderElementsId,
				},
			},
			onCompleted: (response) => {
				setLoading(false);
				const currentElementState =
					response.LearnV2.submitOrderElement?.contentSubmission.definition
						?.currentElementState;
				if (!currentElementState) return;

				if (currentElementState.isCorrect) {
					setSuccessModalVisible(true);
				} else if (
					currentElementState.isCorrect === false ||
					currentElementState.kind === "UntouchedAndPreviouslyIncorrect"
				) {
					setFailureModalVisible(true);
				}
			},
		});
	}, [
		submitOrderElement,
		contentSubmission.id,
		element?.id,
		orderElementsId,
		setSuccessModalVisible,
		setFailureModalVisible,
	]);

	const handleGoToNextClicked = useCallback(() => {
		if (isUntouched) {
			handleSubmitClick();
			return true;
		}
		return false;
	}, [isUntouched, handleSubmitClick]);

	useEffect(() => addGoToNextOnClickListener(handleGoToNextClicked), [handleGoToNextClicked]);

	useEffect(() => {
		setCanGoNext(true);
	}, []);

	const handleDrop = useCallback(
		(droppedItem: any) => {
			if (!droppedItem.destination) return;
			const updatedList = [...orderElementsId];
			const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
			updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
			setOrderElementsId(updatedList);
		},
		[orderElementsId, dispatch],
	);

	const orderItemsIds = useMemo(
		() => element?.orderItems?.map((e) => e.id) || [],
		[element?.orderItems],
	);

	useEffect(() => {
		const selectedOrder = elementState?.inputElementState?.selectedOrder || [];
		const orderItems =
			isInputCorrect && selectedOrder.length > 0 ? selectedOrder : orderItemsIds;
		setOrderElementsId([...orderItems]);
	}, orderItemsIds);

	const getStatusForItem = (index: number, id: string) => {
		const wasCorrect = orderElementsId[index] === id;
		const revealStatus = wasCorrect ? "correct" : "actuallyCorrect";
		return isCorrect ? revealStatus : "default";
	};

	const dragDisabled = isModalVisible || !isUntouched;

	return (
		<ElementWrapper data-selection-menu-element-id={elementState?.element.id}>
			{element?.title && (
				<TkaH1Span tkaColor={shade100}>{stripHtml(element.title)}</TkaH1Span>
			)}
			{element?.answeringTypeText && (
				<Subtitle tkaColor={shade100}>{stripHtml(element?.answeringTypeText)}</Subtitle>
			)}

			<OrderLearnElement
				dragDisabled={dragDisabled}
				elementIds={orderElementsId}
				getStatusForIndexAndElementId={getStatusForItem}
				onDrop={handleDrop}
				learnElementFragmentRef={element}
			/>
		</ElementWrapper>
	);
};
