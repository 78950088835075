import { css, cva } from "@styled-system/css";
import { flex, grid, stack } from "@styled-system/patterns";

export const openButtonClass = css({
	position: "relative",
	paddingX: "12",
	paddingY: "[7px]",
	backgroundColor: "shade.0",
	borderRadius: "full",
});

export const openButtonIndicatorClass = css({
	position: "absolute",
	top: "0",
	right: "0",
	w: "16",
	h: "16",
	background: "secondary.100",
	borderRadius: "full",
	transform: "translate(18%, -18%)",
});

export const popoverWrapperClass = flex({
	position: "absolute",
	zIndex: "100",
	top: "40",
	right: "0",
	direction: "column",
	bg: "shade.0",
	borderRadius: "12",
	p: "24",
	shadow: "flyout",
	width: "[25rem]",
});

export const pricesWrapperClass = flex({
	direction: "column",
	gap: "8",
});

export const pricesItemClass = flex({
	direction: "row",
	justify: "space-between",
	alignItems: "center",
	gap: "8",
});

export const currencyTextClass = cva({
	base: {
		fontVariantNumeric: "tabular-nums",
	},
	variants: {
		color: {
			success: {
				color: "success.100",
			},
			default: {
				color: "shade.80",
			},
		},
	},
	defaultVariants: {
		color: "default",
	},
});

export const monthlyRateTextClass = css({
	color: "shade.60",
	fontSize: "12",
	lineHeight: "18",
});

export const buttonClass = css({
	width: "full",
});

export const productListClass = stack({
	gap: "8",
});

export const productWrapperClass = grid({
	position: "relative",
	gap: "12",
	width: "full",
	gridTemplateColumns: "[90px 1fr]",
	border: "1px solid",
	borderColor: "shade.20",
	borderRadius: "12",
	padding: "12",
});

export const productLeftClass = flex({
	flexDirection: "column",
	gap: "12",
	alignItems: "flex-start",
	width: "full",
});

export const productImageClass = css({
	height: "[84px]",
	width: "full",
	objectFit: "cover",
	borderRadius: "4",
});

export const controlWrapperClass = flex({
	alignItems: "center",
	justifyContent: "space-between",
	width: "full",
	gap: "8",
});

export const controlAmountTextClass = css({
	userSelect: "none",
});

export const productRightClass = flex({
	flexDirection: "column",
	justifyContent: "space-between",
	height: "full",
});

export const productTitleClass = css({
	maxWidth: "11/12",
});

export const productPriceClass = flex({
	alignSelf: "flex-end",
	flexDirection: "column",
	textAlign: "right",
});

export const deleteButtonClass = css({
	position: "absolute",
	top: "12",
	right: "[15px]",
	zIndex: "10",
});

export const dividerClass = css({
	marginY: "24",
	borderColor: "shade.10",
});
