import { Card } from "@thekeytechnology/academies-lib-webapp/components/card";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { Skeleton } from "@thekeytechnology/academies-lib-webapp/components/skeleton";
import { shade20 } from "@themes/colors";
import {
	wrapperClass,
	userWrapperClass,
	userDataWrapperClass,
	iconWrapperClass,
	nameWrapperClass,
	labelsWrapperClass,
} from "./licenses-single-user-assignment-card.styles";

export const LicensesSingleUserAssignmentCardSkeleton = () => {
	return (
		<Card bodyPaddingInRem={0.875} borderColor={shade20}>
			<div className={wrapperClass}>
				<div className={userWrapperClass}>
					<div className={userDataWrapperClass}>
						<div className={iconWrapperClass}>
							<Icon icon="user" sizeRem={1.5} />
						</div>
						<Skeleton width="2.5rem" height="2.5rem" borderRadius="2.5rem" />
						<div className={nameWrapperClass}>
							<Skeleton width="5rem" height="1.125rem" />
							<Skeleton width="10rem" height="1.25rem" />
						</div>
					</div>
					<div className={labelsWrapperClass}>
						<Skeleton width="10rem" height="1.125rem" />
					</div>
				</div>
			</div>
		</Card>
	);
};
