import { z } from "zod";

export const privateClientFormSchema = z.object({
	salutation: z.string({ required_error: "Bitte wähle eine Anrede aus." }),
	title: z.string().optional(),
	firstName: z.string({ required_error: "Bitte gib einen Vornamen ein." }),
	lastName: z.string({ required_error: "Bitte gib einen Nachnamen ein." }),
	street: z.string({ required_error: "Bitte gib eine Straße ein." }),
	houseNumber: z.string({ required_error: "Bitte gib eine Hausnummer ein." }),
	city: z.string({ required_error: "Bitte gib einen Ort ein." }),
	postCode: z
		.string({ required_error: "Bitte gib eine Postleitzahl ein." })
		.length(5, "Bitte gib eine Gültige Postleitzahl ein")
		.regex(/^[0-9]{5}/, "Bitte gib eine Gültige Postleitzahl ein"),
	phoneNumber: z
		.string()
		.regex(
			/^(\+[1-9]\d+)([1-9]\d+)([1-9]\d+)(\-\d+){0,1}$|^(0\d+)([1-9]\d+)(\-\d+){0,1}$|^([1-9]\d+)(\-\d+){0,1}$/,
			"Bitte gib eine gültige Telefonnummer ein.",
		)
		.optional(),
	dateOfBirth: z
		.date({ required_error: "Bitte gib ein Geburtsdatum ein." })
		.max(new Date(), "Bitte gib ein gültiges Geburtsdatum ein."),
});
