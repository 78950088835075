import { css, cva } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = css({
	display: "grid",
	height: "full",
	bg: "shade.5",
	gridTemplateRows: "[1fr min-content]",
	xl: {
		gridTemplateColumns: "[min-content 1fr]",
	},
});

export const navbarWrapperClass = css({
	zIndex: "10",
	gridRowStart: "2",
	gridRowEnd: "2",
	boxShadow: "[0rem -0.125rem 0.625rem 0rem token(primary.100/10)]",
	xl: {
		gridRow: "[auto]",
		boxShadow: "[none]",
	},
});

export const screenWrapperClass = flex({
	position: "relative",
	direction: "column",
	alignItems: "center",
	gridRowStart: "1",
	gridRowEnd: "1",
	overflow: "hidden",
	width: "full",
	xl: {
		gridRow: "[auto]",
	},
});

export const scrollWrapperClass = flex({
	direction: "column",
	flex: "1",
	overflowY: "auto",
	width: "full",
	xl: {
		overflow: "hidden",
	},
});

export const childrenWrapperClass = cva({
	base: {
		flex: "1",
		bg: "shade.0",
		borderRadius: "24",
		p: "24",
		overflowY: "auto",
		md: {
			p: "40",
		},
	},
	variants: {
		noPadding: {
			true: {
				p: "0",
				md: {
					p: "0",
				},
			},
		},
		hasBottomContent: {
			true: {
				gridRow: "[1 / 2]",
			},
		},
		isHeaderVisible: {
			true: {
				minHeight: "[calc(100dvh - 4rem - 1.5rem)]",
				maxHeight: "[calc(100dvh - 4rem - 1.5rem)]",
				md: {
					minHeight: "[calc(100dvh - 4rem - 2.5rem)]",
					maxHeight: "[calc(100dvh - 4rem - 2.5rem)]",
				},
				xl: {
					minHeight: "[calc(100dvh - 4rem - 3.5rem)]",
					maxHeight: "[calc(100dvh - 4rem - 3.5rem)]",
				},
			},
			false: {
				minHeight: "[calc(100dvh - 1.5rem)]",
				maxHeight: "[calc(100dvh - 1.5rem)]",
				md: {
					minHeight: "[calc(100dvh - 2.5rem)]",
					maxHeight: "[calc(100dvh - 2.5rem)]",
				},
				xl: {
					minHeight: "[calc(100dvh - 3.5rem)]",
					maxHeight: "[calc(100dvh - 3.5rem)]",
				},
			},
		},
	},
	compoundVariants: [
		{
			hasBottomContent: true,
			isHeaderVisible: true,
			css: {
				minHeight: "[calc(100dvh - 4rem - 1.5rem - var(--bottom-content-height))]",
				maxHeight: "[calc(100dvh - 4rem - 1.5rem - var(--bottom-content-height))]",
				md: {
					minHeight: "[calc(100dvh - 4rem - 2.5rem - var(--bottom-content-height))]",
					maxHeight: "[calc(100dvh - 4rem - 2.5rem - var(--bottom-content-height))]",
				},
				xl: {
					minHeight: "[calc(100dvh - 4rem - 3.5rem - var(--bottom-content-height))]",
					maxHeight: "[calc(100dvh - 4rem - 3.5rem - var(--bottom-content-height))]",
				},
			},
		},
		{
			hasBottomContent: true,
			isHeaderVisible: false,
			css: {
				minHeight: "[calc(100dvh - 1.5rem - var(--bottom-content-height))]",
				maxHeight: "[calc(100dvh - 1.5rem - var(--bottom-content-height))]",
				md: {
					minHeight: "[calc(100dvh - 2.5rem - var(--bottom-content-height))]",
					maxHeight: "[calc(100dvh - 2.5rem - var(--bottom-content-height))]",
				},
				xl: {
					minHeight: "[calc(100dvh - 3.5rem - var(--bottom-content-height))]",
					maxHeight: "[calc(100dvh - 3.5rem - var(--bottom-content-height))]",
				},
			},
		},
	],
});

export const bottomContentWrapperClass = css({
	p: "0",
	maxWidth: "[none]",
	width: "full",
	xl: {
		zIndex: "40",

		left: "0",
		right: "0",
		bottom: "24",
		position: "relative",
		gridRowStart: "1",
		gridRowEnd: "1",
		display: "flex",
		justifyContent: "center",
	},
});
