/**
 * @generated SignedSource<<ba0f41e15b82e3fb3f5535ad9b79e762>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userRulesTab_AccountMemberInlineFragment$data = {
  readonly user?: {
    readonly user: {
      readonly id: string;
    };
  };
  readonly " $fragmentType": "userRulesTab_AccountMemberInlineFragment";
};
export type userRulesTab_AccountMemberInlineFragment$key = {
  readonly " $data"?: userRulesTab_AccountMemberInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userRulesTab_AccountMemberInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "userRulesTab_AccountMemberInlineFragment"
};

(node as any).hash = "200fc58efed7e7dac9da469d9ae2a3bd";

export default node;
