import { Icon } from "@thekeytechnology/epic-ui";
import { useFragment } from "react-relay";
import { match } from "ts-pattern";
import { colorShade80Class } from "@themes/color-classes";
import { P3Span } from "@themes/font-tags";
import { REWARD_RESULT_FRAGMENT } from "./content-finished-reward-item.graphql";
import {
	cardClass,
	childrenWrapperClass,
	contentClass,
	contentWrapperClass,
	iconWrapperClass,
	rewardNameClass,
	wrapperClass,
} from "./content-finished-reward-item.styles";
import { ContentFinishedRewardItemProps } from "./content-finished-reward-item.types";

export const ContentFinishedRewardItemComponent = ({
	title,
	value,
	valueExtensionElement,
	children,
	icon,
	iconVariant,
	iconRenderer,
	rewardFragmentRef,
}: ContentFinishedRewardItemProps) => {
	const reward = useFragment(REWARD_RESULT_FRAGMENT, rewardFragmentRef ?? null);

	const renderValue =
		value ??
		(reward
			? match(reward.kind)
					.with("GamificationPoints_PointsReceived", () => reward.points?.num)
					.with("IHKCertificate_ReadyToOrder", () => "IHK Zertifikat")
					.otherwise(() => null)
			: null);

	return (
		<div className={wrapperClass}>
			<div className={cardClass}>
				<div
					className={iconWrapperClass({
						iconVariant,
					})}
				>
					{iconRenderer ?? <Icon icon={icon} />}
				</div>
				<div className={contentClass}>
					<P3Span className={colorShade80Class}>{title}</P3Span>
					<div className={contentWrapperClass}>
						<span className={rewardNameClass}>{renderValue}</span>
						{valueExtensionElement}
					</div>
				</div>
			</div>
			{children && <div className={childrenWrapperClass}>{children}</div>}
		</div>
	);
};
