import * as Sentry from "@sentry/react";
import { selectCurrentUserId } from "@thekeytechnology/academies-lib-webapp";
import { type FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Location, useLocation } from "react-router-dom";
import { useTKAOptinCookie } from "@hooks/use-tka-optin-cookie";
import { gtmTrackPageView } from "./google-tag-manager";

export const PageViewTracker: FC = () => {
	const location = useLocation();
	const [lastLocation, setLastLocation] = useState<Location>();
	const currentUserId = useSelector(selectCurrentUserId);
	const [isDOMLoaded, setIsDOMLoaded] = useState(false);
	const { accepted } = useTKAOptinCookie();

	useEffect(() => {
		const handleDOMContentLoaded = () => {
			setIsDOMLoaded(true);
		};

		if (document.readyState === "loading") {
			document.addEventListener("DOMContentLoaded", handleDOMContentLoaded);
		} else {
			setIsDOMLoaded(true);
		}

		return () => {
			document.removeEventListener("DOMContentLoaded", handleDOMContentLoaded);
		};
	}, []);

	useEffect(() => {
		if (accepted) {
			const trackers = document.createElement("script");

			trackers.src = "/tracker.js";
			trackers.async = true;

			document.body.appendChild(trackers);

			return () => {
				document.body.removeChild(trackers);
			};
		}
	}, [accepted]);

	useEffect(() => {
		if (!isDOMLoaded) return;

		try {
			gtmTrackPageView({
				userId: currentUserId,
				userIsLoggedIn: Boolean(currentUserId),
				pageLocation: window.location.origin + location.pathname,
				pageReferrer: lastLocation
					? window.location.origin + lastLocation.pathname
					: window.location.origin,
			});
			setLastLocation(location);
		} catch (e) {
			Sentry.captureException(e);
		}
	}, [location, isDOMLoaded]);

	return <></>;
};
