import { Avatar } from "@thekeytechnology/academies-lib-webapp/components/avatar";
import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Card } from "@thekeytechnology/academies-lib-webapp/components/card";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import React, { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { licensesSingleUserAssignmentCard_deleteSingleUserAssignmentRuleMutation } from "@relay/licensesSingleUserAssignmentCard_deleteSingleUserAssignmentRuleMutation.graphql";
import { licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation } from "@relay/licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation.graphql";
import { shade100, shade20 } from "@themes/colors";
import { H2Span, H3Span, TkaP2Span, P3Label } from "@themes/font-tags";
import {
	ACCOUNT_MEMBER_FRAGMENT,
	DELETE_SINGLE_USER_ASSIGNMENT_RULE_MUTATION,
	EDIT_SINGLE_USER_ASSIGNMENT_RULE_MUTATION,
	SINGLE_USER_ASSIGNMENT_RULE_FRAGMENT,
} from "./licenses-single-user-assignment-card.graphql";
import {
	wrapperClass,
	userWrapperClass,
	userDataWrapperClass,
	iconWrapperClass,
	nameWrapperClass,
	labelsWrapperClass,
	allocationWrapperClass,
	allocationButtonWrapperClass,
	allocationButtonClass,
} from "./licenses-single-user-assignment-card.styles";
import { type LicensesSingleUserAssignmentCardProps } from "./licenses-single-user-assignment-card.types";
import { LicensesRemoveRuleModal } from "../licenses-remove-rule-modal";

export const LicensesSingleUserAssignmentCard = ({
	userRef,
	licenseCount: defaultLicenseCount,
	onLicenseCountChange,
	onDelete,
	onSubmit,
	showSubmitButton = true,
	showUserIcon = true,
	showModalOnDelete = true,
	showGroups = true,
	queryFragmentRef,
}: LicensesSingleUserAssignmentCardProps) => {
	const data = useFragment(SINGLE_USER_ASSIGNMENT_RULE_FRAGMENT, queryFragmentRef ?? null);
	const userData = useFragment(ACCOUNT_MEMBER_FRAGMENT, userRef ?? null);

	const [editRule] =
		useMutation<licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation>(
			EDIT_SINGLE_USER_ASSIGNMENT_RULE_MUTATION,
		);
	const [deleteRule] =
		useMutation<licensesSingleUserAssignmentCard_deleteSingleUserAssignmentRuleMutation>(
			DELETE_SINGLE_USER_ASSIGNMENT_RULE_MUTATION,
		);

	const [licenseCount, setLicenseCount] = useState(
		() => data?.maxLicenseAmount ?? defaultLicenseCount ?? 1,
	);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const userGroups = userData?.user?.groups.filter((group) => !group.isBuiltIn) ?? [];
	const deletable = (data?.licensesUsedByUser ?? 0) === 0;

	const handleSubmit = () => {
		if (!queryFragmentRef) {
			onSubmit?.(licenseCount);
			return;
		}

		if (!data?.id) return;

		editRule({
			variables: {
				input: {
					userId: data.userId,
					assignmentRuleId: data.id,
					maxLicenseAmount: licenseCount,
				},
			},
			onCompleted: () => {
				onSubmit?.(licenseCount);
			},
		});
	};

	const handleDelete = () => {
		if (!data?.id) return;

		deleteRule({
			variables: {
				input: {
					assignmentRuleId: data.id,
				},
			},
			onCompleted: () => {
				setShowDeleteModal(false);
				onDelete?.();
			},
		});
	};

	const handleLicenseCountChange = (newCount: number) => {
		onLicenseCountChange?.(newCount);
	};

	const handleLicenseCountDecreaseOrDeleteButtonClick = () => {
		if (deletable) {
			if (showModalOnDelete) {
				setShowDeleteModal(true);
				return;
			}

			onDelete?.();
			return;
		}

		const newCount = licenseCount - 1;
		setLicenseCount(newCount);
		handleLicenseCountChange(newCount);
	};

	return (
		<>
			{showDeleteModal && (
				<LicensesRemoveRuleModal
					isVisible
					onCancel={() => setShowDeleteModal(false)}
					onSubmit={handleDelete}
					content={<TkaP2Span>Lizenz Zuweisung für den Nutzer</TkaP2Span>}
				/>
			)}
			<Card bodyPaddingInRem={0.875} borderColor={shade20}>
				<div className={wrapperClass}>
					<div className={userWrapperClass}>
						<div className={userDataWrapperClass}>
							{showUserIcon && (
								<div className={iconWrapperClass}>
									<Icon icon="user" sizeRem={1.5} />
								</div>
							)}
							<Avatar name={userData?.name} sizeInRem={2.5} />
							<div className={nameWrapperClass}>
								<P3Label>
									{userData?.isManager ? "Manager:In" : "Benutzer:In"}
								</P3Label>
								<H3Span>{userData?.name}</H3Span>
							</div>
						</div>
						{showGroups && userGroups.length > 0 && (
							<div className={labelsWrapperClass}>
								{userGroups.map((group) => (
									<Label
										key={group.id}
										label={group.name}
										size="smal"
										severity="info"
									/>
								))}
							</div>
						)}
					</div>
					<div className={allocationWrapperClass}>
						<div className={allocationButtonWrapperClass}>
							{deletable && (
								<button
									type="button"
									onClick={handleLicenseCountDecreaseOrDeleteButtonClick}
									disabled={!deletable}
									className={allocationButtonClass}
								>
									<Icon
										color={!deletable ? shade20 : shade100}
										icon={deletable ? "trash" : "remove"}
										sizeRem={0.5625}
									/>
									<span className="sr-only">Entfernen</span>
								</button>
							)}
							<H2Span>
								{data?.licensesUsedByUser ?? 0}/{licenseCount}
							</H2Span>
						</div>
						{showSubmitButton && (
							<Button
								label="Zuweisen"
								onClick={handleSubmit}
								disabled={licenseCount === (data?.maxLicenseAmount ?? 1)}
							/>
						)}
					</div>
				</div>
			</Card>
		</>
	);
};
