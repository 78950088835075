/**
 * @generated SignedSource<<5c7c271db39882ec40ef8542ff954980>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type RewardKind = "CrmTreeHeadUpdater" | "ExternalLicense" | "GamificationPoints" | "IHKCertificate" | "LearnStreak";
export type RewardResultKind = "CrmTreeHeadUpdater_ErrorOccurred" | "CrmTreeHeadUpdater_HeadUpdated" | "CrmTreeHeadUpdater_NotPassed" | "ExternalLicense_ErrorOccurred" | "ExternalLicense_IssuedToUser" | "ExternalLicense_NotPassed" | "GamificationPoints_AlreadyReceivedPoints" | "GamificationPoints_ErrorOccurred" | "GamificationPoints_NoPointsReceived" | "GamificationPoints_NotPassedIHKTestFlow" | "GamificationPoints_PointsReceived" | "IHKCertificate_ErrorOccurred" | "IHKCertificate_ReadyToOrder" | "LearnStreak_ErrorOccurred" | "LearnStreak_LearnStreakUpdated";
import { FragmentRefs } from "relay-runtime";
export type contentFinishedRewardItem_RewardResultFragment$data = {
  readonly kind: RewardResultKind;
  readonly points?: {
    readonly num: number;
  } | null | undefined;
  readonly rewardKind?: RewardKind;
  readonly " $fragmentType": "contentFinishedRewardItem_RewardResultFragment";
};
export type contentFinishedRewardItem_RewardResultFragment$key = {
  readonly " $data"?: contentFinishedRewardItem_RewardResultFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentFinishedRewardItem_RewardResultFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentFinishedRewardItem_RewardResultFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GamificationPointsReceivedByContent",
          "kind": "LinkedField",
          "name": "points",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "num",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "GamificationPoints_PointsReceivedRewardResult",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rewardKind",
          "storageKey": null
        }
      ],
      "type": "IHKCertificate_ReadyToOrderRewardResult",
      "abstractKey": null
    }
  ],
  "type": "RewardResult",
  "abstractKey": "__isRewardResult"
};

(node as any).hash = "8b437fb37e05d53e62e300f0f7aa3810";

export default node;
