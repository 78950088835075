import { css, cva } from "@styled-system/css";
import { flex, grid, stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	gap: "12",
	width: "full",
});

export const cardClass = grid({
	gridTemplateColumns: "[40px minmax(0, 1fr)]",
	gap: "16",
	width: "full",
	paddingY: "16",
	paddingX: "[1.125rem]",
	boxShadow: "card",
	border: "1px solid",
	borderColor: "primary.20",
	borderRadius: "12",
});

export const iconWrapperClass = cva({
	base: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "full",
		height: "full",
		borderRadius: "12",
	},
	variants: {
		iconVariant: {
			default: {
				backgroundColor: "[rgb(245, 246, 250)]",
			},
			green: {
				backgroundColor: "secondary.20",
			},
			blue: {
				backgroundColor: "info.0",
			},
		},
	},
	defaultVariants: {
		iconVariant: "default",
	},
});

export const contentClass = stack({
	gap: "0",
});

export const contentWrapperClass = flex({
	justifyContent: "space-between",
	alignItems: "center",
});

export const rewardNameClass = css({
	textStyle: "h3",
	color: "primary.100",
});

export const childrenWrapperClass = stack({
	width: "full",
	paddingY: "12",
	gap: "12",
});
