import { graphql } from "react-relay";

export const QUERY = graphql`
	query gamificationPointsQuery {
		Viewer {
			Auth {
				currentUser {
					user {
						extension(userExtensionKind: Academies) {
							... on AcademiesUserExtension {
								sumGamificationPoints
							}
						}
					}
				}
			}
		}
	}
`;
