import { useState } from "react";
import { useLocation } from "react-router-dom";
import { PATHS_PROTECTED_FROM_CUSTOMER_MESSAGE_MODAL } from "./onboarding-modal-injector.consts";
import { MessageModal } from "./parts/message-modal";
import { STORAGE_KEY_IS_ONBOARDING_MODAL_SHOWN } from "../../consts";

export const OnboardingModalInjector = () => {
	const location = useLocation();
	const currentPath = location.pathname;
	const isCoursePage = PATHS_PROTECTED_FROM_CUSTOMER_MESSAGE_MODAL.includes(currentPath);
	const isDefaultVisible =
		!isCoursePage && localStorage.getItem(STORAGE_KEY_IS_ONBOARDING_MODAL_SHOWN) === "true";

	const [isVisible, setIsVisible] = useState(isDefaultVisible);

	const handleOnHide = () => {
		localStorage.removeItem(STORAGE_KEY_IS_ONBOARDING_MODAL_SHOWN);
		setIsVisible(false);
	};

	return (
		<MessageModal
			isVisible={isVisible}
			onHide={handleOnHide}
			messages={[
				{
					kind: "image",
					element: {
						image: {
							id: "onboarding-modal-image-1",
							url: "/onboarding/1.png",
						},
					},
				},
				{
					kind: "image",
					element: {
						image: {
							id: "onboarding-modal-image-2",
							url: "/onboarding/2.png",
						},
					},
				},
				{
					kind: "image",
					element: {
						image: {
							id: "onboarding-modal-image-3",
							url: "/onboarding/3.png",
						},
					},
				},
				{
					kind: "image",
					element: {
						image: {
							id: "onboarding-modal-image-4",
							url: "/onboarding/4.png",
						},
					},
				},
				{
					kind: "image",
					element: {
						image: {
							id: "onboarding-modal-image-5",
							url: "/onboarding/5.png",
						},
					},
				},
				{
					kind: "image",
					element: {
						image: {
							id: "onboarding-modal-image-6",
							url: "/onboarding/6.png",
						},
					},
				},
				{
					kind: "imageWithButton",
					element: {
						image: {
							id: "onboarding-modal-image-7",
							url: "/onboarding/7.png",
						},
					},
					button: {
						kind: "confirm",
						text: "Bestätigen",
					},
				},
			]}
		/>
	);
};
