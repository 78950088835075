import { graphql } from "react-relay";

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment materialsTree_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		typeDefinition {
			... on LearnOpportunityBranchTypeDefinition {
				children {
					id
					image {
						url
					}
					structureDefinition {
						title
					}
					indexPath
					typeDefinition {
						... on LearnOpportunityBranchTypeDefinition {
							children {
								id
								indexPath
								attachmentsV2 {
									id
									title
									... on DefaultFileAttachment {
										file {
											url
											name
										}
									}
								}
							}
						}
					}
				}
			}
		}
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				viewerTreeState {
					kind
					... on IsStartedViewerTreeState {
						headContentId
					}
				}
				extension {
					... on PublishedRootExtensionImpl {
						unlockInfo {
							kind
						}
					}
				}
			}
		}
	}
`;
