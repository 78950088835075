import { MAX_STREAK_DAYS } from "./learn-streak.consts";

export const getDurationOfStreak = (startDate?: string) => {
	if (!startDate) {
		return 0;
	}
	const start = new Date(startDate);
	const end = new Date();
	end.setHours(0, 0, 0, 0);
	const diff = end.getTime() - start.getTime();
	const diffInDays = diff / (1000 * 60 * 60 * 24);
	return Math.round(diffInDays);
};

export const getDaysUntilStreakDone = (startDate?: string) => {
	if (!startDate) {
		return MAX_STREAK_DAYS;
	}
	const duration = getDurationOfStreak(startDate);
	return Math.max(Math.round(MAX_STREAK_DAYS - duration), 0);
};
