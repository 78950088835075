import { cx } from "@styled-system/css";
import { P3Anchor } from "@themes/font-tags";
import { footerItemClass, footerStyledBarClass } from "./footer.styles";
import { type FooterProps } from "./footer.types";

export const Footer = ({ items }: FooterProps) => {
	return (
		<div className={cx(footerStyledBarClass, "my-footer")}>
			{items.map((item) => (
				<P3Anchor
					key={item.label}
					className={footerItemClass}
					{...(item.link
						? { href: item.link }
						: item.onClick
						? { onClick: item.onClick }
						: {})}
				>
					{item.label}
				</P3Anchor>
			))}
		</div>
	);
};
