import { cva } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	gap: "0",
});

export const activeContentWrapperClass = cva({
	variants: {
		active: {
			true: {
				xl: {
					backgroundColor: "shade.0",
					marginRight: "-24",
					paddingRight: "24",
					borderTopLeftRadius: "10",
					borderBottomLeftRadius: "10",
				},
			},
		},
	},
});

export const noAttachmentsWrapperClass = stack({
	gap: "0",
	alignItems: "center",
	justifyContent: "center",
	textAlign: "center",
	padding: "16",
});
