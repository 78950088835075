import { LocalDateTime, ZoneId } from "@js-joda/core";
import { ValidatedDropdown, ValidatedInputText } from "@thekeytechnology/academies-lib-webapp";
import { parseDateTime } from "@thekeytechnology/academies-lib-webapp/utils";
import { InputSwitch } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { ValidatedCalendar } from "@components/validated-calendar";
import type { reminderForm_createNoteMutation } from "@relay/reminderForm_createNoteMutation.graphql";
import type { reminderForm_editNoteMutation } from "@relay/reminderForm_editNoteMutation.graphql";
import { H2Span, P2Span } from "@themes/font-tags";
import { INTERVAL_TYPE_OPTIONS } from "./reminder-form.consts";
import { NOTE_FRAGMENT, EDIT_NOTE_MUTATION, CREATE_NOTE_MUTATION } from "./reminder-form.graphql";
import {
	formClass,
	intervalDropdownClass,
	intervalFieldsWrapperClass,
	intervalIntervalWrapperClass,
	intervalWrapperClass,
} from "./reminder-form.style";
import {
	reminderSchema,
	type ReminderFormRef,
	type ReminderFormProps,
	type ReminderFormState,
} from "./reminder-form.types";
import { dateTimeFormatter } from "./reminder-form.utils";

export const ReminderForm = forwardRef<ReminderFormRef, ReminderFormProps>(function ReminderForm(
	{ onSuccess, noteFragmentRef },
	ref,
) {
	const data = useFragment(NOTE_FRAGMENT, noteFragmentRef ?? null);
	const [createReminder] = useMutation<reminderForm_createNoteMutation>(CREATE_NOTE_MUTATION);
	const [editReminder] = useMutation<reminderForm_editNoteMutation>(EDIT_NOTE_MUTATION);

	const [isRecurring, setIsRecurring] = useState(data?.reminder?.kind === "Recurring");
	const isUpdate = Boolean(data && data?.id);

	const form = useFormik<ReminderFormState>({
		initialValues: {
			content: data?.content ?? "",
			isRecurring: data?.reminder?.kind === "Recurring",
			interval:
				data?.reminder?.interval?.value || data?.reminder?.interval?.value === 0
					? (data.reminder.interval.value + 1).toString()
					: undefined,
			intervalType: data?.reminder?.interval?.kind ?? undefined,
			remindAt: data?.reminder?.remindAt
				? parseDateTime(data?.reminder?.remindAt)
				: new Date(),
		},
		validateOnBlur: false,
		validateOnChange: false,
		validationSchema: toFormikValidationSchema(reminderSchema),
		onSubmit: (values) => {
			const remindAt = values.remindAt.toISOString();

			if (isUpdate && data?.id) {
				const kind = data?.reminder?.kind;
				const isCompleted = data?.reminder?.isCompleted ?? false;

				editReminder({
					variables: {
						input: {
							noteId: data?.id,
							content: values.content,
							reminder: {
								...(kind &&
									kind === "NonRecurring" && {
										NonRecurring: {
											kind: "NonRecurring",
											isCompleted,
											remindAt: LocalDateTime.parse(
												values.remindAt.toISOString(),
												dateTimeFormatter,
											)
												.atZone(ZoneId.UTC)
												.toString(),
										},
									}),
								...(kind &&
									kind === "Recurring" &&
									values.intervalType &&
									values.interval && {
										Recurring: {
											kind: "Recurring",
											isCompleted,
											remindAt: LocalDateTime.parse(
												values.remindAt.toISOString(),
												dateTimeFormatter,
											)
												.atZone(ZoneId.UTC)
												.toString(),
											interval: {
												[values.intervalType]: {
													kind: values.intervalType,
													value: parseInt(values.interval) - 1,
												},
											},
										},
									}),
							},
						},
					},
					onCompleted: () => {
						onSuccess?.();
					},
				});

				return;
			}

			createReminder({
				variables: {
					input: {
						content: values.content,
						reminder: {
							...(!isRecurring && {
								NonRecurring: {
									kind: "NonRecurring",
									isCompleted: false,
									remindAt,
								},
							}),
							...(isRecurring &&
								values.intervalType &&
								values.interval && {
									Recurring: {
										kind: "Recurring",
										isCompleted: false,
										remindAt,
										interval: {
											[values.intervalType]: {
												kind: values.intervalType,
												value: parseInt(values.interval) - 1,
											},
										},
									},
								}),
						},
					},
				},
				onCompleted: () => {
					onSuccess?.();
				},
			});
		},
	});

	const handleOnIsRecurringChange = (checked: boolean) => {
		form.setFieldValue("isRecurring", checked);
		setIsRecurring(checked);
	};

	useImperativeHandle(ref, () => ({
		submit: form.submitForm,
		validate: () => form.validateForm().then((errors) => Object.keys(errors).length === 0),
	}));

	return (
		<div className={formClass}>
			<ValidatedCalendar
				formikConfig={form}
				showTime
				name="remindAt"
				label="Erinnerungsdatum & Uhrzeit*"
				placeholder="..."
			/>
			<ValidatedInputText
				formikConfig={form}
				name="content"
				label="Erinnerungstext*"
				placeholder="Woran willst du erinnert werden?"
			/>
			<InputSwitch
				name="isRecurring"
				label="Wiederkehrende Erinnerung"
				checked={isRecurring}
				onChange={handleOnIsRecurringChange}
			/>
			{isRecurring && (
				<div className={intervalWrapperClass}>
					<H2Span>Intervall-Einstellungen</H2Span>
					<div className={intervalIntervalWrapperClass}>
						<P2Span>Erinnerung wiederholen alle...</P2Span>
						<div className={intervalFieldsWrapperClass}>
							<ValidatedInputText
								formikConfig={form}
								name="interval"
								placeholder="1, 2, 3..."
							/>
							<ValidatedDropdown
								className={intervalDropdownClass}
								formikConfig={form}
								name="intervalType"
								placeholder="Bitte Zyklus wählen..."
								options={INTERVAL_TYPE_OPTIONS}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
});
