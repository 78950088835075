/**
 * @generated SignedSource<<c3e5d6e801013fc137bf56075db32baa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Billing_CompanyType = "Ag" | "Einzelunternehmen" | "Ev" | "Gbr" | "Gmbh" | "GmbhCoKg" | "Kg" | "OeffentlicheEinrichtung" | "Ohg" | "PartmbH" | "Stiftung" | "Ug" | "Unknown";
export type Billing_CustomerDetailsDataKind = "Business" | "Private";
export type Billing_Salutation = "Divers" | "Mr" | "Mrs" | "Unknown";
export type ItemKind = "BulkDiscount" | "Discount" | "GlobalDiscount" | "LimitedDiscount" | "Product";
import { FragmentRefs } from "relay-runtime";
export type shoppingCartInvoice_OrderFragment$data = {
  readonly cart: {
    readonly items: ReadonlyArray<{
      readonly amount?: number;
      readonly code?: string;
      readonly kind: ItemKind;
      readonly product?: {
        readonly data: {
          readonly licenseDefinition?: {
            readonly data: {
              readonly rootInfo?: {
                readonly image: {
                  readonly url: string | null | undefined;
                } | null | undefined;
                readonly rootId: string;
                readonly title: string;
              } | null | undefined;
            };
          } | null | undefined;
        };
        readonly id: string;
        readonly netPrice: number;
        readonly title: string;
      } | null | undefined;
      readonly title?: string;
      readonly validUntil?: string | null | undefined;
      readonly value?: number;
    }>;
    readonly totals: {
      readonly appliedDiscount: {
        readonly grossPrice: number;
      } | null | undefined;
      readonly appliedDiscountPercentage: number | null | undefined;
      readonly includingAllDiscounts: {
        readonly grossPrice: number;
        readonly netPrice: number;
      };
      readonly monthlyOptions: ReadonlyArray<{
        readonly monthlyRate: {
          readonly grossPrice: number;
        };
      }>;
      readonly withoutDiscounts: {
        readonly grossPrice: number;
      };
    };
  } | null | undefined;
  readonly customerDetails: {
    readonly city: string;
    readonly country: string;
    readonly data: {
      readonly company?: string;
      readonly companyDetails?: string | null | undefined;
      readonly companyType?: Billing_CompanyType;
      readonly dateOfBirth?: string;
      readonly kind: Billing_CustomerDetailsDataKind;
      readonly title?: string | null | undefined;
    };
    readonly firstName: string;
    readonly houseNumber: string;
    readonly invoiceEmail: string;
    readonly lastName: string;
    readonly phoneNumber: string;
    readonly postalCode: string;
    readonly salutation: Billing_Salutation;
    readonly street: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartTemplate_OrderFragment">;
  readonly " $fragmentType": "shoppingCartInvoice_OrderFragment";
};
export type shoppingCartInvoice_OrderFragment$key = {
  readonly " $data"?: shoppingCartInvoice_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartInvoice_OrderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPrice",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grossPrice",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "shoppingCartInvoice_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": (v3/*: any*/),
              "type": "LimitedDiscountItem",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "validUntil",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "type": "DiscountItem",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v3/*: any*/),
              "type": "GlobalDiscountItem",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "data",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "LicenseDefinition",
                              "kind": "LinkedField",
                              "name": "licenseDefinition",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": null,
                                  "kind": "LinkedField",
                                  "name": "data",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "InlineFragment",
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "LicenseDefinitionRootInfo",
                                          "kind": "LinkedField",
                                          "name": "rootInfo",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "rootId",
                                              "storageKey": null
                                            },
                                            (v1/*: any*/),
                                            {
                                              "alias": null,
                                              "args": null,
                                              "concreteType": "File",
                                              "kind": "LinkedField",
                                              "name": "image",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "kind": "ScalarField",
                                                  "name": "url",
                                                  "storageKey": null
                                                }
                                              ],
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "type": "TreeLicenseDefinitionData",
                                      "abstractKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "LicenseProductData",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "ProductItem",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Totals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "withoutDiscounts",
              "plural": false,
              "selections": (v6/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "appliedDiscount",
              "plural": false,
              "selections": (v6/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "includingAllDiscounts",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "appliedDiscountPercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "monthlyOptions",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "monthlyRate",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Billing_CustomerDetails",
      "kind": "LinkedField",
      "name": "customerDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "street",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "houseNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salutation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dateOfBirth",
                  "storageKey": null
                }
              ],
              "type": "Billing_PrivateCustomerDetails",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "company",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyDetails",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyType",
                  "storageKey": null
                }
              ],
              "type": "Billing_BusinessCustomerDetails",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "shoppingCartTemplate_OrderFragment"
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "6d09ff4546772b70a6c1f18840208cca";

export default node;
