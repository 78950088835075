import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const dayClass = flex({
	position: "relative",
	justifyContent: "center",
	alignItems: "center",
	width: "[40px]",
	height: "[40px]",
	borderRadius: "full",
});

export const dayTextClass = css({
	position: "absolute",
	top: "[50%]",
	left: "[50%]",
	transform: "translate(-50%, -50%)",
	color: "shade.0",
});
