/**
 * @generated SignedSource<<350d38bcd484f3f806a53f0a243a6135>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type LearnableKind = "Course";
import { FragmentRefs } from "relay-runtime";
export type gridView_PublishedLearnableFragment$data = {
  readonly id: string;
  readonly kind: LearnableKind;
  readonly " $fragmentSpreads": FragmentRefs<"educationalOfferCard_PublishedCourseLearnableFragment">;
  readonly " $fragmentType": "gridView_PublishedLearnableFragment";
};
export type gridView_PublishedLearnableFragment$key = {
  readonly " $data"?: gridView_PublishedLearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"gridView_PublishedLearnableFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "gridView_PublishedLearnableFragment"
};

(node as any).hash = "7abf925ed33cddd452c076498d58e569";

export default node;
