import { Image } from "@thekeytechnology/academies-lib-webapp/components/image";
import { stripHtml } from "@thekeytechnology/academies-lib-webapp/utils";
import { useFragment } from "react-relay";
import { instructorCard_InstructorFragment$key } from "@relay/instructorCard_InstructorFragment.graphql";
import { colorShade100Class, colorShade60Class, colorShade80Class } from "@themes/color-classes";
import { H3Span, P2Span, P3Span } from "@themes/font-tags";
import { INSTRUCTOR_FRAGMENT } from "./instructor-card.graphql";
import { dataWrapperClass, headerClass, wrapperClass } from "./instructor-card.styles";
import { InstructorCardProps } from "./instructor-card.types";

export const InstructorCard = ({ instructorFragmentRef }: InstructorCardProps) => {
	const instructor = useFragment<instructorCard_InstructorFragment$key>(
		INSTRUCTOR_FRAGMENT,
		instructorFragmentRef,
	);

	const shortDescription = stripHtml(instructor.shortDescription ?? "");

	return (
		<div className={wrapperClass}>
			{instructor.image?.url && (
				<Image src={instructor.image?.url} borderRadius="12px 12px 0 0" />
			)}
			<div className={dataWrapperClass}>
				<header className={headerClass}>
					<H3Span className={colorShade100Class}>{instructor.name}</H3Span>
					<P3Span className={colorShade60Class}>{instructor.position}</P3Span>
				</header>
				<P2Span className={colorShade80Class}>{shortDescription}</P2Span>
			</div>
		</div>
	);
};
