import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { InputSwitch } from "@thekeytechnology/academies-lib-webapp/components/input-switch";
import { Dropdown } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { NoteColor } from "@relay/noteCard_NoteFragment.graphql";
import { colorShade100Class } from "@themes/color-classes";
import { H3Span, P2Span } from "@themes/font-tags";
import { COLORS_OPTIONS } from "./filters-modal.consts";
import {
	buttonWrapperClass,
	colorToSortByInputClass,
	colorToSortByInputWrapperClass,
	filterHeadlineClass,
	groupWrapperClass,
	inputSwitchWrapperClass,
	wrapperClass,
} from "./filters-modal.styles";
import { FiltersModalProps } from "./filters-modal.types";
import { NotesScreenContext } from "../../notes.context";

export const FiltersModal = ({ onClose: handleOnClose }: FiltersModalProps) => {
	const { filters, helpers } = useContext(NotesScreenContext);

	const buildIsChecked = (color: NoteColor) => filters.colors.includes(color);
	const handleOnChange = {
		default: () => {
			helpers.toggleColor("default");
		},
		yellow: () => {
			helpers.toggleColor("yellow");
		},
		green: () => {
			helpers.toggleColor("green");
		},
		blue: () => {
			helpers.toggleColor("blue");
		},
		red: () => {
			helpers.toggleColor("red");
		},
		setColorToSortBy: (value?: string) => {
			helpers.setColorToSortBy(value as NoteColor);
		},
	};

	const handleOnColorToSortByReset = () => {
		helpers.setColorToSortBy(undefined);
	};

	return (
		<div className={wrapperClass}>
			<div className={filterHeadlineClass}>Filter</div>
			<div className={groupWrapperClass}>
				<H3Span className={colorShade100Class}>Nach Farbe sortieren</H3Span>
				<div className={colorToSortByInputWrapperClass}>
					<Dropdown
						options={COLORS_OPTIONS}
						value={filters.colorToSortBy}
						placeholder="Farbe auswählen..."
						onChange={handleOnChange.setColorToSortBy}
						className={colorToSortByInputClass}
					/>
					{filters.colorToSortBy !== undefined && (
						<Button
							onClick={handleOnColorToSortByReset}
							colorVersion={"tertiary"}
							title="Zurücksetzen"
							iconName="close"
						/>
					)}
				</div>
			</div>
			<div className={groupWrapperClass}>
				<H3Span className={colorShade100Class}>Farben anzeigen</H3Span>
				<div className={inputSwitchWrapperClass}>
					<P2Span className={colorShade100Class}>Schwarz</P2Span>
					<InputSwitch
						checked={buildIsChecked("default")}
						onChange={handleOnChange.default}
					/>
				</div>
				<div className={inputSwitchWrapperClass}>
					<P2Span className={colorShade100Class}>Gelb</P2Span>
					<InputSwitch
						checked={buildIsChecked("yellow")}
						onChange={handleOnChange.yellow}
					/>
				</div>
				<div className={inputSwitchWrapperClass}>
					<P2Span className={colorShade100Class}>Grün</P2Span>
					<InputSwitch
						checked={buildIsChecked("green")}
						onChange={handleOnChange.green}
					/>
				</div>
				<div className={inputSwitchWrapperClass}>
					<P2Span className={colorShade100Class}>Blau</P2Span>
					<InputSwitch checked={buildIsChecked("blue")} onChange={handleOnChange.blue} />
				</div>
				<div className={inputSwitchWrapperClass}>
					<P2Span className={colorShade100Class}>Rot</P2Span>
					<InputSwitch checked={buildIsChecked("red")} onChange={handleOnChange.red} />
				</div>
			</div>
			<div className={buttonWrapperClass}>
				<Button
					className={"ml-auto"}
					onClick={handleOnClose}
					colorVersion={"tertiary"}
					label="Schließen"
				/>
			</div>
		</div>
	);
};
