import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { MultiSelect as PRMultiSelect } from "primereact/multiselect";
import type { FC } from "react";
import { cx } from "@styled-system/css";
import { colorError100Class, colorPrimary100Class } from "@themes/color-classes";
import { shade0 } from "@themes/colors";
import { P2Span, P3Label, L1Span } from "@themes/font-tags";
import {
	multiSelectClass,
	selectedItemButtonClass,
	selectedItemLabelWrapperClass,
	selectedItemTemplateWrapperClass,
	wrapperClass,
} from "./multi-select.styles";
import type { MultiSelectBaseProps } from "./multi-select.types";
import { getLabelColor } from "./multi-select.utils";

export const MultiSelect: FC<MultiSelectBaseProps> = ({
	inline,
	label,
	onChange,
	value,
	options,
	optionLabel,
	onCloseClick,
	placeholder,
	smallTextBelow,
	hasError,
	disabled,
	className,
	...props
}) => {
	const selectedItemTemplate = (e?: { id: string; name: string }) => {
		if (!e) return <P2Span>{placeholder}</P2Span>;

		const isCloseable = onCloseClick !== undefined;

		const handleCloseClick = () => {
			onCloseClick?.(e.id);
		};

		return (
			<div className={selectedItemTemplateWrapperClass}>
				<div
					className={selectedItemLabelWrapperClass({
						isCloseable,
					})}
				>
					<L1Span>{e.name}</L1Span>
				</div>
				{isCloseable && (
					<button
						type="button"
						className={selectedItemButtonClass}
						onClick={handleCloseClick}
					>
						<Icon icon="close" sizeRem={0.65} color={shade0} />
					</button>
				)}
			</div>
		);
	};

	const labelColor = getLabelColor(hasError ?? false, disabled ?? false);

	return (
		<div className={wrapperClass}>
			{label && <P3Label className={labelColor}>{label}</P3Label>}
			<PRMultiSelect
				onChange={onChange}
				value={value}
				options={options}
				optionLabel={optionLabel}
				display="chip"
				filter
				selectedItemTemplate={selectedItemTemplate}
				inline={inline}
				disabled={disabled}
				className={cx(
					multiSelectClass({
						hasError,
					}),
					className,
				)}
				{...props}
			/>
			{smallTextBelow && (
				<L1Span className={hasError ? colorError100Class : colorPrimary100Class}>
					{smallTextBelow}
				</L1Span>
			)}
		</div>
	);
};
