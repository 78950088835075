import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { ReactComponent as LogoSVG } from "@assets/logo-new.svg";
import { shade0, shade100 } from "@themes/colors";
import { headerWrapperClass, leftItemClass } from "./header.styles";
import { HeaderProps } from "./header.types";

export const HeaderSkeleton = ({ canGoBack, leftIcon, leftItem }: HeaderProps) => {
	const showLeftItem = (canGoBack || leftIcon || leftItem) && canGoBack !== false;

	return (
		<div className={headerWrapperClass}>
			{showLeftItem && (
				<div className={leftItemClass}>
					{leftItem ?? (
						<Icon
							icon={leftIcon ?? "arrowLeft"}
							color={shade100}
							sizeRem={1}
							circleOutlineColor={shade0}
						/>
					)}
				</div>
			)}
			<LogoSVG height={18} />
		</div>
	);
};
