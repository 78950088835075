import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { ValidatedInputText } from "@thekeytechnology/academies-lib-webapp/components/validated-input-text";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { InputTextType } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import { colorSecondary100Class } from "@themes/color-classes";
import { H1Span, P3Span } from "@themes/font-tags";
import {
	type PasswordResetPasswordFormState,
	type PasswordResetPasswordStepProps,
} from "./password-reset-password-step.interface";
import { FormWrapper, InputsWrapper, TextCenter } from "./password-reset-password-step.styles";

export const PasswordResetPasswordStep = ({
	onGoToLogin,
	onNext,
}: PasswordResetPasswordStepProps) => {
	const { t } = useTkaTranslation("resetPasswordForm");

	const form = useFormik<PasswordResetPasswordFormState>({
		initialValues: {
			password: "",
			passwordRepeat: "",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			password: Yup.string()
				.min(8, t("reset_password_form.validationInvalidPassword"))
				.required(t("reset_password_form.validationRequiredPassword")),
			passwordRepeat: Yup.string().oneOf(
				[Yup.ref("password"), ""],
				t("reset_password_form.validationInvalidPasswordDuplicate"),
			),
		}),
		onSubmit: (values) => {
			onNext?.(values);
		},
	});

	return (
		<form onSubmit={form.handleSubmit}>
			<FormWrapper>
				<TextCenter>
					<H1Span>{t("reset_password_form.title")}</H1Span>
				</TextCenter>
				<InputsWrapper>
					<ValidatedInputText
						formikConfig={form}
						name="password"
						type={InputTextType.Password}
						icon="lock"
						placeholder={t("reset_password_form.placeholderPassword")}
					/>
					<ValidatedInputText
						formikConfig={form}
						name="passwordRepeat"
						type={InputTextType.Password}
						icon="lock"
						placeholder={t("reset_password_form.placeholderPasswordDuplicate")}
					/>
				</InputsWrapper>
				<Button fillParent label={t("reset_password_form.buttonText")} />
				<TextCenter>
					<P3Span>
						{t("reset_password_form.rememberPassword")}
						&nbsp;
						<P3Span className={colorSecondary100Class} onClick={onGoToLogin}>
							{t("reset_password_form.backToLogin")}
						</P3Span>
					</P3Span>
				</TextCenter>
			</FormWrapper>
		</form>
	);
};
