import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { ResponsiveBottomSheetOverlayPanel } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-overlay-panel";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { selectHasPermission } from "@thekeytechnology/academies-lib-webapp/slices";
import { SyntheticEvent, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLazyLoadQuery } from "react-relay";
import { accountContextMenu_Query } from "@relay/accountContextMenu_Query.graphql";
import { colorError100Class } from "@themes/color-classes";
import { error100 } from "@themes/colors";
import { H3Span } from "@themes/font-tags";
import { spacing8 } from "@themes/spacing";
import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "./account-context-menu.graphql";
import { AccountContextMenuSkeleton } from "./account-context-menu.skeleton";
import {
	wrapperClass,
	optionsWrapperClass,
	itemWrapperClass,
	permanentActionsButtonClass,
} from "./account-context-menu.styles";
import { DeleteAccountModalsFlow } from "../delete-account-modals-flow";
import { LeaveAccountModal } from "../leave-account-modal/leave-account-modal.component";

const AccountContextMenuComponent = () => {
	const query = useLazyLoadQuery<accountContextMenu_Query>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		{},
		{ fetchPolicy: "store-and-network" },
	);

	const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);
	const [showLeaveAccountModal, setShowLeaveAccountModal] = useState(false);
	const [showDeleteAccountFlow, setShowDeleteAccountFlow] = useState(false);

	const isManager = useSelector((state) =>
		selectHasPermission(state, "UserInAccountPermission_System_Owner"),
	);

	const auth = query?.Viewer?.Auth;
	if (!auth) return null;

	const handleOnShowContextMenu = (event: SyntheticEvent<Element, Event>) => {
		event.preventDefault();
		overlayRef?.current?.toggle(event, event.currentTarget);
	};

	const handleOnLeaveAccountClick = () => {
		overlayRef?.current?.hide();
		setShowLeaveAccountModal(true);
	};

	const handleOnDeleteAccountClick = () => {
		overlayRef?.current?.hide();
		setShowDeleteAccountFlow(true);
	};

	const canLeaveAccount = true;
	const canDeleteAccount = isManager;

	return (
		<>
			<button
				type="button"
				onClick={handleOnShowContextMenu}
				className={permanentActionsButtonClass}
			>
				<Icon icon="contextMenu" color={error100} />
				<H3Span className={colorError100Class}>Permanente Aktionen</H3Span>
			</button>
			<ResponsiveBottomSheetOverlayPanel ref={overlayRef} padding={spacing8}>
				<div className={wrapperClass}>
					<div className={optionsWrapperClass}>
						{canLeaveAccount && (
							<div onClick={handleOnLeaveAccountClick} className={itemWrapperClass()}>
								<Icon icon="logout" sizeInRem={1.5} />
								Konto verlassen
							</div>
						)}
						{canDeleteAccount && (
							<div
								onClick={handleOnDeleteAccountClick}
								className={itemWrapperClass({ dangerous: true })}
							>
								<Icon icon="trash" sizeInRem={1.5} color={error100} />
								Konto löschen
							</div>
						)}
					</div>
				</div>
			</ResponsiveBottomSheetOverlayPanel>
			{showLeaveAccountModal && (
				<LeaveAccountModal
					isVisible
					onDismiss={() => setShowLeaveAccountModal(false)}
					onSuccess={() => setShowLeaveAccountModal(false)}
					authViewerSchemaFragmentRef={auth}
				/>
			)}
			{showDeleteAccountFlow && (
				<DeleteAccountModalsFlow
					isVisible
					onDismiss={() => setShowDeleteAccountFlow(false)}
					authViewerSchemaFragmentRef={auth}
				/>
			)}
		</>
	);
};

export const AccountContextMenu = withSuspense(
	AccountContextMenuComponent,
	AccountContextMenuSkeleton,
);
