import { cva } from "@styled-system/css";

export const contentScrollViewClass = cva({
	base: {
		flex: "[1 1 0%]",
		overflowX: "hidden",
		overflowY: "auto",
		scrollbar: "hidden",
	},
	variants: {
		withWidth: {
			true: {
				width: "var(--width)",
			},
		},
	},
});

export const hideOverflowClass = cva({
	base: {
		display: "flex",
		alignItems: "flex-start",
		transitionProperty: "all",
		transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
		transitionDuration: "150ms",
		overflowY: "hidden",
	},
	variants: {
		delay: {
			true: {
				transitionDelay: "300ms",
			},
			false: {
				transitionProperty: "none",
			},
		},
		withHeight: {
			true: {
				height: "var(--height)",
			},
		},
		withWidth: {
			true: {
				width: "var(--width)",
			},
		},
	},
});
