import { EpicH2Span } from "@thekeytechnology/epic-ui";
import styled from "styled-components";

export const NavigateWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${({ theme }) => theme.spacing.spacing12.rem()};
	align-items: center;

	&:hover {
		cursor: pointer;
	}
`;

export const NavigateTitle = styled(EpicH2Span)`
	flex-grow: 1;
`;
