import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { graphql, useFragment } from "react-relay";
import { MaterialCard } from "@components/material-card";
import { attachmentsCollection_AttachmentsCollectionFragment$key } from "@relay/attachmentsCollection_AttachmentsCollectionFragment.graphql";
import { shade100 } from "@themes/colors";
import { TkaH2Span } from "@themes/font-tags";
import {
	getBranchNodeNumberFromIndexPath,
	getContentNodeNumberFromIndexPath,
} from "@utils/index-path.util";
import { AttachmentsCollectionProps } from "./attachments-collection.interface";
import { Wrapper } from "./attachments-collection.styles";

const ATTACHMENTS_COLLECTION_FRAGMENT = graphql`
	fragment attachmentsCollection_AttachmentsCollectionFragment on LearnOpportunityV2 {
		indexPath
		attachmentsV2 {
			title
			... on FileAttachment {
				file {
					url
					name
				}
			}
		}
	}
`;

export const AttachmentsCollection = ({
	attachmentsCollectionFragmentRef,
	isUnlocked,
}: AttachmentsCollectionProps) => {
	const { indexPath, attachmentsV2 } =
		useFragment<attachmentsCollection_AttachmentsCollectionFragment$key>(
			ATTACHMENTS_COLLECTION_FRAGMENT,
			attachmentsCollectionFragmentRef,
		);

	const { t } = useTkaTranslation("modulesAndLessons");

	const moduleNumber = indexPath ? getBranchNodeNumberFromIndexPath(indexPath) : 1;
	const lessonNumber = indexPath ? getContentNodeNumberFromIndexPath(indexPath) : 1;

	const lessonName = `${t("modules_and_lessons.lesson")} ${moduleNumber}.${lessonNumber}`;

	return attachmentsV2?.length > 0 ? (
		<Wrapper>
			<TkaH2Span tkaColor={shade100}>{t("modules_and_lessons.attachmentsTitle")}</TkaH2Span>
			{attachmentsV2.map((attachment) => (
				<MaterialCard
					isExpanded
					disabled={!isUnlocked}
					key={attachment.title}
					icon="dataFileBarsDownload"
					title={lessonName}
					fileUrl={attachment.file?.url}
					fileName={attachment.file?.name}
					description={attachment.title ?? ""}
				/>
			))}
		</Wrapper>
	) : null;
};
