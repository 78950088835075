import { css, cva } from "@styled-system/css";
import { flex, stack } from "@styled-system/patterns";

export const mainWrapperClass = stack({
	gap: "12",
});

export const imageTitleClass = stack({
	position: "relative",
	gap: "24",
});

export const taglineTitleClass = stack({
	flex: "1",
	alignItems: "flex-start",
	justifyContent: "space-between",
	gap: "4",
});

export const taglineClass = flex({
	justifyContent: "space-between",
});

export const ihkIconClass = css({
	height: "16",
});

export const imageWrapperClass = flex({
	position: "relative",
	flex: "1",
});

export const imageClass = css({
	width: "full",
	aspectRatio: "16 / 9",
	height: "auto",
	borderRadius: "12",
	objectFit: "cover",
});

export const progressWrapperClass = cva({
	base: {
		position: "absolute",
		zIndex: "100",
		top: "8",
		right: "8",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: "8",
		paddingBlock: "4",
		paddingLeft: "16",
		paddingRight: "4",
		backgroundColor: "onPrimary",
		borderRadius: "10",
	},
	variants: {
		isFinished: {
			true: {
				backgroundColor: "secondary.100",
			},
		},
	},
});

export const progressIsFinishedTextClass = css({
	color: "[rgb(24, 66, 118)]",
});

export const gamificationPointsProgressClass = cva({
	base: {
		display: "flex",
		alignItems: "center",
		gap: "4",
		paddingX: "8",
		paddingY: "4",
		borderRadius: "20",
		backgroundColor: "shade.0",
		color: "warning.100",
	},
	variants: {
		isFinished: {
			true: {
				backgroundColor: "[rgb(24, 66, 118)]",
			},
		},
	},
});

export const gamificationPointsProgressDividerClass = cva({
	base: {
		height: "10",
		width: "2",
		borderRadius: "[1px]",
		backgroundColor: "shade.10",
	},
	variants: {
		isFinished: {
			true: {
				backgroundColor: "[rgb(7, 158, 131)]",
			},
		},
	},
});

export const priceWrapperClass = stack({
	position: "absolute",
	zIndex: "100",
	bottom: "8",
	right: "8",
	alignItems: "center",
	justifyContent: "center",
	gap: "8",
	borderRadius: "10",
	padding: "8",
	backgroundColor: "onPrimary",
});

export const videoTrailerClass = css({
	marginTop: "0",
});
