import { grid } from "@styled-system/patterns";

export const messagesOverviewClass = grid({
	gridTemplateColumns: "2",
	mt: "32",
	gap: "40",
	md: {
		gap: "80",
	},
	xl: {
		gridTemplateColumns: "3",
	},
	"2xl": {
		gridTemplateColumns: "4",
	},
	_only: {
		justifyContent: "start",
	},
});
