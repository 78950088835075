import { MultiDialogFlowContainer } from "@thekeytechnology/academies-lib-webapp/containers/multi-dialog-flow-container";
import { CreateReportModal } from "./parts/create-report-modal";
import { CreateReportSuccessModal } from "./parts/create-report-success-modal/create-report-success-modal.component";
import { ReportModalFlowContextProvider } from "./report-modal-flow.context";
import { type ReportModalFlowProps } from "./report-modal-flow.types";

export const ReportModalFlow = ({ isVisible, onDismiss }: ReportModalFlowProps) => {
	return (
		<ReportModalFlowContextProvider>
			<MultiDialogFlowContainer isVisible={isVisible} onDismiss={onDismiss}>
				{({ onNext, onClose }) => {
					return (
						<>
							<CreateReportModal isVisible onDismiss={onClose} onSuccess={onNext} />
							<CreateReportSuccessModal isVisible onDismiss={onClose} />
						</>
					);
				}}
			</MultiDialogFlowContainer>
		</ReportModalFlowContextProvider>
	);
};
