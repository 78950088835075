import { Cart } from "./shopping-cart-payment-method.types";

export const getProductItems = (cart: Cart) =>
	cart?.items.filter((item) => item.kind === "Product");

export const getCartDiscountItems = (cart: Cart) =>
	cart?.items.filter((item) => item.kind === "Discount");

export const getCartLimitedDiscountItems = (cart: Cart) =>
	cart?.items.filter((item) => item.kind === "LimitedDiscount");
