import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { LicensesSingleUserAssignmentCardSkeleton } from "@components/licenses-single-user-assignment-card";
import { allocationListWrapperClass, allocationUserListClass } from "./user-rules-tab.styles";

export const UserRulesTabSkeleton = () => {
	return (
		<div className={allocationListWrapperClass}>
			<Label label="Verfügbar" size="smal" severity="success" />
			<div className={allocationUserListClass}>
				<LicensesSingleUserAssignmentCardSkeleton />
				<LicensesSingleUserAssignmentCardSkeleton />
				<LicensesSingleUserAssignmentCardSkeleton />
				<LicensesSingleUserAssignmentCardSkeleton />
			</div>
			<Label label="Verbraucht" size="smal" severity="info" />
			<div className={allocationUserListClass}>
				<LicensesSingleUserAssignmentCardSkeleton />
				<LicensesSingleUserAssignmentCardSkeleton />
				<LicensesSingleUserAssignmentCardSkeleton />
				<LicensesSingleUserAssignmentCardSkeleton />
			</div>
		</div>
	);
};
