import { EpicIcons, Icon } from "@thekeytechnology/epic-ui";
import { useEffect, useRef, useState } from "react";
import CookieImage from "@assets/cookie.png";
import { CookieBannerText } from "@components/cookie-banner-text";
import { useTKAOptinCookie } from "@hooks/use-tka-optin-cookie";
import { shade100 } from "@themes/colors";
import {
	backdropClass,
	buttonsWrapperClass,
	closeButtonClass,
	consentButtonClass,
	cookieImageClass,
	firstLayerWrapperClass,
	headerClass,
	headlineClass,
	wrapperClass,
} from "./cookie-banner.styles";

declare global {
	interface Window {
		Cookiebot: {
			consent: {
				statistics: boolean;
			};
			show: () => void;
			hide: () => void;
			submitCustomConsent: (
				optinPreferences: boolean,
				optinStatistics: boolean,
				optinMarketing: boolean,
			) => void;
		};
		CookiebotDialog: {
			visible?: boolean;
		};
	}
}

export const CookieBanner = () => {
	const { cookieExists, setCookie } = useTKAOptinCookie();
	const [showFirstLayer, setShowFirstLayer] = useState(!cookieExists);
	const showFirstLayerRef = useRef<boolean>();
	showFirstLayerRef.current = showFirstLayer;

	const handleAcceptAllOnClick = () => {
		window.Cookiebot.submitCustomConsent(true, true, true);
		setShowFirstLayer(false);
	};

	const handleDeclineAllOnClick = () => {
		window.Cookiebot.submitCustomConsent(false, false, false);
		setShowFirstLayer(false);
	};

	const handleMoreDetailsOnClick = () => {
		window.Cookiebot.show();
	};

	useEffect(() => {
		const handleCookiebotOnDialogDisplay = () => {
			if (showFirstLayerRef.current) {
				setShowFirstLayer(false);
				return;
			}

			setShowFirstLayer(true);
			window.Cookiebot.hide();
		};

		const handleFirstDialogDisplay = () => {
			window.Cookiebot.hide();
			removeCookiebotDialogFix();
			setShowFirstLayer(true);
		};

		const removeCookiebotDialogFix = () => {
			const cookiebotDialogShowFix = document.getElementById("cookiebotDialogShowFix");
			if (!cookiebotDialogShowFix) return;

			cookiebotDialogShowFix.remove();
			window.removeEventListener("CookiebotOnDialogDisplay", handleFirstDialogDisplay);
			window.addEventListener("CookiebotOnDialogDisplay", handleCookiebotOnDialogDisplay);
		};

		const handleConsentChange = () => {
			if ("Cookiebot" in window) {
				setCookie(window.Cookiebot.consent.statistics);
				setShowFirstLayer(false);
			}
			removeCookiebotDialogFix();
		};

		window.addEventListener("CookiebotOnDialogDisplay", handleFirstDialogDisplay);
		window.addEventListener("CookiebotOnConsentReady", handleConsentChange);
		window.addEventListener("CookiebotOnAccept", handleConsentChange);
		window.addEventListener("CookiebotOnDecline", handleConsentChange);

		if (window.CookiebotDialog && window.CookiebotDialog.visible) {
			handleFirstDialogDisplay();
		}

		return () => {
			window.removeEventListener("CookiebotOnDialogDisplay", handleFirstDialogDisplay);
			window.removeEventListener("CookiebotOnDialogDisplay", handleCookiebotOnDialogDisplay);
			window.removeEventListener("CookiebotOnConsentReady", handleConsentChange);
			window.removeEventListener("CookiebotOnAccept", handleConsentChange);
			window.removeEventListener("CookiebotOnDecline", handleConsentChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!showFirstLayer) return null;

	return (
		<div className={wrapperClass}>
			<div className={backdropClass} />
			<div className={firstLayerWrapperClass}>
				<button
					type="button"
					onClick={handleDeclineAllOnClick}
					className={closeButtonClass}
				>
					<Icon icon={EpicIcons.TIMES} color={shade100} />
				</button>
				<header className={headerClass}>
					<img src={CookieImage} alt="" aria-hidden="true" className={cookieImageClass} />
					<h2 className={headlineClass}>Datenschutzeinstellungen</h2>
				</header>

				<div>
					<CookieBannerText />
				</div>

				<div className={buttonsWrapperClass}>
					<button
						type="button"
						className={consentButtonClass({
							variant: "decline",
						})}
						onClick={handleDeclineAllOnClick}
					>
						Ablehnen
					</button>
					<button
						type="button"
						className={consentButtonClass({
							variant: "moreDetails",
						})}
						onClick={handleMoreDetailsOnClick}
					>
						Mehr Details
					</button>
					<button
						type="button"
						className={consentButtonClass({
							variant: "accept",
						})}
						onClick={handleAcceptAllOnClick}
					>
						Akzeptieren
					</button>
				</div>
			</div>
		</div>
	);
};
