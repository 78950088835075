import { css } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	gap: "0",
});

export const taskDescriptionClass = css({
	mt: "12",
	mb: "32",
	color: "shade.100",
});
