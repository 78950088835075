import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { useMatch, useNavigate } from "react-router-dom";
import { shade0, shade100 } from "@themes/colors";
import { L1Span } from "@themes/font-tags";
import { linkTextClass, wrapperClass } from "./navbar-item.styles";
import { NavbarItemProps } from "./navbar-item.types";
import { memo } from "react";

const NavbarItemComponent = ({
	icon,
	iconActive,
	iconName,
	iconActiveName,
	label,
	path,
}: NavbarItemProps) => {
	const navigate = useNavigate();
	const { isXLargeUp } = useWindowSize();
	const isActive = useMatch(path);
	const color = isXLargeUp ? shade0 : shade100;

	const handleOnClick = () => {
		navigate(path);
	};

	const renderIcon = () => {
		if (icon) {
			if (iconActive) {
				if (isActive) {
					return iconActive({ color, hexColor: color.hexValue() });
				}
				return icon({ color, hexColor: color.hexValue() });
			}

			return icon({ color, hexColor: color.hexValue() });
		}

		if (isActive) {
			if (iconActiveName) {
				return <Icon icon={iconActiveName} sizeRem={1.25} color={color} />;
			}
			if (iconActive) {
				return iconActive({ color, hexColor: color.hexValue() });
			}
		}

		return <Icon icon={iconName} sizeRem={1.25} color={color} />;
	};

	return (
		<div className={wrapperClass} onClick={handleOnClick}>
			{renderIcon()}
			<L1Span
				className={linkTextClass({
					isActive: Boolean(isActive),
				})}
			>
				{label}
			</L1Span>
		</div>
	);
};

export const NavbarItem = memo(NavbarItemComponent);
