import { ValidatedInputText } from "@thekeytechnology/academies-lib-webapp/components/validated-input-text";
import { InputText, InputTextStatus } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { forwardRef, useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { privateClientFormSchema } from "./private-client-form.consts";
import { QUERY_FRAGMENT } from "./private-client-form.graphql";
import {
	inputsWrapperClass,
	rowSpan2Class,
	rowSpan4Class,
	rowSpan6Class,
	rowSpan6PlaceholderClass,
	rowSpan6WrapperClass,
} from "./private-client-form.styles";
import {
	PrivateClientFormRef,
	PrivateClientFormProps,
	PrivateClientFormState,
	PrivateClientFormValues,
} from "./private-client-form.types";
import { SalutationDropdown } from "../salutation-dropdown";
import { ValidatedCalendar } from "../validated-calendar";

export const PrivateClientForm = forwardRef<PrivateClientFormRef, PrivateClientFormProps>(
	function PrivateClientForm({ initialValues, baseDataFragmentRef, onSubmit }, ref) {
		const query = useFragment(QUERY_FRAGMENT, baseDataFragmentRef);

		const form = useFormik<PrivateClientFormState>({
			initialValues: {
				salutation: initialValues?.salutation || query?.salutation || undefined,
				title: initialValues?.title || query?.title || "",
				firstName: initialValues?.firstName || query?.firstName || "",
				lastName: initialValues?.lastName || query?.lastName || "",
				street: initialValues?.street || query?.street || "",
				houseNumber: initialValues?.houseNumber || query?.houseNumber || "",
				city: initialValues?.city || query?.city || "",
				postCode: initialValues?.postCode || query?.postalCode || "",
				phoneNumber: initialValues?.phoneNumber || query?.phoneNumber || "",
			},
			validateOnChange: false,
			validateOnBlur: false,
			validationSchema: toFormikValidationSchema(privateClientFormSchema),
			onSubmit: (values) => {
				onSubmit?.(values as PrivateClientFormValues);
			},
		});

		useImperativeHandle(ref, () => ({
			submit: form.submitForm,
		}));

		return (
			<form onSubmit={form.handleSubmit}>
				<div className={inputsWrapperClass}>
					<div className={rowSpan6WrapperClass}>
						<div className={rowSpan2Class}>
							<SalutationDropdown
								formikConfig={form}
								name="salutation"
								label="Anrede*"
								placeholder="Anrede"
							/>
						</div>
						<div className={rowSpan4Class}>
							<ValidatedInputText
								formikConfig={form}
								name="title"
								label={"Titel"}
								placeholder={"Titel"}
							/>
						</div>
					</div>
					<div className={rowSpan6PlaceholderClass} />
					<div className={rowSpan6Class}>
						<ValidatedInputText
							formikConfig={form}
							name="firstName"
							label={"Name*"}
							placeholder={"Name"}
						/>
					</div>
					<div className={rowSpan6Class}>
						<ValidatedInputText
							formikConfig={form}
							name="lastName"
							label={"Nachname*"}
							placeholder={"Nachname"}
						/>
					</div>
					<div className={rowSpan6WrapperClass}>
						<div className={rowSpan4Class}>
							<ValidatedInputText
								formikConfig={form}
								name="street"
								label={"Straße*"}
								placeholder={"Straße"}
							/>
						</div>
						<div className={rowSpan2Class}>
							<ValidatedInputText
								formikConfig={form}
								name="houseNumber"
								label={"Hausnummer*"}
								placeholder={"Hausnummer"}
							/>
						</div>
					</div>

					<div className={rowSpan6WrapperClass}>
						<div className={rowSpan4Class}>
							<ValidatedInputText
								formikConfig={form}
								name="city"
								label={"Ort/Stadt*"}
								placeholder={"Ort/Stadt"}
							/>
						</div>
						<div className={rowSpan2Class}>
							<ValidatedInputText
								formikConfig={form}
								name="postCode"
								label={"PLZ*"}
								placeholder={"PLZ"}
							/>
						</div>
					</div>
					<div className={rowSpan6Class}>
						<InputText
							name="country"
							label={"Land*"}
							status={InputTextStatus.Disabled}
							placeholder={"Deutschland"}
							value={"Deutschland"}
						/>
					</div>
					<div className={rowSpan6Class}>
						<ValidatedCalendar
							formikConfig={form}
							name="dateOfBirth"
							label={"Geburtsdatum*"}
							placeholder={"01.01.1990"}
						/>
					</div>
					<div className={rowSpan6Class}>
						<ValidatedInputText
							formikConfig={form}
							name="phoneNumber"
							label={"Telefonnummer"}
							placeholder={"+4917611223344"}
						/>
					</div>
				</div>
			</form>
		);
	},
);

export type PrivateClientForm = PrivateClientFormRef;
