import type { DialogTemplateProps } from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import type { IconMap } from "@thekeytechnology/epic-ui";
import { match } from "ts-pattern";
import type { ElementTypeV2 } from "@relay/upsertNoteModal_NoteFragment.graphql";

export const getElementTypeName = (elementType?: ElementTypeV2) => {
	return match(elementType)
		.with("matrix", () => "Matrix")
		.with("multipleChoice", () => "Fragen")
		.with("text", "enhancedText", () => "Text")
		.with("podcast", "podcastWithTimestamp", () => "Podcast")
		.with("video", () => "Video")
		.with("markMistakes", () => "Fehler markieren")
		.with("file", () => "Heft")
		.with("clozeText", () => "Lückentext")
		.with("order", () => "Reihenfolge")
		.with("textWithPages", () => "Text mit Seiten")
		.with("reminder", () => "Erinnerung")
		.with("persolog", "persologEvaluation", "persologQuestion", () => "PERSOLOG")
		.otherwise(() => "Element");
};

export const getElementTypeIcon = (elementType?: ElementTypeV2): keyof IconMap => {
	if (!elementType) return "informationCircle";

	return match<ElementTypeV2, keyof IconMap>(elementType)
		.with("matrix", () => "expandFull")
		.with("multipleChoice", () => "checkCircle")
		.with("text", "enhancedText", "textWithPages", () => "pencil")
		.with("podcast", "podcastWithTimestamp", () => "microphonePodcast2")
		.with("video", () => "video")
		.with("markMistakes", () => "close")
		.with("file", () => "folder")
		.with("clozeText", () => "dragDrop")
		.with("order", () => "listBullets")
		.with("reminder", () => "timeClockCircle")
		.with("persolog", "persologEvaluation", "persologQuestion", () => "user")
		.exhaustive();
};

export const getDialogTemplateProps = (
	isElementPage: boolean,
	isNoteData: boolean,
): DialogTemplateProps => {
	if (isElementPage) {
		if (isNoteData) {
			return {
				secondaryButtonLabel: "Zum Kurs",
				primaryButtonLabel: "Zurück",
				primaryButtonStretch: true,
			};
		}

		return { primaryButtonLabel: "Zurück", primaryButtonStretch: true };
	}

	return {
		primaryButtonLabel: "Speichern",
		primaryButtonIconName: "arrowRight",
		secondaryButtonLabel: "Abbrechen",
	};
};

export const getSourceTypeName = (
	definitionType?: "Element" | "Course" | "Module" | "child" | "root",
) => {
	return match(definitionType)
		.with("Course", "root", () => "Kurs")
		.with("Module", "child", () => "Lektion")
		.with("Element", () => "Element")
		.otherwise(() => "Element");
};
