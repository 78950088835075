import { cva } from "@styled-system/css";

export const sideMenuContentWrapperClass = cva({
	base: {
		zIndex: "1",
		gridColumn: "[1 / -1]",
		width: "full",
		maxWidth: "[57rem]",
		margin: "[0 auto 0]",
		overflowY: "auto",
		scrollbar: "hidden",
		xl: {
			maxWidth: "[52rem]",
		},
		"3xl": {
			maxWidth: "[75rem]",
		},
	},
	variants: {
		sideMenuOnly: {
			true: {
				display: "none",
				xl: {
					display: "block",
				},
			},
		},
		showInSideMenu: {
			true: {
				xl: {
					gridColumn: "[1 / 2]",
				},
			},
			false: {
				xl: {
					gridColumn: "[2 / -1]",
				},
			},
		},
		isSideMenu: {
			true: {
				xl: {
					paddingTop: "0",
					paddingBottom: "0",
					paddingRight: "24",
					paddingLeft: "40",
				},
			},
			false: {
				xl: {
					gridRow: "[1 / -1]",
					paddingTop: "40",
					paddingBottom: "0",
					paddingRight: "40",
					paddingLeft: "40",
				},
			},
		},
	},
	defaultVariants: {
		showInSideMenu: false,
		sideMenuOnly: false,
	},
});
