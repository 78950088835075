import { Card } from "@thekeytechnology/academies-lib-webapp/components/card";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useTheme } from "styled-components";
import { type MaterialIconProps } from "./material-icon.types";

export const MaterialIcon = ({
	icon,
	iconColor,
	showBorder = false,
	onClick,
	backgroundColor,
}: MaterialIconProps) => {
	const theme = useTheme();

	return (
		<Card
			showBorder={showBorder}
			bodyPaddingInRem={0.5}
			hasMinContent={true}
			onClick={onClick}
			hasCursorPointer={true}
			backgroundColor={backgroundColor}
		>
			<Icon icon={icon} color={iconColor ?? theme.colors.shade100} sizeInRem={1.5} />
		</Card>
	);
};
