import { cva } from "@styled-system/css";
import { flex, stack } from "@styled-system/patterns";

export const mainWrapperClass = cva({
	base: {
		display: "grid",
		alignItems: "center",
		gap: "4",
		padding: "12",
		borderRadius: "4",
		border: "1px solid",
		borderColor: "shade.20",
		cursor: "pointer",
	},
	variants: {
		isSelected: {
			true: {
				xl: {
					border: "0.5px solid",
					borderColor: "shade.20",
					boxShadow: "[none]",
				},
			},
		},
		shouldBeStartedNext: {
			true: {
				boxShadow: "[inset 0 0 0 0.0625rem rgb(41, 19, 113)]",
			},
		},
	},
});

export const groupClass = flex({
	gap: "8",
	alignItems: "center",
});

export const lineClass = flex({
	justifyContent: "space-between",
});

export const headerClass = stack({
	gap: "0",
});

export const pointsWrapperClass = flex({
	alignItems: "center",
	gap: "4",
	backgroundColor: "shade.0",
	borderRadius: "10",
	paddingX: "[0.375rem]",
	paddingY: "[0.125rem]",
});

export const footerClass = flex({
	marginTop: "[0.5625rem]",
	justifyContent: "flex-end",
	width: "full",
});
