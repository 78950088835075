import { stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	gap: "0",
	borderBottom: "1px solid",
	borderColor: "shade.10",
	md: {
		width: "[25rem]",
	},
});
