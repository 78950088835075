import { css, cva } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "24",
	md: {
		minWidth: "[30rem]",
	},
});

export const contentWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "24",
});

export const sourceWrapperClass = css({
	position: "relative",
	display: "flex",
	flexDirection: "column",
});

export const elementWrapperClass = css({
	maxHeight: "[70dvh]",
	overflowX: "visible",
	overflowY: "hidden",
	display: "flex",
	backgroundColor: "onPrimary",
	borderRadius: "8",
	paddingTop: "4",
	paddingLeft: "16",
	paddingRight: "16",
	paddingBottom: "16",
});

export const elementRendererClass = css({
	display: "flex",
	flexDirection: "column",
});

export const sourceCardWrapperClass = css({
	textAlign: "left",
	marginBottom: "-10",
	zIndex: "10",
	position: "relative",
	backgroundColor: "shade.0",
});

export const sourceCardImageClass = css({
	objectFit: "[cover]",
});

export const sourceCardContentClass = css({
	display: "flex",
	alignItems: "center",
	gap: "12",
});

export const sourceCardContentInnerClass = css({
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	gap: "2",
});

export const sourceCardTitleClass = css({
	fontSize: "12",
	lineHeight: "16",
	color: "shade.100",
});

export const sourceElementTypeWrapperClass = css({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	gap: "4",
	paddingX: "8",
	paddingY: "4",
	backgroundColor: "shade.10",
	borderRadius: "4",
});

export const deleteButtonClass = css({
	position: "absolute",
	top: "-8",
	right: "-8",
	zIndex: "20",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "[42px]",
	height: "[30px]",
	borderRadius: "8",
	border: "2px solid",
	borderColor: "primary.100",
	backgroundColor: "shade.0",
});

export const markedTextClass = cva({
	base: {
		padding: "16",
		color: "primary.100",
		backgroundColor: "onPrimary",
		borderRadius: "8",
		fontStyle: "italic",
	},
	variants: {
		hasSource: {
			true: {
				paddingTop: "[1.75rem]",
			},
		},
	},
	defaultVariants: {
		hasSource: true,
	},
});

export const textareaWrapperClass = cva({
	base: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		minWidth: "full",
		minHeight: "[120px]",
		border: "1px solid",
		borderRadius: "12",
		outline: "none",
		transition: "colors",
		md: {
			minWidth: "[380px]",
		},
	},
	variants: {
		color: {
			default: {
				backgroundColor: "onPrimary",
				borderColor: "shade.20",
				color: "shade.80",
			},
			yellow: {
				backgroundColor: "warning.20",
				borderColor: "[#EDBC12]",
				color: "[#EDBC12]",
			},
			blue: {
				backgroundColor: "info.20",
				color: "info.100",
				borderColor: "info.100",
			},
			green: {
				backgroundColor: "success.20",
				color: "success.100",
				borderColor: "success.100",
			},
			red: {
				backgroundColor: "error.20",
				color: "error.100",
				borderColor: "error.100",
			},
		},
	},
	defaultVariants: {
		color: "red",
	},
});

export const textareaClass = css({
	flex: "1",
	textStyle: "p2",
	paddingX: "12",
	paddingY: "8",
	width: "full",
	minHeight: "full",
	border: "none",
	outline: "none",
	resize: "none",
	scrollbarWidth: "none",
});

export const noteColorPickerClass = css({
	position: "absolute!",
	top: "-10",
	right: "-10",
});
