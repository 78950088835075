import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	direction: "column",
	align: "flex-end",
	width: "full",
	borderTopRadius: "12",
	md: {
		width: "full",
	},
	xl: {
		gap: "8",
		boxShadow: "card",
		width: "[calc(100% - 24px - 24px)]",
		maxWidth: "[52rem]",
		borderRadius: "24",
	},
	"3xl": {
		maxWidth: "[75rem]",
	},
});

export const childrenWrapperClass = flex({
	transform: "translateY(8px)",
	align: "center",
	width: "full",
	p: "16",
	pb: "24",
	bg: "[rgba(243, 243, 244, 0.8)]",
	borderTopRadius: "6",
	backdropFilter: "[blur(24px)]",
});

export const contentWrapperClass = flex({
	position: "relative",
	justify: "flex-end",
	zIndex: "12",
	width: "full",
	height: "full",
	p: "16",
	bg: "shade.0",
	borderTopRadius: "12",
	lg: {
		borderRadius: "24",
	},
});

export const buttonsWrapperClass = flex({
	justify: "flex-end",
	align: "center",
	gap: "16",
	width: "full",
	xl: {
		width: "[unset]",
	},
});

export const bottomTextWrapperClass = css({
	position: "absolute",
	px: "16",
	bottom: "2",
	right: "10",
});
