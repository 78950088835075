import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment userRulesTab_UserQueryFragment on Query {
		AccountMemberManagement {
			AccountMembers(filterByGroupIds: []) {
				accountMembers {
					edges {
						node {
							...licensesSingleUserAssignmentCard_AccountMemberFragment
							...userRulesTab_AccountMemberInlineFragment
						}
					}
				}
			}
		}
	}
`;

export const SINGLE_USER_ASSIGNMENT_RULE_INLINE_FRAGMENT = graphql`
	fragment userRulesTab_QueryFragment on SingleUserAssignmentRule @inline {
		id
		userId
		licensesUsedByUser
		maxLicenseAmount
	}
`;

export const ACCOUNT_MEMBER_INLINE_FRAGMENT = graphql`
	fragment userRulesTab_AccountMemberInlineFragment on AccountMember @inline {
		... on UserInAccountWrapper {
			user {
				user {
					id
				}
			}
		}
	}
`;
