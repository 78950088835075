import { useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { LicensesGroupCard } from "@components/licenses-group-card";
import { manageLicensesTab_Query } from "@relay/manageLicensesTab_Query.graphql";
import { Path } from "@router/paths";
import { QUERY } from "./manage-licenses-tab.graphql";
import { listWrapperClass } from "./manage-licenses-tab.styles";
import { LicenseContextMenu } from "./parts/license-context-menu/license-context-menu.component";
import { LicenseSettings } from "./parts/license-settings";

export const ManageLicensesTab = () => {
	const navigate = useNavigate();
	const data = useLazyLoadQuery<manageLicensesTab_Query>(QUERY, {
		kinds: ["Course"],
	});

	const handleOnClick = () => {
		navigate(Path.progression.path);
	};

	const handleLicenseCardOnClick = (id?: string) => {
		id && navigate(Path.profileMenu.account.manageLicenses.detail.withId(id).path);
	};

	return (
		<div className={listWrapperClass}>
			<LicenseSettings queryFragmentRef={data} />
			<EmptyPlaceholder
				title="Keine Lizenzen gefunden"
				subtitle="Schau dich gerne in unserem Angebotskatalog um, um die für dich passenden Angebote zu finden."
				iconName="loginKey"
				buttonLabel="Zu den Angeboten"
				onClick={handleOnClick}
			>
				{data?.LicenseGrouping.LicenseGroupings.edges?.map((edge) => (
					<LicensesGroupCard
						key={edge?.node.id}
						isAdminCard
						onClick={handleLicenseCardOnClick}
						licensesGroupCardFragmentRef={edge?.node}
					>
						{(ref) => <LicenseContextMenu ref={ref} queryFragmentRef={edge?.node} />}
					</LicensesGroupCard>
				))}
			</EmptyPlaceholder>
		</div>
	);
};
