import { EpicP2Span } from "@thekeytechnology/epic-ui";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import { VideoPlayerContext } from "@components/video-player/video-player.context";

import { type Subtitle, type VideoPlayerSubtitlesProps } from "./video-player-subtitles.interface";
import { TranscriptsWrapper } from "./video-player-subtitles.styles";
import { progressInRange } from "./video-player-subtitles.utils";

export const VideoPlayerSubtitles = ({
	subtitles,
	hidden,
	onSubtitleIndexChanged,
}: VideoPlayerSubtitlesProps) => {
	const theme = useTheme();
	const [currentSubtitle, setCurrentSubtitle] = useState<Subtitle>();
	const { progress } = useContext(VideoPlayerContext);

	const roundedProgress = Math.round(progress * 10) / 10;

	const sortedSubtitles = useMemo(
		() => subtitles?.slice().sort((a, b) => a.startTimeInSec - b.startTimeInSec) || [],
		[subtitles],
	);

	useEffect(() => {
		if (sortedSubtitles.length === 0 || progressInRange(progress, currentSubtitle)) return;

		const newSubtitleIndex = sortedSubtitles.findIndex((subtitle) =>
			progressInRange(progress, subtitle),
		);
		const newSubtitle = sortedSubtitles[newSubtitleIndex];

		if ((currentSubtitle && !newSubtitle) || newSubtitle) {
			setCurrentSubtitle(newSubtitle);
			onSubtitleIndexChanged?.(newSubtitleIndex);
		}
	}, [roundedProgress]);

	return currentSubtitle && !hidden ? (
		<TranscriptsWrapper>
			<EpicP2Span $color={theme.colors.shade0}>{currentSubtitle.text}</EpicP2Span>
		</TranscriptsWrapper>
	) : null;
};
