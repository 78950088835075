import { Skeleton, useTheme } from "@thekeytechnology/epic-ui";
import { DividerList } from "@components/divider-list";

export const BranchNodesTreeSkeleton = () => {
	const theme = useTheme();

	const borderRadius = theme.borderRadius?.borderRadius12;

	return (
		<DividerList hasDivider>
			<Skeleton width="100%" height="3.5rem" borderRadius={borderRadius} />
			<Skeleton width="100%" height="3.5rem" borderRadius={borderRadius} />
			<Skeleton width="100%" height="3.5rem" borderRadius={borderRadius} />
			<Skeleton width="100%" height="3.5rem" borderRadius={borderRadius} />
		</DividerList>
	);
};
