import { Skeleton } from "@thekeytechnology/epic-ui";
import { ProfileExpandableBox } from "@components/profile-expandable-box";
import { expandableBoxGridClass, levelProgressWrapperClass } from "./levels-box.styles";

export const LevelsBoxSkeleton = () => {
	return (
		<ProfileExpandableBox
			title="Level"
			header={
				<div className={levelProgressWrapperClass}>
					<Skeleton width="100%" height="0.625rem" />
					<Skeleton width="1.4375rem" height="1.125rem" />
				</div>
			}
		>
			<div className={expandableBoxGridClass({ columnStyle: "5" })}>
				{Array.from({ length: 10 }).map((_, index) => (
					<Skeleton key={index} width="100%" height="7.875rem" />
				))}
			</div>
		</ProfileExpandableBox>
	);
};
