import { css, cva } from "@styled-system/css";
import { flex, grid } from "@styled-system/patterns";

export const wrapperClass = flex({
	position: "absolute",
	zIndex: "10001",
	top: "0",
	left: "0",
	width: "screen",
	height: "screen",
	display: "flex",
	justifyContent: "center",
	alignItems: "flex-end",
	paddingBottom: "40",
});

export const backdropClass = css({
	position: "absolute",
	top: "0",
	left: "0",
	width: "full",
	height: "full",
	backgroundColor: "[rgba(0, 0, 0, 0.45)]",
});

export const firstLayerWrapperClass = css({
	position: "relative",
	zIndex: "1",
	minWidth: "[calc(100dvw - 10%)]",
	maxWidth: "[calc(100dvw - 10%)]",
	borderRadius: "12",
	paddingY: "32",
	paddingX: "40",
	background: "shade.0",
	lg: {
		minWidth: "[718px]",
		maxWidth: "[718px]",
	},
});

export const closeButtonClass = css({
	position: "absolute",
	top: "16",
	right: "16",
});

export const headerClass = flex({
	position: "relative",
	alignItems: "center",
});

export const headlineClass = css({
	fontSize: "[1.25rem]",
	fontWeight: "[700]",
	lineHeight: "[2.4rem]",
	lg: {
		fontSize: "[2rem]",
	},
});

export const cookieImageClass = css({
	position: "absolute",
	height: "[47px]",
	left: "[-56px]",
});

export const buttonsWrapperClass = grid({
	gridTemplateColumns: "1",
	gap: "16",
	mt: "24",
	lg: {
		gridTemplateColumns: "3",
	},
});

export const consentButtonClass = cva({
	base: {
		height: "40",
		paddingY: "8",
		paddingX: "16",
		borderRadius: "full",
		textAlign: "center",
		fontSize: "16",
		fontFamily: "default",
	},
	variants: {
		variant: {
			accept: {
				color: "[#184276]",
				backgroundColor: "[#4ACE7F]",
			},
			decline: {
				color: "[184276]",
				border: "2px solid",
				borderColor: "[#E6E5EF]",
			},
			moreDetails: {
				color: "[#079E83]",
				textDecoration: "underline",
			},
		},
	},
});
