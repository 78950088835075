/**
 * @generated SignedSource<<63b8b1a12c915cc0b1e00fa201c702ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NoteReminderKind = "NonRecurring" | "Recurring";
import { FragmentRefs } from "relay-runtime";
export type reminderCard_ReminderNoteFragment$data = {
  readonly __typename: "Note";
  readonly content: string | null | undefined;
  readonly id: string;
  readonly reminder: {
    readonly __typename: string;
    readonly isCompleted: boolean;
    readonly kind: NoteReminderKind;
    readonly remindAt: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"upsertReminderModal_ReminderNoteFragment">;
  readonly " $fragmentType": "reminderCard_ReminderNoteFragment";
};
export type reminderCard_ReminderNoteFragment$key = {
  readonly " $data"?: reminderCard_ReminderNoteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"reminderCard_ReminderNoteFragment">;
};

import reminderCard_ReminderNoteFragmentRefetch_graphql from './reminderCard_ReminderNoteFragmentRefetch.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": reminderCard_ReminderNoteFragmentRefetch_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "reminderCard_ReminderNoteFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "reminder",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCompleted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remindAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "upsertReminderModal_ReminderNoteFragment"
    }
  ],
  "type": "Note",
  "abstractKey": null
};
})();

(node as any).hash = "08e37f3738811049afcd3ff4f609bac4";

export default node;
