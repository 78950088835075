/**
 * @generated SignedSource<<e8c36fd95a4520d16c5e02bd4cb8d42c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type AccountMemberKind = "Invitation" | "UserInAccount";
import { FragmentRefs } from "relay-runtime";
export type membersTab_AccountMemberFragment$data = {
  readonly __id?: string;
  readonly id: string;
  readonly invitation?: {
    readonly email: string;
    readonly id: string;
  };
  readonly kind: AccountMemberKind;
  readonly user?: {
    readonly user: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"userContextMenu_UserFragment">;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"invitationCard_InvitationFragment" | "userCard_UserInAccountWrapperFragment">;
  readonly " $fragmentType": "membersTab_AccountMemberFragment";
};
export type membersTab_AccountMemberFragment$key = {
  readonly " $data"?: membersTab_AccountMemberFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"membersTab_AccountMemberFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "membersTab_AccountMemberFragment"
};

(node as any).hash = "db1a6dca4c1a7da2db775a8a7072967e";

export default node;
