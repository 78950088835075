import { useCookies } from "@hooks/use-cookies";
import {
	TKAOptinCookie,
	COOKIE_DOMAIN,
	COOKIE_MAX_AGE_SECONDS,
} from "./use-tka-optin-cookie.consts";
import { TKAOptinCookieManager } from "./use-tka-optin-cookie.types";

export const useTKAOptinCookie = (): TKAOptinCookieManager => {
	const { cookie, setCookie, cookieExists } = useCookies<boolean>(TKAOptinCookie);

	const handleSetCookie = (value: boolean) => {
		setCookie(value, { maxAge: COOKIE_MAX_AGE_SECONDS, domain: COOKIE_DOMAIN });
	};

	return {
		accepted: cookie === true,
		cookieExists,
		cookie,
		setCookie: handleSetCookie,
	};
};
