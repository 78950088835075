import { cva } from "@styled-system/css";
import { flex, stack } from "@styled-system/patterns";

export const expandableBoxGridClass = cva({
	base: {
		display: "grid",
	},
	variants: {
		columnStyle: {
			"5": {
				gridTemplateColumns: "2",
				columnGap: "[36px]",
				rowGap: "[6px]",
				md: {
					gridTemplateColumns: "5",
				},
				xl: {
					gridTemplateColumns: "3",
				},
				"2xl": {
					gridTemplateColumns: "5",
				},
			},
			"3": {
				gridTemplateColumns: "1",
				gap: "16",
				md: {
					gridTemplateColumns: "3",
				},
			},
		},
	},
});

export const noCertificatesClass = stack({
	gap: "4",
	width: "full",
	alignItems: "center",
	textAlign: "center",
});

export const noCertificatesIconClass = flex({
	backgroundColor: "primary.20",
	width: "80",
	height: "80",
	alignItems: "center",
	justifyContent: "center",
	borderRadius: "full",
	mb: "12",
});
