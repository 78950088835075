import { graphql } from "react-relay";

export const AUTH_VIEWER_SCHEMA_FRAGMENT = graphql`
	query accountContextMenu_Query {
		Viewer {
			Auth {
				currentUser {
					accounts {
						name
					}
				}
				...deleteAccountModalsFlow_AuthViewerSchemaFragment
				...leaveAccountModal_authViewerSchemaFragmentRef
			}
		}
	}
`;
