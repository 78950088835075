import styled from "styled-components";

export const TranscriptsWrapper = styled.div`
	display: flex;
	padding: ${({ theme }) => theme.spacing.spacing8.rem()};
	background-color: ${({ theme }) => theme.colors.shade100};
	align-items: center;
	overflow-y: auto;
	position: fixed;
	bottom: 20%;
	left: 0;
	text-align: center;
	-ms-overflow-style: none;
	scrollbar-width: none;

	::-webkit-scrollbar {
		display: none;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
		bottom: 30%;
		left: auto;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.large}px) {
		bottom: 15%;
	}
`;
