/**
 * @generated SignedSource<<d7b7785376aac488a6d3aedd18550816>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditSingleUserAssignmentRuleInput = {
  assignmentRuleId: string;
  clientMutationId?: string | null | undefined;
  maxLicenseAmount: number;
  userId: string;
};
export type licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation$variables = {
  input: EditSingleUserAssignmentRuleInput;
};
export type licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation$data = {
  readonly LicenseManagement: {
    readonly editSingleUserAssignmentRule: {
      readonly assignmentRule: {
        readonly " $fragmentSpreads": FragmentRefs<"licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment">;
      };
    } | null | undefined;
  };
};
export type licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation = {
  response: licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation$data;
  variables: licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseManagementMutations",
        "kind": "LinkedField",
        "name": "LicenseManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditSingleUserAssignmentRulePayload",
            "kind": "LinkedField",
            "name": "editSingleUserAssignmentRule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "assignmentRule",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseManagementMutations",
        "kind": "LinkedField",
        "name": "LicenseManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditSingleUserAssignmentRulePayload",
            "kind": "LinkedField",
            "name": "editSingleUserAssignmentRule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "assignmentRule",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "licenseGroupingId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "licensesUsedByUser",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxLicenseAmount",
                        "storageKey": null
                      }
                    ],
                    "type": "SingleUserAssignmentRule",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4d9e679ddd047ab5d718a91fdca54f27",
    "id": null,
    "metadata": {},
    "name": "licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation",
    "operationKind": "mutation",
    "text": "mutation licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation(\n  $input: EditSingleUserAssignmentRuleInput!\n) {\n  LicenseManagement {\n    editSingleUserAssignmentRule(input: $input) {\n      assignmentRule {\n        __typename\n        ...licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment\n        id\n      }\n    }\n  }\n}\n\nfragment licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment on SingleUserAssignmentRule {\n  __typename\n  id\n  userId\n  licenseGroupingId\n  licensesUsedByUser\n  maxLicenseAmount\n}\n"
  }
};
})();

(node as any).hash = "7510275bf49e2821ea72c8091aa0dac4";

export default node;
