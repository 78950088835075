import { Icon } from "@thekeytechnology/academies-lib-webapp";
import { useFragment } from "react-relay";
import { colorShade100Class, colorShade80Class } from "@themes/color-classes";
import { H3Span, P3Span } from "@themes/font-tags";
import { formatDateTime } from "@utils/date-utils";
import { ORDER_FRAGMENT } from "./invoice-card.graphql";
import {
	dataWrapperClass,
	iconWrapperClass,
	textWrapperClass,
	wrapperClass,
} from "./invoice-card.styles";
import { InvoiceCardProps } from "./invoice-card.types";

export const InvoiceCard = ({ orderFragmentRef }: InvoiceCardProps) => {
	const order = useFragment(ORDER_FRAGMENT, orderFragmentRef);

	const { invoiceData } = order;

	const handleDownloadInvoiceOnClick = () => {
		if (!invoiceData) return;
		const { invoiceNumber, invoiceFile } = invoiceData;
		if (!invoiceFile?.url) return;
		const link = document.createElement("a");
		link.href = invoiceFile.url;
		link.download = invoiceNumber ? `Rechnung_${invoiceNumber}.pdf` : "Rechnung.pdf";
		link.target = "_blank";
		if (
			navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
			navigator.userAgent.search("Chrome") < 0
		) {
			document.location = link.href;
		} else {
			const evt = new MouseEvent("click", {
				view: window,
				bubbles: true,
				cancelable: false,
			});
			link.dispatchEvent(evt);
			(window.URL || window.webkitURL).revokeObjectURL(link.href);
		}
		link.click();
	};

	return (
		<li className={wrapperClass}>
			<div className={dataWrapperClass}>
				<div className={iconWrapperClass}>
					<Icon icon="paragraphTwoColumn" sizeRem={1.5} />
				</div>
				<div className={textWrapperClass}>
					{order.invoiceData?.invoiceFile?.uploadDateTime && (
						<P3Span className={colorShade80Class}>
							{formatDateTime(order.invoiceData?.invoiceFile?.uploadDateTime)}
						</P3Span>
					)}
					<H3Span className={colorShade100Class}>
						Rechnung #{order.invoiceData?.invoiceNumber}
					</H3Span>
				</div>
			</div>
			<button type="button" onClick={handleDownloadInvoiceOnClick}>
				<Icon icon="downloadBottom" />
			</button>
		</li>
	);
};
