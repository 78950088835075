import { matchPath, useNavigate } from "react-router-dom";
import { useCurrentPath } from "@hooks/use-current-path";
import { TAB_INDEX_TO_ROUTES_LIST } from "./account.consts";

export const useTabIndexFromPath = () => {
	const currentPath = useCurrentPath();
	const path = currentPath?.pathname;
	if (!path) return 0;
	const index = TAB_INDEX_TO_ROUTES_LIST.findIndex((route) => {
		if (Array.isArray(route)) {
			return route.some((r) => matchPath(r, path));
		}
		return matchPath(route, path);
	});
	return index > -1 ? index : 0;
};

export const useNavigateToTab = () => {
	const navigate = useNavigate();
	const navigateToTab = (index: number) => {
		const tabPath = TAB_INDEX_TO_ROUTES_LIST[index];
		if (Array.isArray(tabPath)) {
			navigate(tabPath[0], { replace: true });
			return;
		}
		tabPath !== undefined && navigate(tabPath, { replace: true });
	};
	return navigateToTab;
};
