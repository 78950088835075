import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { selectCurrentAccountId } from "@thekeytechnology/academies-lib-webapp/slices";
import { parseHtml } from "@thekeytechnology/academies-lib-webapp/utils/parse-html";
import { PathParams } from "@thekeytechnology/epic-ui";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLazyLoadQuery, useRefetchableFragment } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { Message } from "@components/message";
import { useCurrentPath } from "@hooks/use-current-path";
import { useToast } from "@hooks/useToast";
import { type licenseDetails_LicenseGroupingFragment$key } from "@relay/licenseDetails_LicenseGroupingFragment.graphql";
import { type licenseDetails_Query } from "@relay/licenseDetails_Query.graphql";
import { type licenseDetails_RefetchQuery } from "@relay/licenseDetails_RefetchQuery.graphql";
import { Path } from "@router/paths";
import { ManageLicenseDetailsPath } from "@router/paths/profile";
import { colorShade100Class, colorShade80Class, colorWarning100Class } from "@themes/color-classes";
import { H1Span, H2Span, H3Span, P1Span, P2Span, P3Span } from "@themes/font-tags";
import { getLicenseModelKindForAccount } from "@utils/licenses-utils";
import { QUERY_FRAGMENT, QUERY } from "./license-details.graphql";
import {
	assignmentRulesHeaderClass,
	legendLicensBarClass,
	licenseBarContainerClass,
	licenseInfoWrapperClass,
	licenseLabelClass,
	licenseLabelsWrapperClass,
	usedLicensesBarClass,
	wrapperClass,
} from "./license-details.styles";
import { LicensesNewRuleModal } from "./parts/licenses-new-rule-modal";
import { UserRulesTab } from "./parts/user-rules-tab";

export const LicenseDetails = () => {
	const { licenseId } = useParams<PathParams<typeof ManageLicenseDetailsPath>>();
	const navigate = useNavigate();
	const { showSuccess } = useToast();
	const currentPath = useCurrentPath();
	const [showNewRuleModal, setShowNewRuleModal] = useState(
		() => currentPath?.pathname.includes("create-") ?? false,
	);

	const query = useLazyLoadQuery<licenseDetails_Query>(QUERY, {
		licenseGroupingId: licenseId!,
	});
	const [data, refetch] = useRefetchableFragment<
		licenseDetails_RefetchQuery,
		licenseDetails_LicenseGroupingFragment$key
	>(QUERY_FRAGMENT, query);

	const currentAccountId: string | undefined = useSelector(selectCurrentAccountId);
	const accountData = query?.Viewer?.Auth?.currentUser?.accounts;
	const licenseModel =
		(accountData && getLicenseModelKindForAccount(accountData, currentAccountId)) ??
		"FreeForAll";

	const userRules =
		data.LicenseGrouping?.LicenseGrouping?.assignmentRules.filter(
			(rule) => rule.__typename !== "AccountGroupAssignmentRule",
		) ?? [];

	const hasLicenseDescription = Boolean(
		data.LicenseGrouping.LicenseGrouping.learnable?.root?.description,
	);
	const licenseDescription = parseHtml(
		data.LicenseGrouping.LicenseGrouping.learnable?.root?.description ?? "",
	);
	const rootTitle =
		data.LicenseGrouping.LicenseGrouping.learnable?.root?.structureDefinition.title ?? "";

	const availableLicenses = data.LicenseGrouping?.LicenseGrouping?.totalLicenseAmount ?? 0;
	const usedLicenses = data.LicenseGrouping?.LicenseGrouping?.usedLicenseAmount ?? 0;
	const remainingLicenses = availableLicenses - usedLicenses;
	const usedLicensesPercentage = (usedLicenses / availableLicenses) * 100;
	const licensesBlockedByAssignmentRule = data.LicenseGrouping?.LicenseGrouping?.assignmentRules
		?.filter((rule) => rule.__typename === "SingleUserAssignmentRule")
		.map((rule) => rule.maxLicenseAmount)
		.filter(Boolean)
		.reduce((acc, val) => acc + val, 0);
	const licensesAvailableToAssign = remainingLicenses - licensesBlockedByAssignmentRule;
	const canAssignLicenses = licensesAvailableToAssign > 0;

	const handleOnCreateRule = () => {
		showSuccess({
			summary: "Die Zuweisung wurde erstellt",
		});
		if (currentPath?.pathname.includes("create-")) {
			navigate(Path.profileMenu.account.manageLicenses.detail.withId(licenseId!).path);
		}

		refetch(
			{},
			{
				fetchPolicy: "network-only",
			},
		);
	};

	const handleChangeSettingsOnClick = () => {
		navigate(Path.profileMenu.account.manageLicenses.path, {
			state: { showSettings: true },
		});
	};

	const handleGoToManageLicenses = () => {
		navigate(Path.profileMenu.account.manageLicenses.path);
	};

	const handleNewRuleModalOnDismiss = () => {
		setShowNewRuleModal(false);
	};

	const handleNewRuleModalOnOpen = () => {
		setShowNewRuleModal(true);
	};

	return (
		<>
			{showNewRuleModal && (
				<LicensesNewRuleModal
					isVisible
					fragmentRef={query}
					licenseGroupingId={licenseId!}
					freeLicenseCount={licensesAvailableToAssign}
					onDismiss={handleNewRuleModalOnDismiss}
					onCreateRule={handleOnCreateRule}
				/>
			)}
			<div className={wrapperClass}>
				<div className={licenseInfoWrapperClass}>
					<Button
						label="Zurück zu Lizenzen verwalten"
						colorVersion="textlink"
						onClick={handleGoToManageLicenses}
					/>
					<H1Span className={colorShade100Class}>Zuweisungen für "{rootTitle}"</H1Span>
					{hasLicenseDescription && <P1Span>{licenseDescription}</P1Span>}
				</div>

				{licenseModel === "FreeForAll" && (
					<Message
						highlighted
						severity="warn"
						content={
							<>
								<H3Span className={colorWarning100Class}>
									Zuweisungsregeln haben aktuell keinen Effekt
								</H3Span>
								<P2Span className={colorWarning100Class}>
									Da die Lizenz-Einstellungen auf "
									<strong>
										Jeder im Konto kann alle Lizenzen im Konto nutzen
									</strong>
									" gesetzt ist.
								</P2Span>
								<P2Span
									className={colorWarning100Class}
									onClick={handleChangeSettingsOnClick}
								>
									<u>Einstellung ändern</u>
								</P2Span>
							</>
						}
					/>
				)}

				<div>
					<H2Span>Eingelöste Lizenzen</H2Span>
					<div className={licenseBarContainerClass}>
						<div
							className={usedLicensesBarClass}
							style={{ "--width": `${usedLicensesPercentage ?? 0}%` } as any}
						/>
					</div>
					<div className={licenseLabelsWrapperClass}>
						<div className={licenseLabelClass}>
							<div
								className={legendLicensBarClass({
									status: "used",
								})}
							/>
							<P3Span className={colorShade80Class}>
								{usedLicenses}&times; Eingelöst
							</P3Span>
						</div>
						<div className={licenseLabelClass}>
							<div
								className={legendLicensBarClass({
									status: "remaining",
								})}
							/>
							<P3Span className={colorShade80Class}>
								{remainingLicenses}&times; Verfügbar
							</P3Span>
						</div>
					</div>
				</div>

				<div>
					<div className={assignmentRulesHeaderClass}>
						<H2Span>Zuweisungen ({userRules.length})</H2Span>
						<Button
							label="Neue Zuweisung erstellen"
							iconName="add"
							disabled={!canAssignLicenses}
							onClick={handleNewRuleModalOnOpen}
						/>
					</div>
					<UserRulesTab
						remainingLicenses={remainingLicenses}
						userFragmentRef={query}
						items={userRules}
						refetch={refetch}
					/>
				</div>
			</div>
		</>
	);
};
