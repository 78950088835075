import { css } from "@styled-system/css";
import { flex, stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	gap: "16",
});

export const reminderWrapperClass = stack({
	gap: "16",
	border: "1px solid",
	borderColor: "shade.20",
	borderRadius: "12",
	paddingY: "12",
	paddingX: "24",
});

export const reminderLocationClass = flex({
	width: "full",
	alignItems: "center",
	justifyContent: "center",
	gap: "4",
});

export const buttonClass = css({
	width: "full",
});
