import { DialogTemplate } from "@thekeytechnology/academies-lib-webapp/components/dialog-template";

import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { useRef } from "react";
import {
	LicensesNewSingleUserAssignmentForm,
	type LicensesNewSingleUserAssignmentFormRef,
} from "@components/licenses-new-single-user-assignment-form";
import { H1Span } from "@themes/font-tags";
import { wrapperClass } from "./licenses-new-rule-modal.styles";
import { LicensesNewRuleModalProps } from "./licenses-new-rule-modal.types";

export const LicensesNewRuleModal = ({
	isVisible,
	onDismiss,
	onCreateRule,
	freeLicenseCount,
	licenseGroupingId,
	fragmentRef,
}: LicensesNewRuleModalProps) => {
	const userFormRef = useRef<LicensesNewSingleUserAssignmentFormRef>(null);

	const handleSuccess = () => {
		onDismiss?.();
		onCreateRule?.();
	};

	const handleSubmit = () => {
		userFormRef.current?.submit();
	};

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<DialogTemplate
				secondaryButtonLabel="Schließen"
				onSecondaryButtonClick={onDismiss}
				primaryButtonLabel="Erstellen"
				primaryButtonIconName="arrowRight"
				onPrimaryButtonClick={handleSubmit}
				maxWidthRem={34.6}
			>
				<div className={wrapperClass}>
					<H1Span>Neue Zuweisung</H1Span>

					<LicensesNewSingleUserAssignmentForm
						ref={userFormRef}
						queryFragmentRef={fragmentRef}
						licenseGroupingId={licenseGroupingId}
						freeLicenseCount={freeLicenseCount}
						onSuccess={handleSuccess}
					/>
				</div>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
