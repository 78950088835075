import { useEffect } from "react";
import { useFragment } from "react-relay";
import { useTrialProgressTrackerContext } from "@components/trial-progress-tracker/trial-progress-tracker.context";
import { STORAGE_KEY_TRACKING_ITEMS } from "../../consts";
import { LEARN_OPPORTUNITY_V2_FRAGMENT } from "./trial-progress-tracker-item.graphql";
import {
	StorageTrackingItem,
	TrialProgressTrackerItemProps,
} from "./trial-progress-tracker-item.types";
import { calculateProgress } from "./trial-progress-tracker-item.utils";

export const TrialProgressTrackerItem = ({
	learnOpportunityV2FragmentRef,
}: TrialProgressTrackerItemProps) => {
	const { setLessonProgress, removeLessonProgress } = useTrialProgressTrackerContext();
	const data = useFragment(LEARN_OPPORTUNITY_V2_FRAGMENT, learnOpportunityV2FragmentRef ?? null);

	useEffect(() => {
		if (!data) return;

		const oldItems = localStorage.getItem(STORAGE_KEY_TRACKING_ITEMS);
		const parsedOldItems = oldItems ? (JSON.parse(oldItems) as StorageTrackingItem[]) : [];

		const structureDefinition = data.structureDefinition;
		const typeDefinition = data.typeDefinition;
		const lastThreeCharsFromId = data.id.slice(-3);
		const abbreviation = structureDefinition.extension?.abbreviation ?? lastThreeCharsFromId;
		const category = structureDefinition.extension?.tags?.find(({ data }) => data.isTopic);

		let trialProgress = 0;

		const isStarted = structureDefinition.viewerTreeState?.kind === "IsStarted";
		const isFinished = structureDefinition.viewerTreeState?.kind === "IsFinished";

		if (isFinished) {
			removeLessonProgress(abbreviation);
			return;
		}

		const currentContentNodeId = structureDefinition.viewerTreeState?.headContentId;
		const currentContentNodeArr = typeDefinition.children
			?.map((branch) => {
				return branch.typeDefinition.children?.find(
					(child) => child.id === currentContentNodeId,
				);
			})
			.filter(Boolean);

		const allIndexPaths =
			typeDefinition.children
				?.map((branch) => {
					return branch.typeDefinition.children?.map((child) => child.indexPath) ?? false;
				})
				.filter(Boolean)
				.flat() ?? [];

		if (currentContentNodeArr && currentContentNodeArr.length > 0) {
			const currentContentNode = currentContentNodeArr[0];
			const paywallContentNodeIndexPath =
				structureDefinition.extension?.unlockInfo?.firstContentCoordinatesIndexPath;

			if (!paywallContentNodeIndexPath) return;

			const { progress } = calculateProgress(
				allIndexPaths,
				currentContentNode.indexPath,
				paywallContentNodeIndexPath,
			);

			setLessonProgress({
				abbreviation,
				progress: `${currentContentNode.indexPath[1] + 1}.${
					currentContentNode.indexPath[2] + 1
				}`,
			});

			trialProgress = progress;
		}

		let newItems: StorageTrackingItem[] = parsedOldItems;

		const currentOldItem = parsedOldItems.find((item) => item.item_id === data.id);
		if (currentOldItem) {
			newItems = newItems.map((item) => {
				if (item.item_id === data.id) {
					return {
						...item,
						item_category: category?.data.name ?? "",
						trial_total_progress: isStarted ? trialProgress : undefined,
					};
				}
				return item;
			});
		} else {
			newItems.push({
				item_id: data.id,
				item_category: category?.data.name ?? "",
				trial_total_progress: isStarted ? trialProgress : undefined,
			});
		}

		localStorage.setItem(STORAGE_KEY_TRACKING_ITEMS, JSON.stringify(newItems));
	}, [data]);

	return null;
};
