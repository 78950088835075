import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { selectIsLoggedIn } from "@thekeytechnology/academies-lib-webapp/slices";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Path } from "@router/paths";
import { error100 } from "@themes/colors";
import { H1Span, P2Span } from "@themes/font-tags";
import { wrapperClass, iconWrapperClass, textWrapperClass } from "./accept-invite-error.styles";

export const AcceptInviteError = () => {
	const navigate = useNavigate();

	const { t } = useTkaTranslation("inviteScreens");

	const isLoggedIn = useSelector(selectIsLoggedIn);

	const handleGoToOnClick = () => {
		if (isLoggedIn) {
			navigate(Path.dashboard.path);
		} else {
			navigate(Path.login.path);
		}
	};

	const ctaText = isLoggedIn
		? t("invite_screens.invalidInviteLoggedInCta")
		: t("invite_screens.invalidInviteLoggedOutCta");

	return (
		<div className={wrapperClass}>
			<div className={iconWrapperClass}>
				<Icon sizeRem={2.5} icon="close" color={error100} />
			</div>
			<div className={textWrapperClass}>
				<H1Span>{t("invite_screens.invalidInviteTitle")}</H1Span>
				<P2Span>{t("invite_screens.invalidInviteText")}</P2Span>
			</div>
			<Button fillParent label={ctaText} onClick={handleGoToOnClick} />
		</div>
	);
};
