import {
	STORAGE_KEY_TRACKING_ITEMS,
	STORAGE_KEY_TRIAL_LESSON_PROGRESS,
	STORAGE_KEY_USER_IS_INTERNAL,
} from "../consts";

declare global {
	interface Window {
		dataLayer: Record<string, unknown>[];
	}
}

const track = (params: {}) => {
	if (!window.dataLayer) return;
	window.dataLayer.push(params);
};

export const booleanToYesNo = (value: boolean): string => {
	return value ? "ja" : "nein";
};

const getItemFromTrackingStorage = (itemId: string) => {
	const items = localStorage.getItem(STORAGE_KEY_TRACKING_ITEMS);
	const parsedItems = items ? (JSON.parse(items) as ReturnType<typeof gtmItemToDataLayer>[]) : [];
	return parsedItems.find((item) => item.item_id === itemId);
};

const hydrateItemsWithTrackingStorageItem = (items: GtmItem[]): GtmItem[] => {
	return items.map((item) => {
		const trackingItem = getItemFromTrackingStorage(item.itemId);
		if (!trackingItem) return item;
		const lessonProgress = localStorage.getItem(STORAGE_KEY_TRIAL_LESSON_PROGRESS);

		return {
			...item,
			itemCategory: trackingItem.item_category,
			trialLessonProgress: lessonProgress ?? undefined,
			trialTotalProgress: trackingItem.trial_total_progress,
		};
	});
};

const getUserIsInternalFromStorage = () => {
	return localStorage.getItem(STORAGE_KEY_USER_IS_INTERNAL) ?? "nein";
};

export const gtmTrackPageView = ({
	userId,
	userIsLoggedIn,
	pageLocation,
	pageReferrer,
}: {
	userId?: string;
	pageLocation?: string;
	pageReferrer?: string;
	userIsLoggedIn: boolean;
}) => {
	track({
		event: "page_view",
		user_id: userId,
		page_location: pageLocation ?? window.location.href,
		page_referrer: pageReferrer ?? document.referrer,
		user_is_internal: getUserIsInternalFromStorage(),
		user_is_logged_in: booleanToYesNo(userIsLoggedIn),
		custom_platform: "app_web",
	});
};

export const gtmTrackLogin = ({
	userId,
	userIsInternal,
}: {
	userId: string;
	userIsInternal: boolean;
}) => {
	localStorage.setItem(STORAGE_KEY_USER_IS_INTERNAL, booleanToYesNo(userIsInternal));

	const dataObj = {
		event: "login",
		user_id: userId,
		user_is_internal: booleanToYesNo(userIsInternal),
		user_is_logged_in: "ja",
	};

	track(dataObj);
};

export const gtmTrackSignUpRequest = () => {
	track({ event: "sign_up_request" });
};

export const gtmTrackSignUp = ({ userId }: { userId: string }) => {
	const dataObj = {
		event: "sign_up",
		user_id: userId,
	};

	track(dataObj);
};

export const gtmTrackStartTrial = ({
	userId,
	courseId,
	courseName,
	courseCategory,
}: {
	userId: string;
	courseId: string;
	courseName: string;
	courseCategory: string;
}) => {
	const dataObj = {
		event: "start_trial",
		user_id: userId,
		course_id: courseId,
		course_name: courseName,
		course_category: courseCategory,
	};

	track(dataObj);
};

export const gtmTrackEndTrial = ({
	userId,
	courseId,
	courseName,
	courseCategory,
}: {
	userId: string;
	courseId: string;
	courseName: string;
	courseCategory: string;
}) => {
	const dataObj = {
		event: "end_trial",
		user_id: userId,
		course_id: courseId,
		course_name: courseName,
		course_category: courseCategory,
	};

	track(dataObj);
};

type GtmItem = {
	itemId: string;
	itemName: string;
	itemCategory: string;
	price: number;
	quantity: number;
	trialTotalProgress?: number;
	trialLessonProgress?: string;
};

const gtmItemToDataLayer = (item: GtmItem) => {
	return {
		item_id: item.itemId,
		item_name: item.itemName,
		item_category: item.itemCategory,
		price: item.price,
		quantity: item.quantity,
		trial_total_progress: item.trialTotalProgress,
		trial_lesson_progress: item.trialLessonProgress,
	};
};

export const gtmTrackViewItem = ({
	currency,
	value,
	items,
}: {
	currency: string;
	value: number;
	items: GtmItem[];
}) => {
	const convertedItems = hydrateItemsWithTrackingStorageItem(items).map(gtmItemToDataLayer);

	const dataObj = {
		event: "view_item",
		ecommerce: {
			currency,
			value,
			items: convertedItems,
		},
	};

	track({ ecommerce: null });
	track(dataObj);
};

export const gtmTrackAddToCart = ({
	currency,
	value,
	items,
}: {
	currency: string;
	value: number;
	items: GtmItem[];
}) => {
	const convertedItems = hydrateItemsWithTrackingStorageItem(items).map(gtmItemToDataLayer);

	const dataObj = {
		event: "add_to_cart",
		ecommerce: {
			currency,
			value,
			items: convertedItems,
		},
	};

	track({ ecommerce: null });
	track(dataObj);
};

export const gtmTrackRemoveFromCart = ({
	currency,
	value,
	items,
}: {
	currency: string;
	value: number;
	items: GtmItem[];
}) => {
	const convertedItems = hydrateItemsWithTrackingStorageItem(items).map(gtmItemToDataLayer);

	const dataObj = {
		event: "remove_from_cart",
		ecommerce: {
			currency,
			value,
			items: convertedItems,
		},
	};

	track({ ecommerce: null });
	track(dataObj);
};

export const gtmTrackViewCart = ({
	currency,
	value,
	coupon,
	items,
}: {
	currency: string;
	value: number;
	coupon: string;
	items: GtmItem[];
}) => {
	const convertedItems = hydrateItemsWithTrackingStorageItem(items).map(gtmItemToDataLayer);

	const dataObj = {
		event: "view_cart",
		ecommerce: {
			currency,
			value,
			coupon,
			items: convertedItems,
		},
	};

	track({ ecommerce: null });
	track(dataObj);
};

export const gtmTrackBeginCheckout = ({
	currency,
	value,
	coupon,
	items,
}: {
	currency: string;
	value: number;
	coupon: string;
	items: GtmItem[];
}) => {
	const convertedItems = hydrateItemsWithTrackingStorageItem(items).map(gtmItemToDataLayer);

	const dataObj = {
		event: "begin_checkout",
		ecommerce: {
			currency,
			value,
			coupon,
			items: convertedItems,
		},
	};

	track({ ecommerce: null });
	track(dataObj);
};

export const gtmTrackAddShippingInfo = ({
	currency,
	value,
	coupon,
	items,
}: {
	currency: string;
	value: number;
	coupon: string;
	items: GtmItem[];
}) => {
	const convertedItems = hydrateItemsWithTrackingStorageItem(items).map(gtmItemToDataLayer);

	const dataObj = {
		event: "add_shipping_info",
		ecommerce: {
			currency,
			value,
			coupon,
			items: convertedItems,
		},
	};

	track({ ecommerce: null });
	track(dataObj);
};

export const gtmTrackAddPaymentInfo = ({
	currency,
	value,
	coupon,
	paymentType,
	items,
}: {
	currency: string;
	value: number;
	coupon: string;
	paymentType: string;
	items: GtmItem[];
}) => {
	const convertedItems = hydrateItemsWithTrackingStorageItem(items).map(gtmItemToDataLayer);

	const dataObj = {
		event: "add_payment_info",
		ecommerce: {
			currency,
			value,
			coupon,
			payment_type: paymentType,
			items: convertedItems,
		},
	};

	track({ ecommerce: null });
	track(dataObj);
};

export const gtmTrackPurchase = ({
	transactionId,
	currency,
	value,
	coupon,
	paymentType,
	items,
}: {
	transactionId: string;
	currency: string;
	value: number;
	coupon: string;
	paymentType: string;
	items: GtmItem[];
}) => {
	const convertedItems = hydrateItemsWithTrackingStorageItem(items).map(gtmItemToDataLayer);

	const dataObj = {
		event: "purchase",
		ecommerce: {
			transaction_id: transactionId,
			currency,
			value,
			coupon,
			payment_type: paymentType,
			items: convertedItems,
		},
	};

	track({ ecommerce: null });
	track(dataObj);
};

export const gtmTrackRedeemLicense = ({
	userId,
	courseId,
	courseName,
	courseCategory,
	transactionId,
}: {
	userId: string;
	courseId: string;
	courseName: string;
	courseCategory: string;
	transactionId: string;
}) => {
	const dataObj = {
		event: "redeem_license",
		user_id: userId,
		course_id: courseId,
		course_name: courseName,
		course_category: courseCategory,
		transaction_id: transactionId,
	};

	track(dataObj);
};
