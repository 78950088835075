import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { EpicP2Span } from "@thekeytechnology/epic-ui";
import { useCallback, useContext } from "react";
import { useTheme } from "styled-components";
import { DynamicSlideMenuContext } from "@components/dynamic-slide-menu-provider";
import { NavigateTitle, NavigateWrapper } from "./dynamic-slide-menu-navigate-item.styles";
import { type DynamicSlideMenuNavigateItemProps } from "./dynamic-slide-menu-navigate-item.types";

export const DynamicSlideMenuNavigateItem = ({
	title,
	content,
	path,
	children,
}: DynamicSlideMenuNavigateItemProps) => {
	const context = useContext(DynamicSlideMenuContext);
	const theme = useTheme();

	const { path: currentPath, navigate, depth } = context;
	const handleOnClick = useCallback(() => {
		navigate(path);
	}, [navigate, path]);
	if (currentPath.length > depth && !currentPath.includes(path)) return null;

	return currentPath.includes(path) ? (
		<DynamicSlideMenuContext.Provider value={{ ...context, depth: depth + 1 }}>
			{children}
		</DynamicSlideMenuContext.Provider>
	) : (
		<NavigateWrapper onClick={handleOnClick}>
			<NavigateTitle $color={theme.colors.shade0}>{title}</NavigateTitle>
			{content && <EpicP2Span $color={theme.colors.shade0}>{content}</EpicP2Span>}
			<Icon icon="arrowRight2" sizeRem={0.75} color={theme.colors.shade0} />
		</NavigateWrapper>
	);
};
