import { memo, useState } from "react";
import { P2Anchor, P2Span } from "@themes/font-tags";
import { CookieBannerTextProps } from "./cookie-banner-text.types";
import { cx } from "@styled-system/css";
import { contentClass, toggleButtonClass, wrapperClass } from "./cookie-banner-text.styles";
import { EpicIcons, Icon } from "@thekeytechnology/epic-ui";
import { shade0 } from "@themes/colors";

export const CookieBannerTextComponent = ({ className, ...props }: CookieBannerTextProps) => {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div aria-expanded={isExpanded} className={cx(wrapperClass, className)} {...props}>
			<div className={contentClass}>
				<P2Span>Wir, </P2Span>
				<P2Anchor
					href="https://www.thekey.academy/impressum/"
					target="_blank"
					rel="noreferrer"
				>
					the key - academy GmbH
				</P2Anchor>
				<P2Span>, nutzen auf unseren Websites </P2Span>
				<P2Anchor href="https://thekey.academy/" target="_blank" rel="noreferrer">
					https://www.thekey.academy/
				</P2Anchor>{" "}
				<P2Span>und </P2Span>
				<P2Anchor href="https://app.thekey.academy/" target="_blank" rel="noreferrer">
					https://app.thekey.academy/
				</P2Anchor>
				<P2Span>
					{" "}
					Cookies und ähnliche Tracking Technologien wie z.B. Beacons (gemeinsam
					„Cookies”). Mit Klick auf „Alle Akzeptieren” willigst Du in die Nutzung von
					Analyse-Cookies, Präferenz-Cookies und Externe-Medien-Cookies und in die dadurch
					erfolgende Verarbeitung Deiner personenbezogenen Daten für die oben
					beschriebenen Zwecken durch uns oder Dritte, wie zum Beispiel Google, LLC ein.
					Weitere Informationen findest Du in unserer{" "}
				</P2Span>
				<P2Anchor
					href={"https://www.thekey.academy/datenschutzerklaerung/"}
					target="_blank"
					rel="noreferrer"
				>
					Datenschutzerklärung
				</P2Anchor>{" "}
				<P2Span>
					und unter “Mehr Details”. Wenn Du auf „Ablehnen” klickst, werden wir nur
					Essentielle Cookie nutzen. Du kannst unter “Details” Deine Einwilligung
					jederzeit widerrufen und Deine Cookie-Einstellungen ändern.
				</P2Span>
			</div>
			<button
				type="button"
				data-expanded={isExpanded}
				className={toggleButtonClass}
				onClick={() => setIsExpanded(!isExpanded)}
			>
				<Icon
					icon={isExpanded ? EpicIcons.ANGLE_UP : EpicIcons.ANGLE_DOWN}
					color={shade0}
				/>
			</button>
		</div>
	);
};

export const CookieBannerText = memo(CookieBannerTextComponent);
