import { useFragment } from "react-relay";
import { TrialProgressTrackerItem } from "@components/trial-progress-tracker-item/trial-progress-tracker-item.component";
import { PUBLISHED_LEARNABLES_CONNECTION_FRAGMENT } from "./trial-progress-tracker.graphql";
import { TrialProgressTrackerProps } from "./trial-progress-tracker.types";

export const TrialProgressTracker = ({
	publishedLearnablesConnectionFragmentRef,
}: TrialProgressTrackerProps) => {
	const fragment = useFragment(
		PUBLISHED_LEARNABLES_CONNECTION_FRAGMENT,
		publishedLearnablesConnectionFragmentRef ?? null,
	);
	const edges = fragment?.edges ?? [];

	return (
		<>
			{edges.map((edge) => (
				<TrialProgressTrackerItem
					key={edge?.node.id}
					learnOpportunityV2FragmentRef={edge?.node?.root}
				/>
			))}
		</>
	);
};
