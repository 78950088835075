/**
 * @generated SignedSource<<de66e105c82b17a5a16d37e0ed07475c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NoteColor = "blue" | "default" | "green" | "red" | "yellow";
import { FragmentRefs } from "relay-runtime";
export type noteCard_NoteFragment$data = {
  readonly color: NoteColor | null | undefined;
  readonly content: string | null | undefined;
  readonly id: string;
  readonly markedText: string | null | undefined;
  readonly source: {
    readonly element?: {
      readonly " $fragmentSpreads": FragmentRefs<"upsertNoteModal_ElementFragment">;
    } | null | undefined;
    readonly publishedRootNode?: {
      readonly id: string;
      readonly structureDefinition: {
        readonly title: string;
      };
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"upsertNoteModal_NoteFragment">;
  readonly " $fragmentType": "noteCard_NoteFragment";
};
export type noteCard_NoteFragment$key = {
  readonly " $data"?: noteCard_NoteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"noteCard_NoteFragment">;
};

import noteCard_NoteFragmentRefetch_graphql from './noteCard_NoteFragmentRefetch.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "LearnOpportunityV2",
  "kind": "LinkedField",
  "name": "publishedRootNode",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": noteCard_NoteFragmentRefetch_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "noteCard_NoteFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "markedText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "source",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "CourseNoteSource",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "element",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "upsertNoteModal_ElementFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ElementNoteSourceType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "ModuleNoteSource",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "upsertNoteModal_NoteFragment"
    }
  ],
  "type": "Note",
  "abstractKey": null
};
})();

(node as any).hash = "3e5ba97eb55c82b8d0684fb1c802d3c8";

export default node;
