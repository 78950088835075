import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { graphql, useFragment, useMutation } from "react-relay";
import { ClozeTextLearnElement } from "@components/cloze-text-learn-element";
import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider";
import { GapTextStatus } from "@components/gap-text/gap-text.interface";
import { SideMenuContentPadding } from "@containers/side-menu-content-padding";
import { clozeTextElement_ContentSubmissionFragment$key } from "@relay/clozeTextElement_ContentSubmissionFragment.graphql";
import { clozeTextElement_SubmitClozeTextElementMutation } from "@relay/clozeTextElement_SubmitClozeTextElementMutation.graphql";
import { colorShade100Class } from "@themes/color-classes";
import { H1Span } from "@themes/font-tags";
import { SideMenuLayoutContext } from "@layouts/sidemenu-layout/sidemenu-layout.context";
import {
	contentWrapperClass,
	fixedBottomWrapperClass,
	relativeBottomWrapperClass,
	spacing16Class,
	wrapperClass,
} from "./cloze-text-element.styles";
import { ClozeTextElementProps } from "./cloze-text-element.types";
import { ClozeTextElementAnswers } from "../cloze-text-element-answers/cloze-text-element-answers.component";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment clozeTextElement_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					kind
					... on InputAndIsCorrectElementState {
						isCorrect
						inputElementState {
							... on ClozeTextInputElementState {
								checkedAnswers
							}
						}
					}
					... on ClozeTextShowAnswerElementState {
						lastCheckedAnswers
					}
					... on ClozeTextInputElementState {
						checkedAnswers
					}
					element {
						... on ClozeTextLearnElement {
							id
							title
							parts {
								kind
								id
								... on TextPart {
									text
								}
							}
							shuffledAnswers {
								text
								id
							}
							...clozeTextLearnElement_ClozeTextLearnElementFragment
						}
					}
				}
			}
		}
	}
`;

const SUBMIT_CLOZE_TEXT_ELEMENT_MUTATION = graphql`
	mutation clozeTextElement_SubmitClozeTextElementMutation(
		$input: SubmitClozeTextElementV2Input!
	) {
		LearnV2 {
			submitClozeTextElement(input: $input) {
				clientMutationId
				contentSubmission {
					definition {
						... on ActiveELearningContentSubmissionDefinition {
							currentElementState {
								... on InputAndIsCorrectElementState {
									isCorrect
								}
								kind
							}
						}
					}
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;

export const ClozeTextElement = ({ contentSubmissionFragmentRef }: ClozeTextElementProps) => {
	const contentSubmission = useFragment<clozeTextElement_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);
	const [submitClozeTextElement] = useMutation<clozeTextElement_SubmitClozeTextElementMutation>(
		SUBMIT_CLOZE_TEXT_ELEMENT_MUTATION,
	);

	const { bottomContentHeightPx } = useContext(SideMenuLayoutContext);

	const elementState = contentSubmission?.definition?.currentElementState;
	const element = elementState?.element;
	const currentElementStateKind = elementState?.kind || "Untouched";

	const isInputCorrect = currentElementStateKind === "InputAndIsCorrect";
	const isUntouched =
		currentElementStateKind === "Untouched" ||
		currentElementStateKind === "UntouchedAndPreviouslyIncorrect";
	const isInput = currentElementStateKind === "Input";

	const lastCheckedAnswers =
		elementState?.lastCheckedAnswers ||
		elementState?.inputElementState?.checkedAnswers ||
		elementState?.checkedAnswers ||
		undefined;
	const textParts = element?.parts || [];
	const shuffledAnswers = element?.shuffledAnswers || [];

	const {
		isModalVisible,
		setCanGoNext,
		addGoToNextOnClickListener,
		addTryAgainOnClickListener,
		setFailureModalVisible,
		setSuccessModalVisible,
		setLoading,
	} = useContext(ContentSubmissionModalContext);

	const [selectedCellIndex, setSelectedCellIndex] = useState<number | undefined>(0);
	const [selectedAnswersId, setSelectedAnswersId] = useState<string[]>([]);
	const availableAnswers = useMemo(
		() => shuffledAnswers.filter((a) => !selectedAnswersId.includes(a.id)),
		[selectedAnswersId],
	);

	const inputDisabled = isModalVisible || (!isUntouched && !isInput);

	const handleSubmitClick = useCallback(() => {
		setLoading(true);
		submitClozeTextElement({
			variables: {
				input: {
					contentSubmissionId: contentSubmission.id,
					clozeTextElementId: element?.id!,
					checkedAnswers: selectedAnswersId,
				},
			},
			onCompleted: (response) => {
				setLoading(false);
				const currentElementState =
					response.LearnV2.submitClozeTextElement?.contentSubmission.definition
						?.currentElementState;
				if (!currentElementState) return;

				if (currentElementState.isCorrect) {
					setSuccessModalVisible(true);
				} else if (
					currentElementState.isCorrect === false ||
					currentElementState.kind === "UntouchedAndPreviouslyIncorrect"
				) {
					setFailureModalVisible(true);
				}
			},
		});
	}, [
		submitClozeTextElement,
		contentSubmission.id,
		element?.id,
		selectedAnswersId,
		setSuccessModalVisible,
		setFailureModalVisible,
	]);

	const handleGoToNextClicked = useCallback(() => {
		setSelectedCellIndex(undefined);
		if (isUntouched || isInput) {
			handleSubmitClick();
			return true;
		}
		return false;
	}, [isUntouched, isInput, handleSubmitClick]);

	useEffect(() => addGoToNextOnClickListener(handleGoToNextClicked), [handleGoToNextClicked]);

	const handleTryAgainClicked = useCallback(() => {
		setSelectedCellIndex(0);
		setSelectedAnswersId([]);
	}, []);

	const requiredSelections = useMemo(
		() => textParts.filter((part) => part.kind === "cloze").length,
		[textParts],
	);

	useEffect(() => addTryAgainOnClickListener(handleTryAgainClicked), [handleTryAgainClicked]);

	useEffect(() => {
		const requiredSelections = textParts.filter((part) => part.kind === "cloze").length;
		const canGoNext =
			selectedAnswersId.filter(Boolean).length === requiredSelections || !isUntouched;
		setCanGoNext(canGoNext);
	}, [textParts, selectedAnswersId, isUntouched]);

	useEffect(() => {
		if (!isUntouched && lastCheckedAnswers && lastCheckedAnswers.length > 0) {
			setSelectedAnswersId([...lastCheckedAnswers]);
		}
	}, [lastCheckedAnswers, isUntouched]);

	const useAnswersId = selectedAnswersId;

	const calculateGapTextStatus = (): GapTextStatus => {
		if (isInputCorrect) return GapTextStatus.Correct;
		return GapTextStatus.Default;
	};

	const handleAnswerOnClick = useCallback(
		(id: string) => {
			if (selectedCellIndex === undefined) return;
			if (selectedCellIndex < requiredSelections - 1) {
				setSelectedCellIndex(selectedCellIndex + 1);
			} else {
				setSelectedCellIndex(undefined);
			}
			setSelectedAnswersId((answerIds) => {
				const answerSlice = answerIds.slice();
				answerSlice[selectedCellIndex] = id;
				return answerSlice;
			});
		},
		[selectedCellIndex],
	);

	const answersModal = (
		<ClozeTextElementAnswers answers={availableAnswers} answerOnClick={handleAnswerOnClick} />
	);

	return (
		<div className={wrapperClass} data-selection-menu-element-id={elementState?.element.id}>
			<div className={contentWrapperClass} onClick={() => setSelectedCellIndex(undefined)}>
				<SideMenuContentPadding>
					<H1Span className={colorShade100Class}>
						Ergänze folgenden Text mit den vorgegebenen Bausteinen
					</H1Span>
					<div className={spacing16Class} />
					<ClozeTextLearnElement
						disabled={inputDisabled}
						selectedCellIndex={selectedCellIndex}
						setSelectedCellIndex={setSelectedCellIndex}
						answerIds={useAnswersId}
						availableAnswers={availableAnswers}
						getStatusForIndex={calculateGapTextStatus}
						setSelectedAnswersId={setSelectedAnswersId}
						learnElementFragmentRef={element}
					/>
				</SideMenuContentPadding>
			</div>
			<div className={relativeBottomWrapperClass}>{answersModal}</div>
			<div
				className={fixedBottomWrapperClass}
				style={{ "--bottom-px": `${bottomContentHeightPx}px` } as any}
			>
				{answersModal}
			</div>
		</div>
	);
};
