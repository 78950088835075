import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import React, { useContext, useEffect, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider/content-submission-modal-context-provider.context";
import { skipQuestionButton_CanShowElementAnswerMutation } from "@relay/skipQuestionButton_CanShowElementAnswerMutation.graphql";
import { skipQuestionButton_ContentSubmissionFragment$key } from "@relay/skipQuestionButton_ContentSubmissionFragment.graphql";
import { skipQuestionButton_GoToNextElementMutation } from "@relay/skipQuestionButton_GoToNextElementMutation.graphql";
import { skipQuestionButton_ShowElementAnswerMutation } from "@relay/skipQuestionButton_ShowElementAnswerMutation.graphql";
import {
	ELEARNING_CONTENT_SUBMISSION_DEFINITION,
	SHOW_ELEMENT_ANSWER_MUTATION,
	CAN_SHOW_ELEMENT_ANSWER_MUTATION,
	GO_TO_NEXT_ELEMENT_MUTATION,
} from "./skip-question-button.graphql";
import { SkipQuestionButtonProps } from "./skip-question-button.types";

export const SkipQuestionButton = ({ contentSubmissionFragmentRef }: SkipQuestionButtonProps) => {
	const [showingSkipQuestionButton, setShowingSkipQuestionButton] = useState(false);
	const contentSubmission = useFragment<skipQuestionButton_ContentSubmissionFragment$key>(
		ELEARNING_CONTENT_SUBMISSION_DEFINITION,
		contentSubmissionFragmentRef,
	);
	const [showElementAnswer] = useMutation<skipQuestionButton_ShowElementAnswerMutation>(
		SHOW_ELEMENT_ANSWER_MUTATION,
	);
	const [canShowAnswer] = useMutation<skipQuestionButton_CanShowElementAnswerMutation>(
		CAN_SHOW_ELEMENT_ANSWER_MUTATION,
	);
	const [goToNextElement] = useMutation<skipQuestionButton_GoToNextElementMutation>(
		GO_TO_NEXT_ELEMENT_MUTATION,
	);

	const { setCanGoNext, showAnswerClicked, addGoToNextOnClickListener } = useContext(
		ContentSubmissionModalContext,
	);

	const { isMediumUp } = useWindowSize();

	const handleOnGoToNext = () => {
		canShowAnswer({
			variables: {
				input: {
					contentSubmissionId: contentSubmission.id!,
				},
			},
			onCompleted: (response) => {
				setShowingSkipQuestionButton(response.ELearning.canShowElementAnswer?.can ?? false);
			},
		});

		return false;
	};

	useEffect(() => {
		addGoToNextOnClickListener(handleOnGoToNext);
		setCanGoNext(true);
	}, []);

	const onClick = () => {
		showAnswerClicked();
		showElementAnswer({
			variables: {
				input: {
					contentSubmissionId: contentSubmission.id!,
					inputElementId: contentSubmission.definition!.currentElementState!.element!.id!,
				},
			},
			onCompleted: () => {
				goToNextElement({
					variables: {
						input: {
							contentSubmissionId: contentSubmission.id!,
						},
					},
					onCompleted: () => {
						setShowingSkipQuestionButton(false);
					},
				});
			},
		});
	};

	if (!showingSkipQuestionButton) return <React.Fragment />;

	return (
		<Button
			fillParent={!isMediumUp}
			label={"Antwort überspringen"}
			colorVersion={"outline"}
			minWidthRem={10}
			onClick={onClick}
		/>
	);
};
