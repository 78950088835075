import { stack } from "@styled-system/patterns";
import tw from "twin.macro";

export const wrapperClass = stack({
	gap: "24",
});

export const Wrapper = tw.div`
	flex
	flex-col
	gap-24
`;

export const titleWrapperClass = stack({
	gap: "4",
});

export const TitleWrapper = tw.div`
	flex
	flex-col
	gap-4
`;

export const PreviewWrapper = tw.div`
	flex
	flex-col
	gap-12
	md:items-center
	xl:items-start
`;
