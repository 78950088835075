/**
 * @generated SignedSource<<986c9e851130a2a1ffdd471d00988b56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "enhancedText" | "file" | "markMistakes" | "matrix" | "multipleChoice" | "order" | "persolog" | "persologEvaluation" | "persologQuestion" | "podcast" | "podcastWithTimestamp" | "reminder" | "text" | "textWithPages" | "video";
import { FragmentRefs } from "relay-runtime";
export type upsertNoteModal_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly currentElementState?: {
      readonly element: {
        readonly elementType: ElementTypeV2;
        readonly id: string;
      };
    } | null | undefined;
  };
  readonly id: string;
  readonly learnOpportunity: {
    readonly root: {
      readonly structureDefinition: {
        readonly title: string;
      };
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"noteSourceElementRenderer_ContentSubmissionFragment">;
  readonly " $fragmentType": "upsertNoteModal_ContentSubmissionFragment";
};
export type upsertNoteModal_ContentSubmissionFragment$key = {
  readonly " $data"?: upsertNoteModal_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"upsertNoteModal_ContentSubmissionFragment">;
};

import upsertNoteModal_ContentSubmissionFragmentRefetch_graphql from './upsertNoteModal_ContentSubmissionFragmentRefetch.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": upsertNoteModal_ContentSubmissionFragmentRefetch_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "upsertNoteModal_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "currentElementState",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "element",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "elementType",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ActiveELearningContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "learnOpportunity",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnOpportunityV2",
          "kind": "LinkedField",
          "name": "root",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "structureDefinition",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "noteSourceElementRenderer_ContentSubmissionFragment"
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "beb01108521fae59ef4f5d547e8042ef";

export default node;
