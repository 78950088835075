import { BadgeV2 } from "@thekeytechnology/academies-lib-webapp/components/badge-v2";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { ResponsiveBottomSheetOverlayPanel } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-overlay-panel";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import React, { type MouseEvent, useContext, useMemo, useRef } from "react";
import { NoteColor } from "@relay/noteCard_NoteFragment.graphql";
import { colorShade100Class } from "@themes/color-classes";
import { shade100, shade40 } from "@themes/colors";
import { P2Span } from "@themes/font-tags";
import {
	desktopLabelWrapperClass,
	filterTextWrapperClass,
	inputTextMockClass,
	mobileBadgeWrapperClass,
	wrapperClass,
} from "./filters.styles";
import { NotesScreenContext } from "../../notes.context";
import { FiltersModal } from "../filters-modal";

export const Filters = () => {
	const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);

	const { filters } = useContext(NotesScreenContext);
	const { isMediumUp } = useWindowSize();
	const calculatedValue = useMemo(() => {
		const defaultValue = "Filter";

		const translationKeyMap: Record<NoteColor, string> = {
			default: "Schwarz",
			yellow: "Gelb",
			green: "Grün",
			blue: "Blau",
			red: "Rot",
		};
		const kindsValue = filters.colors
			.map((color) => {
				return translationKeyMap[color as keyof typeof translationKeyMap];
			})
			.join(", ");
		return kindsValue || defaultValue;
	}, [filters.colors]);

	const handleOpenOnClick = (event: MouseEvent<HTMLElement>) => {
		overlayRef.current?.show(event, event.currentTarget);
	};

	const handleOnClose = () => {
		overlayRef.current?.hide();
	};

	const iconColor = useMemo(() => (isMediumUp ? shade40 : shade100), [isMediumUp]);
	const iconSize = useMemo(() => (isMediumUp ? 1.5 : 0.75), [isMediumUp]);

	const badgeCount = filters.colors.length;
	const hasBadges = badgeCount > 0;

	return (
		<>
			<div className={wrapperClass}>
				<div className={inputTextMockClass} onClick={handleOpenOnClick}>
					<Icon icon={"filter"} sizeRem={iconSize} color={iconColor} />
					{isMediumUp && (
						<div className={filterTextWrapperClass}>
							<P2Span className={colorShade100Class}>{calculatedValue}</P2Span>
						</div>
					)}

					{isMediumUp && hasBadges && (
						<div className={desktopLabelWrapperClass}>
							<Label label={`+${badgeCount}`} />
						</div>
					)}
				</div>
				{!isMediumUp && hasBadges && (
					<div className={mobileBadgeWrapperClass}>
						<BadgeV2 value={badgeCount} severity={"brandStrong"} />
					</div>
				)}
			</div>
			<ResponsiveBottomSheetOverlayPanel ref={overlayRef}>
				<FiltersModal onClose={handleOnClose} />
			</ResponsiveBottomSheetOverlayPanel>
		</>
	);
};
