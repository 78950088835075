import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { Widget } from "@typeform/embed-react";
import { useLazyLoadQuery } from "react-relay";
import { HeaderFooterScreenTemplate } from "@components/header-footer-screen-template";
import { shoppingCartSuccess_Query } from "@relay/shoppingCartSuccess_Query.graphql";
import { Path } from "@router/paths";
import { success100 } from "@themes/colors";
import { H1Span, P2Span } from "@themes/font-tags";
import { QUERY } from "./shopping-cart-success.graphql";
import { ShoppingCartSuccessScreenSkeleton } from "./shopping-cart-success.skeleton";
import {
	contentWrapperClass,
	iconBackgroundClass,
	textWrapperClass,
	widgetClass,
	wrapperClass,
} from "./shopping-cart-success.styles";

//TODO: add-translations
export const ShoppingCartSuccessScreenComponent = () => {
	const {
		Viewer: {
			Auth: { currentUser },
		},
	} = useLazyLoadQuery<shoppingCartSuccess_Query>(QUERY, {});

	const handleOnClick = () => {
		window.location.href = Path.dashboard.path;
	};

	const userEmail = currentUser?.user.email;
	return (
		<HeaderFooterScreenTemplate showUserCart={false}>
			<div className={wrapperClass}>
				<div className={contentWrapperClass}>
					<div className={iconBackgroundClass}>
						<Icon icon="shoppingBasketCheck1" color={success100} sizeRem={2.5} />
					</div>
					<div className={textWrapperClass}>
						<H1Span>Vielen Dank für Deine Bestellung</H1Span>
						<P2Span>
							Deine Bestellung ist bei uns eingegangen.&nbsp;
							{userEmail && (
								<>
									Du erhältst eine Bestätigung an folgende E-Mailadresse:{" "}
									{userEmail}
								</>
							)}
						</P2Span>
					</div>
				</div>
				<Widget id="iTpLqvPW" className={widgetClass} />
				<div className={contentWrapperClass}>
					<Button fillParent label="Zu deinen Lerninhalten" onClick={handleOnClick} />
				</div>
			</div>
		</HeaderFooterScreenTemplate>
	);
};

export const ShoppingCartSuccessScreen = withSuspense(
	ShoppingCartSuccessScreenComponent,
	ShoppingCartSuccessScreenSkeleton,
);
