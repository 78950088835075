import { Skeleton } from "@thekeytechnology/epic-ui";
import { useTheme } from "styled-components";
import { DividerList } from "../divider-list";

export const MaterialsTreeSkeleton = () => {
	const theme = useTheme();

	return (
		<DividerList hasDivider>
			<Skeleton
				width="100%"
				height="2.2rem"
				borderRadius={theme.borderRadius?.borderRadius10}
			/>
			<Skeleton
				width="100%"
				height="2.2rem"
				borderRadius={theme.borderRadius?.borderRadius10}
			/>
			<Skeleton
				width="100%"
				height="2.2rem"
				borderRadius={theme.borderRadius?.borderRadius10}
			/>
			<Skeleton
				width="100%"
				height="2.2rem"
				borderRadius={theme.borderRadius?.borderRadius10}
			/>
		</DividerList>
	);
};
