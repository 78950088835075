import { css, cva } from "@styled-system/css";

export const levelProgressWrapperClass = css({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	gap: "8",
	width: "full",
	borderRadius: "12",
	backgroundColor: "shade.0",
});

export const levelProgressBarClass = css({
	position: "relative",
	height: "10",
	width: "full",
	borderRadius: "20",
	backgroundColor: "primary.20",
});

export const levelProgressBarRunnerClass = css({
	position: "absolute",
	top: "0",
	left: "0",
	height: "full",
	width: "var(--progress)",
	borderRadius: "20",
	backgroundColor: "primary.80",
});

export const expandableBoxGridClass = cva({
	base: {
		display: "grid",
	},
	variants: {
		columnStyle: {
			"5": {
				gridTemplateColumns: "2",
				columnGap: "[36px]",
				rowGap: "[6px]",
				md: {
					gridTemplateColumns: "5",
				},
				xl: {
					gridTemplateColumns: "3",
				},
				"2xl": {
					gridTemplateColumns: "5",
				},
			},
			"3": {
				gridTemplateColumns: "1",
				gap: "16",
				md: {
					gridTemplateColumns: "3",
				},
			},
		},
	},
});
