/**
 * @generated SignedSource<<54f3af91daed5cb1441fdd8015f55578>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccountMemberKind = "Invitation" | "UserInAccount";
import { FragmentRefs } from "relay-runtime";
export type licensesSingleUserAssignmentCard_AccountMemberFragment$data = {
  readonly id: string;
  readonly isManager: boolean;
  readonly kind: AccountMemberKind;
  readonly name: string;
  readonly user?: {
    readonly groups: ReadonlyArray<{
      readonly id: string;
      readonly isBuiltIn: boolean;
      readonly name: string;
    }>;
    readonly id: string;
    readonly user: {
      readonly id: string;
    };
  };
  readonly " $fragmentType": "licensesSingleUserAssignmentCard_AccountMemberFragment";
};
export type licensesSingleUserAssignmentCard_AccountMemberFragment$key = {
  readonly " $data"?: licensesSingleUserAssignmentCard_AccountMemberFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licensesSingleUserAssignmentCard_AccountMemberFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "licensesSingleUserAssignmentCard_AccountMemberFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isManager",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserInAccount",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UserInAccountGroup",
              "kind": "LinkedField",
              "name": "groups",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isBuiltIn",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "UserInAccountWrapper",
      "abstractKey": null
    }
  ],
  "type": "AccountMember",
  "abstractKey": "__isAccountMember"
};
})();

(node as any).hash = "577c377a33e3aeb27bf6ced517730122";

export default node;
