import { type DefaultTheme } from "styled-components";
import { ReactComponent as LogoSVG } from "@assets/logo-new.svg";
import { ReactComponent as LogoSquareSVG } from "@assets/Tklogo_square.svg";

import { BreakpointInPx } from "./breakpoint";
import {
	brandMain100,
	brandMain20,
	brandMain40,
	brandMain80,
	brandStrong100,
	brandStrong120,
	brandStrong20,
	brandStrong40,
	brandStrong80,
	error0,
	error100,
	error20,
	info0,
	info100,
	info20,
	onBrandMain,
	onBrandStrong,
	shade0,
	shade10,
	shade100,
	shade20,
	shade40,
	shade5,
	shade60,
	shade80,
	success0,
	success100,
	success20,
	warning0,
	warning100,
	warning20,
} from "./colors";
import {
	spacing0,
	spacing10,
	spacing12,
	spacing16,
	spacing2,
	spacing20,
	spacing24,
	spacing32,
	spacing4,
	spacing40,
	spacing64,
	spacing8,
	spacing80,
	spacing96,
} from "./spacing";

export const TKA_THEME: DefaultTheme = {
	colors: {
		primary20: brandMain20,
		primary40: brandMain40,
		primary80: brandMain80,
		primary100: brandMain100,

		secondary20: brandStrong20,
		secondary40: brandStrong40,
		secondary80: brandStrong80,
		secondary100: brandStrong100,
		secondary120: brandStrong120,

		onPrimary: onBrandMain,
		onSecondary: onBrandStrong,

		shade0: shade0,
		shade5: shade5,
		shade10: shade10,
		shade20: shade20,
		shade40: shade40,
		shade60: shade60,
		shade80: shade80,
		shade100: shade100,

		success0: success0,
		success20: success20,
		success100: success100,

		info0: info0,
		info20: info20,
		info100: info100,

		warning0: warning0,
		warning20: warning20,
		warning100: warning100,

		error0: error0,
		error20: error20,
		error100: error100,

		focus: brandMain100,
		fontPrimary: brandMain100,
		fontDisabled: shade40,
	},
	spacing: {
		spacing0: spacing0,
		spacing2: spacing2,
		spacing4: spacing4,
		spacing8: spacing8,
		spacing10: spacing10,
		spacing12: spacing12,
		spacing16: spacing16,
		spacing20: spacing20,
		spacing24: spacing24,
		spacing32: spacing32,
		spacing40: spacing40,
		spacing64: spacing64,
		spacing80: spacing80,
		spacing96: spacing96,
	},
	breakpoints: {
		medium: BreakpointInPx.medium,
		large: BreakpointInPx.large,
		xlarge: BreakpointInPx.xlarge,
		xxlarge: BreakpointInPx.xxlarge,
	},

	LogoComponent: () => <LogoSVG height={18} />,
	LogoSquareComponent: () => <LogoSquareSVG width={38} />,

	componentStyles: {
		card: {
			boxShadow:
				"0px 1px 1px 0px rgba(16, 24, 32, 0.04), 0px 2px 5px 0px rgba(16, 24, 32, 0.02), 0px 4px 0px 0px rgba(41, 19, 113, 0.11)",
		},
		contentCard: {
			activeShadow:
				"0px 1px 1px 0px rgba(16, 24, 32, 0.04), 0px 2px 5px 0px rgba(16, 24, 32, 0.02), 0px 4px 0px 0px rgba(41, 19, 113, 0.11)",
		},
		navbar: {
			backgroundColor: shade100,
		},
		offerCard: {
			label: {
				severity: "default",
			},
			diploma: {
				color: shade80,
			},
		},
	},
};
