import i18n, { BackendModule } from "i18next";
import { initReactI18next } from "react-i18next";
import { ContentfulI18nBackend } from "./ContentfulI18nBackend";
import LEARN_STREAK from "./LEARN_STREAK";

const backend: BackendModule = new ContentfulI18nBackend();
i18n
	// want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
	.use(backend)
	// detect user language
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: "de-DE",
		debug: import.meta.env.REACT_APP_APP_ENVIRONMENT === "dev",
		returnObjects: true,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});

i18n.loadNamespaces([
	"registerForm",
	"loginForm",
	"forgotPasswordForm",
	"resetPasswordForm",
	"activateScreen",
	"dashboardScreen",
	"navbar",
	"Errors",
	"labels",
]);
i18n.addResourceBundle("de-DE", "learnStreak", LEARN_STREAK.de);

i18n.on("languageChanged", (lng) => (document.documentElement.lang = lng));

export const i18nInstance = i18n;
