import { graphql } from "react-relay";

export const LICENSE_GROUPING_FRAGMENT = graphql`
	fragment licenseContextMenu_LicenseFragment on LicenseGrouping {
		id
		... on LearnableLicenseGrouping {
			learnable {
				... on PublishedCourseLearnable {
					root {
						structureDefinition {
							... on LearnOpportunityRootStructureDefinition {
								extension {
									... on PublishedRootExtensionImpl {
										product {
											id
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const ADD_ITEM_TO_USER_CART_MUTATION = graphql`
	mutation licenseContextMenu_addItemToUserCartMutation($input: AddItemToUserCartInput!) {
		Viewer {
			Billing {
				addItemToUserCart(input: $input) {
					userCart {
						...userCart_UserCartFragment
					}
				}
			}
		}
	}
`;
