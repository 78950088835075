import { Skeleton } from "@thekeytechnology/epic-ui";
import { ReactComponent as KeyMedalSvg } from "@assets/key-medal-points.svg";
import { wrapperClass } from "./gamification-points.styles";

export const GamificationPointsSkeleton = () => {
	return (
		<div className={wrapperClass}>
			<Skeleton width="2.5rem" height="1.25rem" />
			<KeyMedalSvg />
		</div>
	);
};
