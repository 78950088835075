import { css } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	alignItems: "center",
	gap: "4",
	background: "onPrimary",
	paddingX: "12",
	paddingY: "8",
	borderRadius: "20",
});
