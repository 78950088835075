/**
 * @generated SignedSource<<52ce0fea4cfab7613480c992cf234287>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licenseDetails_RefetchQuery$variables = {
  licenseGroupingId: string;
};
export type licenseDetails_RefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"licenseDetails_LicenseGroupingFragment">;
};
export type licenseDetails_RefetchQuery = {
  response: licenseDetails_RefetchQuery$data;
  variables: licenseDetails_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "licenseGroupingId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "licenseDetails_RefetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "licenseGroupingId",
            "variableName": "licenseGroupingId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "licenseDetails_LicenseGroupingFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "licenseDetails_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseGroupingQueries",
        "kind": "LinkedField",
        "name": "LicenseGrouping",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "licenseGroupingId"
              }
            ],
            "concreteType": null,
            "kind": "LinkedField",
            "name": "LicenseGrouping",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "usedLicenseAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalLicenseAmount",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "assignmentRules",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxLicenseAmount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "licensesUsedByUser",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "licenseGroupingId",
                        "storageKey": null
                      }
                    ],
                    "type": "SingleUserAssignmentRule",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "learnable",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LearnOpportunityV2",
                            "kind": "LinkedField",
                            "name": "root",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "structureDefinition",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "title",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "PublishedCourseLearnable",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "LearnableLicenseGrouping",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "39085519b23b087ba10a3228086d292e",
    "id": null,
    "metadata": {},
    "name": "licenseDetails_RefetchQuery",
    "operationKind": "query",
    "text": "query licenseDetails_RefetchQuery(\n  $licenseGroupingId: ID!\n) {\n  ...licenseDetails_LicenseGroupingFragment_2p8GkE\n}\n\nfragment licenseDetails_LicenseGroupingFragment_2p8GkE on Query {\n  LicenseGrouping {\n    LicenseGrouping(id: $licenseGroupingId) {\n      __typename\n      id\n      usedLicenseAmount\n      totalLicenseAmount\n      kind\n      ... on LearnableLicenseGrouping {\n        learnable {\n          __typename\n          kind\n          ... on PublishedCourseLearnable {\n            id\n            root {\n              id\n              description\n              structureDefinition {\n                __typename\n                title\n              }\n            }\n          }\n          id\n        }\n      }\n      assignmentRules {\n        __typename\n        ... on SingleUserAssignmentRule {\n          maxLicenseAmount\n        }\n        ...userRulesTab_QueryFragment\n        ...licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment\n        id\n      }\n    }\n  }\n}\n\nfragment licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment on SingleUserAssignmentRule {\n  __typename\n  id\n  userId\n  licenseGroupingId\n  licensesUsedByUser\n  maxLicenseAmount\n}\n\nfragment userRulesTab_QueryFragment on SingleUserAssignmentRule {\n  id\n  userId\n  licensesUsedByUser\n  maxLicenseAmount\n}\n"
  }
};
})();

(node as any).hash = "87d8061086f7b2a177bf2f4e60363381";

export default node;
