import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { Button, EpicIcons, epicTransparent, Icon } from "@thekeytechnology/epic-ui";
import { Carousel, CarouselPageChangeEvent } from "primereact/carousel";
import { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { useTheme } from "styled-components";
import { MessageModalIndicators } from "@components/message-modal-indicators/message-modal-indicators.component";
import { MessageModalPage } from "@components/message-modal-page";
import { Modal } from "@components/modal";
import { messageModal_AcknowledgeMessageMutation } from "@relay/messageModal_AcknowledgeMessageMutation.graphql";
import { messageModal_MessageFragment$key } from "@relay/messageModal_MessageFragment.graphql";
import { ACKNOWLEDGE_MESSAGE_MUTATION, MESSAGE_FRAGMENT } from "./message-modal.graphql";
import {
	buttonClass,
	carouselContainerClass,
	carouselContentClass,
	carouselItemClass,
	closeIconWrapperClass,
	mobileButtonBackgroundClass,
	modalContentClass,
	modalInnerWrapperClass,
	modalOuterWrapperClass,
	pagesContainerClass,
} from "./message-modal.styles";
import { MessageModalProps } from "./message-modal.types";

export const MessageModal = ({
	isVisible,
	messages,
	messageFragmentRef,
	onHide,
}: MessageModalProps) => {
	const { isMediumUp, isLargeUp } = useWindowSize();

	const theme = useTheme();

	const data = useFragment<messageModal_MessageFragment$key>(
		MESSAGE_FRAGMENT,
		messageFragmentRef ?? null,
	);

	const [acknowledgeMessage] = useMutation<messageModal_AcknowledgeMessageMutation>(
		ACKNOWLEDGE_MESSAGE_MUTATION,
	);

	const message = data?.messageDefinition ?? {
		pages: messages ?? [],
	};

	const handleOnAcknowledgeClick = async () => {
		if (!data?.id) return;
		acknowledgeMessage({
			variables: {
				input: {
					messageId: data?.id,
				},
			},
			onCompleted: () => onHide(),
		});
	};

	const pagesToRender = [...message.pages];

	const [currentPageIndex, setCurrentPageIndex] = useState(0);
	const pagesBefore = pagesToRender.slice(0, currentPageIndex);
	const pagesAfter = pagesToRender.slice(currentPageIndex + 1, pagesToRender.length);

	const onPageChange = (event: CarouselPageChangeEvent) => {
		setCurrentPageIndex(event.page);
	};

	const showPreviousPage = () => {
		if (currentPageIndex === 0) return;
		setCurrentPageIndex(currentPageIndex - 1);
	};

	const showNextPage = () => {
		if (currentPageIndex === pagesToRender.length - 1) return;
		setCurrentPageIndex(currentPageIndex + 1);
	};

	const showNextPageButton = pagesAfter.length > 0;
	const showPreviousPageButton = pagesBefore.length > 0;
	const controlButtonBorderRadius = isMediumUp
		? theme.borderRadius?.borderRadius10
		: theme.borderRadius?.borderRadiusFull;

	return (
		<Modal
			backgroundColor={epicTransparent}
			isVisible={isVisible}
			onHide={handleOnAcknowledgeClick}
			fullWidth
		>
			<div className={modalOuterWrapperClass}>
				{isLargeUp && (
					<div className={pagesContainerClass({ isLeft: true })}>
						{pagesBefore.map((value: any) => (
							<MessageModalPage
								key={value.id}
								pageFragmentRef={value}
								onButtonClick={handleOnAcknowledgeClick}
								isPreview
							/>
						))}
					</div>
				)}
				<div className={modalContentClass}>
					<div
						className={buttonClass({ side: "previous", show: showPreviousPageButton })}
					>
						<Button
							icon={EpicIcons.ARROW_LEFT}
							onClick={showPreviousPage}
							backgroundColor={theme.colors.primary100}
							borderRadius={controlButtonBorderRadius}
							stretch
						/>
					</div>
					<div className={modalInnerWrapperClass}>
						<MessageModalIndicators
							amountOfItems={pagesToRender.length}
							currentPageIndex={currentPageIndex}
							onIndicatorClick={(index) => setCurrentPageIndex(index)}
						/>
						<button
							type="button"
							className={closeIconWrapperClass}
							onClick={handleOnAcknowledgeClick}
						>
							<Icon icon="close" sizeRem={1} />
						</button>
						<Carousel
							value={pagesToRender}
							page={currentPageIndex}
							itemTemplate={(value) => (
								<MessageModalPage
									key={value.id}
									messageFragmentRef={data?.messageDefinition}
									pageFragmentRef={value}
									onButtonClick={handleOnAcknowledgeClick}
								/>
							)}
							showIndicators={false}
							showNavigators={false}
							numVisible={1}
							numScroll={1}
							onPageChange={onPageChange}
							pt={{
								content: {
									className: carouselContentClass,
								},
								container: {
									className: carouselContainerClass,
								},
								item: {
									className: carouselItemClass,
								},
							}}
						/>
						{!isMediumUp && <div className={mobileButtonBackgroundClass} />}
					</div>
					<div className={buttonClass({ side: "next", show: showNextPageButton })}>
						<Button
							icon={EpicIcons.ARROW_RIGHT}
							onClick={showNextPage}
							stretch
							backgroundColor={theme.colors.primary100}
							borderRadius={controlButtonBorderRadius}
						/>
					</div>
				</div>
				{isLargeUp && (
					<div className={pagesContainerClass({ isLeft: false })}>
						{pagesAfter.map((value: any) => (
							<MessageModalPage
								key={value.id}
								pageFragmentRef={value}
								onButtonClick={handleOnAcknowledgeClick}
								isPreview
							/>
						))}
					</div>
				)}
			</div>
		</Modal>
	);
};
