import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { Icon } from "@thekeytechnology/epic-ui";
import { useLazyLoadQuery } from "react-relay";
import { ProfileExpandableBox } from "@components/profile-expandable-box";
import { certificatesBox_Query } from "@relay/certificatesBox_Query.graphql";
import { colorPrimary100Class } from "@themes/color-classes";
import { H2Span, P2Span } from "@themes/font-tags";
import { QUERY } from "./certificates-box.graphql";
import { CertificatesBoxSkeleton } from "./certificates-box.skeleton";
import {
	expandableBoxGridClass,
	noCertificatesClass,
	noCertificatesIconClass,
} from "./certificates-box.styles";
import { CertificateItem } from "../certificate-item";

const CertificatesBoxComponent = () => {
	const query = useLazyLoadQuery<certificatesBox_Query>(
		QUERY,
		{},
		{ fetchPolicy: "store-and-network" },
	);

	const finishedLearnables =
		query?.Learnable?.PublishedLearnables?.edges
			?.filter(
				(edge) =>
					edge?.node.root?.structureDefinition.viewerTreeState?.kind === "IsFinished" &&
					edge?.node.root?.structureDefinition.extension?.reducedData?.isIHK,
			)
			.map((edge) => edge?.node) ?? [];
	const hasFinishedLearnables = finishedLearnables.length > 0;

	return (
		<ProfileExpandableBox title="Zertifikate" defaultHeightRem={14.875}>
			{hasFinishedLearnables && (
				<div className={expandableBoxGridClass({ columnStyle: "3" })}>
					{finishedLearnables.map((learnable, index) => (
						<CertificateItem
							key={"c" + index}
							title={
								(learnable?.root?.structureDefinition.extension?.diploma !== ""
									? learnable?.root?.structureDefinition.extension?.diploma
									: learnable?.root?.structureDefinition.title) ?? ""
							}
						/>
					))}
				</div>
			)}
			{!hasFinishedLearnables && (
				<div className={noCertificatesClass}>
					<div className={noCertificatesIconClass}>
						<Icon icon="certifiedCertificate" sizeRem={2.5} />
					</div>
					<H2Span className={colorPrimary100Class}>
						Du hast noch keine Zertifikate erhalten
					</H2Span>
					<P2Span>
						Du kannst Zertifikate erhalten, indem du einen Kurs erst absolviert hast.
					</P2Span>
				</div>
			)}
		</ProfileExpandableBox>
	);
};

export const CertificatesBox = withSuspense(CertificatesBoxComponent, CertificatesBoxSkeleton);
