import { selectCurrentAccountId } from "@thekeytechnology/academies-lib-webapp/slices";
import { type FC, useState } from "react";
import { useSelector } from "react-redux";
import { useMutation, useRefetchableFragment } from "react-relay";
import { RadioButton } from "@components/radio-button";
import { useToast } from "@hooks/useToast";
import type { licenseSettings_EditLicenseModelMutation } from "@relay/licenseSettings_EditLicenseModelMutation.graphql";
import { Kind } from "@relay/licenseSettings_QueryFragment.graphql";
import { colorShade100Class, colorShade60Class } from "@themes/color-classes";
import { H3Span, P2Span } from "@themes/font-tags";
import { getLicenseModelKindForAccount } from "@utils/licenses-utils";
import { EDIT_LICENSE_MODEL_MUTATION, QUERY_FRAGMENT } from "./license-settings.graphql";
import {
	optionWrapperClass,
	optionsWrapperClass,
	textWrapperClass,
	wrapperClass,
} from "./license-settings.styles";
import { LicenseSettingsProps } from "./license-settings.types";

export const LicenseSettings: FC<LicenseSettingsProps> = ({ queryFragmentRef }) => {
	const { showSuccess } = useToast();

	const [editLicenseModel] = useMutation<licenseSettings_EditLicenseModelMutation>(
		EDIT_LICENSE_MODEL_MUTATION,
	);
	const [data, refetch] = useRefetchableFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);

	const currentAccountId: string | undefined = useSelector(selectCurrentAccountId);

	const accountData = data?.Viewer?.Auth?.currentUser?.accounts;
	const licenseModelKind =
		(accountData && getLicenseModelKindForAccount(accountData, currentAccountId)) ??
		"FreeForAll";
	const [selectedOption, setSelectedOption] = useState(licenseModelKind);

	const createOnChangeHandler = (kind: Kind) => () => {
		setSelectedOption(kind);
		editLicenseModel({
			variables: {
				input: {
					licenseModelKind: kind,
				},
			},
			onCompleted: () => {
				showSuccess({
					summary: "Lizenz-Einstellungen gespeichert",
				});
				refetch({}, { fetchPolicy: "store-and-network" });
			},
		});
	};

	return (
		<div className={wrapperClass} data-no-selection-menu>
			<H3Span>Wer darf die Lizenzen nutzen?</H3Span>
			<div className={optionsWrapperClass}>
				<div className={optionWrapperClass}>
					<div className={textWrapperClass}>
						<P2Span className={colorShade100Class}>Jeder im Konto</P2Span>
						<P2Span className={colorShade60Class}>
							Jeder darf die Lizenzen ohne Einschränkung nutzen. Die bestehenden
							Regeln werden ignoriert.
						</P2Span>
					</div>
					<RadioButton
						value={"FreeForAll"}
						checked={selectedOption === "FreeForAll"}
						onChange={createOnChangeHandler("FreeForAll")}
					/>
				</div>
				<div className={optionWrapperClass}>
					<div className={textWrapperClass}>
						<P2Span className={colorShade100Class}>Nur auf Zuweisung</P2Span>
						<P2Span className={colorShade60Class}>
							Innerhalb der Lizenzen werden die Regeln für die Nutzung festgelegt.
						</P2Span>
					</div>
					<RadioButton
						value={"Assigment"}
						checked={selectedOption === "Assigment"}
						onChange={createOnChangeHandler("Assigment")}
					/>
				</div>
			</div>
		</div>
	);
};
