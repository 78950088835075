import { AuthTemplate } from "@components/auth-template";
import { AuthTemplateStep } from "@components/auth-template-step";
import { MultiStepAuthContainer } from "@containers/multi-step-auth-container";
import { ActivateErrorStep } from "./parts/activate-error-step";

export const ActivateErrorScreen = () => {
	return (
		<AuthTemplate hideImage>
			<MultiStepAuthContainer StepWrapper={AuthTemplateStep}>
				{() => <ActivateErrorStep />}
			</MultiStepAuthContainer>
		</AuthTemplate>
	);
};
