import { UserHeader } from "@components/user-header";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout";
import { AccountContextMenu } from "./parts/account-context-menu";
import { CertificatesBox } from "./parts/certificates-box";
import { LevelsBox } from "./parts/levels-box";
import {
	boxesClass,
	buttonsWrapperClass,
	seperatorClass,
	userHeaderClass,
	wrapperClass,
} from "./profile.styles";

export const ProfileScreen = () => {
	return (
		<ScreenWithProfileMenuLayout>
			<div className={wrapperClass}>
				<UserHeader className={userHeaderClass} />
				<div className={boxesClass}>
					<LevelsBox />
					<CertificatesBox />
				</div>
				<div className={buttonsWrapperClass}>
					<hr className={seperatorClass} />
					<div>
						<AccountContextMenu />
					</div>
				</div>
			</div>
		</ScreenWithProfileMenuLayout>
	);
};
