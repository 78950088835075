import { graphql } from "react-relay";

export const QUERY = graphql`
	query levelsBox_Query {
		Gamification {
			LevelDefinitions {
				edges {
					node {
						id
						requiredPoints
						...levelItem_LevelDefinitionFragment
					}
				}
			}
		}
		Viewer {
			Auth {
				currentUser {
					user {
						extensions {
							kind
							... on AcademiesUserExtension {
								level
								progressInPercent
								sumGamificationPoints
							}
						}
					}
				}
			}
		}
		Learnable {
			PublishedLearnables(tagIds: [], kinds: []) {
				edges {
					node {
						... on PublishedCourseLearnable {
							id
							root {
								structureDefinition {
									... on LearnOpportunityRootStructureDefinition {
										extension {
											... on PublishedRootExtensionImpl {
												diploma
												reducedData {
													isIHK
												}
											}
										}
										viewerTreeState {
											kind
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
