/**
 * @generated SignedSource<<3d622c1522b84fe0253229409df1f59d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountSwitchMenu_AuthViewerSchemaFragment$data = {
  readonly currentUser: {
    readonly accounts: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly user: {
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"userAvatar_UserFragment">;
    };
  } | null | undefined;
  readonly " $fragmentType": "accountSwitchMenu_AuthViewerSchemaFragment";
};
export type accountSwitchMenu_AuthViewerSchemaFragment$key = {
  readonly " $data"?: accountSwitchMenu_AuthViewerSchemaFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"accountSwitchMenu_AuthViewerSchemaFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "accountSwitchMenu_AuthViewerSchemaFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUser",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "userAvatar_UserFragment"
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "accounts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AuthViewerSchema",
  "abstractKey": null
};
})();

(node as any).hash = "9018cd5ef73beaae39774041debb089f";

export default node;
