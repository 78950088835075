import { createContext } from "react";
import { LearnableKind } from "@relay/gridView_Query.graphql";

export type IOffersScreenContext = {
	activeIndex: number;
	filtersAreVisible: boolean;
	searchIsVisibleOnMobile: boolean;
	onFilterChangeListeners: ((filters: IOffersScreenContext["filters"]) => void)[];
	filters: {
		first?: number;
		last?: number;
		after?: string;
		before?: string;
		tagIds: string[];
		kinds: LearnableKind[];
		text?: string;
	};
	helpers: {
		setText: (text?: string) => void;
		setTagIds: (tagIds: string[]) => void;
		setKinds: (kinds: LearnableKind[]) => void;
		setActiveIndex: (num: number) => void;
		toggleKind: (kind: LearnableKind) => void;
		toggleFiltersAreVisible: () => void;
		toggleSearchIsVisibleOnMobile: () => void;
		addOnFilterChangeListener: (
			listener: (filters: IOffersScreenContext["filters"]) => void,
		) => void;
	};
};
export const OffersScreenContext = createContext<IOffersScreenContext>({
	activeIndex: 0,
	filtersAreVisible: false,
	searchIsVisibleOnMobile: false,
	onFilterChangeListeners: [],
	filters: {
		tagIds: [],
		kinds: [],
	},
	helpers: {
		setText: (_) => {},
		setTagIds: (_) => {},
		setKinds: (_) => {},
		setActiveIndex: () => {},
		toggleKind: () => {},
		toggleFiltersAreVisible: () => {},
		toggleSearchIsVisibleOnMobile: () => {},
		addOnFilterChangeListener: () => {},
	},
});
