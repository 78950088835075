import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { FC, useContext, useEffect } from "react";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { ReactComponent as NoteIcon } from "@assets/notes-icon.svg";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { NoteCard } from "@components/note-card";
import { notesOverview_Query } from "@relay/notesOverview_Query.graphql";
import { notesOverview_QueryFragment$key } from "@relay/notesOverview_QueryFragment.graphql";
import { notesOverview_RefetchQuery } from "@relay/notesOverview_RefetchQuery.graphql";
import { NotesScreenContext } from "@screens/notes/notes.context";
import { QUERY, QUERY_FRAGMENT } from "./notes-overview.graphql";
import { NotesOverviewSkeleton } from "./notes-overview.skeleton";
import { grid, nextButtonWrapperClass } from "./notes-overview.styles";
import { NotesOverviewProps } from "./notes-overview.types";
import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";

export const NotesOverviewComponent: FC<NotesOverviewProps> = () => {
	const query = useLazyLoadQuery<notesOverview_Query>(
		QUERY,
		{},
		{
			fetchPolicy: "store-and-network",
		},
	);
	const { data, refetch, hasNext, loadNext } = usePaginationFragment<
		notesOverview_RefetchQuery,
		notesOverview_QueryFragment$key
	>(QUERY_FRAGMENT, query);
	const {
		filters,
		helpers: { registerRefetch, unregisterRefetch },
	} = useContext(NotesScreenContext);

	const handleLoadNext = () => {
		loadNext(20);
	};

	const handleDeleteNote = () => {
		refetch(
			{
				filterByText: filters.text,
				includeNoteColors: filters.colors,
				noteColorToSortBy: filters.colorToSortBy,
			},
			{
				fetchPolicy: "store-and-network",
			},
		);
	};

	useEffect(() => {
		const refetchId = registerRefetch(() => {
			refetch(
				{
					...filters,
					filterByText: filters.text,
					includeNoteColors: filters.colors,
					noteColorToSortBy: filters.colorToSortBy,
				},
				{
					fetchPolicy: "store-and-network",
				},
			);
		});

		return () => {
			unregisterRefetch(refetchId);
		};
	}, []);

	useEffect(() => {
		refetch(
			{
				...filters,
				filterByText: filters.text,
				includeNoteColors: filters.colors,
				noteColorToSortBy: filters.colorToSortBy,
			},
			{
				fetchPolicy: "store-and-network",
			},
		);
	}, [filters]);

	const edges = data?.Note?.Note.edges;
	const notes = edges?.filter((edge) => edge?.node.reminder === null) || [];

	return (
		<>
			<EmptyPlaceholder
				isVisible={notes?.length === 0}
				icon={<NoteIcon width="2.5rem" height="2.5rem" />}
				iconBackgroundColor="warning20"
				title="Hier findest du deine Notizen"
				subtitle="Du kannst jederzeit Notizen erstellen und einsehen. Indem du einen Textabschnitt markierst, hast du auch die Möglichkeit, deine Notizen direkt zu erstellen."
			>
				<div className={grid}>
					{notes.map((note) => (
						<NoteCard
							key={note?.node.id}
							onDeleteNote={handleDeleteNote}
							noteFragmentRef={note?.node}
						/>
					))}
				</div>
				{hasNext && (
					<div className={nextButtonWrapperClass}>
						<Button
							type="button"
							onClick={handleLoadNext}
							label="Weitere Notizen laden"
						/>
					</div>
				)}
			</EmptyPlaceholder>
		</>
	);
};

export const NotesOverview = withSuspense(NotesOverviewComponent, NotesOverviewSkeleton);
