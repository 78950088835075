import { graphql } from "react-relay";

export const ELEARNING_CONTENT_SUBMISSION_DEFINITION = graphql`
	fragment skipQuestionButton_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					element {
						id
					}
				}
			}
		}
	}
`;

export const CAN_SHOW_ELEMENT_ANSWER_MUTATION = graphql`
	mutation skipQuestionButton_CanShowElementAnswerMutation($input: CanShowElementAnswerInput!) {
		ELearning {
			canShowElementAnswer(input: $input) {
				clientMutationId
				can
			}
		}
	}
`;

export const SHOW_ELEMENT_ANSWER_MUTATION = graphql`
	mutation skipQuestionButton_ShowElementAnswerMutation($input: ShowElementAnswerElementInput!) {
		ELearning {
			showElementAnswerElement(input: $input) {
				contentSubmission {
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;

export const GO_TO_NEXT_ELEMENT_MUTATION = graphql`
	mutation skipQuestionButton_GoToNextElementMutation($input: GoToNextElementInput!) {
		ELearning {
			goToNextElement(input: $input) {
				clientMutationId
				contentSubmission {
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;
