import { EpicIcons, epicPrimary100, Icon } from "@thekeytechnology/epic-ui";
import { useFragment } from "react-relay";
import { match } from "ts-pattern";
import IHKLogoImage from "@assets/ihk-logo.png";
import { CircularProgressbar } from "@components/circular-progressbar";
import { colorShade80Class } from "@themes/color-classes";
import { H3Span, P3Span } from "@themes/font-tags";
import { OfferCategory, TITLE_CUTOFF_LENGTH } from "./offer-card.consts";
import { LEARN_OPPORTUNITY_V2_FRAGMENT } from "./offer-card.graphql";
import {
	descriptionClass,
	dividerClass,
	footerClass,
	footerStatusTextClass,
	headerClass,
	headerContentClass,
	imageClass,
	imageDiplomaClass,
	imageDiplomaWrapperClass,
	imageOverlayClass,
	imageWrapperClass,
	progressbarWrapperClass,
	progressOverlayWrapperClass,
	progressWrapperClass,
	tagTitleClass,
	tagWrapperClass,
	textWrapperClass,
	titleClass,
	wrapperClass,
} from "./offer-card.styles";
import { OfferCardProps } from "./offer-card.types";

export const OfferCard = ({
	showDescription = false,
	onClick,
	publishedLearnOpportunityV2FragmentRef,
}: OfferCardProps) => {
	const learnOpportunityV2 = useFragment(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		publishedLearnOpportunityV2FragmentRef ?? null,
	);

	if (!learnOpportunityV2) return null;

	const structureDefinition = learnOpportunityV2?.structureDefinition;

	const imageUrl = learnOpportunityV2?.image?.url;
	const title = learnOpportunityV2?.structureDefinition.title ?? "";
	const shortDescription = learnOpportunityV2?.shortDescription;
	const progress = structureDefinition?.progressPercentage;
	const tags = structureDefinition?.extension?.tags ?? [];
	const tag = tags.find((t) => t.data.isTopic);
	const category: OfferCategory = (tag?.data.name as OfferCategory) ?? OfferCategory.Management;

	const treeStateKind = structureDefinition?.viewerTreeState?.kind;
	const isStarted = treeStateKind === "IsStarted";
	const isFinished = treeStateKind === "IsFinished";

	const isInDemo = structureDefinition?.extension?.unlockInfo?.kind === "Demo";
	const isLocked =
		structureDefinition?.viewerTreeState?.headContentId ===
			structureDefinition?.extension?.unlockInfo?.firstContentId && isInDemo;

	const handleOnClick = () => {
		learnOpportunityV2?.id && onClick?.(learnOpportunityV2.id);
	};

	const statusText = match({
		isLocked,
		isStarted,
		isFinished,
	})
		.with({ isFinished: true }, () => "Abgeschlossen")
		.with({ isStarted: false, isLocked: false }, () => "Kostenlos testen")
		.otherwise(() => "Fortsetzen");

	return (
		<button type="button" onClick={handleOnClick} className={wrapperClass}>
			<div className={headerClass}>
				<div className={imageWrapperClass}>
					{imageUrl && (
						<>
							<img src={imageUrl} alt="" className={imageClass} />
							<div
								className={imageOverlayClass({
									category,
								})}
							/>
							{structureDefinition?.extension?.reducedData?.isIHK && (
								<div className={imageDiplomaWrapperClass}>
									<img src={IHKLogoImage} className={imageDiplomaClass} />
								</div>
							)}
						</>
					)}
				</div>
				<div className={headerContentClass}>
					{tag && (
						<div
							className={tagWrapperClass({
								category,
							})}
						>
							<span
								className={tagTitleClass({
									category,
								})}
							>
								{tag.data.name}
							</span>
						</div>
					)}
					<div className={textWrapperClass}>
						<H3Span className={titleClass}>
							{title.substring(0, TITLE_CUTOFF_LENGTH)}
							{title.length > TITLE_CUTOFF_LENGTH ? "..." : ""}
						</H3Span>
						{showDescription && shortDescription && (
							<P3Span className={descriptionClass}>{shortDescription}</P3Span>
						)}
					</div>
				</div>
			</div>
			<hr className={dividerClass} />
			<div className={footerClass}>
				<div>
					<span className={footerStatusTextClass}>{statusText}</span>
				</div>
				<div className={progressWrapperClass}>
					{isStarted && <P3Span className={colorShade80Class}>{progress}%</P3Span>}
					{isStarted && isLocked && <P3Span>|</P3Span>}
					{isLocked && <P3Span className={colorShade80Class}>Lizenz benötigt</P3Span>}
					{progress !== undefined && progress !== null && (
						<div className={progressbarWrapperClass}>
							{isLocked && (
								<div className={progressOverlayWrapperClass}>
									<Icon
										icon={EpicIcons.LOCK}
										sizeRem={0.625}
										color={epicPrimary100}
									/>
								</div>
							)}
							<CircularProgressbar
								value={progress}
								strokeWidth={20}
								width="1rem"
								height="1rem"
							/>
						</div>
					)}
				</div>
			</div>
		</button>
	);
};
