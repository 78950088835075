import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { LabelSeverity } from "@thekeytechnology/academies-lib-webapp/components/label/label.types";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { readInlineData, useFragment } from "react-relay";
import { tags_AcademiesRooTagsConnectionFragment$key } from "@relay/tags_AcademiesRooTagsConnectionFragment.graphql";
import {
	tags_AcademiesRootTagFragment$data,
	tags_AcademiesRootTagFragment$key,
} from "@relay/tags_AcademiesRootTagFragment.graphql";
import { ACADEMIES_ROOT_TAG_FRAGMENT, QUERY_FRAGMENT } from "./tags.graphql";
import { labelWrapperClass, toggleExtendCollapseClass, wrapperClass } from "./tags.styles";
import { TagsProps } from "./tags.types";
import { OffersScreenContext } from "../../offers.context";
import { ToggleExtendCollapse } from "../../parts/tags/parts/toggle-extend-collapse";

export const Tags = ({ queryFragmentRef }: TagsProps) => {
	const {
		filters,
		helpers: { setTagIds },
	} = useContext(OffersScreenContext);
	const containerRef = useRef<HTMLDivElement>(null);
	const [_, setHasOverflow] = useState(false);
	const [isCollapsed, setIsCollapsed] = useState<boolean | undefined>(undefined);
	const { isMediumUp, windowWidth } = useWindowSize();

	useEffect(() => {
		const container = containerRef.current;
		if (isMediumUp) return setIsCollapsed(undefined);

		if (container) {
			const hasOverflowX = container?.scrollWidth > container?.clientWidth;

			setHasOverflow(hasOverflowX);
		}
	}, [windowWidth]);

	const fragment = useFragment<tags_AcademiesRooTagsConnectionFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);
	const tags = fragment.AcademiesTag.AcademiesTags.edges?.map((e) =>
		readInlineData<tags_AcademiesRootTagFragment$key>(ACADEMIES_ROOT_TAG_FRAGMENT, e!.node),
	);
	const calculateTagSeverity = useCallback(
		(academiesTagData: tags_AcademiesRootTagFragment$data): LabelSeverity => {
			return filters.tagIds.includes(academiesTagData.id) ? "brandStrong" : "default";
		},
		[tags, filters.tagIds],
	);
	const calculateTagIsClosable = useCallback(
		(academiesTagData: tags_AcademiesRootTagFragment$data) => {
			return filters.tagIds.includes(academiesTagData.id);
		},
		[tags, filters.tagIds],
	);

	const buildOnClick = useCallback(
		(academiesTagData: tags_AcademiesRootTagFragment$data) => {
			const tagIsActive = filters.tagIds.includes(academiesTagData.id);
			if (tagIsActive)
				return () => {
					setTagIds([...filters.tagIds].filter((tagId) => academiesTagData.id !== tagId));
				};
			return () => {
				setTagIds([...filters.tagIds, academiesTagData.id]);
			};
		},
		[tags, filters.tagIds],
	);
	const handleToggleOnClick = () => {
		setIsCollapsed((isCollapsed) => !isCollapsed);
	};

	return (
		<div ref={containerRef} className={wrapperClass({ isCollapsed })}>
			{tags?.map((t) => (
				<div key={t.id} className={labelWrapperClass}>
					<Label
						label={t.data.name}
						size={"big"}
						severity={calculateTagSeverity(t)}
						closable={calculateTagIsClosable(t)}
						onClick={buildOnClick(t)}
						onCloseClick={buildOnClick(t)}
					/>
				</div>
			))}
			{!isMediumUp && (
				<div className={toggleExtendCollapseClass}>
					<ToggleExtendCollapse
						isCollapsed={isCollapsed}
						onToggle={handleToggleOnClick}
					/>
				</div>
			)}
		</div>
	);
};
