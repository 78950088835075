import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const headerWrapperClass = flex({
	position: "relative",
	justifyContent: "center",
	alignItems: "center",
	minHeight: "[4rem]",
	minWidth: "[19rem]",
});

export const leftItemClass = css({
	position: "absolute",
	left: "16",
	my: "auto",
});

export const rightItemClass = flex({
	position: "absolute",
	right: "16",
	my: "auto",
	gap: "8",
});
