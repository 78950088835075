import { css } from "@styled-system/css";
import { flex, stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	gap: "0",
	justifyContent: "space-between",
	md: {
		flexDirection: "row",
	},
});

export const userWrapperClass = stack({
	gap: "12",
	md: {
		flexDirection: "row",
	},
});

export const userDataWrapperClass = flex({
	alignItems: "center",
	gap: "12",
});

export const iconWrapperClass = css({
	display: "none",
	justifyContent: "center",
	alignItems: "center",
	width: "40",
	height: "40",
	backgroundColor: "shade.10",
	borderRadius: "12",
	md: {
		display: "flex",
	},
});

export const nameWrapperClass = stack({
	gap: "0",
	alignItems: "start",
	justifyContent: "space-between",
	height: "full",
});

export const labelsWrapperClass = flex({
	gap: "4",
	alignItems: "end",
	minHeight: "full",
	md: {
		marginLeft: "4",
	},
});

export const allocationWrapperClass = flex({
	alignItems: "center",
	justifyContent: "space-between",
	gap: "8",
	borderTop: "1px solid",
	borderColor: "shade.10",
	marginTop: "12",
	paddingTop: "12",
	md: {
		justifyContent: "end",
		borderTop: "none",
		marginTop: "0",
		paddingTop: "0",
	},
});

export const allocationButtonWrapperClass = flex({
	alignItems: "center",
	gap: "16",
});

export const allocationButtonClass = flex({
	alignItems: "center",
	justifyContent: "center",
	width: "24",
	height: "24",
	backgroundColor: "shade.5",
	borderRadius: "full",
	_disabled: {
		cursor: "not-allowed",
		opacity: "0.5",
	},
});
