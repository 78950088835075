import { ReduxStore } from "@thekeytechnology/academies-lib-webapp";
import {
	logout,
	refreshLogin,
	selectJwtClaimData,
	type JwtTokenData,
} from "@thekeytechnology/academies-lib-webapp/slices";
import { authMiddleware } from "react-relay-network-modern";

export const isJwtTokenData = (response: unknown): response is JwtTokenData => {
	return (
		typeof response === "object" &&
		response !== null &&
		"accessToken" in response &&
		"refreshToken" in response
	);
};

export const refreshJWTToken = () => {
	const state = ReduxStore.getState();
	const accountId = selectJwtClaimData(state)?.accountId;
	return fetch(`${import.meta.env.REACT_APP_API_BASE}/api/refresh-token`, {
		method: "POST",
		headers: new Headers({ "content-type": "application/json" }),
		body: JSON.stringify({
			refreshToken: state.auth.loginData?.refreshToken,
			accountId: accountId,
		}),
	})
		.then((res) => res.json())
		.then((json) => {
			if (!json || !isJwtTokenData(json)) {
				Promise.reject(new Error("Invalid response"));
				return Promise.reject(new Error("Refresh token failed"));
			}

			const token = json.accessToken;
			ReduxStore.dispatch(refreshLogin({ loginData: json }));
			return token;
		})
		.catch(() => {
			ReduxStore.dispatch(logout());
			return Promise.reject(new Error("Refresh token failed"));
		});
};

const getToken = () => {
	const storeToken = ReduxStore.getState().auth.loginData?.accessToken;
	if (storeToken) {
		return storeToken;
	}

	const searchParms = new URLSearchParams(window.location.search);
	if (searchParms.has("token")) {
		return searchParms.get("token") ?? "";
	}

	return "";
};

export const JwtMiddleware = authMiddleware({
	allowEmptyToken: true,
	token: getToken,
	tokenRefreshPromise: refreshJWTToken,
});
