/**
 * @generated SignedSource<<3077de2047504d5e7d89e5e1a030387a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ElementStateKind = "Input" | "InputAndIsCorrect" | "ShowAnswer" | "Untouched" | "UntouchedAndPreviouslyIncorrect";
export type ElementTypeV2 = "clozeText" | "enhancedText" | "file" | "markMistakes" | "matrix" | "multipleChoice" | "order" | "persolog" | "persologEvaluation" | "persologQuestion" | "podcast" | "podcastWithTimestamp" | "reminder" | "text" | "textWithPages" | "video";
export type Persolog_ClassType = "D" | "G" | "I" | "S";
import { FragmentRefs } from "relay-runtime";
export type persologEvaluationElement_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly currentElementState?: {
      readonly element: {
        readonly classes?: ReadonlyArray<{
          readonly classType: Persolog_ClassType;
          readonly image: {
            readonly id: string;
            readonly name: string;
            readonly url: string | null | undefined;
          } | null | undefined;
          readonly text: string;
          readonly title: string;
        }>;
        readonly elementType: ElementTypeV2;
        readonly evaluation?: {
          readonly classes: ReadonlyArray<{
            readonly classType: Persolog_ClassType;
            readonly percentage: number;
          }>;
        };
        readonly id: string;
        readonly title: string;
        readonly " $fragmentSpreads": FragmentRefs<"persologEvaluationClassModal_PersologEvaluationLearnElementFragment">;
      };
      readonly kind: ElementStateKind;
    } | null | undefined;
  };
  readonly id: string;
  readonly " $fragmentType": "persologEvaluationElement_ContentSubmissionFragment";
};
export type persologEvaluationElement_ContentSubmissionFragment$key = {
  readonly " $data"?: persologEvaluationElement_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"persologEvaluationElement_ContentSubmissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "classType",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "persologEvaluationElement_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "currentElementState",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "element",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "elementType",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Persolog_Class",
                          "kind": "LinkedField",
                          "name": "classes",
                          "plural": true,
                          "selections": [
                            (v2/*: any*/),
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "text",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "File",
                              "kind": "LinkedField",
                              "name": "image",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "name",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "url",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Persolog_Evaluation",
                          "kind": "LinkedField",
                          "name": "evaluation",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Persolog_EvaluationClass",
                              "kind": "LinkedField",
                              "name": "classes",
                              "plural": true,
                              "selections": [
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "percentage",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "persologEvaluationClassModal_PersologEvaluationLearnElementFragment"
                        }
                      ],
                      "type": "Persolog_PersologEvaluationLearnElement",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ActiveELearningContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "f2d298a9e72a0f6f583f3d70535d0ac8";

export default node;
