import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useState } from "react";
import { DividerList } from "@components/divider-list";
import { H3Span } from "@themes/font-tags";
import { childrenWrapperClass, rowWrapperClass } from "./profile-menu-row.styles";
import { ProfileMenuRowProps } from "./profile-menu-row.types";

export const ProfileMenuRow = ({
	icon,
	iconName,
	label,
	onClick,
	isShort,
	children,
}: ProfileMenuRowProps) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
		if (children) {
			setIsExpanded((p) => !p);
		}
		onClick?.(event);
	};

	return (
		<>
			<button type="button" className={rowWrapperClass({ isShort })} onClick={handleOnClick}>
				{icon ?? <Icon icon={iconName} sizeRem={1.5} />}
				<H3Span>{label}</H3Span>
				{children && <Icon icon={isExpanded ? "arrowUp" : "arrowDown"} />}
			</button>
			{children && isExpanded && (
				<div className={childrenWrapperClass}>
					<DividerList hasDivider gapRem={1}>
						{children}
					</DividerList>
				</div>
			)}
		</>
	);
};
