import React from "react";
import { dividerListWrapperClass } from "./divider-list.styles";
import { DividerListWrapperStyles, type DividerListProps } from "./divider-list.types";
import { Divider } from "../divider/divider.component";

export const DividerList = ({ hasDivider, gapRem, children }: DividerListProps) => {
	if (!hasDivider || !Array.isArray(children) || children.length < 2) {
		return <div className={dividerListWrapperClass}>{children}</div>;
	}

	const result: JSX.Element[] = [];
	children.forEach((c: unknown, index) => {
		if (!c || !React.isValidElement(c)) return;
		result.push(c);
		result.push(<Divider key={`divider-${index}`} />);
	});
	result.pop();

	return (
		<div
			className={dividerListWrapperClass}
			style={{ "--gapRem": gapRem ? `${gapRem}rem` : undefined } as DividerListWrapperStyles}
		>
			{result}
		</div>
	);
};
