import { cva } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const indicatorsClass = flex({
	position: "relative",
	zIndex: "10",
	justifyContent: "center",
	gap: "8",
	marginX: "32",
	transform: "translateY(2.5rem)",
});

export const indicatorButtonClass = cva({
	base: {
		height: "8",
		borderRadius: "16",
		width: "[min(44px,100%)]",
	},
	variants: {
		isActive: {
			true: {
				bg: "secondary.80",
			},
			false: {
				bg: "shade.0",
			},
		},
	},
});
