import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { useLazyLoadQuery } from "react-relay";
import { ProfileExpandableBox } from "@components/profile-expandable-box";
import { levelsBox_Query } from "@relay/levelsBox_Query.graphql";
import { colorShade80Class } from "@themes/color-classes";
import { P3Span } from "@themes/font-tags";
import { QUERY } from "./levels-box.graphql";
import { LevelsBoxSkeleton } from "./levels-box.skeleton";
import {
	expandableBoxGridClass,
	levelProgressBarClass,
	levelProgressBarRunnerClass,
	levelProgressWrapperClass,
} from "./levels-box.styles";
import { LevelProgressBarRunnerStyle } from "./levels-box.types";
import { LevelItem } from "../level-item";

const LevelsBoxComponent = () => {
	const query = useLazyLoadQuery<levelsBox_Query>(
		QUERY,
		{},
		{ fetchPolicy: "store-and-network" },
	);

	const academiesUserExtension = query?.Viewer?.Auth?.currentUser?.user?.extensions?.find(
		(e) => e.kind === "Academies",
	);

	const progressInPercent = academiesUserExtension?.progressInPercent ?? 0;
	const sumGamificationPoints = academiesUserExtension?.sumGamificationPoints ?? 0;

	const levelDefinitions =
		query?.Gamification?.LevelDefinitions?.edges
			?.map((e) => e?.node)
			.map((node) => {
				const isUnlocked = (node?.requiredPoints ?? 0) <= sumGamificationPoints;
				return [node, isUnlocked] as const;
			}) ?? [];

	const sortedLevelDefinitions = levelDefinitions.sort((a, b) => {
		if (a[0]?.requiredPoints === b[0]?.requiredPoints) {
			return 0;
		}
		return a[0]?.requiredPoints! < b[0]?.requiredPoints! ? -1 : 1;
	});

	return (
		<ProfileExpandableBox
			title="Ränge"
			header={
				<div className={levelProgressWrapperClass}>
					<div className={levelProgressBarClass}>
						<div
							className={levelProgressBarRunnerClass}
							style={
								{
									"--progress": `${progressInPercent}%`,
								} as LevelProgressBarRunnerStyle
							}
						/>
					</div>
					<P3Span className={colorShade80Class}>{progressInPercent}%</P3Span>
				</div>
			}
		>
			<div className={expandableBoxGridClass({ columnStyle: "5" })}>
				{sortedLevelDefinitions.map(([levelDefinition, isUnlocked]) => (
					<LevelItem
						key={levelDefinition?.id}
						isUnlocked={isUnlocked}
						levelDefinitionFragmentRef={levelDefinition}
					/>
				))}
			</div>
		</ProfileExpandableBox>
	);
};

export const LevelsBox = withSuspense(LevelsBoxComponent, LevelsBoxSkeleton);
