import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { PathParams } from "@thekeytechnology/epic-ui";
import { useCallback, useState } from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { match } from "ts-pattern";
import { TrialProgressTrackerItem } from "@components/trial-progress-tracker-item";
import { ContentSubmissionScreen_ContentSubmissionFragment$key } from "@relay/ContentSubmissionScreen_ContentSubmissionFragment.graphql";
import { ContentSubmissionScreen_NodeQuery } from "@relay/ContentSubmissionScreen_NodeQuery.graphql";
import { ContentSubmissionPath } from "@router/paths";
import { AsyncContentSubmission } from "@screens/async-content-submission";
import { ELearningContentSubmission } from "@screens/elearning-content-submission";
import { ContentSubmissionSkeleton } from "./content-submission.skeleton";

const QUERY = graphql`
	query ContentSubmissionScreen_NodeQuery($id: ID!) {
		node(id: $id) {
			... on ContentSubmission {
				id
				definition {
					contentKind
				}
				...ContentSubmissionScreen_ContentSubmissionFragment
				learnOpportunity {
					root {
						...trialProgressTrackerItem_LearnOpportunityV2Fragment
					}
				}
			}
		}
		Viewer {
			Auth {
				currentUser {
					user {
						extension(userExtensionKind: Academies) {
							... on AcademiesUserExtension {
								level
							}
						}
					}
				}
			}
		}
	}
`;

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment ContentSubmissionScreen_ContentSubmissionFragment on ContentSubmission {
		...ELearningContentSubmissionScreen_ContentSubmissionFragment
		...asyncContentSubmissionScreen_ContentSubmissionFragment
	}
`;

export const ContentSubmissionComponent = () => {
	const { rootId } = useParams<PathParams<typeof ContentSubmissionPath>>();
	const [fetchKey, setFetchKey] = useState(1);

	const query = useLazyLoadQuery<ContentSubmissionScreen_NodeQuery>(
		QUERY,
		{ id: rootId || "" },
		{ UNSTABLE_renderPolicy: "partial", fetchPolicy: "network-only", fetchKey },
	);

	const userLevel = query.Viewer.Auth.currentUser?.user.extension?.level ?? 0;

	const contentSubmission = useFragment<ContentSubmissionScreen_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		query.node ?? null,
	);

	const handleRefetch = useCallback(() => {
		setFetchKey((prev) => prev + 1);
	}, []);

	if (!contentSubmission) return null;

	const element = match(query.node?.definition?.contentKind ?? "ELearning")
		.with("Async", () => (
			<AsyncContentSubmission
				userLevel={userLevel}
				contentSubmissionFragmentRef={contentSubmission}
				refetch={handleRefetch}
			/>
		))
		.with("ELearning", () => (
			<ELearningContentSubmission
				userLevel={userLevel}
				contentSubmissionFragmentRef={contentSubmission}
			/>
		))
		.exhaustive();

	return (
		<>
			<TrialProgressTrackerItem
				learnOpportunityV2FragmentRef={query?.node?.learnOpportunity?.root}
			/>
			{element}
		</>
	);
};

export const ContentSubmission = withSuspense(
	ContentSubmissionComponent,
	ContentSubmissionSkeleton,
);
