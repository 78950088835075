/**
 * @generated SignedSource<<bb564f6e8a6432ee7301493788e1d364>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type messageModalInjector_LastUnseenMessageQuery$variables = {
  skip: boolean;
};
export type messageModalInjector_LastUnseenMessageQuery$data = {
  readonly Viewer: {
    readonly Message: {
      readonly LastUnseenMessage?: {
        readonly hasSeen: boolean;
        readonly messageDefinition: {
          readonly showUntil: string | null | undefined;
        };
        readonly " $fragmentSpreads": FragmentRefs<"messageModal_MessageFragment">;
      } | null | undefined;
    };
  };
};
export type messageModalInjector_LastUnseenMessageQuery = {
  response: messageModalInjector_LastUnseenMessageQuery$data;
  variables: messageModalInjector_LastUnseenMessageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasSeen",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showUntil",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalTitle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ImageElement",
  "kind": "LinkedField",
  "name": "element",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "messageModalInjector_LastUnseenMessageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageViewerSchema",
            "kind": "LinkedField",
            "name": "Message",
            "plural": false,
            "selections": [
              {
                "condition": "skip",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Message",
                    "kind": "LinkedField",
                    "name": "LastUnseenMessage",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MessageDefinition",
                        "kind": "LinkedField",
                        "name": "messageDefinition",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "messageModal_MessageFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "messageModalInjector_LastUnseenMessageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageViewerSchema",
            "kind": "LinkedField",
            "name": "Message",
            "plural": false,
            "selections": [
              {
                "condition": "skip",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Message",
                    "kind": "LinkedField",
                    "name": "LastUnseenMessage",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MessageDefinition",
                        "kind": "LinkedField",
                        "name": "messageDefinition",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MessageDefinitionPage",
                            "kind": "LinkedField",
                            "name": "pages",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "data",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v7/*: any*/),
                                      (v8/*: any*/)
                                    ],
                                    "type": "ImagePage",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v7/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "button",
                                        "plural": false,
                                        "selections": [
                                          (v5/*: any*/),
                                          (v8/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "text",
                                            "storageKey": null
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v6/*: any*/)
                                            ],
                                            "type": "LinkButton",
                                            "abstractKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      (v8/*: any*/)
                                    ],
                                    "type": "ImageWithButtonPage",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bccf2ee200b5023e0c648acf164018f9",
    "id": null,
    "metadata": {},
    "name": "messageModalInjector_LastUnseenMessageQuery",
    "operationKind": "query",
    "text": "query messageModalInjector_LastUnseenMessageQuery(\n  $skip: Boolean!\n) {\n  Viewer {\n    Message {\n      LastUnseenMessage @skip(if: $skip) {\n        hasSeen\n        messageDefinition {\n          showUntil\n          id\n        }\n        ...messageModal_MessageFragment\n        id\n      }\n    }\n  }\n}\n\nfragment messageModalPage_MessageFragment on MessageDefinition {\n  internalTitle\n}\n\nfragment messageModalPage_PageFragment on MessageDefinitionPage {\n  internalTitle\n  data {\n    __typename\n    ... on ImagePage {\n      element {\n        image {\n          id\n          url\n        }\n      }\n      kind\n    }\n    ... on ImageWithButtonPage {\n      element {\n        image {\n          id\n          url\n        }\n      }\n      button {\n        __typename\n        kind\n        text\n        ... on ConfirmButton {\n          text\n          kind\n        }\n        ... on TextCopyButton {\n          text\n          kind\n        }\n        ... on LinkButton {\n          text\n          url\n          kind\n        }\n      }\n      kind\n    }\n  }\n}\n\nfragment messageModal_MessageFragment on Message {\n  id\n  messageDefinition {\n    ...messageModalPage_MessageFragment\n    showUntil\n    pages {\n      id\n      ...messageModalPage_PageFragment\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ed8069fa831abfb640817d75de69979";

export default node;
