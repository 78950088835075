import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment accountHeader_QueryFragment on Query
	@argumentDefinitions(filterByGroupIds: { type: "[ID!]", defaultValue: [] })
	@refetchable(queryName: "accountHeader_QueryFragmentRefetch") {
		Viewer {
			Auth {
				currentUser {
					accounts {
						id
						name
						groupAssociations {
							group {
								name
							}
						}
					}
				}
			}
		}
		AccountMemberManagement {
			AccountMembers(filterByGroupIds: $filterByGroupIds) {
				userCount
			}
		}
	}
`;
