import { graphql } from "react-relay";

export const QUERY = graphql`
	query activeRootsCarousel_Query {
		Viewer {
			Learn {
				getActiveRoots {
					id
					...offerCard_LearnOpportunityV2Fragment
				}
			}
		}
	}
`;
