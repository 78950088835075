import { ValidatedInputText } from "@thekeytechnology/academies-lib-webapp/components/validated-input-text";
import { useFormik } from "formik";
import { ForwardedRef, forwardRef, useImperativeHandle } from "react";
import { directDebitFormSchema } from "./direct-debit-form.consts";
import { inputsWrapperClass } from "./direct-debit-form.styles";
import {
	CreditcardFormClass,
	DirectDebitFormProps,
	DirectDebitFormState,
} from "./direct-debit-form.types";

const DirectDebitFormComponent = ({ onSubmit }: DirectDebitFormProps, ref: ForwardedRef<any>) => {
	const form = useFormik<DirectDebitFormState>({
		initialValues: {
			iban: "",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: directDebitFormSchema,
		onSubmit: (values) => {
			onSubmit?.(values);
		},
	});

	useImperativeHandle(ref, () => ({
		submit: form.submitForm,
	}));

	return (
		<form onSubmit={form.handleSubmit}>
			<div className={inputsWrapperClass}>
				<ValidatedInputText
					formikConfig={form}
					name="iban"
					label={"IBAN*"}
					placeholder={"DEOO 0000 0000 0000 0000 OO"}
				/>
			</div>
		</form>
	);
};

export const DirectDebitForm = forwardRef(DirectDebitFormComponent);
export type DirectDebitForm = CreditcardFormClass;
