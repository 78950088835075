import { ValidatedInputText } from "@thekeytechnology/academies-lib-webapp/components/validated-input-text";
import { InputText, InputTextStatus } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { ForwardedRef, forwardRef, useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { businessClientFormSchema } from "./business-client-form.consts";
import { QUERY_FRAGMENT } from "./business-client-form.graphql";
import {
	inputsWrapperClass,
	rowSpan2Class,
	rowSpan4Class,
	rowSpan6Class,
	rowSpan6WrapperClass,
} from "./business-client-form.styles";
import {
	BusinessClientForm as BusinessClientFormClass,
	BusinessClientFormProps,
	BusinessClientFormState,
	BusinessClientFormValues,
} from "./business-client-form.types";
import { BusinessTypeDropdown } from "../business-type-dropdown";

const BusinessClientFormComponent = (
	{ initialValues, baseDataFragmentRef, onSubmit }: BusinessClientFormProps,
	ref: ForwardedRef<any>,
) => {
	const query = useFragment(QUERY_FRAGMENT, baseDataFragmentRef);

	const form = useFormik<BusinessClientFormState>({
		initialValues: {
			company: initialValues?.company ?? query?.companyName ?? "",
			recipient: initialValues?.recipient ?? query?.billingOffice ?? "",
			email: initialValues?.email ?? query?.invoiceEmail ?? "",
			street: initialValues?.street ?? query?.street ?? "",
			houseNumber: initialValues?.houseNumber ?? query?.houseNumber ?? "",
			city: initialValues?.city ?? query?.city ?? "",
			postCode: initialValues?.postCode ?? query?.postalCode ?? "",
			phoneNumber: initialValues?.phoneNumber ?? query?.phoneNumber ?? "",
			businessType: initialValues?.businessType ?? query?.companyLegalForm ?? undefined,
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: toFormikValidationSchema(businessClientFormSchema),
		onSubmit: (values) => {
			onSubmit?.(values as BusinessClientFormValues);
		},
	});

	useImperativeHandle(ref, () => ({
		submit: form.submitForm,
	}));

	return (
		<form onSubmit={form.handleSubmit}>
			<div className={inputsWrapperClass}>
				<div className={rowSpan6Class}>
					<ValidatedInputText
						formikConfig={form}
						name="company"
						label={"Name des Unternehmens*"}
						placeholder={"Name des Unternehmens (inkl. Geschäftsform)"}
					/>
				</div>
				<div className={rowSpan6Class}>
					<ValidatedInputText
						formikConfig={form}
						name="recipient"
						label={"Rechnungsstelle oder Empfänger:in*"}
						placeholder={"Rechnungsstelle oder Empfänger:in"}
					/>
				</div>
				<div className={rowSpan6Class}>
					<BusinessTypeDropdown
						formikConfig={form}
						name="businessType"
						label="Unternehmensart*"
						placeholder="Unternehmensart"
					/>
				</div>
				<div className={rowSpan6Class}>
					<ValidatedInputText
						formikConfig={form}
						name="email"
						label={"E-Mail für Rechnung*"}
						placeholder={"E-Mail für Rechnung"}
					/>
				</div>
				<div className={rowSpan6WrapperClass}>
					<div className={rowSpan4Class}>
						<ValidatedInputText
							formikConfig={form}
							name="street"
							label={"Straße*"}
							placeholder={"Straße"}
						/>
					</div>
					<div className={rowSpan2Class}>
						<ValidatedInputText
							formikConfig={form}
							name="houseNumber"
							label={"Hausnummer*"}
							placeholder={"Hausnummer"}
						/>
					</div>
				</div>

				<div className={rowSpan6WrapperClass}>
					<div className={rowSpan4Class}>
						<ValidatedInputText
							formikConfig={form}
							name="city"
							label={"Ort/Stadt*"}
							placeholder={"Ort/Stadt"}
						/>
					</div>
					<div className={rowSpan2Class}>
						<ValidatedInputText
							formikConfig={form}
							name="postCode"
							label={"PLZ*"}
							placeholder={"PLZ"}
						/>
					</div>
				</div>
				<div className={rowSpan6Class}>
					<InputText
						name="country"
						label={"Land*"}
						status={InputTextStatus.Disabled}
						placeholder={"Deutschland"}
						value={"Deutschland"}
					/>
				</div>
				<div className={rowSpan6Class}>
					<ValidatedInputText
						formikConfig={form}
						name="phoneNumber"
						label={"Telefonnummer*"}
						placeholder={"+4917611223344"}
					/>
				</div>
			</div>
		</form>
	);
};

export const BusinessClientForm = forwardRef(BusinessClientFormComponent);
export type BusinessClientForm = BusinessClientFormClass;
