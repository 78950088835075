import { graphql } from "react-relay";

export const QUERY = graphql`
	query licenseDetails_Query($licenseGroupingId: ID!) {
		...licenseDetails_LicenseGroupingFragment @arguments(licenseGroupingId: $licenseGroupingId)
		...licensesNewSingleUserAssignmentForm_QueryFragment
		...userRulesTab_UserQueryFragment

		Viewer {
			Auth {
				currentUser {
					accounts {
						id
						extension(accountExtensionKind: Tka) {
							... on TkaAccountExtension {
								licenseModelKind
							}
						}
					}
				}
			}
		}
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment licenseDetails_LicenseGroupingFragment on Query
	@refetchable(queryName: "licenseDetails_RefetchQuery")
	@argumentDefinitions(licenseGroupingId: { type: "ID!" }) {
		LicenseGrouping {
			LicenseGrouping(id: $licenseGroupingId) {
				id
				usedLicenseAmount
				totalLicenseAmount
				kind
				... on LearnableLicenseGrouping {
					learnable {
						kind
						... on PublishedCourseLearnable {
							id
							root {
								id
								description
								structureDefinition {
									title
								}
							}
						}
					}
				}

				assignmentRules {
					__typename
					... on SingleUserAssignmentRule {
						maxLicenseAmount
					}
					...userRulesTab_QueryFragment
					...licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment
				}
			}
		}
	}
`;
