import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import ReactConfetti from "react-confetti";
import { CONFETTI_COLORS, CONFETTI_NUMBER_OF_PIECES } from "./content-finished-confetti.consts";
import { ContentFinishedConfettiProps } from "./content-finished-confetti.types";
import { drawConfettiShape } from "./content-finished-confetti.utils";

export const ContentFinishedConfetti = ({ source, onComplete }: ContentFinishedConfettiProps) => {
	const { windowHeight, windowWidth } = useWindowSize();

	return (
		<ReactConfetti
			width={windowWidth}
			height={windowHeight}
			confettiSource={{
				x: source?.x ?? 0,
				y: source?.y ?? 0,
				w: source?.w ?? windowWidth,
				h: source?.h ?? 0,
			}}
			numberOfPieces={CONFETTI_NUMBER_OF_PIECES}
			colors={CONFETTI_COLORS}
			recycle={false}
			onConfettiComplete={onComplete}
			drawShape={drawConfettiShape}
		/>
	);
};
