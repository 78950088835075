import { css, cva } from "@styled-system/css";
import { flex, stack } from "@styled-system/patterns";

export const wrapperClass = flex({
	minHeight: "full",
	direction: "column",
	align: "center",
	mt: "40",
	flex: "1",
});

export const contentWrapperClass = flex({
	direction: "column",
	align: "center",
	justify: "center",
	gap: "24",
	maxWidth: "[20.4375rem]",
});

export const medalsWrapperClass = flex({
	position: "relative",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	width: "full",
	height: "[13.75rem]",
});

export const medalsPercentageCorrectClass = cva({
	base: {
		position: "absolute",
		display: "block",
		zIndex: "1",
		color: "warning.100",
		textAlign: "right",
		left: "50%",
		transform: "translateX(-90%)",
	},
	variants: {
		isWoodMedal: {
			true: {
				bottom: "[51.5px]",
			},
			false: {
				bottom: "[56.5px]",
			},
		},
	},
});

export const headerWrapperClass = stack({
	align: "center",
	gap: "8",
});

export const headerTitleClass = css({
	textAlign: "center",
	color: "shade.100",
});

export const headerSubtitleClass = css({
	textAlign: "center",
	color: "shade.80",
});

export const rewardListClass = flex({
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	width: "full",
	gap: "12",
});

export const levelRewardProgressClass = flex({
	gap: "8",
	flex: "1",
	justifyContent: "flex-end",
	alignItems: "center",
});

export const levelRewardProgressWrapperClass = flex({
	alignItems: "flex-start",
	width: "[117px]",
	height: "16",
	borderRadius: "full",
	backgroundColor: "primary.20",
});

export const levelRewardProgressWrapperInnerClass = css({
	height: "full",
	backgroundColor: "warning.100",
	borderRadius: "full",
});
