import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useCallback, useState } from "react";
import { colorShade100Class } from "@themes/color-classes";
import { H2Span, P2Span } from "@themes/font-tags";
import {
	listWrapperClass,
	mainWrapperClass,
	textWrapperClass,
	iconWrapperClass,
} from "./materials-branch-node-card.styles";
import { type MaterialsBranchNodeCardProps } from "./materials-branch-node-card.types";

export const MaterialsBranchNodeCard = ({
	branchNumber,
	branchName,
	children,
}: MaterialsBranchNodeCardProps) => {
	const { t } = useTkaTranslation("modulesAndLessons");

	const [isExpanded, setIsExpanded] = useState(false);

	const handleOnExpandClick = useCallback(() => {
		setIsExpanded(!isExpanded);
	}, [isExpanded]);

	return (
		<div className={listWrapperClass}>
			<button type="button" onClick={handleOnExpandClick} className={mainWrapperClass}>
				<div className={textWrapperClass}>
					<P2Span>
						{t("modules_and_lessons.module")} {branchNumber}
					</P2Span>
					<H2Span className={colorShade100Class}>{branchName}</H2Span>
				</div>
				<div className={iconWrapperClass}>
					<Icon icon={isExpanded ? "arrowUp" : "arrowDown"} />
				</div>
			</button>
			{isExpanded && children}
		</div>
	);
};
