import { UserAvatar } from "@thekeytechnology/academies-lib-webapp";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { motion } from "framer-motion";
import { HTMLAttributes } from "react";
import { useLazyLoadQuery } from "react-relay";
import { userHeader_Query } from "@relay/userHeader_Query.graphql";
import { cx } from "@styled-system/css";
import { colorShade0Class } from "@themes/color-classes";
import { H1Span, H3Span, P2Span, P3Span } from "@themes/font-tags";
import { greetingForTimeOfDay } from "@utils/greeting";
import { QUERY } from "./user-header.graphql";
import { UserHeaderSkeleton } from "./user-header.skeleton";
import {
	gradientWrapperClass,
	userDataClass,
	userDataWrapperClass,
	userLevelAndButtonWrapperClass,
	userLevelProgressBarClass,
	userLevelProgressClass,
	userLevelProgressWrapperClass,
	userLevelTitleClass,
	userLevelWrapperClass,
} from "./user-header.styles";
import { UserHeaderProps } from "./user-header.types";

const UserHeaderComponent = ({ onClick, className }: UserHeaderProps) => {
	const data = useLazyLoadQuery<userHeader_Query>(
		QUERY,
		{},
		{ fetchPolicy: "store-and-network" },
	);

	const user = data?.Viewer?.Auth?.currentUser?.user;
	if (!user) return null;

	const academiesUserExtension = user.extensions?.find((e) => e.kind === "Academies");
	const tkaUserExtension = user.extensions?.find((e) => e.kind === "Tka");

	const firstName = academiesUserExtension?.firstName;
	const lastName = academiesUserExtension?.lastName;

	const userLevel = academiesUserExtension?.level ?? 0;
	const userLevelProgress = academiesUserExtension?.progressInPercent ?? 0;
	const userLevelTitle = tkaUserExtension?.levelDefinition?.title;

	const WrapperComponent = onClick
		? ({ onClick: _, ...props }: HTMLAttributes<HTMLButtonElement>) => (
				<button onClick={onClick} {...props} />
		  )
		: (props: HTMLAttributes<HTMLDivElement>) => <div {...props} />;

	return (
		<WrapperComponent className={cx(gradientWrapperClass, className)}>
			<div className={userDataWrapperClass}>
				{data && <UserAvatar userFragmentRef={user} sizeInRem={5} />}
				<div className={userDataClass}>
					<P3Span>{greetingForTimeOfDay()}</P3Span>
					<H1Span>
						{firstName} {lastName}
					</H1Span>
					<P2Span>
						Schön, dass Du wieder da bist. Schau Dich doch mal bei
						<br />
						den Kursen um. Viel Spaß mit der thekey.academy
					</P2Span>
				</div>
			</div>
			<div className={userLevelAndButtonWrapperClass}>
				<div className={userLevelWrapperClass}>
					<div className={userLevelTitleClass}>
						<H3Span className={colorShade0Class}>{userLevelTitle}</H3Span>
						<P3Span className={colorShade0Class}>Level {userLevel}</P3Span>
					</div>
					<div className={userLevelProgressWrapperClass}>
						<div className={userLevelProgressClass}>
							<motion.div
								animate={{
									width: userLevelProgress,
								}}
								transition={{
									type: "spring",
									stiffness: 210,
									damping: 16,
								}}
								className={userLevelProgressBarClass}
							/>
						</div>
						<P3Span className={colorShade0Class}>{userLevelProgress}%</P3Span>
					</div>
				</div>
			</div>
		</WrapperComponent>
	);
};

export const UserHeader = withSuspense(UserHeaderComponent, UserHeaderSkeleton);
