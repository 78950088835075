export const drawConfettiShape = (context: CanvasRenderingContext2D) => {
	context.beginPath();

	context.moveTo(22.6466, 9.67425);
	context.bezierCurveTo(22.7136, 10.3864, 22.5647, 11.1023, 22.2194, 11.7288);
	context.bezierCurveTo(21.874, 12.3552, 21.3481, 12.8632, 20.7101, 13.1868);
	context.bezierCurveTo(15.5592, 15.8873, 9.55346, 16.4561, 3.98706, 14.7708);
	context.bezierCurveTo(3.29936, 14.5718, 2.68752, 14.1704, 2.23123, 13.6187);
	context.bezierCurveTo(1.77494, 13.0671, 1.49536, 12.3908, 1.4289, 11.678);
	context.lineTo(0.890343, 5.90433);
	context.bezierCurveTo(0.839217, 5.3551, 0.916267, 4.8015, 1.11544, 4.28711);
	context.bezierCurveTo(1.31461, 3.77272, 1.63047, 3.31158, 2.03814, 2.93999);
	context.bezierCurveTo(2.44581, 2.56841, 2.93416, 2.29652, 3.46475, 2.14574);
	context.bezierCurveTo(3.99535, 1.99495, 4.5537, 1.96939, 5.09586, 2.07105);
	context.bezierCurveTo(9.17665, 2.8594, 13.3978, 2.46367, 17.261, 0.930571);
	context.bezierCurveTo(17.775, 0.72945, 18.3287, 0.650461, 18.8784, 0.699835);
	context.bezierCurveTo(19.4282, 0.74921, 19.9589, 0.925593, 20.4289, 1.21509);
	context.bezierCurveTo(20.8988, 1.50458, 21.2951, 1.89927, 21.5865, 2.36805);
	context.bezierCurveTo(21.8779, 2.83682, 22.0564, 3.36687, 22.108, 3.91641);
	context.lineTo(22.6466, 9.67425);

	context.closePath();
	context.fill();
};
