import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { EpicIcons, H3Span, Icon } from "@thekeytechnology/epic-ui";
import { FC, useContext, useEffect } from "react";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { ReminderCard } from "@components/reminder-card";
import { reminderOverview_Query } from "@relay/reminderOverview_Query.graphql";
import { reminderOverview_QueryFragment$key } from "@relay/reminderOverview_QueryFragment.graphql";
import { reminderOverview_RefetchQuery } from "@relay/reminderOverview_RefetchQuery.graphql";
import { NotesScreenContext } from "@screens/notes/notes.context";
import { info100 } from "@themes/colors";
import { NOTE_CONNECTION_FRAGMENT, QUERY } from "./reminder-overview.graphql";
import {
	listClass,
	listWrapperClass,
	nextButtonWrapperClass,
	wrapperClass,
} from "./reminder-overview.style";
import { ReminderOverviewProps } from "./reminder-overview.types";

export const ReminderOverviewComponent: FC<ReminderOverviewProps> = () => {
	const query = useLazyLoadQuery<reminderOverview_Query>(
		QUERY,
		{},
		{ fetchPolicy: "store-and-network" },
	);
	const { data, refetch, loadNext, hasNext } = usePaginationFragment<
		reminderOverview_RefetchQuery,
		reminderOverview_QueryFragment$key
	>(NOTE_CONNECTION_FRAGMENT, query);
	const {
		filters,
		helpers: { registerRefetch, unregisterRefetch },
	} = useContext(NotesScreenContext);

	const handleLoadNext = () => {
		loadNext(20);
	};

	const edges = data?.Note?.Note.edges;
	const incompleteReminders =
		edges?.filter((edge) => edge?.node.reminder && !edge.node.reminder.isCompleted) ?? [];
	const completeReminders = edges?.filter((edge) => edge?.node.reminder?.isCompleted) ?? [];

	const handleDeleteReminder = () => {
		refetch(
			{
				filterByText: filters.text,
			},
			{
				fetchPolicy: "store-and-network",
			},
		);
	};

	useEffect(() => {
		const refetchId = registerRefetch(() => {
			refetch(
				{
					...filters,
					filterByText: filters.text,
				},
				{
					fetchPolicy: "store-and-network",
				},
			);
		});

		return () => {
			unregisterRefetch(refetchId);
		};
	}, []);

	useEffect(() => {
		refetch({
			...filters,
			filterByText: filters.text,
		});
	}, [filters]);

	const isEmpty = incompleteReminders.length === 0 && completeReminders.length === 0;

	return (
		<EmptyPlaceholder
			isVisible={isEmpty}
			icon={<Icon icon={EpicIcons.BELL} sizeRem={2.5} color={info100} />}
			iconBackgroundColor="onPrimary"
			title="Hier findest du deine Erinnerungen"
			subtitle="Du kannst jederzeit Erinnerungen erstellen und einsehen. Du wirst dann nach Bedarf auf der Plattform und via Mail erinnert."
		>
			<div className={wrapperClass}>
				{incompleteReminders.length > 0 && (
					<div className={listWrapperClass}>
						<H3Span>Erinnerungen</H3Span>
						<div className={listClass}>
							{incompleteReminders.map((note) => (
								<ReminderCard
									key={note?.node.id}
									onDeleteReminder={handleDeleteReminder}
									noteFragmentRef={note?.node}
								/>
							))}
						</div>
					</div>
				)}
				{completeReminders.length > 0 && (
					<div className={listWrapperClass}>
						<H3Span>Erledigt</H3Span>
						<div className={listClass}>
							{completeReminders.map((note) => (
								<ReminderCard
									key={note?.node.id}
									onDeleteReminder={handleDeleteReminder}
									noteFragmentRef={note?.node}
								/>
							))}
						</div>
					</div>
				)}
				{hasNext && (
					<div className={nextButtonWrapperClass}>
						<Button
							type="button"
							onClick={handleLoadNext}
							label="Weitere Erinnerungen laden"
						/>
					</div>
				)}
			</div>
		</EmptyPlaceholder>
	);
};
