import React from "react";
import { ScreenWithNavbarAndBottomContentLayoutSkeleton } from "@layouts/screen-with-navbar-and-bottom-content-layout/screen-with-navbar-and-bottom-content-layout.skeleton";
import { MessagePreviewCardSkeleton } from "@screens/messages/parts/message-preview-card/message-preview-card.skeleton";
import { messagesOverviewClass } from "@screens/messages/parts/messages-overview/messages-overview.styles";

export const MessagesScreenSkeleton = () => {
	const numCardSkeletons = 6;
	return (
		<ScreenWithNavbarAndBottomContentLayoutSkeleton>
			<div className={messagesOverviewClass}>
				{Array.from({ length: numCardSkeletons }).map((_, index) => (
					<MessagePreviewCardSkeleton key={index} />
				))}
			</div>
		</ScreenWithNavbarAndBottomContentLayoutSkeleton>
	);
};
