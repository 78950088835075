import { css } from "@styled-system/css";
import { grid } from "@styled-system/patterns";

export const wrapperClass = grid({
	gridTemplateColumns: "4",
	gridColumn: "4",
	alignContent: "flex-start",
	height: "full",
	gap: "32",
});

export const contentCardsWrapperClass = css({
	gridColumn: "4",
	lg: {
		gridColumn: "2",
	},
});

export const attachmentsWrapperClass = css({
	gridColumn: "4",
	lg: {
		gridColumn: "2",
	},
});

export const instructorsClass = css({
	gridColumn: "4",
	lg: {
		gridColumn: "2",
	},
});
