/**
 * @generated SignedSource<<207d715b69f41a26a8ad34972580defc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type LearnableKind = "Course";
export type LicenseGroupingKind = "Learnable";
import { FragmentRefs } from "relay-runtime";
export type licensesGroupCard_LicenseGroupingFragment$data = {
  readonly assignmentRules: ReadonlyArray<{
    readonly licenseGroupingId: string;
  }>;
  readonly id: string;
  readonly kind: LicenseGroupingKind;
  readonly learnable?: {
    readonly kind: LearnableKind;
    readonly root?: {
      readonly description: string | null | undefined;
      readonly id: string;
      readonly image: {
        readonly thumbnail: string | null | undefined;
      } | null | undefined;
      readonly structureDefinition: {
        readonly title: string;
      };
    } | null | undefined;
  } | null | undefined;
  readonly totalLicenseAmount: number;
  readonly totalLicenseAmountForUser: number;
  readonly usedLicenseAmount: number;
  readonly usedLicenseAmountForUser: number;
  readonly " $fragmentType": "licensesGroupCard_LicenseGroupingFragment";
};
export type licensesGroupCard_LicenseGroupingFragment$key = {
  readonly " $data"?: licensesGroupCard_LicenseGroupingFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licensesGroupCard_LicenseGroupingFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "licensesGroupCard_LicenseGroupingFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalLicenseAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usedLicenseAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalLicenseAmountForUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usedLicenseAmountForUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "assignmentRules",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "licenseGroupingId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "learnable",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LearnOpportunityV2",
                  "kind": "LinkedField",
                  "name": "root",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "structureDefinition",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "File",
                      "kind": "LinkedField",
                      "name": "image",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "thumbnail",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "PublishedCourseLearnable",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "LearnableLicenseGrouping",
      "abstractKey": null
    }
  ],
  "type": "LicenseGrouping",
  "abstractKey": "__isLicenseGrouping"
};
})();

(node as any).hash = "7534e7a117f1728bf859ba16a4047b62";

export default node;
