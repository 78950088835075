import { flex, stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	textAlign: "center",
	gap: "40",
});

export const iconWrapperClass = flex({
	width: "[7.5rem]",
	height: "[7.5rem]",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: "error.0",
	borderRadius: "[3.75rem]",
	alignSelf: "center",
});

export const textWrapperClass = stack({
	gap: "12",
	alignItems: "center",
	justifyContent: "center",
});
