import { stripHtml } from "@thekeytechnology/academies-lib-webapp/utils";
import { useFragment } from "react-relay";
import { ReminderLearnElement } from "@components/reminder-learn-element";
import { SideMenuContentPadding } from "@containers/side-menu-content-padding";
import { reminderElement_ActiveELearningContentSubmissionDefinitionFragment$key } from "@relay/reminderElement_ActiveELearningContentSubmissionDefinitionFragment.graphql";
import { colorShade100Class } from "@themes/color-classes";
import { H1Span } from "@themes/font-tags";
import { ACTIVE_E_LEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT } from "./reminder-element.graphql";
import { spacing32Class } from "./reminder-element.styles";
import { ReminderElementProps } from "./reminder-element.types";

export const ReminderElement = ({
	contentSubmissionDefinitionFragmentRef,
}: ReminderElementProps) => {
	const contentSubmissionDefinition =
		useFragment<reminderElement_ActiveELearningContentSubmissionDefinitionFragment$key>(
			ACTIVE_E_LEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT,
			contentSubmissionDefinitionFragmentRef,
		);

	const element = contentSubmissionDefinition?.currentElementState?.element;

	return (
		<SideMenuContentPadding data-selection-menu-element-id={element?.id}>
			{element?.title && (
				<>
					<H1Span className={colorShade100Class}>{stripHtml(element.title)}</H1Span>
					<div className={spacing32Class} />
				</>
			)}
			<ReminderLearnElement learnElementFragmentRef={element} />
		</SideMenuContentPadding>
	);
};
