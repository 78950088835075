/**
 * @generated SignedSource<<f703df6bb7f111afbb53924de8fd3e3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountContextMenu_Query$variables = Record<PropertyKey, never>;
export type accountContextMenu_Query$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly currentUser: {
        readonly accounts: ReadonlyArray<{
          readonly name: string;
        }>;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"deleteAccountModalsFlow_AuthViewerSchemaFragment" | "leaveAccountModal_authViewerSchemaFragmentRef">;
    };
  };
};
export type accountContextMenu_Query = {
  response: accountContextMenu_Query$data;
  variables: accountContextMenu_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "accountContextMenu_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "accounts",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "deleteAccountModalsFlow_AuthViewerSchemaFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "leaveAccountModal_authViewerSchemaFragmentRef"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "accountContextMenu_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "accounts",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d5a8bd95d4bd39ff5076badd7c7720bc",
    "id": null,
    "metadata": {},
    "name": "accountContextMenu_Query",
    "operationKind": "query",
    "text": "query accountContextMenu_Query {\n  Viewer {\n    Auth {\n      currentUser {\n        accounts {\n          name\n          id\n        }\n      }\n      ...deleteAccountModalsFlow_AuthViewerSchemaFragment\n      ...leaveAccountModal_authViewerSchemaFragmentRef\n    }\n  }\n}\n\nfragment deleteAccountModalsFlow_AuthViewerSchemaFragment on AuthViewerSchema {\n  currentUser {\n    accounts {\n      id\n      name\n    }\n  }\n}\n\nfragment leaveAccountModal_authViewerSchemaFragmentRef on AuthViewerSchema {\n  currentUser {\n    accounts {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "494ff4b6a77a0dbeda0386f93ee2e8cf";

export default node;
