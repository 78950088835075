import { graphql } from "react-relay";

export const QUERY = graphql`
	query rootOverview_GetRootOverviewQuery($id: ID!) {
		node(id: $id) {
			... on LearnOpportunityV2 {
				id
				description
				instructorsV2 {
					edges {
						node {
							id
						}
					}
					...instructorsComponent_InstructorsConnectionFragment
				}
				structureDefinition {
					title
					... on LearnOpportunityRootStructureDefinition {
						extension {
							... on PublishedRootExtensionImpl {
								learnGoals
								reducedData {
									receivableGamificationPoints
									isIHK
								}
								tags {
									id
									data {
										name
										isTopic
									}
								}
								unlockInfo {
									kind
								}
								licenseAvailability {
									... on LicenseAvailabilityFreeLicensesAvailable {
										numAvailable
									}
								}
								product {
									id
									netPrice
								}
								diploma
								ihkState
							}
						}
					}
				}
			}
		}
	}
`;

export const ADD_ITEM_TO_USER_CART_MUTATION = graphql`
	mutation rootOverview_AddItemToUserCartMutation($input: AddItemToUserCartInput!) {
		Viewer {
			Billing {
				addItemToUserCart(input: $input) {
					userCart {
						...userCart_UserCartFragment
					}
				}
			}
		}
	}
`;
