/**
 * @generated SignedSource<<7fa2501efeca7649b031a77763b0dba9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ImageElement_ElearningContentSubmissionDefinitionFragment$data = {
  readonly currentElementState: {
    readonly element: {
      readonly file?: {
        readonly url: string | null | undefined;
      } | null | undefined;
      readonly id: string;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"imageLearnElement_ImageLearnElementFragment">;
    };
  } | null | undefined;
  readonly " $fragmentType": "ImageElement_ElearningContentSubmissionDefinitionFragment";
};
export type ImageElement_ElearningContentSubmissionDefinitionFragment$key = {
  readonly " $data"?: ImageElement_ElearningContentSubmissionDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ImageElement_ElearningContentSubmissionDefinitionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ImageElement_ElearningContentSubmissionDefinitionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "currentElementState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "element",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "file",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "imageLearnElement_ImageLearnElementFragment"
                }
              ],
              "type": "FileLearnElement",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ActiveELearningContentSubmissionDefinition",
  "abstractKey": null
};

(node as any).hash = "13002ec1f8c461cd8469c5462619cd66";

export default node;
