/**
 * @generated SignedSource<<3834f76d855d4b1dd719efc3cb9684fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userRulesTab_QueryFragment$data = {
  readonly id: string;
  readonly licensesUsedByUser: number;
  readonly maxLicenseAmount: number;
  readonly userId: string;
  readonly " $fragmentType": "userRulesTab_QueryFragment";
};
export type userRulesTab_QueryFragment$key = {
  readonly " $data"?: userRulesTab_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userRulesTab_QueryFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "userRulesTab_QueryFragment"
};

(node as any).hash = "f200adf6de589974463184118b1a4756";

export default node;
