/**
 * @generated SignedSource<<8f08963f10ea069e7356207080287c02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type shoppingCartTemplate_AuthViewerSchemaFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"avatarWithAccountSwitch_AuthViewerSchemaFragment" | "screenSidemenuLayout_AuthViewerSchemaFragment">;
  readonly " $fragmentType": "shoppingCartTemplate_AuthViewerSchemaFragment";
};
export type shoppingCartTemplate_AuthViewerSchemaFragment$key = {
  readonly " $data"?: shoppingCartTemplate_AuthViewerSchemaFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartTemplate_AuthViewerSchemaFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "shoppingCartTemplate_AuthViewerSchemaFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "avatarWithAccountSwitch_AuthViewerSchemaFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "screenSidemenuLayout_AuthViewerSchemaFragment"
    }
  ],
  "type": "AuthViewerSchema",
  "abstractKey": null
};

(node as any).hash = "c8376f2737991ae343129270d646b8b3";

export default node;
