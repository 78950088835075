import { NoteColor } from "@relay/noteCard_NoteFragment.graphql";

export const COLORS: NoteColor[] = ["default", "yellow", "green", "blue", "red"];

export const COLORS_OPTIONS = [
	{
		label: "Schwarz",
		value: "default",
	},
	{
		label: "Gelb",
		value: "yellow",
	},
	{
		label: "Grün",
		value: "green",
	},
	{
		label: "Blau",
		value: "blue",
	},
	{
		label: "Rot",
		value: "red",
	},
];
