import IntercomLib from "@intercom/messenger-js-sdk";
import { memo, useEffect } from "react";
import { useLazyLoadQuery } from "react-relay";
import { ReactComponent as IntercomIcon } from "@assets/intercom-launcher-icon.svg";
import { intercom_Query } from "@relay/intercom_Query.graphql";
import { cx } from "@styled-system/css";
import { decodeBase64 } from "@utils/decoder.utils";
import { removeGraphqlPrefixFromId } from "@utils/id-converter.utils";
import { INTERCOM_SHARED_OPTIONS } from "./intercom.consts";
import { QUERY } from "./intercom.graphql";
import { buttonClass } from "./intercom.styles";

export const IntercomComponent = () => {
	const query = useLazyLoadQuery<intercom_Query>(QUERY, {});

	const user = query?.Viewer.Auth.currentUser?.user;
	const tkaExtension = user?.extensions.find((extension) => extension.kind === "Tka");

	useEffect(() => {
		if (!user || !tkaExtension) {
			IntercomLib({
				...INTERCOM_SHARED_OPTIONS,
			});
			return;
		}

		const userId = decodeBase64(removeGraphqlPrefixFromId(user.id));
		IntercomLib({
			...INTERCOM_SHARED_OPTIONS,
			user_id: userId,
			email: user.email,
			name: user.name,
			user_hash: tkaExtension.intercomUserWebHash,
		});
	}, [user, tkaExtension]);

	return (
		<button type="button" className={cx(buttonClass, "intercom-button")} title="Kontakt">
			<IntercomIcon />
		</button>
	);
};

export const Intercom = memo(IntercomComponent);
