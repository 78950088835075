import * as Sentry from "@sentry/react";
import { Avatar } from "@thekeytechnology/academies-lib-webapp/components/avatar";
import { Divider } from "@thekeytechnology/academies-lib-webapp/components/divider";
import { DividerList } from "@thekeytechnology/academies-lib-webapp/components/divider-list";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { UserAvatar } from "@thekeytechnology/academies-lib-webapp/components/user-avatar";
import { useAuthContext } from "@thekeytechnology/academies-lib-webapp/hooks/use-auth-context";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { selectCurrentAccountId } from "@thekeytechnology/academies-lib-webapp/slices";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { accountSwitchMenu_AuthViewerSchemaFragment$key } from "@relay/accountSwitchMenu_AuthViewerSchemaFragment.graphql";
import { brandStrong100, error100 } from "@themes/colors";
import { TkaB1Span, TkaH1Span, TkaH2Span } from "@themes/font-tags";

import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "./account-switch-menu.graphql";
import {
	AccountRow,
	CurrentUserWrapper,
	FlexGrow,
	MenuWrapper,
	SignOutRow,
} from "./account-switch-menu.styles";
import { AccountSwitchMenuProps } from "./account-switch-menu.types";

export const AccountSwitchMenu = ({
	authViewerSchemaFragmentRef,
	hideSelectedAccount,
	onAccountSelected,
}: AccountSwitchMenuProps) => {
	const auth = useFragment<accountSwitchMenu_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerSchemaFragmentRef,
	);

	const { t } = useTkaTranslation("navbar");
	const { logout, switchAccount } = useAuthContext();

	const currentAccountId = useSelector(selectCurrentAccountId);
	const accounts = auth.currentUser?.accounts ?? [];

	const handleAccountOnClick = (id: string) => () => {
		switchAccount(id);
		onAccountSelected?.();
	};

	const handleLogoutOnClick = () => {
		logout();
		Sentry.setUser(null);
	};

	const name = auth.currentUser?.user.name;
	const hasMoreThenOneAccount = accounts.length > 1;

	const accountsList = hasMoreThenOneAccount ? (
		<DividerList hasDivider gapRem={1}>
			{[
				<Fragment key="first-divider" />,
				...accounts.map((account) => (
					<AccountRow key={account.id} onClick={handleAccountOnClick(account.id)}>
						<FlexGrow>
							<Avatar name={account.name} />
							<TkaH2Span>{account.name}</TkaH2Span>
						</FlexGrow>
						{!hideSelectedAccount && account.id === currentAccountId && (
							<Icon icon="correct" sizeRem={1.5} color={brandStrong100} />
						)}
					</AccountRow>
				)),
				<Fragment key="last-divider" />,
			]}
		</DividerList>
	) : (
		<Divider />
	);

	return (
		<MenuWrapper>
			{name && (
				<CurrentUserWrapper>
					{name && <UserAvatar userFragmentRef={auth.currentUser.user} />}
					<TkaH1Span>{name}</TkaH1Span>
				</CurrentUserWrapper>
			)}
			{accountsList}
			<SignOutRow onClick={handleLogoutOnClick}>
				<Icon color={error100} icon="logout" sizeRem={1} />
				<TkaB1Span extraBold tkaColor={error100}>
					{t("navbar.logout")}
				</TkaB1Span>
			</SignOutRow>
		</MenuWrapper>
	);
};
