import { PathParams } from "@thekeytechnology/epic-ui";
import { Suspense, useEffect } from "react";
import { useFragment, usePreloadedQuery, useQueryLoader } from "react-relay";
import { useParams } from "react-router-dom";
import { materialOverview_LearnOpportunityV2Fragment$key } from "@relay/materialOverview_LearnOpportunityV2Fragment.graphql";
import { materialOverview_Query } from "@relay/materialOverview_Query.graphql";
import { RootPath } from "@router/paths";
import {
	LEARN_OPPORTUNITY_V2_FRAGMENT,
	MATERIAL_OVERVIEW_QUERY,
} from "./material-overview.graphql";
import { MaterialOverviewSkeleton } from "./material-overview.skeleton";
import {
	MaterialOverviewProps,
	MaterialOverviewWithPreloadedQueryProps,
} from "./material-overview.types";
import { MaterialsTab } from "../materials-tab";

export const MaterialOverviewWithPreloadedQuery = ({
	queryRef,
}: MaterialOverviewWithPreloadedQueryProps) => {
	const query = usePreloadedQuery<materialOverview_Query>(MATERIAL_OVERVIEW_QUERY, queryRef);

	const node = useFragment<materialOverview_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		query.node ?? null,
	);

	return <MaterialsTab learnOpportunityV2FragmentRef={node!} />;
};

export const MaterialOverview = (props: MaterialOverviewProps) => {
	const { rootId } = useParams<PathParams<typeof RootPath>>();
	const [queryReference, loadQuery] =
		useQueryLoader<materialOverview_Query>(MATERIAL_OVERVIEW_QUERY);

	useEffect(() => {
		rootId && loadQuery({ id: rootId });
	}, [loadQuery, rootId]);

	return (
		<Suspense fallback={<MaterialOverviewSkeleton />}>
			{queryReference && (
				<MaterialOverviewWithPreloadedQuery {...props} queryRef={queryReference} />
			)}
		</Suspense>
	);
};
