import { graphql } from "react-relay";

export const QUERY = graphql`
	query certificatesBox_Query {
		Learnable {
			PublishedLearnables(tagIds: [], kinds: []) {
				edges {
					node {
						... on PublishedCourseLearnable {
							id
							root {
								structureDefinition {
									title
									... on LearnOpportunityRootStructureDefinition {
										extension {
											... on PublishedRootExtensionImpl {
												diploma
												reducedData {
													isIHK
												}
											}
										}
										viewerTreeState {
											kind
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
