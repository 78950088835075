/**
 * @generated SignedSource<<b5ee8759c687cb823ecd8b4bd79e9186>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type AcademiesTagKind = "Child" | "Root";
import { FragmentRefs } from "relay-runtime";
export type tags_AcademiesRootTagFragment$data = {
  readonly canBeDeleted: boolean;
  readonly children: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly data: {
          readonly isClickable: boolean;
          readonly isTopic: boolean;
          readonly name: string;
        };
        readonly id: string;
        readonly kind: AcademiesTagKind;
        readonly subId: string;
      };
    } | null | undefined> | null | undefined;
  };
  readonly data: {
    readonly isClickable: boolean;
    readonly isTopic: boolean;
    readonly name: string;
  };
  readonly id: string;
  readonly kind: AcademiesTagKind;
  readonly " $fragmentType": "tags_AcademiesRootTagFragment";
};
export type tags_AcademiesRootTagFragment$key = {
  readonly " $data"?: tags_AcademiesRootTagFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"tags_AcademiesRootTagFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "tags_AcademiesRootTagFragment"
};

(node as any).hash = "3233b70c4a2f50d452a718894650f96e";

export default node;
