import { graphql } from "react-relay";

export const QUERY = graphql`
	query reminderOverview_Query(
		$includeNoteSourceKinds: [NoteSourceKind!]
		$includeNoteColors: [NoteColor!]
		$noteColorToSortBy: NoteColor
		$filterByText: String
		$first: Int
		$after: String
	) {
		...reminderOverview_QueryFragment
			@arguments(
				includeNoteSourceKinds: $includeNoteSourceKinds
				includeNoteColors: $includeNoteColors
				noteColorToSortBy: $noteColorToSortBy
				filterByText: $filterByText
				first: $first
				after: $after
			)
	}
`;

export const NOTE_CONNECTION_FRAGMENT = graphql`
	fragment reminderOverview_QueryFragment on Query
	@argumentDefinitions(
		includeNoteSourceKinds: { type: "[NoteSourceKind!]", defaultValue: null }
		includeNoteColors: { type: "[NoteColor!]", defaultValue: null }
		noteColorToSortBy: { type: "NoteColor" }
		filterByText: { type: "String" }
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
	)
	@refetchable(queryName: "reminderOverview_RefetchQuery") {
		Note {
			Note(
				hasReminder: true
				includeNoteSourceKinds: $includeNoteSourceKinds
				includeNoteColors: $includeNoteColors
				noteColorToSortBy: $noteColorToSortBy
				filterByText: $filterByText
				first: $first
				after: $after
			) @connection(key: "reminderOverview_Note") {
				__id
				pageInfo {
					endCursor
					hasPreviousPage
					hasNextPage
					startCursor
				}
				edges {
					cursor
					node {
						id
						reminder {
							kind
							isCompleted
							remindAt
						}
						...reminderCard_ReminderNoteFragment
					}
				}
			}
		}
	}
`;
