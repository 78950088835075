import { css } from "@styled-system/css";
import { flex, grid, stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	gap: "24",
	padding: "16",
	minWidth: "[min(45rem, 85vw)]",
});

export const iconWrapperWrapperClass = flex({
	justifyContent: "center",
});

export const iconWrapperClass = css({
	padding: "40",
	borderRadius: "full",
	backgroundColor: "success.100/10",
	flexGrow: "0",
	maxWidth: "[fit-content]",
});

export const titleWrapperClass = stack({
	gap: "4",
});

export const inputsWrapperClass = grid({
	gridTemplateColumns: "12",
	gap: "24",
	md: {
		rowGap: "16",
		columnGap: "16",
	},
	lg: {
		rowGap: "24",
		columnGap: "32",
	},
});

export const rowSpan6WrapperClass = grid({
	gridTemplateColumns: "12",
	gridColumn: "12",
	rowGap: "24",
	md: {
		gap: "16",
	},
	lg: {
		gridTemplateColumns: "6",
		gridColumn: "6",
	},
});

export const rowSpan6Class = css({
	gridColumn: "12",
	md: {
		gridColumn: "6",
	},
});

export const rowSpan4Class = css({
	gridColumn: "12",
	md: {
		gridColumn: "6",
	},
	lg: {
		gridColumn: "4",
	},
});

export const rowSpan2Class = css({
	gridColumn: "12",
	md: {
		gridColumn: "6",
	},
	lg: {
		gridColumn: "2",
	},
});

export const rowSpan12Class = css({
	gridColumn: "12",
});

export const hrClass = css({
	gridColumn: "12",
	color: "shade.10",
});

export const checkboxWrapperClass = flex({
	gap: "12",
	alignItems: "center",
});

export const centerWrapperClass = flex({
	width: "full",
	alignItems: "center",
	justifyContent: "center",
});
