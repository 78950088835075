import { cva } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	flexDirection: "row",
	alignItems: "center",
	bg: "shade.0",
	gap: "40",
	px: "24",
	py: "16",
	xl: {
		h: "full",
		flexDirection: "column",
		bg: "primary.100",
		px: "16",
		py: "24",
	},
});

export const centerWrapperClass = flex({
	justifyContent: "space-between",
	alignItems: "center",
	flex: "1",
	xl: {
		flexDirection: "column",
		justifyContent: "center",
	},
});

export const visibilityClass = cva({
	variants: {
		showXLUp: {
			true: {
				display: "none",
				xl: {
					display: "block",
				},
			},
		},
		hideXLUp: {
			true: {
				display: "block",
				xl: {
					display: "none",
				},
			},
		},
	},
});
