import { css, cva } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const modalContentClass = css({
	display: "flex",
	m: "auto",
	maxH: "screen",
	position: "relative",
});

export const modalInnerWrapperClass = css({
	position: "relative",
});

export const modalOuterWrapperClass = css({
	m: "[-1.5em]", // because there is an uneditable wrapper div in the modal with padding
	h: "screen",
	alignItems: "center",
	justifyContent: "space-between",
	display: "flex",
	lg: { display: "grid", gridTemplateColumns: "[30% 1fr 30%]" },
});

export const pagesContainerClass = cva({
	base: {
		display: "flex",
		gap: "8!",
		filter: "[brightness(50%)]",
		overflow: "clip",
	},
	variants: {
		isLeft: {
			true: {
				justifyContent: "flex-end",
			},
		},
	},
});

export const carouselContentClass = flex({
	direction: "column-reverse!",
	m: "auto",
	borderRadius: "16",
});

export const carouselContainerClass = css({
	w: "[25em]",
	x: "-10!",
	gap: "16",
	mdDown: {
		w: "[20em]",
	},
	m: "auto",
	position: "relative",
});

export const buttonClass = cva({
	base: {
		height: "[fit-content]",
		my: "auto",
		zIndex: "10",
		w: "[2.5em!]",
		mx: "[2em]",
		mdDown: {
			w: "[46%!]",
			mx: "[0.5em]",
			position: "absolute!",
			bottom: "-8",
		},
	},
	variants: {
		side: {
			previous: {
				mdDown: {
					left: "8",
				},
			},
			next: {
				mdDown: {
					right: "8",
				},
			},
		},
		show: {
			true: {
				opacity: "0.5",
			},
			false: {
				opacity: "0",
				pointerEvents: "none",
			},
		},
	},
});

export const mobileButtonBackgroundClass = css({
	bg: "shade.100",
	width: "[100%]",
	height: "[100%]",
	position: "absolute",
	top: "0",
	left: "0",
	zIndex: "-1",
	transform: "[translateY(1em)]",
	borderRadius: "16",
});

export const carouselItemClass = css({
	flex: "[1 0 100%]",
});

export const closeIconWrapperClass = css({
	position: "absolute",
	zIndex: "1",
	top: "20",
	right: "[18px]",
});
