import { graphql } from "react-relay";

export const QUERY = graphql`
	query shoppingCartPaymentMethod_Query($orderId: ID!, $skip: Boolean!) {
		node(id: $orderId) @skip(if: $skip) {
			... on Order {
				...shoppingCartPaymentMethod_OrderFragment
			}
		}
		Viewer {
			Auth {
				...shoppingCartTemplate_AuthViewerSchemaFragment
			}
		}
	}
`;

export const ORDER_FRAGMENT = graphql`
	fragment shoppingCartPaymentMethod_OrderFragment on Order {
		id
		cart {
			items {
				kind
				... on LimitedDiscountItem {
					title
					value
				}
				... on DiscountItem {
					validUntil
					code
					title
					value
				}
				... on GlobalDiscountItem {
					title
					value
				}
				... on ProductItem {
					amount
					product {
						id
						title
						netPrice
						data {
							... on LicenseProductData {
								licenseDefinition {
									data {
										... on TreeLicenseDefinitionData {
											rootInfo {
												rootId
												title
												image {
													url
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			totals {
				includingAllDiscounts {
					grossPrice
				}
				monthlyOptions {
					downPayment {
						grossPrice
						netPrice
						taxRatePercentage
					}
					financedAmount {
						grossPrice
						netPrice
						taxRatePercentage
					}
					monthlyRate {
						grossPrice
						netPrice
						taxRatePercentage
					}
					rateCount
					totalAmount {
						grossPrice
						netPrice
						taxRatePercentage
					}
					totalAmount {
						grossPrice
					}
				}
			}
		}
		allowedPaymentMethods {
			paymentMethods
		}
		selectedPaymentMethod {
			kind
			paymentMethodType
			... on MonthlyPaymentMethod {
				chosenOption {
					rateCount
				}
			}
		}
		...shoppingCartTemplate_OrderFragment
	}
`;

export const CHANGE_PAYMENT_METHOD_MUTATION = graphql`
	mutation shoppingCartPaymentMethod_ChangePaymentMethodMutation(
		$input: ChangePaymentMethodInput!
	) {
		Billing {
			changePaymentMethod(input: $input) {
				order {
					...shoppingCartPaymentMethod_OrderFragment
				}
			}
		}
	}
`;
