import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = css({
	position: "relative",
	lineHeight: "[1.3125rem]",
	"&[aria-expanded='false']": {
		height: "[130px]",
		_after: {
			content: '""',
			position: "absolute",
			bottom: "[-1px]",
			height: "full",
			width: "full",
			backgroundImage:
				"[linear-gradient(to top,rgba(255, 255, 255, 1) 0%,rgba(255, 255, 255, 0) 100%)]",
			pointerEvents: "none",
		},
	},
});

export const contentClass = css({
	height: "full",
	overflow: "hidden",
});

export const toggleButtonClass = flex({
	alignItems: "center",
	justifyContent: "center",
	borderRadius: "8",
	height: "[30px]",
	width: "[42px]",
	backgroundColor: "[#184276]",
	marginY: "0",
	marginX: "auto",
	zIndex: "10",
	"&[data-expanded='false']": {
		position: "relative",
		top: "-32",
	},
});
