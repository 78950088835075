import { z } from "zod";

export const businessClientFormSchema = z.object({
	company: z.string({ required_error: "Bitte gib den Name des Unternehmens ein." }),
	recipient: z.string({ required_error: "Bitte gib eine Rechnungsstelle ein." }),
	businessType: z.string({ required_error: "Bitte wähle eine Unternehmensart aus." }),
	email: z
		.string({ required_error: "Bitte gib eine E-Mail Adresse ein." })
		.email("Bitte gib eine gültige E-Mail Adresse ein."),
	street: z.string({ required_error: "Bitte gib eine Straße ein." }),
	houseNumber: z.string({ required_error: "Bitte gib eine Hausnummer ein." }),
	city: z.string({ required_error: "Bitte gib einen Ort ein." }),
	postCode: z
		.string({ required_error: "Bitte gib eine Postleitzahl ein." })
		.length(5, "Bitte gib eine Gültige Postleitzahl ein")
		.regex(/^[0-9]{5}/, "Bitte gib eine Gültige Postleitzahl ein"),
	phoneNumber: z
		.string({ required_error: "Bitte gib eine Telefonnummer ein." })
		.regex(
			/^((\+49|0)1)(5[0-25-9]\d|6([23]|0\d?)|7([0-57-9]|6\d))\d{7,9}$/,
			"Bitte gib eine gültige Telefonnummer ein.",
		),
});
