import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { useMutation } from "react-relay";
import { ReactComponent as BuyLicenseKeySVG } from "@assets/buy-license-key.svg";
import { useUserCartContext } from "@components/user-cart/user-cart.context";
import { unlockWallItem_AddItemToUserCartMutation } from "@relay/unlockWallItem_AddItemToUserCartMutation.graphql";
import { colorPrimary100Class } from "@themes/color-classes";
import { P3Span } from "@themes/font-tags";
import { ADD_ITEM_TO_USER_CART_MUTATION } from "./unlock-wall-item.graphql";
import { cardClass, wrapperClass } from "./unlock-wall-item.styles";
import { type UnlockWallItemProps } from "./unlock-wall-item.types";

// TODO: add-translations
export const UnlockWallItem = ({ productId }: UnlockWallItemProps) => {
	const { setUserCartOpen } = useUserCartContext();

	const [addItemToUserCart] = useMutation<unlockWallItem_AddItemToUserCartMutation>(
		ADD_ITEM_TO_USER_CART_MUTATION,
	);

	const handleAddItemToUserCart = () => {
		addItemToUserCart({
			variables: {
				input: {
					productId,
				},
			},
			onCompleted: () => {
				setUserCartOpen(true);
			},
		});
	};

	return (
		<div className={wrapperClass}>
			<div className={cardClass}>
				<BuyLicenseKeySVG />
				<P3Span className={colorPrimary100Class}>
					Ohne Lizenz geht es hier nicht mehr weiter.
				</P3Span>
				{productId && (
					<Button
						type="button"
						onClick={handleAddItemToUserCart}
						label="Lizenz zum Warenkorb hinzufügen"
					/>
				)}
			</div>
		</div>
	);
};
