import { type DropdownOption } from "@thekeytechnology/epic-ui/components/dropdown";
import {
	type DSAReportProblemType,
	type DSAReportType,
} from "@relay/createReportModal_createReportMutation.graphql";

export const getProblemTypeOptions = (): DropdownOption[] => {
	const translations: Record<DSAReportProblemType, string> = {
		Accessibility: "Zugänglichkeit verbessern",
		ConsumerProtection: "Verbraucherschutz",
		Disinformation: "Desinformation",
		IllegalContents: "Illegale oder falsche Inhalte",
		Other: "Sonstige",
		PrivacyRequest: "Datenschutzanfragen",
		ProtectionOfMinors: "Schutz von Kindern und Jugendlichen",
		TransparencyForOnlineAds: "Transparenz bei Online-Werbung",
		UserRightsAndComplaints: "Nutzerrechte und Beschwerden",
	};
	return Object.keys(translations)
		.sort()
		.map((key) => ({
			value: key,
			label: translations[key as DSAReportProblemType],
		}));
};

export const getReportTypeOptions = (): DropdownOption[] => {
	const translations: Record<DSAReportType, string> = {
		Other: "Sonstiges",
		Report: "DSA-Meldung",
		Complaint: "Beschwerde",
		Feedback: "Feedback",
	};
	return Object.keys(translations)
		.sort()
		.map((key) => ({
			value: key,
			label: translations[key as DSAReportType],
		}));
};
