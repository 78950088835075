import { css } from "@styled-system/css";

export const greenGradientClass = css({
	position: "relative",
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	marginTop: "24",
	paddingX: "32",
	paddingY: "16",
	borderRadius: "12",
	width: "full",
	gap: "16",
	background: "[radial-gradient(120% 1000% at 5% 54%, #7270CF 0%, #079E83 50%, #27E59B 100%)]",
	boxShadow:
		"[0px 1px 1px 0px rgba(16, 24, 32, 0.04), 0px 2px 5px 0px rgba(16, 24, 32, 0.02), 0px 4px 0px 0px rgba(41, 19, 113, 0.11)]",
	md: {
		flexDirection: "row",
		gap: "0",
	},
});

export const userDataClass = css({
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	gap: "4",
});

export const daysWrapperClass = css({
	display: "flex",
	alignItems: "center",
	gap: "16",
	flexWrap: "wrap",
});
