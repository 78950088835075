import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { QUERY } from "@components/report-modal-flow/parts/system-email/system-email.graphql";
import { type SystemEmailProps } from "@components/report-modal-flow/parts/system-email/system-email.types";
import { type systemEmail_Query } from "@relay/systemEmail_Query.graphql";
import { cx } from "@styled-system/css";
import { P2Anchor, P3Anchor } from "@themes/font-tags";
import { DSA_REPORT_EMAIL_FALLBACK } from "../../../../consts";

export const SystemEmail = ({ className, isBig }: SystemEmailProps) => {
	const query = useLazyLoadQuery<systemEmail_Query>(QUERY, {});

	const Typography = isBig ? P2Anchor : P3Anchor;

	return (
		<Suspense fallback={DSA_REPORT_EMAIL_FALLBACK}>
			<Typography
				className={cx(className)}
				href={"mailto:" + query.Viewer.Reports.SystemEmail}
			>
				{query.Viewer.Reports.SystemEmail}
			</Typography>
		</Suspense>
	);
};
