export const VOCAB_CONTENT_NODE_ID =
	"TGVhcm5PcHBvcnR1bml0eVYyOk5vZGU6c3RhbmRhbG9uZS12b2NhYi1jb250ZW50";

export const USER_IN_ACCOUNT_GROUP_OWNER_ID = "VXNlckluQWNjb3VudEdyb3VwOm93bmVy";
export const USER_IN_ACCOUNT_GROUP_USER_ID = "VXNlckluQWNjb3VudEdyb3VwOnVzZXI=";

export const IMPRINT_URL = "https://thekey.academy/impressum";
export const DATA_PROTECTION_URL = "https://thekey.academy/datenschutz";
export const AGB_URL = "https://thekey.academy/agb";

export const DSA_REPORT_EMAIL_FALLBACK = "app@thekey.academy";

export const STORAGE_KEY_TRACKING_ITEMS = "tkt-tracking-items";
export const STORAGE_KEY_TRIAL_LESSON_PROGRESS = "tkt-trial-lesson-progress";
export const STORAGE_KEY_USER_IS_INTERNAL = "tkt-user-is-internal";
export const STORAGE_KEY_IS_ONBOARDING_MODAL_SHOWN = "tkt-is-onboarding-modal-shown";
