import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { H2Span, P2Span } from "@thekeytechnology/epic-ui";
import { useTranslation } from "react-i18next";
import { useLazyLoadQuery } from "react-relay";
import { learnStreak_Query } from "@relay/learnStreak_Query.graphql";
import { QUERY } from "./learn-streak.graphql";
import { LearnStreakSkeleton } from "./learn-streak.skeleton";
import { greenGradientClass, userDataClass, daysWrapperClass } from "./learn-streak.styles";
import { LearnStreakProps } from "./learn-streak.types";
import { getDaysUntilStreakDone, getDurationOfStreak } from "./learn-streak.utils";
import { colorShade0Class } from "../../../../themes/color-classes";
import { WEEKDAYS } from "../../dashboard.consts";
import { LearnStreakWeekday } from "../learn-streak-weekday/learn-streak-weekday.component";

export const LearnStreakComponent = ({}: LearnStreakProps) => {
	const data = useLazyLoadQuery<learnStreak_Query>(
		QUERY,
		{},
		{ fetchPolicy: "store-and-network" },
	);

	const { t } = useTranslation("learnStreak");

	const academiesUserExtension = data?.Viewer?.Auth?.currentUser?.user?.extensions?.find(
		(e) => e.kind === "Academies",
	);
	const learnStreak = academiesUserExtension?.learnStreak;

	const durationOfStreak = getDurationOfStreak(learnStreak?.startDate);
	const daysRemaining = getDaysUntilStreakDone(learnStreak?.startDate);
	const renderedWeekdays = WEEKDAYS.map((weekday, idx) => {
		return (
			<LearnStreakWeekday
				key={weekday}
				weekday={weekday}
				weekdayIndex={idx}
				startDate={learnStreak?.startDate}
			/>
		);
	});

	return (
		<div className={greenGradientClass}>
			<div className={userDataClass}>
				<H2Span className={colorShade0Class}>
					{t("streak_done", { count: durationOfStreak })}
				</H2Span>
				<P2Span className={colorShade0Class}>
					{t("days_remaining", { count: daysRemaining })}
				</P2Span>
			</div>
			<div className={daysWrapperClass}>{renderedWeekdays}</div>
		</div>
	);
};

export const LearnStreak = withSuspense(LearnStreakComponent, LearnStreakSkeleton);
