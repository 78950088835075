import { css } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	marginTop: "12",
	gap: "16",
});

export const userListClass = css({
	marginTop: "8",
	spaceY: "8",
});
