import { MaterialCardSkeleton } from "@components/material-card";
import { mainWrapperClass } from "./material-cards-collection.styles";
import type { MainWrapperStyle } from "./material-cards-collection.types";

export const MaterialCardsCollectionSkeleton = ({ rows = 2 }: { rows?: number }) => {
	return (
		<div className={mainWrapperClass} style={{ "--rows": rows } as MainWrapperStyle}>
			<MaterialCardSkeleton />
			<MaterialCardSkeleton />
			<MaterialCardSkeleton />
			<MaterialCardSkeleton />
		</div>
	);
};
