/**
 * @generated SignedSource<<d52f88d7fc9d6069df027e2bb5815315>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NoteReminderKind = "NonRecurring" | "Recurring";
export type ReminderIntervalKind = "Daily" | "Monthly" | "Weekly";
import { FragmentRefs } from "relay-runtime";
export type reminderForm_QueryFragment$data = {
  readonly content: string | null | undefined;
  readonly id: string;
  readonly reminder: {
    readonly interval?: {
      readonly kind: ReminderIntervalKind;
      readonly value?: number;
    };
    readonly isCompleted: boolean;
    readonly kind: NoteReminderKind;
    readonly remindAt: string;
  } | null | undefined;
  readonly " $fragmentType": "reminderForm_QueryFragment";
};
export type reminderForm_QueryFragment$key = {
  readonly " $data"?: reminderForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"reminderForm_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "reminderForm_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "reminder",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remindAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCompleted",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "interval",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": (v1/*: any*/),
                  "type": "DailyInterval",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v1/*: any*/),
                  "type": "WeeklyInterval",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v1/*: any*/),
                  "type": "MonthlyInterval",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RecurringNoteReminder",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Note",
  "abstractKey": null
};
})();

(node as any).hash = "791f002ebb9b299ae731b851b45e6517";

export default node;
