/**
 * @generated SignedSource<<c2413b2a2348d0688d36d6b24339dd04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeleteNoteInput = {
  clientMutationId?: string | null | undefined;
  ids: ReadonlyArray<string>;
};
export type noteCard_DeleteNoteMutation$variables = {
  input: DeleteNoteInput;
};
export type noteCard_DeleteNoteMutation$data = {
  readonly Note: {
    readonly deleteNote: {
      readonly clientMutationId: string | null | undefined;
    } | null | undefined;
  };
};
export type noteCard_DeleteNoteMutation = {
  response: noteCard_DeleteNoteMutation$data;
  variables: noteCard_DeleteNoteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "NoteMutations",
    "kind": "LinkedField",
    "name": "Note",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "DeleteNotePayload",
        "kind": "LinkedField",
        "name": "deleteNote",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "noteCard_DeleteNoteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "noteCard_DeleteNoteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ac3bf2c4257d9cd9e3e331a459ddebd3",
    "id": null,
    "metadata": {},
    "name": "noteCard_DeleteNoteMutation",
    "operationKind": "mutation",
    "text": "mutation noteCard_DeleteNoteMutation(\n  $input: DeleteNoteInput!\n) {\n  Note {\n    deleteNote(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "913ee727f8c3ec7f9ce667dd809189ed";

export default node;
