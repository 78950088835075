import { DialogTemplate } from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { Suspense, useContext } from "react";
import { IconHeadlineButtonTemplate } from "@components/icon-headline-button-template";
import { ReportModalFlowContext } from "@components/report-modal-flow/report-modal-flow.context";
import { colorSecondary120Class } from "@themes/color-classes";
import { success100 } from "@themes/colors";
import { P2Span } from "@themes/font-tags";
import {
	wrapperClass,
	listWrapperClass,
	listItemClass,
	referenceNumberWrapperClass,
} from "./create-report-success-modal.styles";
import { DSA_REPORT_EMAIL_FALLBACK } from "../../../../consts";
import { type CreateReportSuccessModalProps } from "../create-report-success-modal/create-report-success-modal.types";
import { SystemEmail } from "../system-email";

export const CreateReportSuccessModal = ({
	isVisible = false,
	onDismiss,
}: CreateReportSuccessModalProps) => {
	const { setReferenceNumber, referenceNumber } = useContext(ReportModalFlowContext);

	const handleOnDismiss = () => {
		setReferenceNumber("");
		onDismiss?.();
	};

	return (
		<ResponsiveBottomSheetModal
			isVisible={isVisible}
			dismissable={true}
			onDismiss={handleOnDismiss}
		>
			<DialogTemplate
				maxWidthRem={27.5}
				hasSeperator
				primaryButtonStretch
				primaryButtonLabel="Okay"
				primaryButtonIconName="arrowRight"
				onPrimaryButtonClick={handleOnDismiss}
			>
				<div className={wrapperClass}>
					<IconHeadlineButtonTemplate
						iconName="feedback2"
						iconColor={success100}
						iconBackgroundColor="success0"
						title="Vielen Dank"
						subtitle="Deine Meldung wurde erfolgreich eingereicht"
					/>
					<div className={listWrapperClass}>
						<div className={listItemClass}>
							<Icon icon={"checkBadge"} color={success100} sizeRem={1.25} />
							<P2Span>Deine Meldung wird nun von unserem Team geprüft.</P2Span>
						</div>
						<div className={listItemClass}>
							<Icon icon={"checkBadge"} color={success100} sizeRem={1.25} />
							<P2Span>
								Wir bemühen uns, alle Anfragen zügig zu bearbeiten und werden dich
								innerhalb der nächsten 5 Werktage kontaktieren.
							</P2Span>
						</div>
						<div className={listItemClass}>
							<Icon icon={"checkBadge"} color={success100} sizeRem={1.25} />
							<P2Span>
								Solltest du in der Zwischenzeit weitere Fragen haben oder
								zusätzliche Informationen bereitstellen wollen, kontaktiere uns mit
								der Referenznummer{" "}
								<span className={referenceNumberWrapperClass}>
									[{referenceNumber ?? "Fehler"}]
								</span>{" "}
								bitte unter:{" "}
								<Suspense fallback={DSA_REPORT_EMAIL_FALLBACK}>
									<SystemEmail className={colorSecondary120Class} isBig />
								</Suspense>
							</P2Span>
						</div>
					</div>
				</div>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
