import { graphql } from "react-relay";

export const QUERY = graphql`
	query learnStreak_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						extensions {
							kind
							... on AcademiesUserExtension {
								learnStreak {
									startDate
								}
							}
						}
					}
				}
			}
		}
	}
`;
