import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";
import { H1Span } from "@themes/font-tags";
import { headerClass, searchAndFiltersWrapperClass } from "./offers.styles";
import { GridViewSkeleton } from "./parts/grid-view/grid-view.skeleton";
import { SearchBarSkeleton } from "./parts/search-bar/search-bar.skeleton";

export const OffersScreenSkeleton = () => {
	return (
		<ScreenWithNavbarLayout>
			<header className={headerClass}>
				<H1Span>Entdecken</H1Span>
				<div className={searchAndFiltersWrapperClass}>
					<SearchBarSkeleton />
				</div>
			</header>
			<GridViewSkeleton />
		</ScreenWithNavbarLayout>
	);
};
