/**
 * @generated SignedSource<<82a5201b41b4cf0e0b03d6b30c281364>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type messagesOverview_MessagesFragment$data = {
  readonly Viewer: {
    readonly Message: {
      readonly MessagesForUser: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly messageDefinition: {
              readonly id: string;
            };
            readonly " $fragmentSpreads": FragmentRefs<"messageModal_MessageFragment" | "messagePreviewCard_MessageFragment">;
          };
        } | null | undefined> | null | undefined;
      };
    };
  };
  readonly " $fragmentType": "messagesOverview_MessagesFragment";
};
export type messagesOverview_MessagesFragment$key = {
  readonly " $data"?: messagesOverview_MessagesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"messagesOverview_MessagesFragment">;
};

import messagesOverview_Refetch_graphql from './messagesOverview_Refetch.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": messagesOverview_Refetch_graphql
    }
  },
  "name": "messagesOverview_MessagesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerQueryType",
      "kind": "LinkedField",
      "name": "Viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MessageViewerSchema",
          "kind": "LinkedField",
          "name": "Message",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MessagesConnection",
              "kind": "LinkedField",
              "name": "MessagesForUser",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MessagesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Message",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "messagePreviewCard_MessageFragment"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "messageModal_MessageFragment"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "MessageDefinition",
                          "kind": "LinkedField",
                          "name": "messageDefinition",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "e348fed031b0322833f30d71dfd7b266";

export default node;
