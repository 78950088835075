import { graphql } from "react-relay";

export const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment uploadElement_ContentSubmissionFragment on ContentSubmission {
		id
		learnOpportunity {
			structureDefinition {
				title
			}
			description
			indexPath
		}
		definition {
			... on PassedAsyncContentSubmissionDefinition {
				status
				element {
					... on UploadAsyncLearnElement {
						file {
							id
							name
							url
						}
					}
				}
			}
			... on ActiveAsyncContentSubmissionDefinition {
				status
				element {
					... on UploadAsyncLearnElement {
						file {
							id
							name
							url
						}
					}
					id
					taskDescription
				}
			}
		}
	}
`;

export const SUBMIT_UPLOAD_ASYNC_ELEMENT_MUTATION = graphql`
	mutation uploadElement_SubmitUploadAsyncElementMutation(
		$input: SubmitUploadAsyncElementInput!
	) {
		AsyncContent {
			submitUploadAsyncElement(input: $input) {
				clientMutationId
				contentSubmission {
					id
					learnOpportunity {
						...contentOverview_LearnOpportunityV2Fragment
					}
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;
