import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment licensesNewSingleUserAssignmentForm_QueryFragment on Query
	@argumentDefinitions(filterByGroupIds: { type: "[ID!]!", defaultValue: [] }) {
		AccountMemberManagement {
			AccountMembers(filterByGroupIds: $filterByGroupIds) {
				accountMembers {
					edges {
						node {
							id
							name
							kind
							... on UserInAccountWrapper {
								user {
									user {
										id
										name
									}
								}
							}
							...licensesSingleUserAssignmentCard_AccountMemberFragment
						}
					}
				}
			}
		}
	}
`;

export const CREATE_NEW_SINGLE_USER_ASSIGNMENT_RULE_MUTATION = graphql`
	mutation licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation(
		$input: CreateSingleUserAssignmentRuleInput!
	) {
		LicenseManagement {
			createSingleUserAssignmentRule(input: $input) {
				clientMutationId
				assignmentRule {
					id
					licenseGroupingId
					...licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment
				}
			}
		}
	}
`;
