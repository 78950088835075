import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const contentWrapperClass = css({
	display: "block",
	width: "full",
	flex: "1",
});

export const header = flex({
	flexDirection: "column",
	justifyContent: "flex-start",
	gap: "24",
	height: "full",
	width: "full",
	flex: "1",
	lg: {
		gap: "40",
	},
});

export const headerInnerClass = css({
	display: "flex",
	justifyContent: "space-between",
	gap: "24",
});

export const headerFilterWrapperClass = css({
	display: "flex",
	alignItems: "center",
	gap: "8",
});

export const noGrowWrapperClass = css({
	flexGrow: "0",
	lg: {
		width: "[11rem]",
	},
});
