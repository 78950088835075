/**
 * @generated SignedSource<<e39ecf1d1bd72012142fd70188ce6297>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LearnableKind = "Course";
export type licensesTab_MyLicensesQuery$variables = {
  kinds: ReadonlyArray<LearnableKind>;
  licensesAvailable?: boolean | null | undefined;
  licensesConsumed?: boolean | null | undefined;
  text?: string | null | undefined;
};
export type licensesTab_MyLicensesQuery$data = {
  readonly LicenseGrouping: {
    readonly MyLicenses: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"licensesGroupCard_LicenseGroupingFragment">;
        };
      } | null | undefined> | null | undefined;
    };
  };
};
export type licensesTab_MyLicensesQuery = {
  response: licensesTab_MyLicensesQuery$data;
  variables: licensesTab_MyLicensesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "kinds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "licensesAvailable"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "licensesConsumed"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "text"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "kinds",
    "variableName": "kinds"
  },
  {
    "kind": "Variable",
    "name": "licensesAvailable",
    "variableName": "licensesAvailable"
  },
  {
    "kind": "Variable",
    "name": "licensesConsumed",
    "variableName": "licensesConsumed"
  },
  {
    "kind": "Variable",
    "name": "text",
    "variableName": "text"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "licensesTab_MyLicensesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseGroupingQueries",
        "kind": "LinkedField",
        "name": "LicenseGrouping",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LicenseGroupingsConnection",
            "kind": "LinkedField",
            "name": "MyLicenses",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LicenseGroupingsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "licensesGroupCard_LicenseGroupingFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "licensesTab_MyLicensesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseGroupingQueries",
        "kind": "LinkedField",
        "name": "LicenseGrouping",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LicenseGroupingsConnection",
            "kind": "LinkedField",
            "name": "MyLicenses",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LicenseGroupingsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isLicenseGrouping"
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalLicenseAmount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "usedLicenseAmount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalLicenseAmountForUser",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "usedLicenseAmountForUser",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "assignmentRules",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "licenseGroupingId",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "learnable",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LearnOpportunityV2",
                                    "kind": "LinkedField",
                                    "name": "root",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "structureDefinition",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "description",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "File",
                                        "kind": "LinkedField",
                                        "name": "image",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "thumbnail",
                                            "storageKey": null
                                          },
                                          (v2/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "PublishedCourseLearnable",
                                "abstractKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "LearnableLicenseGrouping",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "835232d996552cd22d8610342babd24a",
    "id": null,
    "metadata": {},
    "name": "licensesTab_MyLicensesQuery",
    "operationKind": "query",
    "text": "query licensesTab_MyLicensesQuery(\n  $kinds: [LearnableKind!]!\n  $licensesAvailable: Boolean\n  $licensesConsumed: Boolean\n  $text: String\n) {\n  LicenseGrouping {\n    MyLicenses(kinds: $kinds, licensesAvailable: $licensesAvailable, licensesConsumed: $licensesConsumed, text: $text) {\n      edges {\n        node {\n          __typename\n          id\n          ...licensesGroupCard_LicenseGroupingFragment\n        }\n      }\n    }\n  }\n}\n\nfragment licensesGroupCard_LicenseGroupingFragment on LicenseGrouping {\n  __isLicenseGrouping: __typename\n  id\n  kind\n  totalLicenseAmount\n  usedLicenseAmount\n  totalLicenseAmountForUser\n  usedLicenseAmountForUser\n  assignmentRules {\n    __typename\n    licenseGroupingId\n    id\n  }\n  ... on LearnableLicenseGrouping {\n    learnable {\n      __typename\n      kind\n      ... on PublishedCourseLearnable {\n        root {\n          structureDefinition {\n            __typename\n            title\n          }\n          id\n          description\n          image {\n            thumbnail\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "be18a072eed4291f9516c049e4cf0650";

export default node;
