import { css } from "@styled-system/css";
import { grid } from "@styled-system/patterns";

export const groupWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "8",
});

export const inputSwitchWrapperClass = css({
	display: "flex",
	width: "full",
	alignItems: "center",
	justifyContent: "space-between",
});

export const wrapperClass = css({
	gap: "24",
	display: "flex",
	flexDirection: "column",
	zIndex: "1000",
	md: {
		width: "[18.5rem]",
	},
});

export const buttonWrapperClass = css({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	md: {
		display: "none",
	},
});

export const filterHeadlineClass = css({
	md: {
		display: "none",
	},
});

export const colorToSortByInputWrapperClass = grid({
	gap: "8",
	alignItems: "center",
	width: "full",
	gridTemplateColumns: "[1fr auto]",
});

export const colorToSortByInputClass = css({
	flex: "1",
});
