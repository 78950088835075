import { indicatorButtonClass, indicatorsClass } from "./message-modal-indicators.styles";
import { MessageModalIndicatorsProps } from "./message-modal-indicators.types";

export const MessageModalIndicators = ({
	amountOfItems,
	onIndicatorClick,
	currentPageIndex,
}: MessageModalIndicatorsProps) => {
	const handleOnClick = (index: number) => {
		onIndicatorClick(index);
	};

	return (
		<div className={indicatorsClass}>
			{Array.from({ length: amountOfItems }).map((_, index) => (
				<div
					key={index}
					className={indicatorButtonClass({
						isActive: index === currentPageIndex,
					})}
					onClick={() => handleOnClick(index)}
				/>
			))}
		</div>
	);
};
