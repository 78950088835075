import { Icon } from "@thekeytechnology/academies-lib-webapp";
import { ResponsiveBottomSheetOverlayPanel } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-overlay-panel";
import { formatDateTime } from "@thekeytechnology/academies-lib-webapp/utils";
import { SyntheticEvent, useRef, useState, type FC } from "react";
import { useMutation, useRefetchableFragment } from "react-relay";
import { UpsertReminderModal } from "@components/upsert-reminder-modal";
import type { reminderCard_CheckReminderNoteMutation } from "@relay/reminderCard_CheckReminderNoteMutation.graphql";
import type { reminderCard_DeleteReminderNoteMutation } from "@relay/reminderCard_DeleteReminderNoteMutation.graphql";
import type { reminderCard_ReminderNoteFragment$key } from "@relay/reminderCard_ReminderNoteFragment.graphql";
import type { reminderCard_ReminderNoteFragmentRefetch } from "@relay/reminderCard_ReminderNoteFragmentRefetch.graphql";
import { error100 } from "@themes/colors";
import { H3Span, P3Span } from "@themes/font-tags";
import { spacing0 } from "@themes/spacing";
import {
	SET_NOTE_IS_COMPLETED_MUTATION,
	DELETE_NOTE_MUTATION,
	NOTE_FRAGMENT,
} from "./reminder-card.graphql";
import {
	cardClass,
	checkboxClass,
	checkIconClass,
	contentClass,
	contentWrapperClass,
	contextMenuButtonClass,
	contextMenuContentClass,
	contextMenuItemClass,
	srOnlyClass,
	titleClass,
} from "./reminder-card.style";
import { ReminderCardProps } from "./reminder-card.types";

export const ReminderCard: FC<ReminderCardProps> = ({ onDeleteReminder, noteFragmentRef }) => {
	const [upsertReminderModalOpen, setUpsertReminderModalOpen] = useState(false);

	const contextMenuOverlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);

	const [note, refetch] = useRefetchableFragment<
		reminderCard_ReminderNoteFragmentRefetch,
		reminderCard_ReminderNoteFragment$key
	>(NOTE_FRAGMENT, noteFragmentRef ?? null);
	const [deleteNote] = useMutation<reminderCard_DeleteReminderNoteMutation>(DELETE_NOTE_MUTATION);
	const [checkNote] = useMutation<reminderCard_CheckReminderNoteMutation>(
		SET_NOTE_IS_COMPLETED_MUTATION,
	);

	if (!note || !note.reminder) return null;

	const isCompleted = note.reminder.isCompleted;
	const remindAt = note.reminder.remindAt;
	const content = note.content;

	const handleRefetch = () => {
		refetch(
			{
				id: note.id,
			},
			{
				fetchPolicy: "store-and-network",
			},
		);
	};

	const handleDelete = () => {
		deleteNote({
			variables: {
				input: {
					ids: [note.id],
				},
			},
			onCompleted: () => {
				onDeleteReminder?.();
			},
		});
	};

	const handleCheckboxClick = () => {
		checkNote({
			variables: {
				input: {
					noteId: note.id,
					isCompleted: !isCompleted,
				},
			},
			onCompleted: () => {
				handleRefetch();
			},
		});
	};

	const handleContextMenuOnClick = (
		event?: SyntheticEvent<Element, Event> | null,
		target?: EventTarget | HTMLElement | null,
	) => {
		contextMenuOverlayRef.current?.show(event, target);
	};

	const handleUpsertReminderModalOpen = () => {
		setUpsertReminderModalOpen(true);
	};

	const handleUpsertNoteModalDismiss = () => {
		setUpsertReminderModalOpen(false);
	};

	const handleUpsertNoteSuccess = () => {
		refetch(
			{
				id: note.id,
			},
			{
				fetchPolicy: "store-and-network",
			},
		);
		setUpsertReminderModalOpen(false);
	};

	return (
		<>
			{upsertReminderModalOpen && (
				<UpsertReminderModal
					onDismiss={handleUpsertNoteModalDismiss}
					onSuccess={handleUpsertNoteSuccess}
					noteFragmentRef={note}
				/>
			)}
			<div
				className={cardClass({
					isCompleted,
				})}
			>
				<div className={contentWrapperClass}>
					<button
						className={checkboxClass({ isCompleted })}
						type="button"
						onClick={handleCheckboxClick}
					>
						{isCompleted && <span className={checkIconClass({ isCompleted })}>✓</span>}
						<span className={srOnlyClass}>
							{isCompleted ? "Abgeschlossen" : "Nicht abgeschlossen"}
						</span>
					</button>
					<div className={contentClass}>
						<P3Span>
							<time dateTime={remindAt}>{formatDateTime(remindAt)}</time>
						</P3Span>
						<H3Span className={titleClass}>{content}</H3Span>
					</div>
				</div>
				<button
					type="button"
					onClick={handleContextMenuOnClick}
					className={contextMenuButtonClass}
				>
					<Icon icon="contextMenu" />
				</button>

				<ResponsiveBottomSheetOverlayPanel ref={contextMenuOverlayRef} padding={spacing0}>
					<div className={contextMenuContentClass}>
						{!isCompleted && (
							<button
								type="button"
								onClick={handleUpsertReminderModalOpen}
								className={contextMenuItemClass({
									color: "default",
								})}
							>
								<Icon icon="pencil" />
								Bearbeiten
							</button>
						)}
						<button
							type="button"
							onClick={handleDelete}
							className={contextMenuItemClass({
								color: "error",
							})}
						>
							<Icon icon="trash" color={error100} />
							Löschen
						</button>
					</div>
				</ResponsiveBottomSheetOverlayPanel>
			</div>
		</>
	);
};
