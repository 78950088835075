import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { ValidatedInputText } from "@thekeytechnology/academies-lib-webapp/components/validated-input-text";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { ButtonType, InputTextType } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import { colorSecondary120Class } from "@themes/color-classes";
import { H1Span, P2Span, P3Span } from "@themes/font-tags";
import {
	formWrapperClass,
	inputsWrapperClass,
	invitationTitleWrapperClass,
	textCenterClass,
	textRightClass,
} from "./login-email-password.styles";
import {
	type LoginEmailPasswordFormState,
	type LoginEmailPasswordProps,
} from "./login-email-password.types";

export const LoginEmailPassword = ({
	headerText,
	onSubmit,
	onGoToSignUp,
	onGoToForgotPassword,
}: LoginEmailPasswordProps) => {
	const { t } = useTkaTranslation("loginForm");

	const form = useFormik<LoginEmailPasswordFormState>({
		initialValues: {
			email: "",
			password: "",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email(t("login_form.validationInvalidEmail") ?? "")
				.required(t("login_form.validationRequiredEmail") ?? ""),
			password: Yup.string()
				.min(8, t("login_form.validationInvalidPassword") ?? "")
				.required(t("login_form.validationRequiredPassword") ?? ""),
		}),
		onSubmit: (values) => {
			onSubmit?.(values);
		},
	});

	return (
		<form onSubmit={form.handleSubmit}>
			<div className={formWrapperClass}>
				<div className={textCenterClass}>
					<div className={invitationTitleWrapperClass}>
						{headerText && <P2Span>{headerText}</P2Span>}
						<H1Span>{t("login_form.title")}</H1Span>
					</div>
					<H1Span>{t("login_form.title2")}</H1Span>
				</div>
				<div className={inputsWrapperClass}>
					<ValidatedInputText
						formikConfig={form}
						icon="email"
						name="email"
						placeholder={t("login_form.placeholderEmail")}
					/>
					<ValidatedInputText
						formikConfig={form}
						bottomLabel={t("login_form.helperTextPassword")}
						icon="lock"
						name="password"
						placeholder={t("login_form.placeholderPassword")}
						type={InputTextType.Password}
					/>
					<div className={textRightClass}>
						<P3Span className={colorSecondary120Class} onClick={onGoToForgotPassword}>
							{t("login_form.forgotPassword")}
						</P3Span>
					</div>
				</div>

				<Button type={ButtonType.Submit} label={t("login_form.buttonText")} fillParent />

				<div className={textCenterClass}>
					<P3Span>
						{t("login_form.newHere")}&nbsp;
						<P3Span className={colorSecondary120Class} onClick={onGoToSignUp}>
							{t("login_form.createAccount")}
						</P3Span>
					</P3Span>
				</div>
			</div>
		</form>
	);
};
