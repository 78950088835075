import { removeGraphqlPrefixFromId } from "./id-converter.utils";

export const isBonusCourse = (courseId?: string): boolean => {
	if (!courseId) return false;
	return (
		atob(removeGraphqlPrefixFromId(courseId)) === "Node:7a326f27-7fd0-40a3-a500-825f7c8183a6"
	);
};

export const canSeeBonusModule = (moduleIndex: number, userLevel: number): boolean => {
	const fixedLevels = [15, 20, 23, 26];

	if (moduleIndex < fixedLevels.length) {
		return userLevel >= fixedLevels[moduleIndex];
	}

	const requiredLevel = 26 + (moduleIndex - 3) * 2;

	return userLevel >= requiredLevel;
};

export const getHighestBonusModuleIndexCappedAt = (userLevel: number, cappedAt: number): number => {
	let highestBonusModuleIndex = 0;
	for (let i = 0; i < cappedAt; i++) {
		if (canSeeBonusModule(i, userLevel)) {
			highestBonusModuleIndex = i;
		}
	}
	return highestBonusModuleIndex;
};
