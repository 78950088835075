import { graphql } from "react-relay";

export const ACTIVE_E_LEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT = graphql`
	fragment reminderElement_ActiveELearningContentSubmissionDefinitionFragment on ActiveELearningContentSubmissionDefinition {
		currentElementState {
			element {
				id
				title
				...reminderLearnElement_ReminderLearnElementFragment
			}
		}
	}
`;
