import * as Sentry from "@sentry/react";
import { AccountsDropdown } from "@thekeytechnology/academies-lib-webapp/components/accounts-dropdown";
import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { UserAvatar } from "@thekeytechnology/academies-lib-webapp/components/user-avatar";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { useAuthContext } from "@thekeytechnology/academies-lib-webapp/hooks/use-auth-context";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { MouseEvent, useState } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CogSettingsSvg } from "@assets/cog-settings.svg";
import { ReactComponent as DataPrivacySvg } from "@assets/dataprivacy.svg";
import { ReactComponent as KeyMedalSvg } from "@assets/key-medal-points.svg";
import { ReactComponent as LegalScaleSvg } from "@assets/legal-scale.svg";
import { ReactComponent as ImprintSvg } from "@assets/square.svg";
import { ReactComponent as TeamworkSvg } from "@assets/teamwork.svg";
import { DividerList } from "@components/divider-list";
import { rowWrapperClass } from "@components/profile-menu-row/profile-menu-row.styles";
import { ReportModalFlow } from "@components/report-modal-flow";
import { profileMenu_AuthViewerSchemaFragment$key } from "@relay/profileMenu_AuthViewerSchemaFragment.graphql";
import { profileMenu_Query } from "@relay/profileMenu_Query.graphql";
import { Path } from "@router/paths";
import { colorSecondary120Class, colorWarning100Class } from "@themes/color-classes";
import { H1Span, H3Span, P2Span } from "@themes/font-tags";
import { AUTH_VIEWER_SCHEMA_FRAGMENT, QUERY } from "./profile-menu.graphql";
import { ProfileMenuSkeleton } from "./profile-menu.skeleton";
import {
	avatarWrapperClass,
	currentUserWrapperClass,
	menuWrapperClass,
	pointsWrapperClass,
	userNameWrapperClass,
} from "./profile-menu.styles";
import { ProfileMenuProps } from "./profile-menu.types";
import { DATA_PROTECTION_URL, IMPRINT_URL } from "../../consts";
import { ProfileMenuRow } from "../profile-menu-row";

export const ProfileMenuComponent = ({
	onGrayBackground,
	onNavigated,
	onClose,
}: ProfileMenuProps) => {
	const query = useLazyLoadQuery<profileMenu_Query>(QUERY, {});

	const auth = useFragment<profileMenu_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		query.Viewer.Auth,
	);

	const navigate = useNavigate();
	const { t } = useTkaTranslation("navbar");
	const { logout } = useAuthContext();
	const [isShowingCreateReportFlow, setIsShowingCreateReportFlow] = useState(false);
	const accounts = auth?.currentUser?.accounts ?? [];

	const handleLogoutOnClick = (event: MouseEvent<HTMLElement>) => {
		logout();
		Sentry.setUser(null);
		onNavigated?.(event);
	};
	const handleCreateReportOnClick = (_: MouseEvent<HTMLElement>) => {
		setIsShowingCreateReportFlow(true);
	};
	const handleDismissModalFlowOnClick = () => {
		setIsShowingCreateReportFlow(false);
	};

	const createMenuItemOnClickHandler = (path: string) => (event: MouseEvent<HTMLElement>) => {
		navigate(path);
		onNavigated?.(event);
	};

	const handleOpenCookieBannerOnClick = () => {
		if (!window.Cookiebot) return;
		window.Cookiebot.show();
	};

	const handleImprintOnClick = () => {
		window.open(IMPRINT_URL, "_blank", "noopener noreferrer");
	};

	const handleDataProtectionOnClick = () => {
		window.open(DATA_PROTECTION_URL, "_blank", "noopener noreferrer");
	};

	const handleOnAccountSelected = () => {
		onClose?.();
	};

	const name = auth?.currentUser?.user?.name;
	const hasMoreThenOneAccount = accounts.length > 1;
	const points = auth?.currentUser?.user.extension?.sumGamificationPoints ?? 0;

	return (
		<>
			<ReportModalFlow
				isVisible={isShowingCreateReportFlow}
				onDismiss={handleDismissModalFlowOnClick}
			/>
			<div className={menuWrapperClass}>
				{name && (
					<button
						type="button"
						onClick={createMenuItemOnClickHandler(Path.profileMenu.profile.path)}
						className={currentUserWrapperClass}
					>
						<div className={avatarWrapperClass}>
							<UserAvatar userFragmentRef={auth.currentUser.user} sizeInRem={5} />
							<div
								className={pointsWrapperClass({
									background: onGrayBackground ? "white" : "gray",
								})}
							>
								<H3Span className={colorWarning100Class}>{points}</H3Span>
								<KeyMedalSvg />
							</div>
						</div>
						<div className={userNameWrapperClass}>
							<H1Span>{name}</H1Span>
							<P2Span className={colorSecondary120Class}>Profil ansehen</P2Span>
						</div>
					</button>
				)}
				<DividerList hasDivider gapRem={1}>
					{hasMoreThenOneAccount && auth?.currentUser && (
						<AccountsDropdown
							label="Team"
							onAccountSelected={handleOnAccountSelected}
							currentUserFragmentRef={auth.currentUser}
						/>
					)}
					<ProfileMenuRow
						icon={<TeamworkSvg />}
						label="Team & Lizenzen"
						onClick={createMenuItemOnClickHandler(Path.profileMenu.account.path)}
					/>
					<ProfileMenuRow
						icon={<CogSettingsSvg />}
						label="Cookie-Einstellungen öffnen"
						onClick={handleOpenCookieBannerOnClick}
					/>
					<ProfileMenuRow icon={<LegalScaleSvg />} label="Impressum und Datenschutz">
						<ProfileMenuRow
							icon={<ImprintSvg />}
							label="Impressum"
							onClick={handleImprintOnClick}
						/>
						<ProfileMenuRow
							icon={<DataPrivacySvg />}
							label="Datenschutz"
							onClick={handleDataProtectionOnClick}
						/>
					</ProfileMenuRow>
					<div className={rowWrapperClass({ isDualRow: true })}>
						<ProfileMenuRow
							iconName={"question"}
							label={"Inhalte melden & Feedback"}
							onClick={handleCreateReportOnClick}
							isShort
						/>
						<Button
							colorVersion={"error"}
							label={t("navbar.logout")}
							onClick={handleLogoutOnClick}
							iconName={"logout"}
						/>
					</div>
				</DividerList>
			</div>
		</>
	);
};

export const ProfileMenu = withSuspense(ProfileMenuComponent, ProfileMenuSkeleton);
