import { css, cva } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	flex: "1",
	direction: "column",
	xl: {
		overflow: "hidden",
		pb: "0",
	},
});

export const sideMenuClass = cva({
	base: {
		flex: "1",
		paddingBottom: "24",
		gridTemplateRows: "[1fr min-content]",
		xl: {
			overflow: "hidden",
			paddingBottom: "0",
		},
	},
	variants: {
		noPadding: {
			true: {
				paddingTop: "0",
				paddingLeft: "0",
				paddingRight: "0",
				paddingBottom: "0",
				md: {
					paddingTop: "0",
					paddingLeft: "0",
					paddingRight: "0",
					paddingBottom: "0",
				},
				xl: {
					paddingTop: "0",
					paddingLeft: "0",
					paddingRight: "0",
					paddingBottom: "0",
				},
			},
		},
		hasBottomContent: {
			true: {
				md: {
					paddingTop: "0",
					paddingBottom: "40",
				},
			},
		},
	},
});

export const sideMenuContentClass = cva({
	base: {
		xl: {
			overflowY: "hidden",
			p: "24",
		},
	},
	variants: {
		noPadding: {
			true: {
				p: "0",
				md: {
					p: "0",
				},
				xl: {
					p: "0",
				},
			},
		},
		hasBottomContent: {
			true: {
				gridRowStart: "1",
				gridRowEnd: "3",
			},
		},
	},
});

export const childrenWrapperClass = cva({
	base: {
		display: "flex",
		flexDirection: "column",
		borderTopRadius: "24",
		bg: "shade.0",
		md: {
			borderRadius: "24",
			overflowY: "auto",
		},
	},
	variants: {
		noPadding: {
			true: {
				p: "0",
				maxWidth: "[none]",
				md: {
					p: "0",
				},
				xl: {
					p: "0",
				},
			},
		},
		hasBottomContent: {
			true: {
				gridRow: "[1 / 2]",
			},
		},
		isHeaderVisible: {
			true: {
				minHeight: "[calc(100dvh - 4rem - 5.5rem)]",
				maxHeight: "[calc(100dvh - 4rem - 5.5rem)]",
				md: {
					minHeight: "[calc(100dvh - 4rem - 2.5rem)]",
					maxHeight: "[calc(100dvh - 4rem - 2.5rem)]",
				},
				xl: {
					minHeight: "[calc(100dvh - 4rem - 3.5rem)]",
					maxHeight: "[calc(100dvh - 4rem - 3.5rem)]",
				},
			},
			false: {
				minHeight: "[calc(100dvh - 5.5rem)]",
				maxHeight: "[calc(100dvh - 5.5rem)]",
				md: {
					minHeight: "[calc(100dvh - 2.5rem)]",
					maxHeight: "[calc(100dvh - 2.5rem)]",
				},
				xl: {
					minHeight: "[calc(100dvh - 3.5rem)]",
					maxHeight: "[calc(100dvh - 3.5rem)]",
				},
			},
		},
		isContentSubmission: {
			true: {
				minHeight: "[calc(100dvh - 4rem)]",
				maxHeight: "[calc(100dvh - 4rem)]",
				md: {
					minHeight: "[calc(100dvh - 4rem)]",
					maxHeight: "[calc(100dvh - 4rem)]",
				},
				xl: {
					minHeight: "[calc(100dvh - 4rem)]",
					maxHeight: "[calc(100dvh - 4rem)]",
				},
			},
		},
	},
	compoundVariants: [
		{
			hasBottomContent: true,
			isHeaderVisible: true,
			css: {
				minHeight: "[calc(100dvh - 4rem - 1.5rem - var(--bottom-content-height))]",
				maxHeight: "[calc(100dvh - 4rem - 1.5rem - var(--bottom-content-height))]",
				md: {
					minHeight: "[calc(100dvh - 4rem - 2.5rem - var(--bottom-content-height))]",
					maxHeight: "[calc(100dvh - 4rem - 2.5rem - var(--bottom-content-height))]",
				},
				xl: {
					minHeight: "[calc(100dvh - 4rem - 3.5rem - var(--bottom-content-height))]",
					maxHeight: "[calc(100dvh - 4rem - 3.5rem - var(--bottom-content-height))]",
				},
			},
		},
		{
			hasBottomContent: true,
			isHeaderVisible: false,
			css: {
				minHeight: "[calc(100dvh - 1.5rem - var(--bottom-content-height))]",
				maxHeight: "[calc(100dvh - 1.5rem - var(--bottom-content-height))]",
				md: {
					minHeight: "[calc(100dvh - 2.5rem - var(--bottom-content-height))]",
					maxHeight: "[calc(100dvh - 2.5rem - var(--bottom-content-height))]",
				},
				xl: {
					minHeight: "[calc(100dvh - 3.5rem - var(--bottom-content-height))]",
					maxHeight: "[calc(100dvh - 3.5rem - var(--bottom-content-height))]",
				},
			},
		},
		{
			isContentSubmission: true,
			hasBottomContent: true,
			isHeaderVisible: true,
			css: {
				position: "relative",
				minHeight: "[calc(100dvh - 2px - var(--bottom-content-height))]",
				maxHeight: "[calc(100dvh - 2px - var(--bottom-content-height))]",
				pb: "[73px]",
				md: {
					pb: "[73px]",
					minHeight: "[calc(100dvh - var(--bottom-content-height))]",
					maxHeight: "[calc(100dvh - var(--bottom-content-height))]",
				},
				xl: {
					pb: "0",
					minHeight: "[calc(100dvh - 4rem - 3.5rem - var(--bottom-content-height))]",
					maxHeight: "[calc(100dvh - 4rem - 3.5rem - var(--bottom-content-height))]",
				},
			},
		},
	],
});

export const bottomContentWrapperClass = css({
	p: "0",
	maxWidth: "[none]",
	zIndex: "40",
	position: "fixed",
	bottom: "0",
	left: "0",
	right: "0",
	xl: {
		bottom: "24",
		position: "relative",
		gridRowStart: "2",
		gridRowEnd: "3",
		display: "flex",
		justifyContent: "center",
	},
});
