import { stack } from "@styled-system/patterns";

export const formWrapperClass = stack({
	gap: "32",
});

export const invitationTitleWrapperClass = stack({
	alignItems: "center",
	justifyContent: "center",
	textAlign: "center",
	gap: "12",
});

export const textCenterClass = stack({
	alignItems: "center",
	justifyContent: "center",
	textAlign: "center",
	gap: "0",
});

export const textRightClass = stack({
	alignItems: "flex-end",
	textAlign: "right",
	gap: "0",
});

export const inputsWrapperClass = stack({
	gap: "12",
});
