import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { H1Span, P1Span } from "@themes/font-tags";
import { Wrapper, TextCenter } from "./password-reset-success-step.styles";
import { type PasswordResetSuccessStepProps } from "./password-reset-success-step.types";

export const PasswordResetSuccessStep = ({ onGoToLogin }: PasswordResetSuccessStepProps) => {
	const { t } = useTkaTranslation("resetPasswordForm");

	return (
		<Wrapper>
			<TextCenter>
				<H1Span>{t("reset_password_form.successTitle")}</H1Span>
				<P1Span>{t("reset_password_form.successBody")}</P1Span>
			</TextCenter>
			<Button
				fillParent
				label={t("reset_password_form.successButtonText")}
				iconName="arrowRight"
				onClick={onGoToLogin}
			/>
		</Wrapper>
	);
};
