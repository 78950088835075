/**
 * @generated SignedSource<<ab2e6f8244fd5ef0d3591871093d225d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type textWithPagesElement_ActiveElearningContentSubmissionDefinitionFragment$data = {
  readonly currentElementState: {
    readonly element: {
      readonly id: string;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"textWithPagesLearnElement_TextWithPagesLearnElementFragment">;
    };
  } | null | undefined;
  readonly " $fragmentType": "textWithPagesElement_ActiveElearningContentSubmissionDefinitionFragment";
};
export type textWithPagesElement_ActiveElearningContentSubmissionDefinitionFragment$key = {
  readonly " $data"?: textWithPagesElement_ActiveElearningContentSubmissionDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"textWithPagesElement_ActiveElearningContentSubmissionDefinitionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "textWithPagesElement_ActiveElearningContentSubmissionDefinitionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "currentElementState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "element",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "textWithPagesLearnElement_TextWithPagesLearnElementFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ActiveELearningContentSubmissionDefinition",
  "abstractKey": null
};

(node as any).hash = "43f4d52cf86c36f87a7237bcd5e0fbd5";

export default node;
