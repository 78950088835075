import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import React, { useCallback, useContext, useEffect } from "react";
import { readInlineData, useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { EducationalOfferCard } from "@components/educational-offer-card";
import { gridView_PublishedLearnableFragment$key } from "@relay/gridView_PublishedLearnableFragment.graphql";
import { gridView_Query } from "@relay/gridView_Query.graphql";
import { gridView_QueryFragment$key } from "@relay/gridView_QueryFragment.graphql";
import { gridViewRefetchQuery } from "@relay/gridViewRefetchQuery.graphql";
import { GridViewSkeleton } from "./grid-view.skeleton";
import { gridClass, gridItemClass, nextButtonWrapperClass, wrapperClass } from "./grid-view.styles";
import { IOffersScreenContext, OffersScreenContext } from "../../offers.context";
import {
	PUBLISHED_LEARNABLE_INLINE_FRAGMENT,
	QUERY,
	QUERY_FRAGMENT,
} from "../grid-view/grid-view.graphql";
import { Tags } from "../tags";

const GridViewComponent = () => {
	const query = useLazyLoadQuery<gridView_Query>(
		QUERY,
		{ kinds: ["Course"], tagIds: [], first: 12 },
		{ fetchPolicy: "store-and-network" },
	);

	const {
		helpers: { addOnFilterChangeListener },
	} = useContext(OffersScreenContext);
	const { data, refetch, hasNext, loadNext, isLoadingNext } = usePaginationFragment<
		gridViewRefetchQuery,
		gridView_QueryFragment$key
	>(QUERY_FRAGMENT, query);

	const learnables = data.Learnable.PublishedLearnables.edges!.map((e) =>
		readInlineData<gridView_PublishedLearnableFragment$key>(
			PUBLISHED_LEARNABLE_INLINE_FRAGMENT,
			e!.node,
		),
	);

	const handleOnFilterChange = useCallback(
		(filters: IOffersScreenContext["filters"]) => {
			refetch({
				...filters,
			});
		},
		[refetch],
	);

	useEffect(() => addOnFilterChangeListener(handleOnFilterChange), [handleOnFilterChange]);

	const handleLoadNext = () => {
		loadNext(12);
	};

	return (
		<div className={wrapperClass}>
			<Tags queryFragmentRef={data} />
			<div className={gridClass}>
				{learnables.map((o) => (
					<div key={o.id} className={gridItemClass}>
						{o.kind === "Course" && (
							<EducationalOfferCard publishedCourseLearnableFragmentRef={o} />
						)}
					</div>
				))}
			</div>
			{hasNext && (
				<div className={nextButtonWrapperClass}>
					<Button
						type="button"
						onClick={handleLoadNext}
						loading={isLoadingNext}
						label="Weitere Angebote laden"
					/>
				</div>
			)}
		</div>
	);
};

export const GridView = withSuspense(GridViewComponent, GridViewSkeleton);
