import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { selectIsLoggedIn } from "@thekeytechnology/academies-lib-webapp/slices";
import { formatCurrency } from "@thekeytechnology/academies-lib-webapp/utils";
import { PathParams } from "@thekeytechnology/epic-ui";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { gtmTrackAddShippingInfo } from "@analytics/google-tag-manager";
import { PaymentDurationRadioButtonTemplate } from "@components/payment-duration-radio-button-template";
import { RadioButtonPanel } from "@components/radio-button-panel";
import {
	ShoppingCartTemplate,
	ShoppingCartTemplateSkeleton,
} from "@components/shopping-cart-template";
import { shoppingCartPaymentMethod_ChangePaymentMethodMutation } from "@relay/shoppingCartPaymentMethod_ChangePaymentMethodMutation.graphql";
import {
	SelectedPaymentMethodKind,
	shoppingCartPaymentMethod_OrderFragment$key,
} from "@relay/shoppingCartPaymentMethod_OrderFragment.graphql";
import { shoppingCartPaymentMethod_Query } from "@relay/shoppingCartPaymentMethod_Query.graphql";
import { Path, ShoppingCartPath } from "@router/paths";
import { H1Span, H2Span, P2Span } from "@themes/font-tags";
import { SURCHARGE_RATE_COUNT } from "./shopping-cart-payment-method.consts";
import {
	CHANGE_PAYMENT_METHOD_MUTATION,
	ORDER_FRAGMENT,
	QUERY,
} from "./shopping-cart-payment-method.graphql";
import {
	monthlyOptionsWrapperClass,
	optionsWrapperClass,
	radioHeaderWrapperClass,
	screenWrapperClass,
	textRightClass,
} from "./shopping-cart-payment-method.styles";
import { StateChange } from "./shopping-cart-payment-method.types";
import {
	getCartDiscountItems,
	getCartLimitedDiscountItems,
	getProductItems,
} from "./shopping-cart-payment-method.utils";

//TODO: add-translations
export const ShoppingCartPaymentMethodScreenComponent = () => {
	const { shoppingCartId } = useParams<PathParams<typeof ShoppingCartPath>>();

	const {
		node,
		Viewer: { Auth },
	} = useLazyLoadQuery<shoppingCartPaymentMethod_Query>(
		QUERY,
		{
			orderId: shoppingCartId ?? "",
			skip: !shoppingCartId,
		},
		{ fetchPolicy: "store-and-network" },
	);

	const order = useFragment<shoppingCartPaymentMethod_OrderFragment$key>(ORDER_FRAGMENT, node!);

	const [changePaymentMethod, isChangingPaymentMethod] =
		useMutation<shoppingCartPaymentMethod_ChangePaymentMethodMutation>(
			CHANGE_PAYMENT_METHOD_MUTATION,
		);

	const isLoggedIn = useSelector(selectIsLoggedIn);

	const navigate = useNavigate();

	const [paymentType, setPaymentType] = useState<SelectedPaymentMethodKind>(
		order.selectedPaymentMethod?.kind ?? "OneTime",
	);
	const [paymentDuration, setPaymentDuration] = useState<number | undefined>(
		order.selectedPaymentMethod?.chosenOption?.rateCount ?? 48,
	);

	const cart = order?.cart;
	const monthlyOptions = useMemo(
		() => cart?.totals.monthlyOptions ?? [],
		[cart?.totals.monthlyOptions],
	);
	const monthlyType = order.allowedPaymentMethods.paymentMethods.find(
		(type) => type === "MonthlyTk" || type === "MonthlyPartner",
	);
	const hasMonthlyPayment = !!monthlyType;

	const handleUpdatePaymentMethod = (change: StateChange, onCompleted?: () => void) => {
		if (!shoppingCartId) return;
		const usePaymentType = change.paymentType ?? paymentType;
		const isMonthly = usePaymentType === "Monthly";
		const usePaymentDuration = change.paymentDuration ?? paymentDuration;
		const defaultMethodType = order.allowedPaymentMethods.paymentMethods.includes("Card")
			? "Card"
			: order.allowedPaymentMethods.paymentMethods[0];
		const paymentMethodType = isMonthly && monthlyType ? monthlyType : defaultMethodType;
		const monthlyOptionByRateCount = isMonthly ? usePaymentDuration : undefined;
		if (isMonthly && !monthlyOptionByRateCount) return;

		changePaymentMethod({
			variables: {
				input: {
					orderId: shoppingCartId,
					selectedPaymentMethodKind: usePaymentType,
					paymentMethodType,
					rateCount: monthlyOptionByRateCount,
				},
			},
			onCompleted,
		});
	};

	const handleMontlyOnChange = () => {
		setPaymentType("Monthly");
		handleUpdatePaymentMethod({ paymentType: "Monthly" });
	};

	const handleOnetimeOnChange = () => {
		setPaymentType("OneTime");
		handleUpdatePaymentMethod({ paymentType: "OneTime" });
	};

	const handleNextOnClick = () => {
		handleUpdatePaymentMethod({}, () => {
			shoppingCartId && navigate(Path.shoppingCart.withId(shoppingCartId).overview.path);
		});
	};

	const handlePreviousOnClick = () => {
		navigate(-1);
	};

	useEffect(() => {
		if (!isLoggedIn) {
			navigate(Path.shoppingCart.withId(order.id).invoice.path);
		}
	}, [isLoggedIn]);

	useEffect(() => {
		const cartDiscounts = getCartDiscountItems(order.cart) ?? [];
		const cartLimitedDiscounts = getCartLimitedDiscountItems(order.cart) ?? [];
		const discounts = [...cartLimitedDiscounts, ...cartDiscounts];

		const discountsString = discounts
			.map((discount) => {
				return `${discount.code}_${discount.value}%`;
			})
			.join(",");

		gtmTrackAddShippingInfo({
			currency: "EUR",
			value: order.cart?.totals.includingAllDiscounts.grossPrice ?? 0,
			coupon: discountsString,
			items:
				getProductItems(order.cart)?.map((item) => {
					const rootInfo = item?.product?.data?.licenseDefinition?.data?.rootInfo;

					return {
						itemId: rootInfo?.rootId!,
						itemName: rootInfo?.title ?? "",
						itemCategory: "",
						price: item?.product?.netPrice ?? 0,
						quantity: item?.amount ?? 0,
					};
				}) ?? [],
		});
	}, []);

	const createMonthlyDurationOnChangeHandler = (duration: number) => () => {
		setPaymentDuration(duration);
		handleUpdatePaymentMethod({ paymentDuration: duration });
	};

	const sortedMonthlyOptions = useMemo(
		() =>
			monthlyOptions
				.slice()
				.sort((a, b) => a.monthlyRate.grossPrice - b.monthlyRate.grossPrice),
		[monthlyOptions],
	);

	const smallestMonthlyOption = sortedMonthlyOptions?.length
		? sortedMonthlyOptions[0]
		: undefined;

	return (
		<ShoppingCartTemplate
			disableNext={isChangingPaymentMethod}
			onNext={handleNextOnClick}
			onPrevious={handlePreviousOnClick}
			authViewerSchemaFragmentRef={Auth}
			orderFragmentRef={order}
		>
			<div data-no-selection-menu className={screenWrapperClass}>
				<H1Span>Bezahlmethode</H1Span>
				<div className={optionsWrapperClass}>
					<H2Span>Wie möchtest du bezahlen?</H2Span>
					{hasMonthlyPayment && (
						<RadioButtonPanel
							checked={paymentType === "Monthly"}
							header={
								<div className={radioHeaderWrapperClass}>
									<P2Span>Monatliche Zahlung</P2Span>
									<div className={textRightClass}>
										<H2Span>
											ab&nbsp;
											{formatCurrency(
												smallestMonthlyOption?.monthlyRate.grossPrice,
											)}
											/ Monat
										</H2Span>
									</div>
								</div>
							}
							onChange={handleMontlyOnChange}
						>
							<div className={monthlyOptionsWrapperClass}>
								{sortedMonthlyOptions?.map((option) => (
									<RadioButtonPanel
										key={option.rateCount}
										checked={paymentDuration === option.rateCount}
										header={
											<PaymentDurationRadioButtonTemplate
												durationMonths={option.rateCount}
												grossPrice={option.totalAmount.grossPrice}
												monthlyRate={option.monthlyRate.grossPrice}
												hasSurcharge={
													option.rateCount >= SURCHARGE_RATE_COUNT
												}
											/>
										}
										onChange={createMonthlyDurationOnChangeHandler(
											option.rateCount,
										)}
									/>
								))}
							</div>
						</RadioButtonPanel>
					)}
					<RadioButtonPanel
						checked={paymentType === "OneTime"}
						header={
							<div className={radioHeaderWrapperClass}>
								<P2Span>Einmalzahlung</P2Span>
								<H2Span>
									{formatCurrency(cart?.totals.includingAllDiscounts.grossPrice)}
								</H2Span>
							</div>
						}
						onChange={handleOnetimeOnChange}
					/>
				</div>
			</div>
		</ShoppingCartTemplate>
	);
};

export const ShoppingCartPaymentMethodScreen = withSuspense(
	ShoppingCartPaymentMethodScreenComponent,
	ShoppingCartTemplateSkeleton,
);
