import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { useMemo } from "react";
import { useFragment } from "react-relay";
import { colorShade60Class } from "@themes/color-classes";
import { P3Span } from "@themes/font-tags";
import {
	getBranchNodeNumberFromIndexPath,
	getContentNodeNumberFromIndexPath,
	isAfterIndexPath,
	isEqualIndexPath,
} from "@utils/index-path.util";
import { LEARN_OPPORTUNITY_V2_FRAGMENT } from "./materials-tree.graphql";
import { activeContentWrapperClass, noAttachmentsWrapperClass } from "./materials-tree.styles";
import { type BranchNode, type ContentNode, type MaterialsTreeProps } from "./materials-tree.types";
import { DividerList } from "../divider-list";
import { MaterialCard } from "../material-card";
import { MaterialsBranchNodeCard } from "../materials-branch-node-card";

// TODO: add-translations
export const MaterialsTree = ({
	activeAttachmentId,
	onAttachmentOnClick,
	learnOpportunityV2FragmentRef,
}: MaterialsTreeProps) => {
	const { t } = useTkaTranslation("modulesAndLessons");

	const {
		typeDefinition: { children: branches },
		structureDefinition,
	} = useFragment(LEARN_OPPORTUNITY_V2_FRAGMENT, learnOpportunityV2FragmentRef ?? null);

	const { isXLargeUp } = useWindowSize();

	const headContentId = structureDefinition.viewerTreeState?.headContentId;

	const isStarted = structureDefinition.viewerTreeState?.kind === "IsStarted";
	const isFinished = structureDefinition.viewerTreeState?.kind === "IsFinished";
	const unlockInfoKind = structureDefinition.extension?.unlockInfo?.kind;
	const hasFullAccess = unlockInfoKind === "FullAccess" || unlockInfoKind === "WallNotFound";

	const headIndexPath: readonly number[] | undefined = useMemo(() => {
		let indexPath: readonly number[] | undefined;
		branches?.forEach((branch) => {
			const foundContent = branch.typeDefinition?.children?.find(
				(content) => content.id === headContentId,
			);
			if (foundContent) {
				indexPath = foundContent.indexPath;
			}
		});
		return indexPath;
	}, [headContentId, branches]);

	const renderContentMaterials = (node: ContentNode) => {
		const isUnlocked =
			hasFullAccess &&
			((isStarted &&
				(isAfterIndexPath(node.indexPath, headIndexPath) ||
					isEqualIndexPath(node.indexPath, headIndexPath))) ||
				isFinished);

		const branchNumber = getBranchNodeNumberFromIndexPath(node.indexPath);
		const contentNumber = getContentNodeNumberFromIndexPath(node.indexPath);

		const title = `${t("modules_and_lessons.lesson")} ${branchNumber}.${contentNumber}`;

		const createAttachmentOnClickHandler = (attachmentId: string) => () => {
			onAttachmentOnClick?.(attachmentId);
		};

		return node.attachmentsV2.map((attachment) => (
			<div
				key={attachment.id}
				className={activeContentWrapperClass({
					active: activeAttachmentId === attachment.id,
				})}
			>
				<MaterialCard
					key={attachment.id}
					disabled={!isUnlocked}
					isExpanded
					noBorder={activeAttachmentId === attachment.id && isXLargeUp}
					icon="dataFileBarsDownload"
					title={title}
					description={attachment.title ?? ""}
					fileUrl={attachment.file?.url}
					fileName={attachment.file?.name}
					onClick={createAttachmentOnClickHandler(attachment.id)}
				/>
			</div>
		));
	};

	const renderBranchNodeCard = (node: BranchNode) => {
		const renderAttachments = node.typeDefinition?.children?.map(renderContentMaterials);
		return (
			<MaterialsBranchNodeCard
				key={node.id}
				branchNumber={getBranchNodeNumberFromIndexPath(node.indexPath)}
				branchName={node.structureDefinition.title}
			>
				{renderAttachments}
			</MaterialsBranchNodeCard>
		);
	};

	const branchesWithAttachments = branches?.filter((branch) =>
		branch?.typeDefinition?.children?.some((child) => child.attachmentsV2.length > 0),
	);
	const hasBranchesWithAttachments = !!branchesWithAttachments?.length;

	return hasBranchesWithAttachments ? (
		<DividerList hasDivider>{branchesWithAttachments?.map(renderBranchNodeCard)}</DividerList>
	) : (
		<div className={noAttachmentsWrapperClass}>
			<P3Span className={colorShade60Class}>Keine Materialien in diesem Kurs</P3Span>
		</div>
	);
};
