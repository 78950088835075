import { css } from "@styled-system/css";
import { vstack } from "@styled-system/patterns";

export const wrapperClass = css({
	paddingTop: "24",
	paddingBottom: "32",
	paddingX: "24",
	height: "full",
	overflowY: "auto",
});

export const userHeaderClass = css({
	mb: "40",
});

export const boxesClass = vstack({
	gap: "24",
});

export const buttonsWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "24",
	marginTop: "24",
	md: {
		gap: "40",
		marginTop: "40",
	},
});

export const seperatorClass = css({
	borderTop: "1px solid",
	borderColor: "shade.10",
	width: "full",
});
