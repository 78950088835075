import { css } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const childrenWrapperClass = stack({
	height: "full",
	overflowY: "auto",
	padding: "var(--padding)",
});

export const closeIconWrapperClass = css({
	position: "absolute",
	top: "16",
	right: "16",
});
