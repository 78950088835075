import { graphql } from "react-relay";

export const QUERY = graphql`
	query notesOverview_Query(
		$includeNoteSourceKinds: [NoteSourceKind!]
		$includeNoteColors: [NoteColor!]
		$noteColorToSortBy: NoteColor
		$filterByText: String
		$first: Int
		$after: String
	) {
		...notesOverview_QueryFragment
			@arguments(
				includeNoteSourceKinds: $includeNoteSourceKinds
				includeNoteColors: $includeNoteColors
				noteColorToSortBy: $noteColorToSortBy
				filterByText: $filterByText
				first: $first
				after: $after
			)
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment notesOverview_QueryFragment on Query
	@argumentDefinitions(
		includeNoteSourceKinds: { type: "[NoteSourceKind!]", defaultValue: null }
		includeNoteColors: { type: "[NoteColor!]", defaultValue: null }
		noteColorToSortBy: { type: "NoteColor" }
		filterByText: { type: "String" }
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
	)
	@refetchable(queryName: "notesOverview_RefetchQuery") {
		Note {
			Note(
				hasReminder: false
				includeNoteSourceKinds: $includeNoteSourceKinds
				includeNoteColors: $includeNoteColors
				noteColorToSortBy: $noteColorToSortBy
				filterByText: $filterByText
				first: $first
				after: $after
			) @connection(key: "notesOverview_Note") {
				edges {
					node {
						id
						reminder {
							__typename
						}
						...noteCard_NoteFragment
					}
				}
			}
		}
	}
`;
