import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoSVG } from "@assets/logo-new.svg";
import { AvatarWithAccountSwitch } from "@components/avatar-with-account-switch";
import { Intercom } from "@components/intercom";
import { UserCart } from "@components/user-cart/user-cart.component";
import { header_AuthViewerSchemaFragment$key } from "@relay/header_AuthViewerSchemaFragment.graphql";
import { Path } from "@router/paths";
import { shade0, shade100 } from "@themes/colors";
import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "./header.graphql";
import { headerWrapperClass, leftItemClass, rightItemClass } from "./header.styles";
import { HeaderProps } from "./header.types";

export const Header = ({
	canGoBack,
	leftIcon,
	leftItem,
	onLeftItemClick,
	showAvatar,
	authViewerSchemaFragmentRef,
	showUserCart = true,
}: HeaderProps) => {
	const navigate = useNavigate();

	const auth = useFragment<header_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerSchemaFragmentRef ?? null,
	);

	const handleLeftItemOnClick = () => {
		if (onLeftItemClick) {
			onLeftItemClick();
		} else {
			navigate(-1);
		}
	};

	const handleLogoOnClick = () => {
		navigate(Path.dashboard.path);
	};

	const showLeftItem =
		(canGoBack || leftIcon || leftItem || onLeftItemClick) && canGoBack !== false;

	return (
		<div className={headerWrapperClass}>
			{showLeftItem && (
				<div className={leftItemClass}>
					{leftItem ?? (
						<Icon
							icon={leftIcon ?? "arrowLeft"}
							color={shade100}
							sizeRem={1}
							circleOutlineColor={shade0}
							onClick={handleLeftItemOnClick}
						/>
					)}
				</div>
			)}
			<LogoSVG height={18} onClick={handleLogoOnClick} />
			<div className={rightItemClass}>
				<Intercom />
				{showUserCart && <UserCart />}
				{showAvatar && auth && (
					<AvatarWithAccountSwitch authViewerSchemaFragmentRef={auth} />
				)}
			</div>
		</div>
	);
};
