/**
 * @generated SignedSource<<52c2a6bb36657d64ab3d4487ab07ee44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AsyncElementKind = "Upload";
import { FragmentRefs } from "relay-runtime";
export type asyncContentSubmissionScreen_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly element?: {
      readonly elementKind: AsyncElementKind;
      readonly id: string;
    } | null | undefined;
  };
  readonly id: string;
  readonly learnOpportunity: {
    readonly description: string | null | undefined;
    readonly indexPath: ReadonlyArray<number>;
    readonly structureDefinition: {
      readonly title: string;
    };
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"contentSubmissionNavigation_ContentSubmissionFragment" | "uploadElement_ContentSubmissionFragment">;
  readonly " $fragmentType": "asyncContentSubmissionScreen_ContentSubmissionFragment";
};
export type asyncContentSubmissionScreen_ContentSubmissionFragment$key = {
  readonly " $data"?: asyncContentSubmissionScreen_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"asyncContentSubmissionScreen_ContentSubmissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "element",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "elementKind",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "asyncContentSubmissionScreen_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "learnOpportunity",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "structureDefinition",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "indexPath",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "PassedAsyncContentSubmissionDefinition",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "ActiveAsyncContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "uploadElement_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentSubmissionNavigation_ContentSubmissionFragment"
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "379648ab1983b327caf31dae18ec9390";

export default node;
