import { useState } from "react";
import { graphql, useFragment } from "react-relay";
import { AudioPlayer } from "@components/audio-player";
import { AudioPlayerContext } from "@components/audio-player/audio-player.context";
import { DynamicImageDisplayComponent } from "@components/dynamic-image-display";
import { PodcastWithTimestamp_PodcastWithTimestampElementFragment$key } from "@relay/PodcastWithTimestamp_PodcastWithTimestampElementFragment.graphql";
import { shade100 } from "@themes/colors";
import { PodcastWithTimestampProps } from "./podcast-with-timestamp.interface";
import {
	PlayerWrapper,
	TextWrapper,
	Title,
	Wrapper,
	ImageWrapper,
	ElementWrapper,
} from "./podcast-with-timestamp.styles";

const PODCAST_WITH_TIMESTAMP_ELEMENT_FRAGMENT = graphql`
	fragment PodcastWithTimestamp_PodcastWithTimestampElementFragment on PodcastWithTimestampLearnElement {
		id
		title
		start
		end
		duration
		podcastFile {
			url
		}
		...DynamicImageDisplayComponent_PodcastWithTimestampElementFragment
	}
`;

export const PodcastWithTimestamp = ({
	podcastWithTimestampFragmentRef,
}: PodcastWithTimestampProps) => {
	const podcastWithTimestamp =
		useFragment<PodcastWithTimestamp_PodcastWithTimestampElementFragment$key>(
			PODCAST_WITH_TIMESTAMP_ELEMENT_FRAGMENT,
			podcastWithTimestampFragmentRef,
		);

	const [isPlaying, setIsPlaying] = useState(false);
	const [trackProgress, setTrackProgress] = useState<number>(podcastWithTimestamp.start);

	return (
		<ElementWrapper data-selection-menu-element-id={podcastWithTimestamp?.id}>
			<AudioPlayerContext.Provider
				value={{
					progress: trackProgress,
					isPlaying: isPlaying,
					start: podcastWithTimestamp.start,
					end: podcastWithTimestamp.end,
					duration: podcastWithTimestamp.duration,
					setProgress: (progress: number) => setTrackProgress(Math.trunc(progress)),
					setIsPlaying: setIsPlaying,
				}}
			>
				<Wrapper>
					<PlayerWrapper>
						<ImageWrapper>
							<DynamicImageDisplayComponent
								podcastWithTimestampFragmentRef={podcastWithTimestamp}
							/>
						</ImageWrapper>
						<AudioPlayer url={podcastWithTimestamp.podcastFile?.url ?? null} />
					</PlayerWrapper>
					<TextWrapper>
						<Title tkaColor={shade100}>{podcastWithTimestamp.title}</Title>
					</TextWrapper>
				</Wrapper>
			</AudioPlayerContext.Provider>
		</ElementWrapper>
	);
};
