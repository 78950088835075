import { CircularProgressbar } from "@thekeytechnology/academies-lib-webapp/components/circular-progressbar";
import { formatCurrency } from "@thekeytechnology/academies-lib-webapp/utils";
import { useFragment } from "react-relay";
import { useTheme } from "styled-components";
import { ReactComponent as CheckBadgeSVG } from "@assets/checkBadge-blue.svg";
import { VideoTrailer } from "@components/video-trailer";
import { rootProgress_LearnOpportunityV2Fragment$key } from "@relay/rootProgress_LearnOpportunityV2Fragment.graphql";
import {
	colorPrimary100Class,
	colorShade40Class,
	colorWarning100Class,
} from "@themes/color-classes";
import { H1Span, H3Span, L1Span } from "@themes/font-tags";
import { ProgressUnit } from "./root-progress.const";
import { LEARN_OPPORTUNITY_V2_FRAGMENT } from "./root-progress.graphql";
import { RootProgressProps } from "./root-progress.interface";
import {
	gamificationPointsProgressClass,
	gamificationPointsProgressDividerClass,
	imageClass,
	imageTitleClass,
	imageWrapperClass,
	mainWrapperClass,
	priceWrapperClass,
	progressIsFinishedTextClass,
	progressWrapperClass,
	taglineTitleClass,
	videoTrailerClass,
} from "./root-progress.styles";
import { ReactComponent as KeyMedalSvg } from "../../assets/key-medal-points.svg";

export const RootProgress = ({ hideImage, learnOpportunityV2FragmentRef }: RootProgressProps) => {
	const theme = useTheme();

	const rootData = useFragment<rootProgress_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		learnOpportunityV2FragmentRef,
	);

	const progressValue = rootData?.structureDefinition?.progressPercentage ?? 0;
	const product = rootData?.structureDefinition?.extension?.product;
	const receivedGamificationPoints =
		rootData?.structureDefinition?.extension?.receivedGamificationPoints;
	const receivableGamificationPoints =
		rootData?.structureDefinition?.extension?.reducedData?.receivableGamificationPoints;

	const hasLicense =
		(rootData?.structureDefinition?.extension?.licenseAvailability?.numAvailable ?? 0) > 0;

	const isStarted = rootData?.structureDefinition?.viewerTreeState?.kind === "IsStarted";
	const isFinished = rootData?.structureDefinition?.viewerTreeState?.kind === "IsFinished";

	const trailerVideoUrl = rootData?.structureDefinition?.extension?.trailer?.url;
	const trailerVideoThumbnail = rootData?.structureDefinition?.extension?.trailer?.thumbnail;

	return (
		<div className={mainWrapperClass}>
			<div className={imageTitleClass}>
				<div className={taglineTitleClass}>
					<H1Span className={colorPrimary100Class}>
						{rootData?.structureDefinition?.title}
					</H1Span>
				</div>
				{!hideImage && rootData?.image?.url && (
					<div className={imageWrapperClass}>
						{(isStarted || isFinished) && (
							<div
								className={progressWrapperClass({
									isFinished,
								})}
							>
								{isFinished && <CheckBadgeSVG />}
								{!isFinished && (
									<CircularProgressbar
										value={progressValue}
										width={theme.spacing.spacing20}
										height={theme.spacing.spacing20}
										strokeWidth={20}
									/>
								)}
								{isStarted && (
									<H3Span>
										{progressValue} {ProgressUnit}
									</H3Span>
								)}
								{isFinished && (
									<H3Span className={progressIsFinishedTextClass}>
										100% abgeschlossen
									</H3Span>
								)}
								<div
									className={gamificationPointsProgressDividerClass({
										isFinished,
									})}
									aria-hidden="true"
								/>
								<div
									className={gamificationPointsProgressClass({
										isFinished,
									})}
								>
									<H3Span className={colorWarning100Class}>
										{receivedGamificationPoints} /{" "}
										{receivableGamificationPoints}
									</H3Span>
									<KeyMedalSvg />
								</div>
							</div>
						)}
						{!hasLicense && product?.netPrice && (
							<div className={priceWrapperClass}>
								<H1Span className={colorPrimary100Class}>
									{formatCurrency(product.netPrice)}
								</H1Span>
								{!product.isTaxFree && (
									<L1Span className={colorShade40Class}>incl. MwSt.</L1Span>
								)}
							</div>
						)}

						{trailerVideoUrl ? (
							<VideoTrailer
								videoUrl={trailerVideoUrl}
								thumbnail={trailerVideoThumbnail}
								className={videoTrailerClass}
							/>
						) : (
							<img
								src={rootData.root?.image?.url ?? rootData.image.url}
								alt=""
								className={imageClass}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
