import { Skeleton } from "@thekeytechnology/epic-ui";
import { rowWrapperClass } from "./profile-menu-row.styles";

export const ProfileMenuRowSkeleton = () => {
	return (
		<div className={rowWrapperClass()}>
			<Skeleton width="1.5rem" height="1.5rem" />
			<Skeleton width="5rem" />
		</div>
	);
};
