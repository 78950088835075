import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { useState } from "react";
import { readInlineData, useFragment } from "react-relay";
import { LicensesSingleUserAssignmentCard } from "@components/licenses-single-user-assignment-card";
import { useToast } from "@hooks/useToast";
import { type userRulesTab_AccountMemberInlineFragment$key } from "@relay/userRulesTab_AccountMemberInlineFragment.graphql";
import {
	ACCOUNT_MEMBER_INLINE_FRAGMENT,
	SINGLE_USER_ASSIGNMENT_RULE_INLINE_FRAGMENT,
	QUERY_FRAGMENT,
} from "./user-rules-tab.graphql";
import { UserRulesTabSkeleton } from "./user-rules-tab.skeleton";
import {
	allocationListWrapperClass,
	allocationUserGroupClass,
	allocationUserListClass,
} from "./user-rules-tab.styles";
import {
	type SingleUserFragmentsWithId,
	type UserRulesTabComponentProps,
} from "./user-rules-tab.types";

export const UserRulesTabComponent = ({
	remainingLicenses,
	userFragmentRef,
	items,
	refetch,
}: UserRulesTabComponentProps) => {
	const users = useFragment(QUERY_FRAGMENT, userFragmentRef);
	const { showSuccess } = useToast();
	const [reservedLicenseCount, setReservedLicenseCount] = useState<
		{
			id: string;
			licenseCount: number;
		}[]
	>([]);

	const canAssignLicenses =
		remainingLicenses > reservedLicenseCount.reduce((acc, val) => acc + val.licenseCount, 0);

	const groupedItems = items?.reduce<{
		consumed: SingleUserFragmentsWithId[];
		available: SingleUserFragmentsWithId[];
	}>(
		(acc, item) => {
			const itemData = readInlineData(SINGLE_USER_ASSIGNMENT_RULE_INLINE_FRAGMENT, item);
			const licensesConsumed = itemData.licensesUsedByUser >= itemData.maxLicenseAmount;
			const userRef =
				users?.AccountMemberManagement.AccountMembers.accountMembers.edges?.find((edge) => {
					const data = readInlineData<userRulesTab_AccountMemberInlineFragment$key>(
						ACCOUNT_MEMBER_INLINE_FRAGMENT,
						edge?.node!,
					);
					return data?.user?.user?.id === itemData.userId;
				})?.node;

			if (!userRef) return acc;

			if (licensesConsumed) {
				acc.consumed.push({
					id: itemData.id,
					userRef: userRef,
					fragmentRef: item,
				});
			}
			if (!licensesConsumed) {
				acc.available.push({
					id: itemData.id,
					userRef: userRef,
					fragmentRef: item,
				});
			}
			return acc;
		},
		{
			consumed: [],
			available: [],
		},
	);

	const handleDelete = () => {
		showSuccess({
			summary: "Zuweisung gelöscht",
		});

		refetch?.(
			{},
			{
				fetchPolicy: "network-only",
			},
		);
	};

	const createLicenseCountOnChangeHandler = (id: string) => (value: number) => {
		const oldItem = reservedLicenseCount.find((item) => item.id === id);
		if (oldItem) {
			setReservedLicenseCount((prev) =>
				prev.map((item) => (item.id === id ? { ...item, licenseCount: value } : item)),
			);
			return;
		}

		setReservedLicenseCount((prev) => [
			...prev,
			{
				id,
				licenseCount: value,
			},
		]);
	};

	return (
		<div className={allocationListWrapperClass}>
			{groupedItems?.available && groupedItems?.available.length > 0 && (
				<div className={allocationUserGroupClass}>
					<Label label="Verfügbar" size="smal" />
					<div className={allocationUserListClass}>
						{groupedItems?.available?.map((item) => (
							<LicensesSingleUserAssignmentCard
								key={item.id}
								userRef={item.userRef}
								onLicenseCountChange={createLicenseCountOnChangeHandler(item.id)}
								queryFragmentRef={item.fragmentRef}
								canIncrease={canAssignLicenses}
								onDelete={handleDelete}
							/>
						))}
					</div>
				</div>
			)}
			{groupedItems?.consumed && groupedItems?.consumed.length > 0 && (
				<div className={allocationUserGroupClass}>
					<Label label="Verbraucht" size="smal" />
					<div className={allocationUserListClass}>
						{groupedItems.consumed.map((item) => (
							<LicensesSingleUserAssignmentCard
								key={item.id}
								userRef={item.userRef}
								onLicenseCountChange={createLicenseCountOnChangeHandler(item.id)}
								queryFragmentRef={item.fragmentRef}
								canIncrease={canAssignLicenses}
								onDelete={handleDelete}
							/>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export const UserRulesTab = withSuspense(UserRulesTabComponent, UserRulesTabSkeleton);
