/**
 * @generated SignedSource<<b0dc7365383012cb63c3b6c883307f54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type filterModalRefetchQuery$variables = {
  after?: string | null | undefined;
  doCalcMemberCount?: boolean | null | undefined;
  filterByName?: string | null | undefined;
  first?: number | null | undefined;
  includeOwner?: boolean | null | undefined;
  includeUser?: boolean | null | undefined;
};
export type filterModalRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"filterModal_QueryFragment">;
};
export type filterModalRefetchQuery = {
  response: filterModalRefetchQuery$data;
  variables: filterModalRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": true,
    "kind": "LocalArgument",
    "name": "doCalcMemberCount"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByName"
  },
  {
    "defaultValue": 200,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": true,
    "kind": "LocalArgument",
    "name": "includeOwner"
  },
  {
    "defaultValue": true,
    "kind": "LocalArgument",
    "name": "includeUser"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "doCalcMemberCount",
  "variableName": "doCalcMemberCount"
},
v3 = {
  "kind": "Variable",
  "name": "filterByName",
  "variableName": "filterByName"
},
v4 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v5 = {
  "kind": "Variable",
  "name": "includeOwner",
  "variableName": "includeOwner"
},
v6 = {
  "kind": "Variable",
  "name": "includeUser",
  "variableName": "includeUser"
},
v7 = [
  (v1/*: any*/),
  (v4/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "filterModalRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "filterModal_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "filterModalRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementQueries",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "concreteType": "UserInAccountGroupWrappersResult",
            "kind": "LinkedField",
            "name": "UserInAccountGroupWrappers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "UserInAccountGroupWrapperConnection",
                "kind": "LinkedField",
                "name": "userInAccountGroupWrappers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInAccountGroupWrapperEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccountGroupWrapper",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserInAccountGroup",
                            "kind": "LinkedField",
                            "name": "group",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "filterModal_userInAccountGroupWrappers",
                "kind": "LinkedHandle",
                "name": "userInAccountGroupWrappers"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "feaeb3552055f2541d3219d6d9085932",
    "id": null,
    "metadata": {},
    "name": "filterModalRefetchQuery",
    "operationKind": "query",
    "text": "query filterModalRefetchQuery(\n  $after: String = null\n  $doCalcMemberCount: Boolean = true\n  $filterByName: String = null\n  $first: Int = 200\n  $includeOwner: Boolean = true\n  $includeUser: Boolean = true\n) {\n  ...filterModal_QueryFragment_2kU2dR\n}\n\nfragment filterModal_QueryFragment_2kU2dR on Query {\n  AccountMemberManagement {\n    UserInAccountGroupWrappers(includeOwner: $includeOwner, includeUser: $includeUser, doCalcMemberCount: $doCalcMemberCount, filterByName: $filterByName) {\n      userInAccountGroupWrappers(first: $first, after: $after) {\n        edges {\n          node {\n            group {\n              id\n              name\n            }\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "846b999bcfffd410d07f2bca5c89abc5";

export default node;
