import { graphql } from "react-relay";

export const MESSAGE_QUERY = graphql`
	query messageModalInjector_LastUnseenMessageQuery($skip: Boolean!) {
		Viewer {
			Message {
				LastUnseenMessage @skip(if: $skip) {
					hasSeen
					messageDefinition {
						showUntil
					}
					...messageModal_MessageFragment
				}
			}
		}
	}
`;
