import { css, cva } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = cva({
	base: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		height: "[100vh]",
		"2xl": {
			flexDirection: "row",
			justifyContent: "flex-start",
		},
	},
	variants: {
		hideImage: {
			true: {
				"2xl": {
					flexDirection: "column",
					justifyContent: "center",
				},
			},
		},
	},
});

export const leftContentClass = cva({
	base: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		flex: "[1 1 0%]",
		overflowY: "hidden",
		"2xl": {
			flex: "none",
		},
	},
	variants: {
		hideImage: {
			true: {
				"2xl": {
					flex: "[1 1 0%]",
				},
			},
		},
	},
});

export const webappTeaserClass = css({
	display: "none",
	height: "[100vh]",
	backgroundSize: "cover",
	backgroundPosition: "center",
	"2xl": {
		display: "block",
		flexGrow: "1",
	},
});

export const headerContentWrapperClass = cva({
	base: {
		display: "flex",
		flexDirection: "column",
		flex: "[1 1 0%]",
		overflowY: "hidden",
		md: {
			padding: "40",
		},
		"2xl": {
			padding: "0",
		},
	},
	variants: {
		hideImage: {
			true: {
				"2xl": {
					padding: "40",
				},
			},
		},
	},
});
export const headerClass = flex({
	justifyContent: "center",
	alignItems: "center",
	pt: "16",
	pb: "24",
	pl: "24",
	pr: "24",
});

export const borderWrapperClass = cva({
	base: {
		display: "flex",
		flexDirection: "column",
		flex: "[1 1 0%]",
		justifyContent: "center",
		borderRadius: "10",
		borderStyle: "solid",
		borderColor: "shade.10",
		overflowY: "hidden",
		maxHeight: "full",
		md: {
			flex: "none",
			margin: "auto",
			borderWidth: "1px",
		},
		"2xl": {
			flex: "[1 1 0%]",
			margin: "0",
			borderWidth: "0",
		},
	},
	variants: {
		hideImage: {
			true: {
				"2xl": {
					flex: "[none]",
					margin: "auto",
					borderWidth: "1px",
				},
			},
		},
	},
});
