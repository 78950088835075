import { graphql } from "react-relay";

export const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment contentSubmissionNavigation_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ELearningContentSubmissionDefinition {
				progress {
					percentage
				}
				status
			}
		}
		learnOpportunity {
			id
			root {
				id
				structureDefinition {
					title
					... on LearnOpportunityRootStructureDefinition {
						viewerTreeState {
							kind
						}
						extension {
							... on PublishedRootExtensionImpl {
								abbreviation
								cartClickout {
									relativeLink
								}
								unlockInfo {
									kind
								}
								tags {
									id
									data {
										name
										isTopic
									}
								}
								product {
									id
									netPrice
								}
								ihkState
							}
						}
					}
				}
			}
			typeDefinition {
				... on LearnOpportunityContentTypeDefinition {
					contentNodeAdvancementResult {
						status
					}
				}
			}

			nextContentNodeId
			nextContentNode {
				indexPath
				typeDefinition {
					... on LearnOpportunityELearningContentTypeDefinition {
						extension {
							... on LearnContentExtensionImpl {
								isUnlockNecessaryToStart
							}
						}
					}
					... on LearnAsyncContentTypeDefinition {
						extension {
							... on LearnContentExtensionImpl {
								isUnlockNecessaryToStart
							}
						}
					}
				}
			}
		}

		...goToPreviousElementButton_ContentSubmissionFragment
		...goToNextElementButton_ContentSubmissionFragment
		...skipQuestionButton_ContentSubmissionFragment
	}
`;

export const RESTART_CONTENT_NODE_AFTER_PASSED_MUTATION = graphql`
	mutation contentSubmissionNavigation_RestartContentNodeAfterPassedMutation(
		$input: RestartContentNodeAfterPassedInput!
	) {
		LearnV2 {
			restartContentNodeAfterPassed(input: $input) {
				clientMutationId
				contentSubmission {
					id
				}
			}
		}
	}
`;

export const RESTART_CONTENT_NODE_AFTER_FAILED_MUTATION = graphql`
	mutation contentSubmissionNavigation_RestartContentNodeAfterFailedMutation(
		$input: RestartContentNodeAfterFailedInput!
	) {
		LearnV2 {
			restartContentNodeAfterFailed(input: $input) {
				clientMutationId
				contentSubmission {
					id
				}
			}
		}
	}
`;

export const ADD_ITEM_TO_USER_CART_MUTATION = graphql`
	mutation contentSubmissionNavigation_AddItemToUserCartMutation(
		$input: AddItemToUserCartInput!
	) {
		Viewer {
			Billing {
				addItemToUserCart(input: $input) {
					userCart {
						...userCart_UserCartFragment
					}
				}
			}
		}
	}
`;

export const QUERY = graphql`
	query contentSubmissionNavigation_Query {
		Viewer {
			Auth {
				...profileMenu_AuthViewerSchemaFragment
			}
		}
	}
`;
