import { H2Span } from "@thekeytechnology/epic-ui";
import {
	WEEKDAY_CIRCLE_DIAMETER,
	WEEKDAY_CIRCLE_RADIUS,
	WEEKDAY_CIRCLE_NUMBER_OF_DASHES,
	WEEKDAY_CIRCLE_STROKE_WIDTH,
	WEEKDAY_STROKE_DASH_OFFSET,
} from "./learn-streak-weekday.consts";
import { dayClass, dayTextClass } from "./learn-streak-weekday.styles";
import { LearnstreakWeekdayProps } from "./learn-streak-weekday.types";
import { isWithinWeekOrPast, getCircleDasharray } from "./learn-streak-weekday.utils";

export const LearnStreakWeekday = ({
	weekdayIndex,
	weekday,
	startDate,
}: LearnstreakWeekdayProps) => {
	const isCompleted = isWithinWeekOrPast({
		startDate,
		weekdayIndex,
	});

	const strokeWidth = isCompleted ? "5px" : `${WEEKDAY_CIRCLE_STROKE_WIDTH}px`;
	const strokeDasharray = isCompleted
		? "0"
		: getCircleDasharray(WEEKDAY_CIRCLE_RADIUS, WEEKDAY_CIRCLE_NUMBER_OF_DASHES);

	return (
		<div className={dayClass}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				version="1.1"
				width={WEEKDAY_CIRCLE_DIAMETER}
				height={WEEKDAY_CIRCLE_DIAMETER}
				viewBox={`${WEEKDAY_CIRCLE_DIAMETER}, ${WEEKDAY_CIRCLE_DIAMETER}`}
				style={{
					borderRadius: "50%",
				}}
			>
				<circle
					width={WEEKDAY_CIRCLE_DIAMETER}
					height={WEEKDAY_CIRCLE_DIAMETER}
					cx={WEEKDAY_CIRCLE_RADIUS}
					cy={WEEKDAY_CIRCLE_RADIUS}
					r={WEEKDAY_CIRCLE_RADIUS}
					fill="none"
					stroke="white"
					strokeDasharray={strokeDasharray}
					strokeDashoffset={WEEKDAY_STROKE_DASH_OFFSET}
					strokeWidth={strokeWidth}
				/>
			</svg>
			<H2Span className={dayTextClass}>{weekday}</H2Span>
		</div>
	);
};
