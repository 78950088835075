/**
 * @generated SignedSource<<bdba626e762f206e58acaf0b24b25f92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type systemEmail_Query$variables = Record<PropertyKey, never>;
export type systemEmail_Query$data = {
  readonly Viewer: {
    readonly Reports: {
      readonly SystemEmail: string;
    };
  };
};
export type systemEmail_Query = {
  response: systemEmail_Query$data;
  variables: systemEmail_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ViewerQueryType",
    "kind": "LinkedField",
    "name": "Viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReportsViewerSchema",
        "kind": "LinkedField",
        "name": "Reports",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "SystemEmail",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "systemEmail_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "systemEmail_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "7b9b8f3ab77508119509b960fde4d22a",
    "id": null,
    "metadata": {},
    "name": "systemEmail_Query",
    "operationKind": "query",
    "text": "query systemEmail_Query {\n  Viewer {\n    Reports {\n      SystemEmail\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8d8ed90f61c26c625f843c2fee89bc6c";

export default node;
