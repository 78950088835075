/**
 * @generated SignedSource<<ff873ea03333b580b57249b9b01d93a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type SubtitleLanguage = "de" | "en";
import { FragmentRefs } from "relay-runtime";
export type videoLearnElement_VideoLearnElementFragment$data = {
  readonly id: string;
  readonly streamFile: {
    readonly url: string | null | undefined;
  } | null | undefined;
  readonly subtitles: ReadonlyArray<{
    readonly language: SubtitleLanguage;
    readonly subtitles: ReadonlyArray<{
      readonly endTimeInSec: any;
      readonly startTimeInSec: any;
      readonly text: string;
    }>;
  }>;
  readonly thumbnailFile: {
    readonly url: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "videoLearnElement_VideoLearnElementFragment";
};
export type videoLearnElement_VideoLearnElementFragment$key = {
  readonly " $data"?: videoLearnElement_VideoLearnElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"videoLearnElement_VideoLearnElementFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "videoLearnElement_VideoLearnElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StreamFile",
      "kind": "LinkedField",
      "name": "streamFile",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubtitlesForLanguage",
      "kind": "LinkedField",
      "name": "subtitles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "language",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "VideoSubtitle",
          "kind": "LinkedField",
          "name": "subtitles",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startTimeInSec",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endTimeInSec",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "thumbnailFile",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "VideoLearnElement",
  "abstractKey": null
};
})();

(node as any).hash = "bb6292871cf20b0eee881120ee34fae8";

export default node;
