import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { Suspense, useContext, useEffect, useState } from "react";
import { useMutation, usePreloadedQuery, useQueryLoader } from "react-relay";
import { useParams } from "react-router-dom";
import { Element as ReactScrollElement } from "react-scroll";
import { gtmTrackAddToCart } from "@analytics/google-tag-manager";
import { LicenseCard } from "@components/license-card";
import { OrderIhkFlow } from "@components/order-ihk-flow";
import { TextParagraph } from "@components/text-paragraph";
import { useUserCartContext } from "@components/user-cart/user-cart.context";
import { rootOverview_AddItemToUserCartMutation } from "@relay/rootOverview_AddItemToUserCartMutation.graphql";
import { rootOverview_GetRootOverviewQuery } from "@relay/rootOverview_GetRootOverviewQuery.graphql";
import { H1Span } from "@themes/font-tags";
import { GoalsSectionId, TrainerSectionId } from "./root-overview.const";
import { ADD_ITEM_TO_USER_CART_MUTATION, QUERY } from "./root-overview.graphql";
import {
	RootOverviewWithPreloadedQueryProps,
	RootOverviewWithRootIdProps,
} from "./root-overview.interface";
import { RootOverviewSkeleton } from "./root-overview.skeleton";
import {
	addItemToUserCartWrapperClass,
	certificateWrapperClass,
	defaultSectionClass,
	goalsSectionClass,
	textParagraphWrapperClass,
	titleClass,
	titlePointsWrapperClass,
	wrapperClass,
} from "./root-overview.styles";
import { CertificateCard } from "../../../components/certificate-card";
import { InstructorsComponent } from "../../../components/instructors-component";
import { RootScreenContext } from "../root.context";

export const RootOverviewWithPreloadedQuery = ({
	queryRef,
}: RootOverviewWithPreloadedQueryProps) => {
	const { t } = useTkaTranslation("courseScreen");
	const { setSpyEnabled } = useContext(RootScreenContext);
	const { setUserCartOpen } = useUserCartContext();

	const { node } = usePreloadedQuery<rootOverview_GetRootOverviewQuery>(QUERY, queryRef);

	const [addItemToUserCart] = useMutation<rootOverview_AddItemToUserCartMutation>(
		ADD_ITEM_TO_USER_CART_MUTATION,
	);

	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleOrderOnClick = () => {
		setIsModalVisible(true);
	};

	const handleOnDismiss = () => {
		setIsModalVisible(false);
	};

	useEffect(() => {
		setSpyEnabled(true);
		return () => {
			setSpyEnabled(false);
		};
	}, []);

	const title = t("course_screen.overviewTitle");
	const contentTitle = t("course_screen.courseDescriptionTitle");

	const goalsTitle = t("course_screen.goalsTitle");

	const isIHK = node?.structureDefinition?.extension?.reducedData?.isIHK;
	const canOrderIHK = node?.structureDefinition?.extension?.ihkState === "ReadyToOrder";

	const rootName = node?.structureDefinition?.title ?? "";
	const goalsHtml = node?.structureDefinition?.extension?.learnGoals;
	const diploma = node?.structureDefinition?.extension?.diploma ?? "";

	const needLicense = node?.structureDefinition?.extension?.unlockInfo?.kind === "Demo";
	const hasInstructors = (node?.instructorsV2?.edges?.length ?? 0) > 0;

	const hasProduct = node?.structureDefinition?.extension?.product;

	const handleOnCartClickout = () => {
		const productId = node?.structureDefinition?.extension?.product?.id;
		if (!productId) return;

		const rootExtension = node.structureDefinition.extension;
		const rootCategory = node.structureDefinition?.extension?.tags?.find((t) => t.data.isTopic);
		gtmTrackAddToCart({
			currency: "EUR",
			value: rootExtension?.product?.netPrice ?? 0,
			items: [
				{
					itemId: node.id!,
					itemName: node.structureDefinition?.title ?? "",
					itemCategory: rootCategory?.data.name ?? "",
					price: rootExtension?.product?.netPrice ?? 0,
					quantity: 1,
				},
			],
		});

		addItemToUserCart({
			variables: {
				input: {
					productId,
				},
			},
			onCompleted: () => {
				setUserCartOpen(true);
			},
		});
	};

	const ihkCertificate = isIHK && node.id && (
		<div className={certificateWrapperClass}>
			<CertificateCard
				canOrder={canOrderIHK}
				rootName={rootName}
				diploma={diploma}
				rootId={node.id}
				onOrder={handleOrderOnClick}
			/>
		</div>
	);

	return (
		<div className={wrapperClass}>
			<div className={titlePointsWrapperClass}>
				<H1Span className={titleClass}>{title}</H1Span>
			</div>
			{canOrderIHK && ihkCertificate}

			<div className={textParagraphWrapperClass}>
				<TextParagraph title={contentTitle} html={node?.description ?? ""} />
			</div>

			{hasProduct && needLicense && (
				<div className={addItemToUserCartWrapperClass}>
					<Button
						label="Lizenz zum Warenkorb hinzufügen"
						colorVersion="outline"
						className="w-full"
						iconName="shoppingBasket"
						onClick={handleOnCartClickout}
					/>
				</div>
			)}
			{goalsHtml && (
				<ReactScrollElement
					id={GoalsSectionId}
					name={GoalsSectionId}
					className={goalsSectionClass}
				>
					<TextParagraph title={goalsTitle} html={goalsHtml} listIsCheckIcons />
				</ReactScrollElement>
			)}
			{node?.instructorsV2 && hasInstructors && (
				<ReactScrollElement
					id={TrainerSectionId}
					name={TrainerSectionId}
					className={defaultSectionClass}
				>
					<InstructorsComponent instructorsConnectionFragmentRef={node?.instructorsV2} />
				</ReactScrollElement>
			)}
			{!canOrderIHK && ihkCertificate}
			{needLicense && (
				<div className={certificateWrapperClass}>
					<LicenseCard />
				</div>
			)}
			{node?.id && (
				<OrderIhkFlow
					rootId={node.id}
					isVisible={isModalVisible}
					onDismiss={handleOnDismiss}
				/>
			)}
		</div>
	);
};

export const RootOverviewWithRootId = ({ rootId }: RootOverviewWithRootIdProps) => {
	const [queryReference, loadQuery] = useQueryLoader<rootOverview_GetRootOverviewQuery>(QUERY);

	useEffect(() => {
		loadQuery({ id: rootId });
	}, [loadQuery, rootId]);

	return (
		<Suspense fallback={<RootOverviewSkeleton />}>
			{queryReference && <RootOverviewWithPreloadedQuery queryRef={queryReference} />}
		</Suspense>
	);
};

export const RootOverview = () => {
	const { rootId } = useParams();
	return rootId ? <RootOverviewWithRootId rootId={rootId} /> : null;
};
