import { flex, stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	width: "full",
	padding: "24",
	backgroundColor: "shade.5",
	borderRadius: "8",
	gap: "16",
});

export const optionsWrapperClass = stack({
	gap: "16",
	md: {
		flexDirection: "row",
	},
});

export const optionWrapperClass = flex({
	alignItems: "center",
	justifyContent: "space-between",
	width: "full",
	padding: "16",
	backgroundColor: "shade.0",
	borderRadius: "8",
	md: {
		width: "[50%]",
	},
});

export const textWrapperClass = stack({
	gap: "0",
	marginRight: "16",
});
