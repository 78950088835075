import { cva } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const listWrapperClass = cva({
	base: {
		display: "grid",
		gap: "8",
		cursor: "pointer",
		border: "1px solid",
		borderColor: "shade.20",
		borderRadius: "12",
		padding: "16",
	},
	variants: {
		isLocked: {
			true: {
				backgroundColor: "shade.10",
				borderColor: "shade.20",
			},
		},
		hasMarginLeft: {
			true: {
				xl: {
					marginLeft: "24",
				},
			},
		},
	},
	defaultVariants: {
		hasMarginLeft: true,
	},
});

export const mainWrapperClass = cva({
	base: {
		display: "flex",
		alignItems: "center",
		gap: "16",
	},
	variants: {
		isEnabled: {
			true: {
				opacity: "1",
			},
			false: {
				opacity: "0.5",
			},
		},
	},
});

export const infoWrapperClass = stack({
	gap: "0",
	alignItems: "flex-start",
	flexGrow: "1",
});

export const iconsWrapperClass = stack({
	gap: "0",
	justifyContent: "space-between",
	alignItems: "flex-end",
	height: "full",
});

export const pointsWrapperClass = cva({
	base: {
		display: "flex",
		alignItems: "center",
		gap: "4",
		paddingX: "[6px]",
		paddingY: "[3px]",
		borderRadius: "full",
	},
	variants: {
		isHeadAtPreviousBranch: {
			true: {
				backgroundColor: "shade.20",
			},
			false: {
				backgroundColor: "shade.0",
			},
		},
	},
});
