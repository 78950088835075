import { Path } from "@router/paths";

export const TAB_INDEX_TO_ROUTES_LIST = [
	Path.profileMenu.account.members.path,
	Path.profileMenu.account.licenses.path,
	[
		Path.profileMenu.account.manageLicenses.path,
		Path.profileMenu.account.manageLicenses.detail.withIdPlaceholder().path,
	],
	Path.profileMenu.account.invoices.path,
];
