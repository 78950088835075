/**
 * @generated SignedSource<<769be6039379b7e9490b6c3f44a65d1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NoteColor = "blue" | "default" | "green" | "red" | "yellow";
export type NoteReminderKind = "NonRecurring" | "Recurring";
export type ReminderIntervalKind = "Daily" | "Monthly" | "Weekly";
export type EditNoteInput = {
  clientMutationId?: string | null | undefined;
  color?: NoteColor | null | undefined;
  content?: string | null | undefined;
  noteId: string;
  reminder?: NoteReminderInput | null | undefined;
};
export type NoteReminderInput = {
  NonRecurring?: NonRecurringNoteReminderInput | null | undefined;
  Recurring?: RecurringNoteReminderInput | null | undefined;
};
export type RecurringNoteReminderInput = {
  interval: ReminderIntervalInput;
  isCompleted: boolean;
  kind: NoteReminderKind;
  remindAt: string;
  remindUntil?: string | null | undefined;
};
export type ReminderIntervalInput = {
  Daily?: DailyIntervalInput | null | undefined;
  Monthly?: MonthlyIntervalInput | null | undefined;
  Weekly?: WeeklyIntervalInput | null | undefined;
};
export type DailyIntervalInput = {
  kind: ReminderIntervalKind;
  value: number;
};
export type WeeklyIntervalInput = {
  kind: ReminderIntervalKind;
  value: number;
};
export type MonthlyIntervalInput = {
  kind: ReminderIntervalKind;
  value: number;
};
export type NonRecurringNoteReminderInput = {
  isCompleted: boolean;
  kind: NoteReminderKind;
  remindAt: string;
};
export type reminderForm_editNoteMutation$variables = {
  input: EditNoteInput;
};
export type reminderForm_editNoteMutation$data = {
  readonly Note: {
    readonly editNote: {
      readonly note: {
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"reminderCard_ReminderNoteFragment" | "reminderForm_QueryFragment">;
        };
      };
    } | null | undefined;
  };
};
export type reminderForm_editNoteMutation = {
  response: reminderForm_editNoteMutation$data;
  variables: reminderForm_editNoteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reminderForm_editNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NoteMutations",
        "kind": "LinkedField",
        "name": "Note",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditNotePayload",
            "kind": "LinkedField",
            "name": "editNote",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NoteEdge",
                "kind": "LinkedField",
                "name": "note",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Note",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "reminderForm_QueryFragment"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "reminderCard_ReminderNoteFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reminderForm_editNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NoteMutations",
        "kind": "LinkedField",
        "name": "Note",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditNotePayload",
            "kind": "LinkedField",
            "name": "editNote",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NoteEdge",
                "kind": "LinkedField",
                "name": "note",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Note",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "content",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "reminder",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "remindAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isCompleted",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "interval",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v4/*: any*/),
                                    "type": "DailyInterval",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v4/*: any*/),
                                    "type": "WeeklyInterval",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v4/*: any*/),
                                    "type": "MonthlyInterval",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RecurringNoteReminder",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a9a84d1cc63e7599ee08d3646a13ca00",
    "id": null,
    "metadata": {},
    "name": "reminderForm_editNoteMutation",
    "operationKind": "mutation",
    "text": "mutation reminderForm_editNoteMutation(\n  $input: EditNoteInput!\n) {\n  Note {\n    editNote(input: $input) {\n      note {\n        node {\n          ...reminderForm_QueryFragment\n          ...reminderCard_ReminderNoteFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment reminderCard_ReminderNoteFragment on Note {\n  __typename\n  id\n  content\n  reminder {\n    __typename\n    kind\n    isCompleted\n    remindAt\n  }\n  ...upsertReminderModal_ReminderNoteFragment\n}\n\nfragment reminderForm_QueryFragment on Note {\n  id\n  content\n  reminder {\n    __typename\n    kind\n    remindAt\n    isCompleted\n    ... on RecurringNoteReminder {\n      kind\n      interval {\n        __typename\n        kind\n        ... on DailyInterval {\n          value\n        }\n        ... on WeeklyInterval {\n          value\n        }\n        ... on MonthlyInterval {\n          value\n        }\n      }\n    }\n    ... on NonRecurringNoteReminder {\n      kind\n    }\n  }\n}\n\nfragment upsertReminderModal_ReminderNoteFragment on Note {\n  id\n  ...reminderForm_QueryFragment\n}\n"
  }
};
})();

(node as any).hash = "422574e90814edc07f8241372bb38ac6";

export default node;
