import { graphql } from "react-relay";

export const PUBLISHED_LEARNABLES_CONNECTION_FRAGMENT = graphql`
	fragment trialProgressTracker_PublishedLearnablesConnectionFragment on PublishedLearnablesConnection {
		edges {
			node {
				id
				... on PublishedCourseLearnable {
					root {
						...trialProgressTrackerItem_LearnOpportunityV2Fragment
					}
				}
			}
		}
	}
`;
