import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const footerStyledBarClass = flex({
	justifyContent: "center",
	width: "full",
	paddingTop: "12",
	paddingBottom: "12",
	backgroundColor: "shade.5",
});

export const footerItemClass = css({
	marginLeft: "8",
	marginRight: "8",
	color: "shade.100",
});
