import * as Sentry from "@sentry/react";
import { useAuthContext } from "@thekeytechnology/academies-lib-webapp/hooks/use-auth-context";
import { useTranslation } from "react-i18next";
import { decodeToken } from "react-jwt";
import { useLazyLoadQuery } from "react-relay";
import { useLocation, useNavigate } from "react-router-dom";
import { gtmTrackLogin, gtmTrackSignUp } from "@analytics/google-tag-manager";
import { MultiStepAuthContainer } from "@containers/multi-step-auth-container";
import { type loginSteps_InvitationByTokenQuery } from "@relay/loginSteps_InvitationByTokenQuery.graphql";
import { Path } from "@router/paths";
import { INVITATION_QUERY } from "./login-steps.graphql";
import { type LoginStepsProps } from "./login-steps.types";
import { AuthTemplateStep } from "../../../../components/auth-template-step";
import { STORAGE_KEY_IS_ONBOARDING_MODAL_SHOWN } from "../../../../consts";
import { LoginEmailPassword, type LoginEmailPasswordFormState } from "../login-email-password";

export const LoginSteps = ({ inline, onGoToSignUp, onGoToForgotPassword }: LoginStepsProps) => {
	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);
	const orderIdParam = searchParams.get("orderId");
	const { state } = location;

	const navigate = useNavigate();
	const { t } = useTranslation("inviteScreens");

	const {
		Auth: { InvitationByToken: invitation },
	} = useLazyLoadQuery<loginSteps_InvitationByTokenQuery>(INVITATION_QUERY, {
		token: state?.invitationToken ?? "",
		skip: !state?.invitationToken,
	});

	const { login } = useAuthContext();

	const handleShoppingCartInvoiceRedirect = (orderId: string) => {
		navigate(Path.shoppingCart.withId(orderId).invoice.path);
	};

	const handleLoginOnSubmit = (values: LoginEmailPasswordFormState) => {
		login(values.email, values.password).then((response) => {
			if (inline) {
				window.location.reload();
				return;
			}

			const loginResult = response.Auth.loginJwt?.loginResult;

			let userIsInternal = false;
			if (values.email.endsWith("@thekey.academy")) {
				userIsInternal = true;
			}

			if (loginResult) {
				const decodedToken = decodeToken<{ userId: string }>(
					loginResult.jwtTokens.accessToken,
				);

				if (decodedToken) {
					const userId = btoa(`User:${decodedToken.userId}`);

					const firstLogin = loginResult.firstLogin ?? false;
					if (firstLogin) {
						localStorage.setItem(STORAGE_KEY_IS_ONBOARDING_MODAL_SHOWN, "true");
						gtmTrackSignUp({
							userId,
						});
					}

					Sentry.setUser({ id: userId, email: values.email });
					gtmTrackLogin({ userId, userIsInternal });
				}
			}

			if (orderIdParam) {
				handleShoppingCartInvoiceRedirect(orderIdParam);
				return;
			}

			navigate(state?.redirect ?? Path.dashboard.path);
		});
	};

	const handleGoToSignUp = () => {
		if (onGoToSignUp) {
			onGoToSignUp();
			return;
		}

		let path = Path.signup.path;

		if (orderIdParam) {
			path += `?orderId=${orderIdParam}`;
		}

		navigate(path, { state });
	};

	const handleGoToForgotPassword = () => {
		if (onGoToForgotPassword) {
			onGoToForgotPassword();
			return;
		}
		navigate(Path.forgotPassword.path);
	};

	const inviteText =
		invitation?.accountName && invitation?.invitingUserName
			? t("invite_screens.invitedByTitle", {
					account: invitation.accountName,
					invitingUser: invitation.invitingUserName,
			  })
			: undefined;

	const step = (
		<LoginEmailPassword
			headerText={inviteText}
			onSubmit={handleLoginOnSubmit}
			onGoToSignUp={handleGoToSignUp}
			onGoToForgotPassword={handleGoToForgotPassword}
		/>
	);

	return inline ? (
		step
	) : (
		<MultiStepAuthContainer StepWrapper={AuthTemplateStep}>{() => step}</MultiStepAuthContainer>
	);
};
