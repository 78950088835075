import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const memberCardsWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "8",
});

export const loadMoreButtonWrapperClass = flex({
	justifyContent: "center",
	alignItems: "center",
	width: "full",
	marginTop: "12",
});
