import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { useNavigate } from "react-router-dom";
import { L1Span } from "@themes/font-tags";
import {
	bottomTextWrapperClass,
	buttonsWrapperClass,
	childrenWrapperClass,
	contentWrapperClass,
	wrapperClass,
} from "./bottom-navigation-template.styles";
import { BottomNavigationTemplateProps } from "./bottom-navigation-template.types";

export const BottomNavigationTemplate = ({
	actionButtonLabel,
	actionButtonIconName,
	actionButtonDisabled,
	actionButtonLoading,
	actionButtonFillParent,
	actionButtonColorVersion,
	onActionButtonClick,
	actionButton,
	canGoBack,
	backDisabled,
	onBackClick,
	secondaryButton,
	secondaryButtonFillParent,
	bottomText,
	bottomNode,
	children,
}: BottomNavigationTemplateProps) => {
	const navigate = useNavigate();

	const handleOnBackClick = () => {
		if (onBackClick) {
			return onBackClick();
		}
		navigate(-1);
	};

	return (
		<div className={wrapperClass}>
			{children && <div className={childrenWrapperClass}>{children}</div>}
			<div className={contentWrapperClass}>
				<div className={buttonsWrapperClass}>
					{secondaryButton ??
						(canGoBack && (
							<Button
								colorVersion="tertiary"
								iconName="arrowLeft"
								fillParent={secondaryButtonFillParent}
								disabled={backDisabled}
								onClick={handleOnBackClick}
							/>
						))}
					{actionButton ?? (
						<Button
							label={actionButtonLabel}
							iconName={actionButtonIconName}
							disabled={actionButtonDisabled}
							loading={actionButtonLoading}
							fillParent={actionButtonFillParent}
							colorVersion={actionButtonColorVersion}
							onClick={onActionButtonClick}
						/>
					)}
				</div>
				{bottomNode ??
					(bottomText && (
						<div className={bottomTextWrapperClass}>
							<L1Span>{bottomText}</L1Span>
						</div>
					))}
			</div>
		</div>
	);
};
