import { flex, stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	gap: "0",
	paddingTop: "20",
	paddingBottom: "16",
	md: {
		padding: "0",
	},
});

export const optionsWrapperClass = stack({
	gap: "0",
	paddingY: "8",
});

export const itemWrapperClass = flex({
	gap: "12",
	paddingY: "16",
	md: {
		gap: "8",
		paddingY: "8",
		paddingX: "16",
	},
	_hover: {
		opacity: "0.6",
		cursor: "pointer",
	},
});
