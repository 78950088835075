/**
 * @generated SignedSource<<c70fd32ce5d190c722f8b510f8e8180f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type LearnableKind = "Course";
import { FragmentRefs } from "relay-runtime";
export type learnablesGrid_QueryFragment$data = {
  readonly Learnable: {
    readonly PublishedLearnables: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly kind: LearnableKind;
          readonly root?: {
            readonly " $fragmentSpreads": FragmentRefs<"offerCard_LearnOpportunityV2Fragment">;
          } | null | undefined;
        };
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly endCursor: string | null | undefined;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null | undefined;
      };
      readonly " $fragmentSpreads": FragmentRefs<"trialProgressTracker_PublishedLearnablesConnectionFragment">;
    };
  };
  readonly " $fragmentType": "learnablesGrid_QueryFragment";
};
export type learnablesGrid_QueryFragment$key = {
  readonly " $data"?: learnablesGrid_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"learnablesGrid_QueryFragment">;
};

import learnablesGrid_RefetchQuery_graphql from './learnablesGrid_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "Learnable",
  "PublishedLearnables"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "kinds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tagIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "text"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": learnablesGrid_RefetchQuery_graphql
    }
  },
  "name": "learnablesGrid_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnableQueries",
      "kind": "LinkedField",
      "name": "Learnable",
      "plural": false,
      "selections": [
        {
          "alias": "PublishedLearnables",
          "args": [
            {
              "kind": "Variable",
              "name": "kinds",
              "variableName": "kinds"
            },
            {
              "kind": "Variable",
              "name": "tagIds",
              "variableName": "tagIds"
            },
            {
              "kind": "Variable",
              "name": "text",
              "variableName": "text"
            }
          ],
          "concreteType": "PublishedLearnablesConnection",
          "kind": "LinkedField",
          "name": "__dashboard_PublishedLearnables_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPreviousPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PublishedLearnablesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "kind",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "LearnOpportunityV2",
                          "kind": "LinkedField",
                          "name": "root",
                          "plural": false,
                          "selections": [
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "offerCard_LearnOpportunityV2Fragment"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "PublishedCourseLearnable",
                      "abstractKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "trialProgressTracker_PublishedLearnablesConnectionFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "0123a08da58e632b29e8035dc7ee43f7";

export default node;
