import { Button } from "@thekeytechnology/epic-ui";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { match } from "ts-pattern";
import {
	pageButtonWrapperClass,
	pageImageClass,
	pageImageWrapper,
} from "./message-modal-page.styles";
import { MessageModalPageProps } from "./message-modal-page.types";

export const MessageModalPage = ({
	page,
	onButtonClick,
	isPreview = false,
}: MessageModalPageProps) => {
	const image = page?.element?.image;
	const buttonData = page?.kind === "imageWithButton" ? page?.button : undefined;
	const [isLoaded, setIsLoaded] = useState(false);
	const theme = useTheme();

	const navigate = useNavigate();

	const copyToClipboard = () => {
		try {
			if (buttonData) {
				navigator.clipboard.writeText(buttonData.text);
			}
		} catch (error) {}
	};

	const checkIfIsInternalLink = () => {
		const linkTester = document.createElement("a");
		if (!buttonData || buttonData.kind !== "link") return;
		linkTester.href = buttonData.url;
		return linkTester.host === location.host;
	};

	const goToLink = () => {
		if (!buttonData || buttonData.kind !== "link") return;
		const isInternalLink = checkIfIsInternalLink();
		const encodedTitle = encodeURIComponent("onboarding-modal");
		const utmExtension = `?utm_source=CM&utm_medium=webapp&utm_campaign=${encodedTitle}`;

		if (isInternalLink) {
			const internalUrl = new URL(buttonData.url);
			navigate(internalUrl?.pathname + utmExtension);
		} else {
			window.open(buttonData?.url + utmExtension, "_blank", "noopener,noreferrer");
		}
	};

	const handleButtonOnClick = async () => {
		if (!buttonData) return;

		await onButtonClick();

		match(buttonData.kind)
			.with("link", () => {
				if (buttonData.kind !== "link") return;
				goToLink();
			})
			.with("textCopy", () => {
				copyToClipboard();
			})
			.with("confirm", () => {})
			.exhaustive();
	};

	if (!image || !image.url) {
		return null;
	}

	return (
		<div className={pageImageWrapper({ isPreview })} key={image.id}>
			<img
				className={pageImageClass({ isLoaded })}
				src={image.url}
				alt={""}
				loading="eager"
				onLoad={() => setIsLoaded(true)}
			/>
			{buttonData && !isPreview && (
				<div className={pageButtonWrapperClass}>
					<Button
						stretch
						borderRadius={theme.borderRadius?.borderRadiusFull}
						label={buttonData?.text}
						onClick={handleButtonOnClick}
					/>
				</div>
			)}
		</div>
	);
};
