import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { parseHtml } from "@thekeytechnology/academies-lib-webapp/utils/parse-html";
import { useCallback, useContext, useEffect } from "react";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { uploadElement_ContentSubmissionFragment$key } from "@relay/uploadElement_ContentSubmissionFragment.graphql";
import { uploadElement_SubmitUploadAsyncElementMutation } from "@relay/uploadElement_SubmitUploadAsyncElementMutation.graphql";
import { colorShade100Class } from "@themes/color-classes";
import { H1Span, P2Span } from "@themes/font-tags";
import {
	CONTENT_SUBMISSION_FRAGMENT,
	SUBMIT_UPLOAD_ASYNC_ELEMENT_MUTATION,
} from "./upload-element.graphql";
import { wrapperClass, taskDescriptionClass } from "./upload-element.styles";
import { UploadElementProps } from "./upload-element.types";
import { AsyncContentSubmissionFilePreview } from "../async-content-submission-file-preview";
import { AsyncContentSubmissionFileSuccess } from "../async-content-submission-file-success";
import { AsyncContentSubmissionFileUpload } from "../async-content-submission-file-upload";
import { ContentSubmissionModalContext } from "../content-submission-modal-context-provider";

export const UploadElement = ({ contentSubmissionFragmentRef, refetch }: UploadElementProps) => {
	const contentSubmission = useFragment<uploadElement_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const [submitUploadAsyncElement] = useMutation<uploadElement_SubmitUploadAsyncElementMutation>(
		SUBMIT_UPLOAD_ASYNC_ELEMENT_MUTATION,
	);

	const { t } = useTkaTranslation("uploadElementScreen");
	const navigate = useNavigate();

	const { setNextButtonText, setCanGoNext, addGoToNextOnClickListener } = useContext(
		ContentSubmissionModalContext,
	);

	const element = contentSubmission?.definition?.element;
	const status = contentSubmission?.definition?.status;

	useEffect(() => {
		status === "active" &&
			setNextButtonText(t("upload_element_screen.submitDocumentButtonLabel"));
	}, []);

	useEffect(() => {
		element?.file?.id && setCanGoNext(true);
	}, [element?.file?.id]);

	const handleGoToNextClicked = useCallback(() => {
		if (element?.file?.id && status === "active") {
			setCanGoNext(false);
			submitUploadAsyncElement({
				variables: {
					input: {
						contentSubmissionId: contentSubmission.id!,
						uploadAsyncElementId: element?.id!,
						fileId: element.file.id,
					},
				},
				onCompleted: () => {
					setCanGoNext(true);
					setNextButtonText(undefined);
				},
			});
			return true;
		}
		if (status === "passed") {
			navigate(-1);
			return true;
		}
		return true;
	}, [
		status,
		element?.file?.id,
		element?.id,
		contentSubmission.id,
		setCanGoNext,
		setNextButtonText,
		submitUploadAsyncElement,
	]);

	useEffect(() => addGoToNextOnClickListener(handleGoToNextClicked), [handleGoToNextClicked]);

	return (
		<div className={wrapperClass}>
			{status === "passed" ? (
				<AsyncContentSubmissionFileSuccess
					fileName={element?.file?.name}
					url={element?.file?.url || undefined}
				/>
			) : (
				<>
					<H1Span className={colorShade100Class}>
						{t("upload_element_screen.taskTitle")}
					</H1Span>
					<P2Span className={taskDescriptionClass}>
						{element?.taskDescription && parseHtml(element?.taskDescription)}
					</P2Span>
					{element?.file?.name ? (
						<AsyncContentSubmissionFilePreview
							title={t("upload_element_screen.fileUploadTitle")}
							fileName={element.file.name}
							url={element.file.url || undefined}
						/>
					) : (
						element?.id && (
							<AsyncContentSubmissionFileUpload
								asyncElementId={element?.id}
								onFileUploaded={refetch}
							/>
						)
					)}
				</>
			)}
		</div>
	);
};
