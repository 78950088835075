import { css } from "@styled-system/css";

export const carouselContentClass = css({
	display: "flex",
});

export const carouselContainerClass = css({
	md: {
		gap: "16",
	},
});

export const carouselItemClass = css({
	width: "full",
	flex: "[1 0 100%]",
	md: {
		flex: "[1 0 calc(100% / 2 - 8px)!important]",
	},
	lg: {
		flex: "[1 0 calc(100% / 3 - 12px)!important]",
	},
});

export const carouselIndicatorContainerClass = css({
	display: "flex",
	alignItems: "center",
	gap: "[6px]",
	paddingBottom: "4!",
});

export const carouselIndicatorClass = css({
	width: "[6px]",
	height: "[6px]",
	marginRight: "0!",
	marginBottom: "0!",
	borderRadius: "full",
	bg: "shade.10",
	"& > *": {
		width: "[6px!]",
		height: "[6px!]",
	},
	"&.p-highlight": {
		width: "12",
		height: "12",
		bg: "primary.100",
		"& > *": {
			width: "12!",
			height: "12!",
		},
	},
});

export const carouselIndicatorButtonClass = css({
	borderRadius: "full!",
	bg: "transparent!",
});

export const sectionClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "8",
	marginTop: "24",
});

export const sectionHeaderClass = css({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
});

export const gridItemClass = css({
	flexBasis: "100%",
	width: "full",
	height: "full",
	pb: "8",
});
