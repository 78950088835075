import { flex } from "@styled-system/patterns";

export const headerClass = flex({
	width: "full",
	justifyContent: "space-between",
});

export const searchAndFiltersWrapperClass = flex({
	gap: "8",
});

export const noGrowWrapperClass = flex({
	grow: "0",
	lg: {
		width: "[11rem]",
	},
});
