import { PathParams } from "@thekeytechnology/epic-ui";
import { useFragment } from "react-relay";
import { useParams } from "react-router-dom";
import { MaterialsTree } from "@components/materials-tree";
import { MaterialsPath, RootPath } from "@router/paths";
import { colorPrimary100Class } from "@themes/color-classes";
import { H1Span } from "@themes/font-tags";
import { LEARN_OPPORTUNITY_V2_FRAGMENT } from "./materials-tab.graphql";
import { TabWrapper } from "./materials-tab.styles";
import { MaterialsTabProps } from "./materials-tab.types";

export const MaterialsTab = ({ learnOpportunityV2FragmentRef }: MaterialsTabProps) => {
	const learnOpportunityV2 = useFragment(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		learnOpportunityV2FragmentRef,
	);

	const { materialId } = useParams<PathParams<typeof MaterialsPath | typeof RootPath>>();

	return (
		<TabWrapper>
			<H1Span className={colorPrimary100Class}>Material</H1Span>
			{learnOpportunityV2 && (
				<MaterialsTree
					activeAttachmentId={materialId}
					learnOpportunityV2FragmentRef={learnOpportunityV2}
				/>
			)}
		</TabWrapper>
	);
};
