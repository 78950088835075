import { css } from "@styled-system/css";
import { grid, stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	gap: "12",
});

export const listClass = grid({
	gridTemplateColumns: "4",
	gap: "24",
});

export const listItemClass = css({
	gridColumn: "full",
	md: {
		gridColumn: "2",
	},
	lg: {
		gridColumn: "1",
	},
	xl: {
		gridColumn: "2",
	},
	"2xl": {
		gridColumn: "2",
	},
	"3xl": {
		gridColumn: "1",
	},
});
