import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { ResponsiveBottomSheetOverlayPanel } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-overlay-panel";
import { forwardRef, MouseEvent, useImperativeHandle, useRef } from "react";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { useUserCartContext } from "@components/user-cart/user-cart.context";
import { licenseContextMenu_addItemToUserCartMutation } from "@relay/licenseContextMenu_addItemToUserCartMutation.graphql";
import { Path } from "@router/paths";
import { P2Span } from "@themes/font-tags";
import { spacing0 } from "@themes/spacing";
import {
	ADD_ITEM_TO_USER_CART_MUTATION,
	LICENSE_GROUPING_FRAGMENT,
} from "./license-context-menu.grapqhl";
import { itemWrapperClass, optionsWrapperClass, wrapperClass } from "./license-context-menu.styles";
import { LicenseContextMenuProps, LicenseContextMenuRef } from "./license-context-menu.types";

export const LicenseContextMenu = forwardRef<LicenseContextMenuRef, LicenseContextMenuProps>(
	function LicenseContextMenu({ queryFragmentRef }, ref) {
		const license = useFragment(LICENSE_GROUPING_FRAGMENT, queryFragmentRef ?? null);

		const navigate = useNavigate();
		const { setUserCartOpen } = useUserCartContext();
		const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);

		const [addItemToUserCart] = useMutation<licenseContextMenu_addItemToUserCartMutation>(
			ADD_ITEM_TO_USER_CART_MUTATION,
		);

		const handleCreateSingleLicenseClick = () => {
			license?.id &&
				navigate(Path.profileMenu.account.manageLicenses.detail.withId(license.id).path);
		};

		const productId = license?.learnable?.root?.structureDefinition?.extension?.product?.id;

		const handleAddItemToUserCartOnClick = (event: MouseEvent<HTMLElement>) => {
			if (!productId) return;

			addItemToUserCart({
				variables: {
					input: {
						productId,
					},
				},
				onCompleted: () => {
					setUserCartOpen(true);
					overlayRef.current?.toggle(event, event?.currentTarget);
				},
			});
		};

		useImperativeHandle(ref, () => ({
			toggle: (event, target) => {
				overlayRef?.current?.toggle(event, target);
			},
		}));

		return (
			<ResponsiveBottomSheetOverlayPanel ref={overlayRef} padding={spacing0}>
				<div className={wrapperClass}>
					<div className={optionsWrapperClass}>
						<button
							type="button"
							className={itemWrapperClass}
							onClick={handleCreateSingleLicenseClick}
						>
							<Icon icon={"user"} sizeRem={1} />
							<P2Span>Nutzer für Lizenz freischalten</P2Span>
						</button>
						{license?.learnable && (
							<button
								type="button"
								className={itemWrapperClass}
								onClick={handleAddItemToUserCartOnClick}
							>
								<Icon icon={"loginKey"} sizeRem={1} />
								<P2Span>Lizenzen erwerben</P2Span>
							</button>
						)}
					</div>
				</div>
			</ResponsiveBottomSheetOverlayPanel>
		);
	},
);
