import { H3Span } from "@thekeytechnology/epic-ui";
import { Suspense, useEffect } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { ReactComponent as KeyMedalSvg } from "@assets/key-medal-points.svg";
import { gamificationPointsQuery } from "@relay/gamificationPointsQuery.graphql";
import { colorWarning100Class } from "@themes/color-classes";
import { QUERY } from "./gamification-points.graphql";
import { GamificationPointsSkeleton } from "./gamification-points.skeleton";
import { wrapperClass } from "./gamification-points.styles";

export const GamificationPointsComponent = ({
	queryRef,
}: {
	queryRef: PreloadedQuery<gamificationPointsQuery>;
}) => {
	const {
		Viewer: {
			Auth: { currentUser },
		},
	} = usePreloadedQuery(QUERY, queryRef);
	const sumGamificationPoints = currentUser?.user?.extension?.sumGamificationPoints ?? 0;

	return (
		<div className={wrapperClass}>
			<H3Span className={colorWarning100Class}>{sumGamificationPoints}</H3Span>
			<KeyMedalSvg />
		</div>
	);
};

export const GamificationPoints = () => {
	const [queryReference, loadQuery] = useQueryLoader<gamificationPointsQuery>(QUERY);

	useEffect(() => {
		loadQuery({}, { fetchPolicy: "store-and-network" });
	}, [loadQuery]);

	return (
		<Suspense fallback={<GamificationPointsSkeleton />}>
			{queryReference && <GamificationPointsComponent queryRef={queryReference} />}
		</Suspense>
	);
};
