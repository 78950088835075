import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { Carousel } from "primereact/carousel";
import { useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { OfferCard } from "@components/offer-card";
import { activeRootsCarousel_Query } from "@relay/activeRootsCarousel_Query.graphql";
import { Path } from "@router/paths";
import { H2Span } from "@themes/font-tags";
import { QUERY } from "./active-roots-carousel.graphql";
import {
	sectionClass,
	sectionHeaderClass,
	gridItemClass,
	carouselContainerClass,
	carouselContentClass,
	carouselIndicatorButtonClass,
	carouselIndicatorClass,
	carouselIndicatorContainerClass,
	carouselItemClass,
} from "./active-roots-carousel.styles";

export const ActiveRootsCarousel = () => {
	const navigate = useNavigate();
	const { isMediumUp, isXLargeUp } = useWindowSize();
	const query = useLazyLoadQuery<activeRootsCarousel_Query>(
		QUERY,
		{},
		{ fetchPolicy: "store-and-network" },
	);

	const activeRoots = query?.Viewer.Learn.getActiveRoots?.filter(Boolean) ?? [];

	const handleCardOnClick = (learnableId: string) => {
		navigate(Path.root.withId(learnableId).overview.path);
	};

	return (
		<section className={sectionClass}>
			<div className={sectionHeaderClass}>
				<H2Span>Fortsetzen</H2Span>
			</div>
			<EmptyPlaceholder
				isVisible={activeRoots.length === 0}
				title="Du hast noch keine Kurse gestartet"
				subtitle="Du hast aktuell keine Kurse in deinem Konto gestartet."
			>
				<Carousel
					value={activeRoots}
					page={0}
					numVisible={isMediumUp ? 3 : isXLargeUp ? 2 : 1}
					numScroll={1}
					showNavigators={false}
					pt={{
						content: {
							className: carouselContentClass,
						},
						container: {
							className: carouselContainerClass,
						},
						itemsContainer: {
							className: carouselContainerClass,
						},
						item: {
							className: carouselItemClass,
						},
						indicators: {
							className: carouselIndicatorContainerClass,
						},
						indicator: {
							className: carouselIndicatorClass,
						},
						indicatorButton: {
							className: carouselIndicatorButtonClass,
						},
					}}
					itemTemplate={(activeRoot: typeof activeRoots[number]) => (
						<div key={activeRoot.id + "-carousel"} className={gridItemClass}>
							<OfferCard
								onClick={handleCardOnClick}
								publishedLearnOpportunityV2FragmentRef={activeRoot}
							/>
						</div>
					)}
				/>
			</EmptyPlaceholder>
		</section>
	);
};
