export const isWithinWeekOrPast = ({
	startDate: startDateString,
	weekdayIndex,
}: {
	startDate?: string | null;
	weekdayIndex: number;
}) => {
	if (!startDateString) {
		return false;
	}
	const startDate = new Date(startDateString);
	const now = new Date();
	now.setHours(0, 0, 0, 0); // Set now to the start of the day

	const currentDayIndex = (now.getDay() + 6) % 7;
	const startOfWeek = new Date(now);
	startOfWeek.setDate(now.getDate() - currentDayIndex);
	startOfWeek.setHours(0, 0, 0, 0);

	const targetDate = new Date(startOfWeek);
	targetDate.setDate(startOfWeek.getDate() + weekdayIndex + 1);

	return startDate <= targetDate && targetDate <= now;
};
export const getCircleDasharray = (radius: number, numberOfDashes: number) => {
	const circumference = 2 * Math.PI * radius;
	const offset = circumference / numberOfDashes;
	return `2 ${offset}`;
};
