import { Button } from "@thekeytechnology/epic-ui";
import React, { useState } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { match } from "ts-pattern";
import {
	MESSAGE_DEFINITION_FRAGMENT,
	PAGE_FRAGMENT,
} from "@components/message-modal-page/message-modal-page.graphql";
import {
	pageButtonWrapperClass,
	pageImageClass,
	pageImageWrapper,
} from "@components/message-modal-page/message-modal-page.styles";
import { MessageModalPageProps } from "@components/message-modal-page/message-modal-page.types";
import { messageModalPage_MessageFragment$key } from "@relay/messageModalPage_MessageFragment.graphql";
import { messageModalPage_PageFragment$key } from "@relay/messageModalPage_PageFragment.graphql";

export const MessageModalPage = ({
	pageFragmentRef,
	messageFragmentRef,
	onButtonClick,
	isPreview = false,
}: MessageModalPageProps) => {
	const message = useFragment<messageModalPage_MessageFragment$key>(
		MESSAGE_DEFINITION_FRAGMENT,
		messageFragmentRef ?? null,
	);
	const page = useFragment<messageModalPage_PageFragment$key>(PAGE_FRAGMENT, pageFragmentRef);
	const image = page?.data.element?.image;
	const buttonData = page?.data.button;
	const [isLoaded, setIsLoaded] = useState(false);
	const theme = useTheme();

	const navigate = useNavigate();

	const copyToClipboard = () => {
		try {
			if (!buttonData) return;
			navigator.clipboard.writeText(buttonData.text);
		} catch (error) {}
	};

	const checkIfIsInternalLink = () => {
		if (!buttonData || !buttonData.url) return false;
		const linkTester = document.createElement("a");
		linkTester.href = buttonData.url;
		return linkTester.host === location.host;
	};

	const goToLink = () => {
		if (!buttonData || !buttonData.url || !message) return;
		const isInternalLink = checkIfIsInternalLink();
		const encodedTitle = encodeURIComponent(message.internalTitle);
		const utmExtension = `?utm_source=CM&utm_medium=webapp&utm_campaign=${encodedTitle}`;

		if (isInternalLink) {
			const internalUrl = new URL(buttonData.url);
			navigate(internalUrl?.pathname + utmExtension);
		} else {
			window.open(buttonData?.url + utmExtension, "_blank", "noopener,noreferrer");
		}
	};

	const handleButtonOnClick = async () => {
		if (!buttonData) return;

		await onButtonClick();

		match(buttonData.kind)
			.with("link", () => {
				if (!buttonData.url) return;
				goToLink();
			})
			.with("textCopy", () => {
				copyToClipboard();
			})
			.with("confirm", () => {})
			.exhaustive();
	};

	if (!image || !image.url) {
		return null;
	}

	return (
		<div className={pageImageWrapper({ isPreview })} key={image.id}>
			<img
				className={pageImageClass({ isLoaded })}
				src={image.url}
				alt={""}
				loading="eager"
				onLoad={() => setIsLoaded(true)}
			/>
			{buttonData && !isPreview && (
				<div className={pageButtonWrapperClass}>
					<Button
						stretch
						borderRadius={theme.borderRadius?.borderRadiusFull}
						label={buttonData?.text}
						onClick={handleButtonOnClick}
					/>
				</div>
			)}
		</div>
	);
};
