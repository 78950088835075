import { graphql } from "react-relay";

export const QUERY = graphql`
	query learnablesGrid_Query(
		$tagIds: [ID!]!
		$kinds: [LearnableKind!]!
		$first: Int
		$last: Int
		$before: String
		$after: String
		$text: String
	) {
		...learnablesGrid_QueryFragment
			@arguments(
				tagIds: $tagIds
				kinds: $kinds
				first: $first
				last: $last
				before: $before
				after: $after
				text: $text
			)
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment learnablesGrid_QueryFragment on Query
	@argumentDefinitions(
		tagIds: { type: "[ID!]!" }
		kinds: { type: "[LearnableKind!]!" }
		first: { type: "Int", defaultValue: null }
		last: { type: "Int", defaultValue: null }
		before: { type: "String", defaultValue: null }
		after: { type: "String", defaultValue: null }
		text: { type: "String", defaultValue: null }
	)
	@refetchable(queryName: "learnablesGrid_RefetchQuery") {
		Learnable {
			PublishedLearnables(
				before: $before
				after: $after
				first: $first
				last: $last
				tagIds: $tagIds
				text: $text
				kinds: $kinds
			) @connection(key: "dashboard_PublishedLearnables") {
				pageInfo {
					endCursor
					hasPreviousPage
					hasNextPage
					startCursor
				}
				edges {
					node {
						id
						kind
						... on PublishedCourseLearnable {
							root {
								...offerCard_LearnOpportunityV2Fragment
							}
						}
					}
				}
				...trialProgressTracker_PublishedLearnablesConnectionFragment
			}
		}
	}
`;
