import { css } from "@styled-system/css";
import { flex, grid } from "@styled-system/patterns";

export const wrapperClass = grid({
	gridTemplateColumns: "4",
	rowGap: "32",
	overflow: "hidden",
	md: {
		gridTemplateColumns: "12",
	},
	lg: {
		gap: "32",
	},
});

export const titleClass = css({
	width: "full",
});

export const titlePointsWrapperClass = flex({
	gridColumn: "full",
	alignItems: "start",
	justifyContent: "space-between",
	gap: "32",
});

export const textParagraphWrapperClass = flex({
	position: "relative",
	gridColumn: "full",
	gap: "24",
	lg: {
		gridColumn: "6",
	},
});

export const trailerSectionClass = css({
	gridColumn: "full",
	lg: {
		gridColumn: "6",
	},
	xl: {
		gridColumn: "full",
	},
	"2xl": {
		gridColumn: "6",
	},
});

export const goalsSectionClass = css({
	gridColumn: "full",
	lg: {
		gridColumn: "6",
	},
});

export const defaultSectionClass = css({
	gridColumn: "full",
});

export const certificateWrapperClass = css({
	gridColumn: "full",
	xl: {
		display: "none",
	},
});

export const addItemToUserCartWrapperClass = css({
	gridColumn: "full",
	xl: {
		display: "none",
	},
});
