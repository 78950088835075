import { css, cva } from "@styled-system/css";

export const wrapperClass = cva({
	base: {
		position: "relative",
		display: "flex",
		width: "full",
		gap: "4",
		overflow: "hidden",
	},
	variants: {
		isCollapsed: {
			true: {
				flexWrap: "nowrap",
			},
			false: {
				flexWrap: "wrap",
			},
		},
	},
});

export const toggleExtendCollapseClass = css({
	position: "absolute",
	top: "[8px]",
	right: "0",
	transform: "translateX(-0%)",
});

export const labelWrapperClass = css({
	flexShrink: "0",
	flexGrow: "0",
});
