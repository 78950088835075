import { css, cva } from "@styled-system/css";
import { flex, grid, stack } from "@styled-system/patterns";

export const wrapperClass = flex({
	direction: "column",
	gap: "8",
	padding: "8",
	border: "1px solid",
	borderColor: "shade.20",
	borderRadius: "12",
	boxShadow: "card",
	width: "full",
	height: "full",
	textAlign: "left",
});

export const imageWrapperClass = css({
	position: "relative",
	width: "full",
	maxWidth: "[108px]",
	maxHeight: "[85px]",
	borderRadius: "8",
	overflow: "hidden",
});

export const imageClass = css({
	width: "[108px]",
	height: "[85px]",
	objectFit: "cover",
});

export const imageDiplomaWrapperClass = css({
	position: "absolute",
	left: "0",
	bottom: "[-1px]",
});

export const imageDiplomaClass = css({
	transform: "scale(0.5)",
	transformOrigin: "bottom left",
});

export const imageOverlayClass = cva({
	base: {
		position: "absolute",
		top: "0",
		left: "0",
		right: "0",
		bottom: "0",
	},
	variants: {
		category: {
			"Management-Kompetenzen": {
				backgroundImage:
					"linear-gradient(217.06deg, rgba(255, 146, 77, 0) 30.74%, rgba(255, 146, 77, 0.4) 83.84%, rgba(234, 86, 29, 0.4) 100%)",
			},
			"Qualitäts- & Prozessoptimierung": {
				backgroundImage:
					"linear-gradient(217.06deg, rgba(18, 187, 228, 0) 30.74%, rgba(18, 187, 228, 0.4) 83.84%, rgba(35, 154, 250, 0.4) 100%)",
			},
			"Digitale Kompetenzen": {
				backgroundImage:
					"linear-gradient(217.06deg, rgba(254, 212, 47, 0) 30.74%, rgba(254, 212, 47, 0.4) 83.84%, rgba(223, 168, 39, 0.4) 100%)",
			},
			"Marketing & Vertrieb": {
				backgroundImage:
					"linear-gradient(217.06deg, rgba(15, 192, 224, 0) 30.74%, rgba(15, 192, 224, 0.4) 83.84%, rgba(8, 158, 131, 0.4) 100%)",
			},
			"Gesunde Unternehmenskultur": {
				backgroundImage:
					"linear-gradient(217.06deg, rgba(220, 153, 239, 0) 30.74%, rgba(220, 153, 239, 0.4) 83.84%, rgba(140, 82, 255, 0.4) 100%)",
			},
		},
	},
});

export const headerClass = grid({
	gap: "12",
	gridTemplateColumns: "[108px minmax(0,1fr)]",
	width: "full",
	height: "full",
});

export const headerContentClass = stack({
	gap: "8",
	alignItems: "flex-start",
	justifyContent: "center",
});

export const tagWrapperClass = cva({
	base: {
		display: "block",
		borderRadius: "12",
		paddingY: "[6px]",
		paddingX: "8",
	},
	variants: {
		category: {
			"Management-Kompetenzen": {
				background:
					"[linear-gradient(0deg,rgba(255,255,255,0.8),rgba(255,255,255,0.8)),linear-gradient(248.89deg,#FF924D 0%,#FC8946 15.39%,#EA561D 100%)]",
			},
			"Qualitäts- & Prozessoptimierung": {
				background:
					"[linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(248.89deg, #0FC0E0 0%, #2694FE 100%)]",
			},
			"Digitale Kompetenzen": {
				background:
					"[linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(248.89deg, #FED42F 0%, #DFA827 100%)]",
			},
			"Marketing & Vertrieb": {
				background:
					"[linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(248.89deg, #0FC0E0 0%, #089E83 100%)]",
			},
			"Gesunde Unternehmenskultur": {
				background:
					"[linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(248.89deg, #DC99EF 0%, #8C52FF 100%)]",
			},
		},
	},
	defaultVariants: {
		category: "Management-Kompetenzen",
	},
});

export const tagTitleClass = cva({
	base: {
		fontFamily: "default",
		fontSize: "10",
		lineHeight: "10",
	},
	variants: {
		category: {
			"Management-Kompetenzen": {
				color: "error.120",
			},
			"Qualitäts- & Prozessoptimierung": {
				color: "warning.120",
			},
			"Digitale Kompetenzen": {
				color: "info.120",
			},
			"Marketing & Vertrieb": {
				color: "success.100",
			},
			"Gesunde Unternehmenskultur": {
				color: "primary.100",
			},
		},
	},
});

export const textWrapperClass = stack({
	gap: "2",
	paddingLeft: "8",
	width: "full",
	maxWidth: "full",
	overflow: "hidden",
});

export const titleClass = css({
	color: "shade.100",
});

export const descriptionClass = css({
	color: "shade.80",
	maxWidth: "full",
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
});

export const dividerClass = css({
	width: "full",
	borderTop: "1px solid",
	borderColor: "shade.10",
	background: "transparent",
});

export const footerClass = flex({
	width: "full",
	justifyContent: "space-between",
	alignItems: "center",
	gap: "8",
});

export const progressWrapperClass = flex({
	gap: "4",
	alignItems: "center",
});

export const footerStatusTextClass = css({
	color: "shade.80",
	fontSize: "12",
	lineHeight: "[140%]",
	letterSpacing: "0.02em",
	fontWeight: "[600]",
});

export const progressbarWrapperClass = css({
	position: "relative",
});

export const progressOverlayWrapperClass = flex({
	position: "absolute",
	top: "0",
	left: "0",
	right: "0",
	bottom: "0",
	background: "shade.0",
	opacity: "0.5",
	justifyContent: "center",
	alignItems: "center",
});
