import { css, cva } from "@styled-system/css";
import { flex, stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	marginTop: "40",
	gap: "24",
});

export const licenseInfoWrapperClass = stack({
	width: "full",
	gap: "16",
});

export const licenseBarContainerClass = flex({
	position: "relative",
	width: "full",
	height: "16",
	mt: "8",
	mb: "8",
	borderRadius: "[5.875rem]",
	bg: "shade.10",
	_after: {
		content: "''",
		position: "absolute",
		zIndex: 10,
		width: "full",
		height: "full",
		borderRadius: "[5.875rem]",
		boxShadow: "[inset -1px 1px 15px 0px rgba(9, 49, 71, 0.2)]",
		mixBlendMode: "multiply",
	},
	_before: {
		content: "''",
		position: "absolute",
		zIndex: 10,
		width: "full",
		height: "full",
		borderRadius: "[5.875rem]",
		boxShadow: "[inset 0 0 1px 0 rgba(6, 31, 42, 0.3)]",
		mixBlendMode: "darken",
	},
});

export const usedLicensesBarClass = css({
	height: "full",
	width: "var(--width)",
	backgroundColor: "primary.100",
	borderRadius: "[5.875rem]",
	zIndex: 1,
});

export const legendLicensBarClass = cva({
	base: {
		width: "20",
		height: "4",
		borderRadius: "10",
	},
	variants: {
		status: {
			used: {
				backgroundColor: "primary.100",
			},
			remaining: {
				backgroundColor: "shade.20",
			},
		},
	},
});

export const licenseLabelsWrapperClass = flex({
	alignItems: "center",
	gap: "12",
});

export const licenseLabelClass = flex({
	alignItems: "center",
	gap: "8",
});

export const buttonClass = css({
	marginLeft: "-16",
	textDecoration: "underline",
	textDecorationColor: "secondary.100",
});

export const assignmentRulesHeaderClass = flex({
	justifyContent: "space-between",
	alignItems: "center",
});
