import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useCallback } from "react";
import { useFragment } from "react-relay";
import { useTheme } from "styled-components";
import {
	colorError100Class,
	colorSecondary120Class,
	colorShade100Class,
} from "@themes/color-classes";
import { H3Span, P2Span, P3Span } from "@themes/font-tags";
import {
	getBranchNodeNumberFromIndexPath,
	getContentNodeNumberFromIndexPath,
	isAfterIndexPath,
	isEqualIndexPath,
} from "@utils/index-path.util";
import { LEARN_OPPORTUNITY_V2_FRAGMENT } from "./content-card.graphql";
import {
	footerClass,
	groupClass,
	headerClass,
	lineClass,
	mainWrapperClass,
	pointsWrapperClass,
} from "./content-card.styles";
import { type ContentCardProps } from "./content-card.types";

// TODO: add-translations
export const ContentCard = ({
	rootFinished,
	hasFullAccess,
	headIndexPath,
	isSelected,
	onClick,
	learnOpportunityV2FragmentRef,
}: ContentCardProps) => {
	const theme = useTheme();
	const learnOpportunityV2 = useFragment(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		learnOpportunityV2FragmentRef,
	);

	const { t } = useTkaTranslation("modulesAndLessons");

	const handleOnClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			onClick?.();
		},
		[onClick],
	);

	const branchNumber = getBranchNodeNumberFromIndexPath(learnOpportunityV2.indexPath);
	const contentNumber = getContentNodeNumberFromIndexPath(learnOpportunityV2.indexPath);
	const title = learnOpportunityV2.structureDefinition.title;
	const indexPath = learnOpportunityV2.indexPath;
	const contentKind = learnOpportunityV2.typeDefinition.contentKind;

	const receivablePoints =
		learnOpportunityV2.typeDefinition.extension?.receivableGamificationPoints ?? 0;
	const receivedPoints =
		learnOpportunityV2.typeDefinition.extension?.receivedGamificationPoints ?? 0;
	const needsUnlock = learnOpportunityV2.typeDefinition.extension?.isUnlockNecessaryToStart;
	const shouldBeStartedNext = isEqualIndexPath(indexPath, headIndexPath);

	const renderStatus = () => {
		if (rootFinished || isAfterIndexPath(indexPath, headIndexPath)) {
			if (contentKind === "Async") {
				const status = learnOpportunityV2.typeDefinition.task?.status;
				const label = status === "Evaluated" ? "Bewertet" : "eingereicht";
				return (
					<>
						<P2Span className={colorShade100Class}>{label}</P2Span>
						<Icon icon={"checkCircle"} color={theme.colors.shade100} sizeRem={1} />
					</>
				);
			}
			return (
				<>
					<div className={pointsWrapperClass}>
						{receivablePoints > 0 && (
							<P3Span>
								{receivedPoints}/{receivablePoints}
							</P3Span>
						)}
						<Icon icon={"coinStar"} color={theme.colors.shade100} sizeRem={0.875} />
					</div>
				</>
			);
		} else if (isEqualIndexPath(indexPath, headIndexPath)) {
			if (!hasFullAccess && needsUnlock) {
				return (
					<>
						<P2Span className={colorError100Class}>Lizenz benötigt</P2Span>
						<Icon icon={"removeBadge"} color={theme.colors.error100} sizeRem={1} />
					</>
				);
			}
			return (
				<>
					<P2Span className={colorSecondary120Class}>
						{t("modules_and_lessons.startNow")}
					</P2Span>
				</>
			);
		}
		return <Icon icon={"lock"} color={theme.colors.shade100} sizeRem={1} />;
	};

	return (
		<div
			className={mainWrapperClass({
				isSelected,
				shouldBeStartedNext: shouldBeStartedNext && !rootFinished,
			})}
			onClick={handleOnClick}
		>
			<div className={headerClass}>
				<div className={lineClass}>
					<P3Span>
						{t("modules_and_lessons.lesson")} {branchNumber}.{contentNumber}
					</P3Span>
				</div>
				<H3Span className={colorShade100Class}>{title}</H3Span>
				<div className={footerClass}>
					<div className={groupClass}>{renderStatus()}</div>
				</div>
			</div>
		</div>
	);
};
