import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useNavigate } from "react-router-dom";
import { Path } from "@router/index";
import { P1Span, H1Span } from "@themes/font-tags";
import { Wrapper } from "./activate-success-step.styles";

export const ActivateSuccessStep = () => {
	const { t } = useTkaTranslation("activateScreen");
	const navigate = useNavigate();

	const handleGoToLoginOnClick = () => {
		navigate(Path.login.path);
	};

	return (
		<Wrapper>
			<H1Span>{t("activate_screen.successTitle")}</H1Span>
			<P1Span>{t("activate_screen.successBody")}</P1Span>
			<Button
				fillParent
				label={t("activate_screen.successButtonText")}
				iconName="arrowRight"
				onClick={handleGoToLoginOnClick}
			/>
		</Wrapper>
	);
};
