import { Avatar } from "@thekeytechnology/academies-lib-webapp";
import { selectCurrentAccountId } from "@thekeytechnology/academies-lib-webapp/slices";
import { useSelector } from "react-redux";
import { useRefetchableFragment } from "react-relay";
import { accountHeader_QueryFragment$key } from "@relay/accountHeader_QueryFragment.graphql";
import { accountHeader_QueryFragmentRefetch } from "@relay/accountHeader_QueryFragmentRefetch.graphql";
import { greetingForTimeOfDay } from "@utils/greeting";
import { QUERY_FRAGMENT } from "./account-header.graphql";
import {
	contentClass,
	groupDataContentClass,
	groupDataWrapperClass,
	statisticsLabelClass,
	statisticsTitleClass,
	statisticsWrapperClass,
	wrapperClass,
} from "./account-header.styles";
import { AccountHeaderProps } from "./account-header.types";

export const AccountHeader = ({ queryFragmentRef }: AccountHeaderProps) => {
	const [query] = useRefetchableFragment<
		accountHeader_QueryFragmentRefetch,
		accountHeader_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef);

	const currentAccountId = useSelector(selectCurrentAccountId);

	const account = query?.Viewer?.Auth?.currentUser?.accounts.find(
		(a) => a.id === currentAccountId,
	);

	const userCount = query.AccountMemberManagement.AccountMembers.userCount;

	return (
		<div className={wrapperClass}>
			<div className={contentClass}>
				<div className={groupDataWrapperClass}>
					<Avatar name={account?.name} sizeInRem={5} />
					<div className={groupDataContentClass}>
						<span className={statisticsLabelClass}>{greetingForTimeOfDay()}</span>
						<span className={statisticsTitleClass}>{account?.name}</span>
					</div>
				</div>
				<div>
					{userCount > 0 && (
						<div className={statisticsWrapperClass}>
							<span className={statisticsLabelClass}>Mitglieder</span>
							<span className={statisticsTitleClass}>{userCount}</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
