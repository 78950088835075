import { graphql } from "react-relay";

export const QUERY = graphql`
	query manageLicensesTab_Query(
		$kinds: [LearnableKind!]!
		$licensesAvailable: Boolean
		$licensesConsumed: Boolean
		$text: String
	) {
		LicenseGrouping {
			LicenseGroupings(
				kinds: $kinds
				licensesAvailable: $licensesAvailable
				licensesConsumed: $licensesConsumed
				text: $text
			) {
				edges {
					node {
						id
						...licensesGroupCard_LicenseGroupingFragment
						...licenseContextMenu_LicenseFragment
					}
				}
			}
		}
		...licenseSettings_QueryFragment
	}
`;
