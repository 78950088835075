import * as Sentry from "@sentry/react";
import {
	createRelayEnvironment,
	JwtTokenData,
	setLoggedIn,
} from "@thekeytechnology/academies-lib-webapp";
import { Icon as IconComponent } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { ThemeProvider, DEFAULT_THEME } from "@thekeytechnology/academies-lib-webapp/theme";
import { addLocale as primereactAddLocale, locale as primereactSetLocale } from "primereact/api";
import { useEffect, useRef } from "react";
import { CookiesProvider } from "react-cookie";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Environment, RelayEnvironmentProvider } from "react-relay";
import { SelectionMenuContextProvider } from "@components/selection-menu/selection-menu.context";
import { TrialProgressTrackerProvider } from "@components/trial-progress-tracker/trial-progress-tracker.context";
import { UserCartContextProvider } from "@components/user-cart/user-cart.context";
import { useToast } from "@hooks/useToast";
import primeReactLocale from "@i18n/primereact-locale.json";
import { PermissionBasedNavigation } from "@navigation/permissionBasedNavigation";
import { Routes } from "@router/routes";
import { ErrorFallbackComponent } from "@screens/error-fallback";
import { TKA_THEME } from "@themes/theme";
import "./i18n/i18n";
import { isJwtTokenData } from "./network/JwtMiddleware";

if (
	import.meta.env.REACT_APP_APP_ENVIRONMENT === "production" ||
	(import.meta.env.REACT_APP_APP_ENVIRONMENT === "staging" &&
		import.meta.env.REACT_APP_API_BASE === "https://staging.api.thekey.academy")
) {
	Sentry.init({
		dsn: import.meta.env.REACT_APP_SENTRY_DSN,
	});
}

primereactAddLocale("de", primeReactLocale.de);
primereactSetLocale("de");

function App() {
	const { showError } = useToast();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const updateLoginData = (newLoginData: JwtTokenData) => {
		if (newLoginData) {
			dispatch(
				setLoggedIn({
					tokenData: newLoginData,
				}),
			);
		}
	};

	useEffect(() => {
		try {
			if (window.ReactNativeWebView.injectedObjectJson()) {
				const injectedJson = JSON.parse(window.ReactNativeWebView.injectedObjectJson()) as {
					loginData?: unknown;
				};
				const injectedLoginData = injectedJson?.loginData;
				if (isJwtTokenData(injectedLoginData)) updateLoginData(injectedLoginData);
			}
		} catch (_) {}
		window.updateLoginData = (loginDataString: string) => {
			try {
				const appLoginData = JSON.parse(loginDataString);
				if (isJwtTokenData(appLoginData)) updateLoginData(appLoginData);
			} catch (_) {}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnError = (errorMessage: string) => {
		showError({
			summary: "Error",
			detail: errorMessage,
		});
	};

	const relayEnvironmentRef = useRef(
		createRelayEnvironment(handleOnError, t) as unknown as Environment,
	);

	return (
		<Sentry.ErrorBoundary fallback={<ErrorFallbackComponent />}>
			<RelayEnvironmentProvider environment={relayEnvironmentRef.current}>
				<CookiesProvider>
					<TrialProgressTrackerProvider>
						<UserCartContextProvider>
							<SelectionMenuContextProvider>
								<DndProvider backend={HTML5Backend}>
									<ThemeProvider
										theme={{
											...DEFAULT_THEME,
											...TKA_THEME,
											IconComponent,
										}}
									>
										<PermissionBasedNavigation routes={Routes} />
									</ThemeProvider>
								</DndProvider>
							</SelectionMenuContextProvider>
						</UserCartContextProvider>
					</TrialProgressTrackerProvider>
				</CookiesProvider>
			</RelayEnvironmentProvider>
		</Sentry.ErrorBoundary>
	);
}

export default App;

export interface Tuple<T, K> {
	key: T;
	value: K;
}

declare global {
	interface Array<T> {
		distinct(): Array<T>;

		distinctBy<K>(key: (i: T) => K): Array<T>;

		groupBy<K>(key: (i: T) => K): Tuple<K, T[]>[];
	}
}

// eslint-disable-next-line no-extend-native
Array.prototype.distinct = function () {
	return this.filter((x, i) => i === this.indexOf(x));
};

// eslint-disable-next-line no-extend-native
Array.prototype.distinctBy = function <T, K>(keyFunction: (i: T) => K) {
	const knownKeys: K[] = [];
	return this.filter((x) => {
		const key = keyFunction(x);
		if (knownKeys.includes(key)) {
			return false;
		} else {
			knownKeys.push(key);
			return true;
		}
	});
};

// eslint-disable-next-line no-extend-native
Array.prototype.groupBy = function <T, K>(keyFunction: (i: T) => K): Tuple<K, T[]>[] {
	const groups: Tuple<K, T[]>[] = [];

	this.forEach((x) => {
		const keyValue = keyFunction(x);
		const existingGroup = groups.find((g) => g.key === keyValue);
		if (existingGroup) {
			existingGroup.value.push(x);
		} else {
			groups.push({ key: keyValue, value: [x] });
		}
	});
	return groups;
};
