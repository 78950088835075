/**
 * @generated SignedSource<<a3f8ca842da1a74186d49451d1dd5569>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type reminderLearnElement_ReminderLearnElementFragment$data = {
  readonly id: string;
  readonly text: string;
  readonly title: string;
  readonly " $fragmentType": "reminderLearnElement_ReminderLearnElementFragment";
};
export type reminderLearnElement_ReminderLearnElementFragment$key = {
  readonly " $data"?: reminderLearnElement_ReminderLearnElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"reminderLearnElement_ReminderLearnElementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "reminderLearnElement_ReminderLearnElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    }
  ],
  "type": "ReminderLearnElement",
  "abstractKey": null
};

(node as any).hash = "575082a17289f47c2db0bd3d23c64e95";

export default node;
