import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useFragment } from "react-relay";
import { BranchNodeCard } from "@components/branch-node-card";
import { ContentCard } from "@components/content-card";
import { DividerList } from "@components/divider-list";
import { type branchNodesTree_LearnOpportunityV2Fragment$key } from "@relay/branchNodesTree_LearnOpportunityV2Fragment.graphql";
import { getBranchNodeNumberFromIndexPath, getBranchStatus } from "@utils/index-path.util";
import { LEARN_OPPORTUNITY_V2_FRAGMENT } from "./branch-nodes-tree.graphql";
import { activeContentWrapperClass } from "./branch-nodes-tree.styles";
import {
	type BranchNode,
	type BranchNodesTreeProps,
	type ContentNode,
} from "./branch-nodes-tree.types";
import { UnlockWallItem } from "./parts/unlock-wall-item";
import { canSeeBonusModule, isBonusCourse } from "@utils/bonus-course";

export const BranchNodesTree = ({
	onContentNodeClick,
	hasMarginLeft,
	activeNodeId,
	learnOpportunityV2FragmentRef,
	userLevel,
}: BranchNodesTreeProps) => {
	const {
		id: learnOpportunityId,
		typeDefinition: { children: nodes },
		structureDefinition,
	} = useFragment<branchNodesTree_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		learnOpportunityV2FragmentRef ?? null,
	);

	const { isXLargeUp } = useWindowSize();
	const lastActiveNodeIdRef = useRef<string | undefined>(activeNodeId);
	const [focusActiveNode, setFocusActiveNode] = useState(false);

	const headContentId = structureDefinition.viewerTreeState?.headContentId;
	const firstContentId = nodes?.[0]?.typeDefinition?.children?.[0]?.id;

	const headOrFirstContentId = headContentId || firstContentId;
	const isFinished = structureDefinition.viewerTreeState?.kind === "IsFinished";
	const hasFullAccess = structureDefinition.extension?.unlockInfo?.kind === "FullAccess";
	const isAtHeadPaywall =
		headContentId === structureDefinition.extension?.unlockInfo?.firstContentId;

	const isBonusCourseId = isBonusCourse(learnOpportunityId);

	const headIndexPath = useMemo(() => {
		if (!nodes) return null;
		for (const node of nodes) {
			const foundContent = node.typeDefinition?.children?.find(
				(content) => content.id === headOrFirstContentId,
			);
			if (foundContent) {
				return foundContent.indexPath;
			}
		}
		return null;
	}, [headOrFirstContentId, nodes]);

	useEffect(() => {
		if (!lastActiveNodeIdRef.current && activeNodeId) {
			isXLargeUp && setFocusActiveNode(true);
		} else {
			setFocusActiveNode(false);
		}
		lastActiveNodeIdRef.current = activeNodeId;
	}, [activeNodeId]);

	const skipFocus = lastActiveNodeIdRef.current && lastActiveNodeIdRef.current !== activeNodeId;

	const lastUnlockedChildId = useMemo(() => {
		const unlockInfo = structureDefinition.extension?.unlockInfo;
		if (unlockInfo?.kind !== "Demo") return undefined;
		let lastUnlockedId: string | undefined;
		let lastCheckedId: string | undefined;
		nodes?.forEach((node) =>
			node.typeDefinition.children?.forEach((child) => {
				if (child.id === unlockInfo.firstContentId) {
					lastUnlockedId = lastCheckedId;
				}
				lastCheckedId = child.id;
			}),
		);
		return lastUnlockedId;
	}, [structureDefinition.extension?.unlockInfo, nodes]);

	const renderContentNodeCard = (node: ContentNode) => {
		const active = node.id === activeNodeId;

		const handleSetRef = (ref: HTMLDivElement | null) => {
			if (active && focusActiveNode && !skipFocus) {
				ref?.scrollIntoView();
			}
		};

		return (
			<Fragment key={node.id}>
				<div
					ref={handleSetRef}
					className={activeContentWrapperClass({
						active,
					})}
				>
					<ContentCard
						rootFinished={isFinished}
						hasFullAccess={hasFullAccess}
						headIndexPath={headIndexPath}
						isSelected={active}
						onClick={() => onContentNodeClick?.(node.id)}
						learnOpportunityV2FragmentRef={node}
					/>
				</div>
				{node.id === lastUnlockedChildId && !isAtHeadPaywall && (
					<UnlockWallItem productId={structureDefinition.extension?.product?.id ?? ""} />
				)}
			</Fragment>
		);
	};

	const renderBranchNodeCard = (node: BranchNode, index: number) => {
		if (isBonusCourseId) {
			const canSee = canSeeBonusModule(index, userLevel);
			if (!canSee) return null;
		}

		const totalPoints = node.typeDefinition.children?.reduce(
			(acc, child) =>
				acc + (child.typeDefinition.extension?.receivableGamificationPoints ?? 0),
			0,
		);
		const receivedPoints = node.typeDefinition.children?.reduce(
			(acc, child) => acc + (child.typeDefinition.extension?.receivedGamificationPoints ?? 0),
			0,
		);

		return (
			<BranchNodeCard
				key={node.id}
				title={node.structureDefinition.title}
				moduleNumber={getBranchNodeNumberFromIndexPath(node.indexPath)}
				imageUrl={node.image?.url ?? ""}
				indexPath={node.indexPath}
				headIndexPath={headIndexPath}
				contentsCount={node.typeDefinition.children?.length ?? 0}
				isFinished={isFinished}
				totalPoints={totalPoints}
				receivedPoints={receivedPoints}
				status={getBranchStatus(node.indexPath, headIndexPath, isFinished)}
				hasMarginLeft={hasMarginLeft}
			>
				{node.typeDefinition?.children?.map(renderContentNodeCard)}
			</BranchNodeCard>
		);
	};

	if (!nodes) return null;

	return <DividerList>{nodes.map(renderBranchNodeCard)}</DividerList>;
};
