export function calculateProgress(
	positions: (readonly number[])[],
	currentPosition: readonly number[],
	endPosition: readonly number[],
) {
	const sortedPositions = positions.sort((a, b) => {
		for (let i = 0; i < Math.min(a.length, b.length); i++) {
			if (a[i] !== b[i]) {
				return a[i] - b[i];
			}
		}
		return a.length - b.length;
	});

	const currentIndex = sortedPositions.findIndex((pos) =>
		pos.every((val, i) => val === currentPosition[i]),
	);
	const endIndex = sortedPositions.findIndex((pos) =>
		pos.every((val, i) => val === endPosition[i]),
	);

	if (currentIndex === -1 || endIndex === -1) {
		throw new Error("Positions not found in the array.");
	}

	const totalPathLength = Math.abs(endIndex - 0); // Assuming Root is [0]
	const progressLength = Math.abs(currentIndex - 0);
	const progress = (progressLength / totalPathLength) * 100;

	return { progress };
}
