/**
 * @generated SignedSource<<085b1de3484247d1c2efc33c6c814644>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "enhancedText" | "file" | "markMistakes" | "matrix" | "multipleChoice" | "order" | "persolog" | "persologEvaluation" | "persologQuestion" | "podcast" | "podcastWithTimestamp" | "reminder" | "text" | "textWithPages" | "video";
import { FragmentRefs } from "relay-runtime";
export type upsertNoteModal_ElementFragment$data = {
  readonly elementType: ElementTypeV2;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"noteSourceElementRenderer_ElementFragment">;
  readonly " $fragmentType": "upsertNoteModal_ElementFragment";
};
export type upsertNoteModal_ElementFragment$key = {
  readonly " $data"?: upsertNoteModal_ElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"upsertNoteModal_ElementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "upsertNoteModal_ElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "noteSourceElementRenderer_ElementFragment"
    }
  ],
  "type": "LearnElement",
  "abstractKey": "__isLearnElement"
};

(node as any).hash = "862205db4f922d108adc0a5ee5bad9ac";

export default node;
