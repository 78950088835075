import { BranchNodeStatus } from "@components/branch-node-card/branch-node-card.types";

export const getBranchNodeNumberFromIndexPath = (indexPath: readonly number[]) => {
	return (indexPath[1] ?? 0) + 1;
};

export const getContentNodeNumberFromIndexPath = (indexPath: readonly number[]) => {
	return (indexPath[2] ?? 0) + 1;
};

export const getBranchStatus = (
	branchIndexPath: readonly number[],
	headIndexPath?: readonly number[] | null,
	isFinished = false,
): BranchNodeStatus => {
	if (isFinished) return BranchNodeStatus.done;
	if (!headIndexPath) return BranchNodeStatus.locked;
	if (headIndexPath[1] > branchIndexPath[1]) {
		return BranchNodeStatus.done;
	} else if (headIndexPath[1] === branchIndexPath[1]) {
		return BranchNodeStatus.unlocked;
	}
	return BranchNodeStatus.locked;
};

export const isEqualIndexPath = (
	indexPath?: readonly number[] | null,
	compareIndexPath?: readonly number[] | null,
) => {
	const _indexPath = indexPath ?? [];
	const _compareIndexPath = compareIndexPath ?? [];
	return (
		_indexPath[0] === _compareIndexPath[0] &&
		_indexPath[1] === _compareIndexPath[1] &&
		_indexPath[2] === _compareIndexPath[2]
	);
};

export const isAfterIndexPath = (
	indexPath?: readonly number[] | null,
	compareIndexPath?: readonly number[] | null,
) => {
	const _indexPath = indexPath ?? [];
	const _compareIndexPath = compareIndexPath ?? [];
	return (
		_indexPath[0] < _compareIndexPath[0] ||
		(_indexPath[0] === _compareIndexPath[0] && _indexPath[1] < _compareIndexPath[1]) ||
		(_indexPath[0] === _compareIndexPath[0] &&
			_indexPath[1] === _compareIndexPath[1] &&
			_indexPath[2] < _compareIndexPath[2])
	);
};

export const decodeIndexPath = (indexPath?: readonly number[]) => {
	const [rootIndex = 0, branchIndex = 0, contentIndex = 0] = indexPath ?? [];
	return {
		rootNumber: rootIndex + 1,
		branchNumber: branchIndex + 1,
		contentNumber: contentIndex + 1,
	};
};
