import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { useFragment } from "react-relay";
import { useMatch, useNavigate } from "react-router-dom";
import { BasketTimeline } from "@components/basket-timeline";
import { ScreenSidemenuLayout } from "@layouts/screen-sidemenu-layout";
import { shoppingCartTemplate_AuthViewerSchemaFragment$key } from "@relay/shoppingCartTemplate_AuthViewerSchemaFragment.graphql";
import { shoppingCartTemplate_OrderFragment$key } from "@relay/shoppingCartTemplate_OrderFragment.graphql";
import { Path } from "@router/paths";
import { H2Span } from "@themes/font-tags";
import { AUTH_VIEWER_SCHEMA_FRAGMENT, ORDER_FRAGMENT } from "./shopping-cart-template.graphql";
import {
	accountWrapperClass,
	sideMenuContentWrapperClass,
	timelineHeaderWrapperClass,
} from "./shopping-cart-template.styles";
import { ShoppingCartTemplateProps } from "./shopping-cart-template.types";
import { AvatarWithAccountSwitch } from "../avatar-with-account-switch";
import { BottomNavigationTemplate } from "../bottom-navigation-template";
import { PaypalButton } from "../paypal-button";

//TODO: add-translations
export const ShoppingCartTemplate = ({
	disableNext,
	onNext,
	onPrevious,
	onClose,
	onPaymentCompleted,
	authViewerSchemaFragmentRef,
	orderFragmentRef,
	children,
}: ShoppingCartTemplateProps) => {
	const auth = useFragment<shoppingCartTemplate_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerSchemaFragmentRef,
	);
	const order = useFragment<shoppingCartTemplate_OrderFragment$key>(
		ORDER_FRAGMENT,
		orderFragmentRef,
	);

	const { t } = useTkaTranslation("basketScreen");
	const { isXLargeUp } = useWindowSize();
	const navigate = useNavigate();

	const currentPath = [
		useMatch(Path.shoppingCart.withIdPlaceholder().products.path),
		useMatch(Path.shoppingCart.withIdPlaceholder().invoice.path),
		useMatch(Path.shoppingCart.withIdPlaceholder().paymentMethod.path),
		useMatch(Path.shoppingCart.withIdPlaceholder().overview.path),
	];

	const currentStepIndex = currentPath.findIndex(Boolean);
	const currentStep = currentStepIndex < 0 ? 1 : currentStepIndex + 1;

	const basketTimeline = (
		<BasketTimeline
			currentStep={currentStep}
			orientation={isXLargeUp ? "vertical" : "horizontal"}
			orderFragmentRef={order}
		/>
	);

	const isPayStep = currentStep === 4;
	const nextButtonText = isPayStep
		? "Kostenpflichtig bestellen"
		: t("basket_screen.nextButtonLabel");

	const isPaypal = order.selectedPaymentMethod?.paymentMethodType === "Paypal";

	const nextButton =
		isPayStep && isPaypal ? (
			<PaypalButton
				disabled={disableNext}
				onClick={onNext}
				onPaymentCompleted={onPaymentCompleted}
				orderFragmentRef={order}
			/>
		) : undefined;

	const handleOnCloseOnClick = () => {
		if (onClose) {
			onClose();
			return;
		}

		navigate(Path.dashboard.path);
	};

	return (
		<ScreenSidemenuLayout
			headerLeftIconName="close"
			headerLeftIconOnClick={handleOnCloseOnClick}
			noPadding={!isXLargeUp}
			showAvatar={!isXLargeUp}
			authViewerSchemaFragmentRef={auth}
			showUserCart={false}
			sideMenu={
				<div className={sideMenuContentWrapperClass}>
					<H2Span>{t("basket_screen.basketTimelineTitle")}</H2Span>
					{basketTimeline}
					<div className={accountWrapperClass}>
						<AvatarWithAccountSwitch authViewerSchemaFragmentRef={auth} />
					</div>
				</div>
			}
			headerBottomContent={
				!isXLargeUp && <div className={timelineHeaderWrapperClass}>{basketTimeline}</div>
			}
			bottomContent={
				<BottomNavigationTemplate
					onBackClick={onPrevious}
					canGoBack={currentStep > 1}
					actionButtonDisabled={disableNext}
					actionButtonLabel={nextButtonText}
					actionButtonIconName="arrowRight"
					onActionButtonClick={onNext}
					actionButton={nextButton}
				/>
			}
		>
			{children}
		</ScreenSidemenuLayout>
	);
};
