import { stack } from "@styled-system/patterns";

export const allocationListWrapperClass = stack({
	alignItems: "start",
	gap: "16",
});

export const allocationUserGroupClass = stack({
	width: "full",
	alignItems: "start",
	gap: "8",
});

export const allocationUserListClass = stack({
	width: "full",
	gap: "8",
});
