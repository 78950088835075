import { Header } from "@components/header";
import { SidemenuLayout } from "@layouts/sidemenu-layout";
import { isInAppWebview } from "@utils/webview.utils";
import { useFragment } from "react-relay";
import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "./screen-sidemenu-layout.graphql";
import { screenWrapperClass, scrollWrapperClass } from "./screen-sidemenu-layout.styles";
import { ScreenWithSidemenuLayoutProps } from "./screen-sidemenu-layout.types";

export const ScreenSidemenuLayout = ({
	noPadding = false,
	noContentPadding,
	sideMenu,
	headerBottomContent,
	bottomContent,
	canGoBack,
	headerLeftIconName,
	headerLeftIconOnClick,
	children,
	showAvatar,
	authViewerSchemaFragmentRef,
	showUserCart,
	isContentSubmission,
}: ScreenWithSidemenuLayoutProps) => {
	const auth = useFragment(AUTH_VIEWER_SCHEMA_FRAGMENT, authViewerSchemaFragmentRef ?? null);

	const isHeaderVisible = !isInAppWebview();
	return (
		<div className={screenWrapperClass}>
			<div className={scrollWrapperClass}>
				{isHeaderVisible && (
					<Header
						canGoBack={canGoBack}
						leftIcon={headerLeftIconName}
						onLeftItemClick={headerLeftIconOnClick}
						showAvatar={!!(showAvatar && auth)}
						authViewerSchemaFragmentRef={auth!}
						showUserCart={showUserCart}
					/>
				)}
				{headerBottomContent}
				<SidemenuLayout
					noContentPadding={noContentPadding}
					noPadding={noPadding}
					sideMenu={sideMenu}
					bottomContent={bottomContent}
					isHeaderVisible={isHeaderVisible}
					isContentSubmission={isContentSubmission}
				>
					{children}
				</SidemenuLayout>
			</div>
		</div>
	);
};
