import { useSelectionMenuContext } from "@components/selection-menu/selection-menu.context";
import { VideoPlayer } from "@components/video-player";
import { cx } from "@styled-system/css";
import { H2Span } from "@themes/font-tags";
import { VideoTrailerProps } from "./video-trailer.interface";
import { videoWrapperClass, wrapperClass } from "./video-trailer.styles";

export const VideoTrailer = ({ title, videoUrl, thumbnail, className }: VideoTrailerProps) => {
	const { upsertNoteModalOpen } = useSelectionMenuContext();

	return (
		<div className={wrapperClass}>
			{title && <H2Span>{title}</H2Span>}
			<div className={cx(videoWrapperClass, className)}>
				<VideoPlayer
					url={videoUrl}
					subtitles={[]}
					thumbnail={thumbnail}
					disableSpacePlayPause={upsertNoteModalOpen}
				/>
			</div>
		</div>
	);
};
