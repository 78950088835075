/**
 * @generated SignedSource<<42914039303b1a1ce20315738ad24594>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type paypalButton_OrderFragment$data = {
  readonly cart: {
    readonly totals: {
      readonly includingAllDiscounts: {
        readonly grossPrice: number;
      };
    };
  } | null | undefined;
  readonly id: string;
  readonly purchaser: {
    readonly userId: string;
  } | null | undefined;
  readonly " $fragmentType": "paypalButton_OrderFragment";
};
export type paypalButton_OrderFragment$key = {
  readonly " $data"?: paypalButton_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"paypalButton_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "paypalButton_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Totals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "includingAllDiscounts",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "grossPrice",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Purchaser",
      "kind": "LinkedField",
      "name": "purchaser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "4e904eb16bdcfa8dc096b7b8b6ffdec5";

export default node;
