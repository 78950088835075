/**
 * @generated SignedSource<<074adcdb8dc42e55ad0787b0b8f6a1b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccountMemberKind = "Invitation" | "UserInAccount";
import { FragmentRefs } from "relay-runtime";
export type licensesNewSingleUserAssignmentForm_QueryFragment$data = {
  readonly AccountMemberManagement: {
    readonly AccountMembers: {
      readonly accountMembers: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly kind: AccountMemberKind;
            readonly name: string;
            readonly user?: {
              readonly user: {
                readonly id: string;
                readonly name: string;
              };
            };
            readonly " $fragmentSpreads": FragmentRefs<"licensesSingleUserAssignmentCard_AccountMemberFragment">;
          };
        } | null | undefined> | null | undefined;
      };
    };
  };
  readonly " $fragmentType": "licensesNewSingleUserAssignmentForm_QueryFragment";
};
export type licensesNewSingleUserAssignmentForm_QueryFragment$key = {
  readonly " $data"?: licensesNewSingleUserAssignmentForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licensesNewSingleUserAssignmentForm_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "filterByGroupIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "licensesNewSingleUserAssignmentForm_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountMemberManagementQueries",
      "kind": "LinkedField",
      "name": "AccountMemberManagement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filterByGroupIds",
              "variableName": "filterByGroupIds"
            }
          ],
          "concreteType": "AccountMembersResult",
          "kind": "LinkedField",
          "name": "AccountMembers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountMemberConnection",
              "kind": "LinkedField",
              "name": "accountMembers",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountMemberEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "kind",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "UserInAccount",
                              "kind": "LinkedField",
                              "name": "user",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "User",
                                  "kind": "LinkedField",
                                  "name": "user",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v1/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "UserInAccountWrapper",
                          "abstractKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "licensesSingleUserAssignmentCard_AccountMemberFragment"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "f160f92a172675bd20f1bef4854c5dc3";

export default node;
