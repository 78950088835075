import { graphql } from "react-relay";

export const REWARD_RESULT_FRAGMENT = graphql`
	fragment contentFinishedRewardItem_RewardResultFragment on RewardResult {
		kind
		... on GamificationPoints_PointsReceivedRewardResult {
			kind
			points {
				num
			}
		}
		... on IHKCertificate_ReadyToOrderRewardResult {
			rewardKind
		}
	}
`;
