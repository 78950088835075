import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Footer } from "@components/footer";
import { Header } from "@components/header";
import { ReportModalFlow } from "@components/report-modal-flow";
import { wrapperClass, contentWrapperClass } from "./header-footer-screen-template.styles";
import { HeaderFooterScreenTemplateProps } from "./header-footer-screen-template.types";

export const HeaderFooterScreenTemplate = ({
	showUserCart,
	children,
}: HeaderFooterScreenTemplateProps) => {
	const { t } = useTranslation("authLayout");
	const [isShowingCreateReportFlow, setIsShowingCreateReportFlow] = useState(false);

	const handleReportOnClick = () => {
		setIsShowingCreateReportFlow(true);
	};
	const handleReportModalFlowDismissOnClick = () => {
		setIsShowingCreateReportFlow(false);
	};

	const footerItems = [
		{
			label: t("auth_layout.footerImprint"),
			link: "https://www.thekey.academy/impressum",
		},
		{ label: t("auth_layout.footerReport"), onClick: handleReportOnClick },
		{
			label: t("auth_layout.footerPrivacy"),
			link: "https://www.thekey.academy/datenschutzerklaerung/",
		},
		{
			label: t("auth_layout.footerAgb"),
			link: "https://www.thekey.academy/agbs/",
		},
	];

	return (
		<>
			<ReportModalFlow
				isVisible={isShowingCreateReportFlow}
				onDismiss={handleReportModalFlowDismissOnClick}
			/>
			<div className={wrapperClass}>
				<Header showUserCart={showUserCart} />
				<div className={contentWrapperClass}>{children}</div>
				<Footer items={footerItems} />
			</div>
		</>
	);
};
