import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { parseHtml } from "@thekeytechnology/academies-lib-webapp/utils/parse-html";
import { PathParams } from "@thekeytechnology/epic-ui";
import { Suspense, useEffect, useRef } from "react";
import { useFragment, usePreloadedQuery, useQueryLoader } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { Chip } from "@components/chip";
import { contentOverview_GetContentOverviewQuery } from "@relay/contentOverview_GetContentOverviewQuery.graphql";
import { contentOverview_LearnOpportunityV2Fragment$key } from "@relay/contentOverview_LearnOpportunityV2Fragment.graphql";
import { BranchNodesPath, Path, RootPath } from "@router/paths";
import { colorPrimary100Class, colorWarning100Class } from "@themes/color-classes";
import { P2Span, H1Span, H3Span } from "@themes/font-tags";
import {
	getBranchNodeNumberFromIndexPath,
	getContentNodeNumberFromIndexPath,
} from "@utils/index-path.util";
import { CONTENT_OVERVIEW_QUERY, LEARN_OPPORTUNITY_V2_FRAGMENT } from "./content-overview.graphql";
import { ContentOverviewSkeleton } from "./content-overview.skeleton";
import {
	contentPathClass,
	descriptionImageWrapperClass,
	headerWrapperClass,
	imageWrapperClass,
	pointsWrapperClass,
	wrapperClass,
} from "./content-overview.styles";
import {
	ContentOverviewProps,
	ContentOverviewWithPreloadedQueryProps,
} from "./content-overview.types";
import { AsyncContentOverview } from "../async-content-overview";
import { BranchNodeTab } from "../branch-nodes-tab";
import { ELearningContentOverview } from "../e-learning-content-overview";

export const ContentOverviewWithPreloadedQuery = ({
	showDetails,
	queryRef,
}: ContentOverviewWithPreloadedQueryProps) => {
	const query = usePreloadedQuery<contentOverview_GetContentOverviewQuery>(
		CONTENT_OVERVIEW_QUERY,
		queryRef,
	);

	const node = useFragment<contentOverview_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		query.node ?? null,
	);

	const { t } = useTkaTranslation("modulesAndLessons");
	const theme = useTheme();
	const navigate = useNavigate();
	const { rootId, branchNodeId } =
		useParams<PathParams<typeof RootPath | typeof BranchNodesPath>>();
	const { isXLargeUp } = useWindowSize();
	const sizeRef = useRef(isXLargeUp);

	const moduleNumber = node?.indexPath ? getBranchNodeNumberFromIndexPath(node.indexPath) : 1;
	const lessonNumber = node?.indexPath ? getContentNodeNumberFromIndexPath(node.indexPath) : 1;

	const imageUrl = node?.image?.url;
	const description = node?.description;
	const contentKind = node?.typeDefinition?.contentKind;

	const lessonName = `${t("modules_and_lessons.lesson")} ${moduleNumber}.${lessonNumber}`;

	const receivablePoints = node?.typeDefinition.extension?.receivableGamificationPoints ?? 0;
	const receivedPoints = node?.typeDefinition.extension?.receivedGamificationPoints ?? 0;
	const userLevel = query.Viewer.Auth.currentUser?.user.extension?.level ?? 0;

	const renderElementOverview = () => {
		if (!node) return null;
		switch (contentKind) {
			case "Async":
				return <AsyncContentOverview learnOpportunityV2FragmentRef={node} />;
			case "ELearning":
			default:
				return <ELearningContentOverview learnOpportunityV2FragmentRef={node} />;
		}
	};

	useEffect(() => {
		if (!isXLargeUp && sizeRef.current && !showDetails && rootId && branchNodeId) {
			navigate(Path.root.withId(rootId).branchNodes.withId(branchNodeId).lessonOverview.path);
		}
		sizeRef.current = isXLargeUp;
	}, [isXLargeUp]);

	const content = (
		<div
			className={wrapperClass({
				isGrid: contentKind !== "Async",
			})}
		>
			<div className={headerWrapperClass}>
				<div className={pointsWrapperClass}>
					<div />
					{receivablePoints > 0 && (
						<Chip>
							<H3Span className={colorWarning100Class}>
								{receivedPoints}/{receivablePoints}
							</H3Span>
							<Icon
								icon="coinStar"
								sizeRem={1}
								style={{
									stroke: theme.colors.warning100.hexValue(),
								}}
							/>
						</Chip>
					)}
				</div>
				<div className={contentPathClass}>
					<P2Span>{lessonName}</P2Span>
				</div>
				<H1Span className={colorPrimary100Class}>{node?.structureDefinition?.title}</H1Span>
				<div className={descriptionImageWrapperClass}>
					{description && (
						<div>
							<P2Span>{parseHtml(description)}</P2Span>
						</div>
					)}
					{imageUrl && (
						<div
							className={imageWrapperClass}
							style={{
								backgroundImage: `url(${imageUrl})`,
							}}
						/>
					)}
				</div>
			</div>
			{renderElementOverview()}
		</div>
	);

	return isXLargeUp || showDetails ? (
		content
	) : node?.root ? (
		<BranchNodeTab userLevel={userLevel} learnOpportunityV2FragmentRef={node.root} />
	) : null;
};

export const ContentOverview = (props: ContentOverviewProps) => {
	const { branchNodeId } = useParams();
	const [queryReference, loadQuery] =
		useQueryLoader<contentOverview_GetContentOverviewQuery>(CONTENT_OVERVIEW_QUERY);
	useEffect(() => {
		branchNodeId && loadQuery({ id: branchNodeId });
	}, [loadQuery, branchNodeId]);

	return (
		<Suspense fallback={<ContentOverviewSkeleton showDetails={props.showDetails} />}>
			{queryReference && (
				<ContentOverviewWithPreloadedQuery {...props} queryRef={queryReference} />
			)}
		</Suspense>
	);
};
