import { forwardRef } from "react";
import { cx } from "@styled-system/css";
import { wrapperClass } from "./side-menu-content-padding.styles";
import { type SideMenuContentPaddingProps } from "./side-menu-content-padding.types";

export const SideMenuContentPadding = forwardRef<HTMLDivElement, SideMenuContentPaddingProps>(
	function SideMenuContentPadding({ children, className, ...props }, ref) {
		return (
			<div className={cx(wrapperClass, className)} ref={ref} {...props}>
				{children}
			</div>
		);
	},
);
SideMenuContentPadding.displayName = "SideMenuContentPadding";
