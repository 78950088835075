import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { stripHtml } from "@thekeytechnology/academies-lib-webapp/utils";
import { colorShade100Class, colorShade60Class, colorShade80Class } from "@themes/color-classes";
import { H3Span, P2Span, P3Span } from "@themes/font-tags";
import {
	contentClass,
	contentHeaderClass,
	contentTextClass,
	headerClass,
	imageClass,
	imageOverlayClass,
	imageWrapperClass,
	wrapperClass,
} from "./news-card.styles";
import { NewsCardProps } from "./news-card.types";
import { formatNewsDate } from "./news-card.utils";

export const NewsCard = ({ news }: NewsCardProps) => {
	const handleOnClick = () => {
		window.open(news.permalink, "_blank", "noopener noreferrer");
	};

	return (
		<button type="button" className={wrapperClass} onClick={handleOnClick}>
			<header className={headerClass}>
				<div className={imageWrapperClass}>
					{news.image && <img src={news.image} alt={news.title} className={imageClass} />}
					{news.term && (
						<div className={imageOverlayClass}>
							<Label label={news.term} />
						</div>
					)}
				</div>
			</header>
			<div className={contentClass}>
				<div className={contentHeaderClass}>
					<H3Span className={colorShade100Class}>{news.title}</H3Span>
					<P3Span className={colorShade60Class}>{formatNewsDate(news.date)}</P3Span>
				</div>
				<div className={contentTextClass}>
					<P2Span className={colorShade80Class}>{stripHtml(news.excerpt)}</P2Span>
				</div>
			</div>
		</button>
	);
};
