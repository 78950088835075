import { RefObject, useEffect, useRef } from "react";

const defaultEvents = ["mousedown", "touchstart"];

const useClickAway = <E extends Event = Event>(
	ref: RefObject<HTMLElement | null>,
	onClickAway: (event: E) => void,
	events: string[] = defaultEvents,
) => {
	const savedCallback = useRef(onClickAway);

	useEffect(() => {
		savedCallback.current = onClickAway;
	}, [onClickAway]);

	useEffect(() => {
		// @ts-expect-error Can't be typed reliably
		const handler = (event) => {
			const { current: el } = ref;
			el && !el.contains(event.target) && savedCallback.current(event);
		};

		for (const eventName of events) {
			document.addEventListener(eventName, handler);
		}

		return () => {
			for (const eventName of events) {
				document.removeEventListener(eventName, handler);
			}
		};
	}, [events, ref]);
};

export default useClickAway;
