import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useMemo } from "react";
import { useFragment } from "react-relay";
import { instructorsComponent_InstructorsConnectionFragment$key } from "@relay/instructorsComponent_InstructorsConnectionFragment.graphql";
import { colorShade100Class } from "@themes/color-classes";
import { H2Span } from "@themes/font-tags";
import { INSTRUCTORS_CONNECTION_FRAGMENT } from "./instructors-component.graphql";
import { listClass, listItemClass, wrapperClass } from "./instructors-component.styles";
import { InstructorsComponentProps } from "./instructors-component.types";
import { InstructorCard } from "../instructor-card";

export const InstructorsComponent = ({
	instructorsConnectionFragmentRef,
}: InstructorsComponentProps) => {
	const { edges: instructors } =
		useFragment<instructorsComponent_InstructorsConnectionFragment$key>(
			INSTRUCTORS_CONNECTION_FRAGMENT,
			instructorsConnectionFragmentRef,
		);

	const { t } = useTkaTranslation("courseScreen");

	const instructorsList = useMemo(
		() =>
			instructors
				?.map((instructor) => {
					if (!instructor?.node) return;
					return (
						<div className={listItemClass} key={instructor.node.id}>
							<InstructorCard instructorFragmentRef={instructor.node} />
						</div>
					);
				})
				.filter(Boolean) || [],
		[instructors],
	);

	return instructorsList.length > 0 ? (
		<div className={wrapperClass}>
			<H2Span className={colorShade100Class}>{t("course_screen.trainers")}</H2Span>
			<div className={listClass}>{instructorsList}</div>
		</div>
	) : null;
};
