/**
 * @generated SignedSource<<5acfba104dce79f7c2ca82c92b93d2d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ItemKind = "BulkDiscount" | "Discount" | "GlobalDiscount" | "LimitedDiscount" | "Product";
export type PaymentMethodType = "Card" | "Giropay" | "IapApple" | "IapGoogle" | "InvoicePartner" | "InvoiceTk" | "Klarna" | "MonthlyPartner" | "MonthlyTk" | "Paypal" | "Sepa" | "Sofort";
export type SelectedPaymentMethodKind = "Monthly" | "OneTime";
import { FragmentRefs } from "relay-runtime";
export type shoppingCartPaymentMethod_OrderFragment$data = {
  readonly allowedPaymentMethods: {
    readonly paymentMethods: ReadonlyArray<PaymentMethodType>;
  };
  readonly cart: {
    readonly items: ReadonlyArray<{
      readonly amount?: number;
      readonly code?: string;
      readonly kind: ItemKind;
      readonly product?: {
        readonly data: {
          readonly licenseDefinition?: {
            readonly data: {
              readonly rootInfo?: {
                readonly image: {
                  readonly url: string | null | undefined;
                } | null | undefined;
                readonly rootId: string;
                readonly title: string;
              } | null | undefined;
            };
          } | null | undefined;
        };
        readonly id: string;
        readonly netPrice: number;
        readonly title: string;
      } | null | undefined;
      readonly title?: string;
      readonly validUntil?: string | null | undefined;
      readonly value?: number;
    }>;
    readonly totals: {
      readonly includingAllDiscounts: {
        readonly grossPrice: number;
      };
      readonly monthlyOptions: ReadonlyArray<{
        readonly downPayment: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
        readonly financedAmount: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
        readonly monthlyRate: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
        readonly rateCount: number;
        readonly totalAmount: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
      }>;
    };
  } | null | undefined;
  readonly id: string;
  readonly selectedPaymentMethod: {
    readonly chosenOption?: {
      readonly rateCount: number;
    };
    readonly kind: SelectedPaymentMethodKind;
    readonly paymentMethodType: PaymentMethodType;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartTemplate_OrderFragment">;
  readonly " $fragmentType": "shoppingCartPaymentMethod_OrderFragment";
};
export type shoppingCartPaymentMethod_OrderFragment$key = {
  readonly " $data"?: shoppingCartPaymentMethod_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartPaymentMethod_OrderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPrice",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grossPrice",
  "storageKey": null
},
v7 = [
  (v6/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "taxRatePercentage",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rateCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "shoppingCartPaymentMethod_OrderFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": (v4/*: any*/),
              "type": "LimitedDiscountItem",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "validUntil",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "type": "DiscountItem",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v4/*: any*/),
              "type": "GlobalDiscountItem",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "data",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "LicenseDefinition",
                              "kind": "LinkedField",
                              "name": "licenseDefinition",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": null,
                                  "kind": "LinkedField",
                                  "name": "data",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "InlineFragment",
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "LicenseDefinitionRootInfo",
                                          "kind": "LinkedField",
                                          "name": "rootInfo",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "rootId",
                                              "storageKey": null
                                            },
                                            (v2/*: any*/),
                                            {
                                              "alias": null,
                                              "args": null,
                                              "concreteType": "File",
                                              "kind": "LinkedField",
                                              "name": "image",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "kind": "ScalarField",
                                                  "name": "url",
                                                  "storageKey": null
                                                }
                                              ],
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "type": "TreeLicenseDefinitionData",
                                      "abstractKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "LicenseProductData",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "ProductItem",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Totals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "includingAllDiscounts",
              "plural": false,
              "selections": [
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "monthlyOptions",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "downPayment",
                  "plural": false,
                  "selections": (v7/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "financedAmount",
                  "plural": false,
                  "selections": (v7/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "monthlyRate",
                  "plural": false,
                  "selections": (v7/*: any*/),
                  "storageKey": null
                },
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "totalAmount",
                  "plural": false,
                  "selections": (v7/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedPaymentMethods",
      "kind": "LinkedField",
      "name": "allowedPaymentMethods",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethods",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "chosenOption",
              "plural": false,
              "selections": [
                (v8/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "MonthlyPaymentMethod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "shoppingCartTemplate_OrderFragment"
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "e27eaac59cbae066a724e99f0968a10d";

export default node;
