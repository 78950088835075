import { SelectionInput } from "@relay/shoppingCartProducts_UpdateCartMutation.graphql";
import { Cart, Product } from "./shopping-cart-products.types";

export const cloneCartSelection = (cart: Cart): DeepWritable<SelectionInput> => {
	const products = getProductItems(cart);
	const discountCodes = getCartDiscountItems(cart);
	return {
		selectedDiscountCodes: discountCodes?.map((item) => item.code!) ?? [],
		selectedProducts:
			products?.map((item) => ({
				productId: item.product?.id!,
				amount: item.amount ?? 1,
			})) ?? [],
	};
};

export const getProductItems = (cart: Cart) =>
	cart?.items.filter((item) => item.kind === "Product");

export const getCartDiscountItems = (cart: Cart) =>
	cart?.items.filter((item) => item.kind === "Discount");

export const getBulkDiscountItems = (cart: Cart) =>
	cart?.items.filter((item) => item.kind === "BulkDiscount");

export const getGlobalDiscountItems = (cart: Cart) =>
	cart?.items.filter((item) => item.kind === "GlobalDiscount");

export const getCartLimitedDiscountItems = (cart: Cart) =>
	cart?.items.filter((item) => item.kind === "LimitedDiscount");

export const getAllProducts = (cart: Cart): Array<Product> => {
	const itemsInCart: Array<Product> =
		cart?.items
			.filter((item) => item.kind === "Product")
			.map((x) => ({
				id: x.product?.id ?? "",
				title: x.product?.title ?? "",
			})) ?? [];

	const otherAvailableProducts: Array<Product> =
		cart?.otherAvailableProducts.edges?.map((x) => ({
			id: x?.node.id ?? "",
			title: x?.node.title ?? "",
		})) ?? [];

	return itemsInCart.concat(otherAvailableProducts);
};

export const getDiscountString = (discounts: { code?: string; value?: number }[]) => {
	return discounts.map((item) => `${item.code}_${item.value}%`).join(",");
};
