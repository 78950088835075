import { graphql } from "react-relay";

export const ADD_ITEM_TO_USER_CART_MUTATION = graphql`
	mutation unlockWallItem_AddItemToUserCartMutation($input: AddItemToUserCartInput!) {
		Viewer {
			Billing {
				addItemToUserCart(input: $input) {
					userCart {
						...userCart_UserCartFragment
					}
				}
			}
		}
	}
`;
