import { Icon } from "@thekeytechnology/academies-lib-webapp";
import { Card } from "@thekeytechnology/academies-lib-webapp/components/card";
import { DialogTemplate } from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import { Image } from "@thekeytechnology/academies-lib-webapp/components/image";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { type ChangeEvent, useState, type FC } from "react";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { NoteColorPicker } from "@components/note-color-picker";
import { NoteSourceElementRenderer } from "@components/note-source-element-renderer";
import { useToast } from "@hooks/useToast";
import type { NoteColor } from "@relay/noteCard_NoteFragment.graphql";
import type { upsertNoteModal_CreateNoteMutation } from "@relay/upsertNoteModal_CreateNoteMutation.graphql";
import type { upsertNoteModal_EditNoteMutation } from "@relay/upsertNoteModal_EditNoteMutation.graphql";
import { upsertNoteModal_RemoveMarkedTextMutation } from "@relay/upsertNoteModal_RemoveMarkedTextMutation.graphql";
import type { upsertNoteModal_RemoveNoteSourceMutation } from "@relay/upsertNoteModal_RemoveNoteSourceMutation.graphql";
import { Path } from "@router/paths";
import { brandMain100 } from "@themes/colors";
import { H1Span, H4Span, L1Span, P2Span, P3Span } from "@themes/font-tags";
import {
	CREATE_NOTE_MUTATION,
	EDIT_NOTE_MUTATION,
	CONTENT_SUBMISSION_FRAGMENT,
	LEARN_OPPORTUNITY_V2_FRAGMENT,
	LEARN_ELEMENT_FRAGMENT,
	NOTE_FRAGMENT,
	REMOVE_NOTE_SOURCE_MUTATION,
	REMOVE_MARKED_TEXT_MUTATION,
} from "./upsert-note-modal.graphql";
import {
	noteColorPickerClass,
	textareaClass,
	textareaWrapperClass,
	wrapperClass,
	markedTextClass,
	contentWrapperClass,
	elementWrapperClass,
	sourceWrapperClass,
	sourceCardWrapperClass,
	sourceCardTitleClass,
	sourceCardContentClass,
	deleteButtonClass,
	sourceElementTypeWrapperClass,
	sourceCardImageClass,
	sourceCardContentInnerClass,
	elementRendererClass,
} from "./upsert-note-modal.styles";
import type { UpsertNoteModalProps } from "./upsert-note-modal.types";
import {
	getDialogTemplateProps,
	getElementTypeIcon,
	getElementTypeName,
	getSourceTypeName,
} from "./upsert-note-modal.utils";

export const UpsertNoteModal: FC<UpsertNoteModalProps> = ({
	isVisible = true,
	onDismiss,
	onSuccess,
	onRemoveNoteSource,
	noteId,
	color = "default",
	content: defaultContent,
	selectedText: defaultSelectedText,
	noteFragmentRef,
	sourceModuleId,
	sourceLearnOpportunityId,
	contentSubmissionFragmentRef,
	sourceElementId,
	learnOpportunityV2FragmentRef,
	elementFragmentRef,
}) => {
	const isUpdate = !!noteId;

	const noteData = useFragment(NOTE_FRAGMENT, noteFragmentRef ?? null);
	const contentSubmissionData = useFragment(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef ?? null,
	);
	const learnOpportunityV2Data = useFragment(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		learnOpportunityV2FragmentRef ?? null,
	);
	const elementData = useFragment(LEARN_ELEMENT_FRAGMENT, elementFragmentRef ?? null);
	const [createNote] = useMutation<upsertNoteModal_CreateNoteMutation>(CREATE_NOTE_MUTATION);
	const [editNote] = useMutation<upsertNoteModal_EditNoteMutation>(EDIT_NOTE_MUTATION);
	const [removeNoteSource] = useMutation<upsertNoteModal_RemoveNoteSourceMutation>(
		REMOVE_NOTE_SOURCE_MUTATION,
	);
	const [removeMarkedText] = useMutation<upsertNoteModal_RemoveMarkedTextMutation>(
		REMOVE_MARKED_TEXT_MUTATION,
	);

	const navigate = useNavigate();
	const toast = useToast();
	const [content, setContent] = useState(noteData?.content ?? defaultContent ?? "");
	const [selectedColor, setSelectedColor] = useState(noteData?.color ?? color);
	const [selectedText, setSelectedText] = useState(noteData?.markedText ?? defaultSelectedText);
	const [elementSourceShown, setElementSourceShown] = useState(false);
	const [hasSource, setHasSource] = useState(
		() =>
			Boolean(sourceElementId) ||
			Boolean(sourceLearnOpportunityId) ||
			Boolean(elementData) ||
			Boolean(noteData?.source) ||
			false,
	);
	const [noteSourceRemoveIntent, setNoteSourceRemoveIntent] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const isElement =
		noteData?.source?.__typename === "ElementNoteSourceType" ||
		(contentSubmissionData?.definition.currentElementState?.element.id &&
			contentSubmissionData?.definition.currentElementState?.element.id ===
				sourceElementId) ||
		Boolean(elementData);
	const elementType =
		contentSubmissionData?.definition.currentElementState?.element.elementType ||
		elementData?.elementType ||
		noteData?.source?.element?.elementType;

	const definitionType =
		noteData?.source?.kind || learnOpportunityV2Data?.structureDefinition.definitionType;
	const rootNodeTitle =
		learnOpportunityV2Data?.structureDefinition.title ||
		contentSubmissionData?.learnOpportunity?.root?.structureDefinition.title ||
		noteData?.source?.publishedRootNode?.structureDefinition.title;
	const rootImageUrl = noteData?.source?.publishedRootNode?.image?.url;

	const handleOnDismiss = () => {
		onDismiss?.();
	};

	const handleSubmit = () => {
		setIsLoading(true);

		let markedText = selectedText;

		if (noteSourceRemoveIntent) {
			if (isUpdate) {
				if (!noteId) return;
				removeNoteSource({
					variables: {
						input: {
							noteId,
						},
					},
					onCompleted: () => {
						onRemoveNoteSource?.();
					},
				});

				removeMarkedText({
					variables: {
						input: {
							noteId,
						},
					},
					onCompleted: () => {
						onRemoveNoteSource?.();
					},
				});
			}

			markedText = undefined;
		}

		if (isUpdate) {
			editNote({
				variables: {
					input: {
						noteId,
						content,
						color: selectedColor,
					},
				},
				onCompleted: () => {
					toast.showSuccess({
						summary: "Notiz wurde erfolgreich bearbeitet",
					});

					setIsLoading(false);
					onSuccess?.();
				},
			});
			return;
		}

		const shouldAddSource = hasSource && !noteSourceRemoveIntent;
		const hasAnySource = sourceElementId || sourceModuleId || sourceLearnOpportunityId;

		createNote({
			variables: {
				input: {
					content,
					color: selectedColor,
					markedText,
					...(hasAnySource && {
						source: {
							...(shouldAddSource &&
								sourceElementId && {
									Element: {
										kind: "Element",
										learnElementId: sourceElementId,
									},
								}),
							...(shouldAddSource &&
								sourceModuleId &&
								!sourceElementId && {
									Module: {
										kind: "Module",
										contentId: sourceModuleId,
									},
								}),
							...(shouldAddSource &&
								sourceLearnOpportunityId &&
								!sourceModuleId &&
								!sourceElementId && {
									Course: {
										kind: "Course",
										rootNodeId: sourceLearnOpportunityId,
									},
								}),
						},
					}),
				},
			},
			onCompleted: () => {
				toast.showSuccess({
					summary: "Notiz wurde erfolgreich erstellt",
				});

				setIsLoading(false);
				onSuccess?.();
			},
		});
	};

	const handleSecondaryButtonOnClick = () => {
		if (elementSourceShown) {
			const rootNoteId =
				learnOpportunityV2Data?.id || noteData?.source?.publishedRootNode?.id;
			if (!rootNoteId) return;
			navigate(Path.root.withId(rootNoteId).overview.path);
		}

		handleOnDismiss();
	};

	const handlePrimaryButtonClick = () => {
		if (elementSourceShown) {
			setElementSourceShown(false);
			return;
		}

		handleSubmit();
	};

	const handleColorChange = (color: NoteColor) => {
		setSelectedColor(color);
	};

	const handleContentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setContent(e.target.value ?? "");
	};

	const handleRemoveSource = () => {
		if (!hasSource && !selectedText) return;
		setHasSource(false);
		setSelectedText(undefined);
		setNoteSourceRemoveIntent(true);
	};

	const handleShowElementSource = () => {
		if (!isElement) return;
		setElementSourceShown(true);
	};

	const dialogTemplateProps = getDialogTemplateProps(elementSourceShown, Boolean(noteData));

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={handleOnDismiss} dismissable>
			<DialogTemplate
				maxWidthRem={45}
				onSecondaryButtonClick={handleSecondaryButtonOnClick}
				onPrimaryButtonClick={handlePrimaryButtonClick}
				primaryButtonLoading={isLoading}
				{...dialogTemplateProps}
			>
				<div data-no-selection-menu className={wrapperClass}>
					<H1Span>{isUpdate ? "Notiz bearbeiten" : "Notiz hinzufügen"}</H1Span>
					<div className={contentWrapperClass}>
						{(hasSource || selectedText) && (
							<div className={sourceWrapperClass}>
								<>
									{!elementSourceShown && (
										<button
											type="button"
											onClick={handleRemoveSource}
											title="Notizquelle entfernen"
											className={deleteButtonClass}
										>
											<Icon icon="close" color={brandMain100} sizeRem={1} />
										</button>
									)}
									{elementSourceShown && isElement && (
										<div className={elementWrapperClass}>
											<NoteSourceElementRenderer
												contentSubmissionFragmentRef={contentSubmissionData}
												learnElementFragmentRef={elementData}
												className={elementRendererClass}
											/>
										</div>
									)}
									{!elementSourceShown && hasSource && (
										<button
											type="button"
											onClick={handleShowElementSource}
											className={sourceCardWrapperClass}
											disabled={!isElement}
										>
											<Card bodyPaddingInRem={0.625}>
												<div className={sourceCardContentClass}>
													{rootImageUrl && (
														<Image
															src={rootImageUrl}
															alt=""
															className={sourceCardImageClass}
															widthRem={5.9375}
															heightRem={5.125}
															imageStyle={{
																borderRadius: "0.75rem",
																objectFit: "cover",
															}}
														/>
													)}
													<div className={sourceCardContentInnerClass}>
														<P3Span>
															{getSourceTypeName(definitionType)}
														</P3Span>
														<H4Span className={sourceCardTitleClass}>
															{rootNodeTitle}
														</H4Span>
														{isElement && (
															<div
																className={
																	sourceElementTypeWrapperClass
																}
															>
																<Icon
																	icon={getElementTypeIcon(
																		elementType,
																	)}
																	color={brandMain100}
																	sizeRem={0.75}
																/>
																<L1Span>
																	{getElementTypeName(
																		elementType,
																	)}
																</L1Span>
															</div>
														)}
													</div>
												</div>
											</Card>
										</button>
									)}
								</>
								{!elementSourceShown && selectedText && (
									<div
										className={markedTextClass({
											hasSource,
										})}
									>
										<P2Span>“{selectedText}”</P2Span>
									</div>
								)}
							</div>
						)}
						{!elementSourceShown && (
							<div
								className={textareaWrapperClass({
									color: selectedColor,
								})}
							>
								<textarea
									key="note-text"
									className={textareaClass}
									placeholder="Notiz ..."
									value={content}
									onChange={handleContentChange}
								/>
								<NoteColorPicker
									defaultColor={selectedColor}
									onChange={handleColorChange}
									selectedColorSize="lg"
									flyoutDirection="row"
									className={noteColorPickerClass}
								/>
							</div>
						)}
					</div>
				</div>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
