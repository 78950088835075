/**
 * @generated SignedSource<<fc26654feb037915efc815da659a2743>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type avatarWithProfileMenu_AuthViewerSchemaFragment$data = {
  readonly currentUser: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"userAvatar_UserFragment">;
    };
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"accountSwitchMenu_AuthViewerSchemaFragment">;
  readonly " $fragmentType": "avatarWithProfileMenu_AuthViewerSchemaFragment";
};
export type avatarWithProfileMenu_AuthViewerSchemaFragment$key = {
  readonly " $data"?: avatarWithProfileMenu_AuthViewerSchemaFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"avatarWithProfileMenu_AuthViewerSchemaFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "avatarWithProfileMenu_AuthViewerSchemaFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUser",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "userAvatar_UserFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "accountSwitchMenu_AuthViewerSchemaFragment"
    }
  ],
  "type": "AuthViewerSchema",
  "abstractKey": null
};

(node as any).hash = "e20d4ea96b165385aa1e599647f6cf19";

export default node;
