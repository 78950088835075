import { css, cva } from "@styled-system/css";
import { flex, stack } from "@styled-system/patterns";
export const wrapperClass = cva({
	variants: {
		isGrid: {
			true: {
				gridColumn: "4",
				gap: "32",
				gridRow: "[min-content]",
			},
		},
	},
});

export const headerWrapperClass = stack({
	gap: "0",
	gridColumn: "4",
});

export const contentPathClass = css({
	mt: "16",
	mb: "4",
});

export const descriptionImageWrapperClass = flex({
	flexDirection: "column-reverse",
	rowGap: "32",
	mt: "12",
	mb: "24",
	justifyContent: "space-between",
	md: {
		flexDirection: "row",
		columnGap: "16",
	},
});

export const imageWrapperClass = css({
	backgroundSize: "cover",
	backgroundPosition: "center",
	borderRadius: "10",
	aspectRatio: "16 / 9",
	md: {
		width: "[16rem]",
		height: "[9rem]",
	},
	lg: {
		width: "[24rem]",
		height: "[13.5rem]",
	},
});

export const pointsWrapperClass = flex({
	justifyContent: "space-between",
});
