import { flex, stack } from "@styled-system/patterns";

export const flexClass = flex({
	gap: "8",
	alignItems: "center",
	"&[aria-disabled=true]": {
		opacity: "50%",
	},
});

export const flexColumnClass = stack({
	gap: "0",
	flex: "1",
});
