import { TabBar } from "@thekeytechnology/academies-lib-webapp/components/tab-bar";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { TabPanel } from "primereact/tabview";
import { useEffect, useMemo, useRef, useState, type FC } from "react";
import { useFragment } from "react-relay";
import { useSelectionMenuContext } from "@components/selection-menu/selection-menu.context";
import { getInitialTranscriptLanguage, VideoTranscriptLanguage } from "@components/video-menu";
import { type SubtitleLanguage, VideoPlayer, formatSeconds } from "@components/video-player";
import { H3Span, P2Span } from "@themes/font-tags";
import { VIDEO_LEARN_ELEMENT_FRAGMENT } from "./video-learn-element.graphql";
import {
	subtitleItemClass,
	subtitleTextClass,
	tabBarClass,
	transcriptsWrapperClass,
	videoPlayerWrapperClass,
} from "./video-learn-element.styles";
import type { VideoLearnElementProps } from "./video-learn-element.types";

export const VideoLearnElement: FC<VideoLearnElementProps> = ({
	learnElementFragmentRef,
	disableSpacePlayPause,
}) => {
	const { t } = useTkaTranslation("videoPlayerElement");
	const { upsertNoteModalOpen } = useSelectionMenuContext();

	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const highlightedSubtitleRef = useRef<HTMLDivElement>(null);
	const [subtitleIndex, setSubtitleIndex] = useState(-1);
	const [selectedSubtitles, setSelectedSubtitles] = useState<SubtitleLanguage>();

	const element = useFragment(VIDEO_LEARN_ELEMENT_FRAGMENT, learnElementFragmentRef ?? null);

	const subtitles = element?.subtitles;
	const availableLanguages = subtitles?.map((subtitle) => subtitle.language) || [];
	const sortedSubtitles = useMemo(
		() =>
			selectedSubtitles?.subtitles
				?.slice()
				.sort((a, b) => a.startTimeInSec - b.startTimeInSec) || [],
		[selectedSubtitles],
	);
	const [transcriptsLanguage, setTranscriptsLanguage] = useState(
		getInitialTranscriptLanguage(availableLanguages),
	);

	const url = element?.streamFile?.url;
	const subtitlesDisabled = transcriptsLanguage === VideoTranscriptLanguage.Disabled;

	useEffect(() => {
		if (!highlightedSubtitleRef.current) return;

		const highlightedSubtitleTopOffset = highlightedSubtitleRef.current.offsetTop;
		const scrollContainerTopOffset = scrollContainerRef.current?.offsetTop ?? 0;

		const top = highlightedSubtitleTopOffset - scrollContainerTopOffset;
		scrollContainerRef.current?.scrollTo({
			top,
			behavior: "smooth",
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subtitleIndex, highlightedSubtitleRef.current]);

	const renderSubtitle = (subtitle: typeof sortedSubtitles[number], index: number) => {
		const active = index === subtitleIndex;

		return (
			<div
				key={subtitle.startTimeInSec}
				ref={active ? highlightedSubtitleRef : null}
				className={subtitleItemClass}
			>
				<H3Span
					className={subtitleTextClass({
						active,
					})}
				>
					{formatSeconds(subtitle.startTimeInSec)}
				</H3Span>
				<P2Span
					className={subtitleTextClass({
						active,
					})}
				>
					{subtitle.text}
				</P2Span>
			</div>
		);
	};

	return (
		<>
			{url && (
				<div className={videoPlayerWrapperClass}>
					<VideoPlayer
						url={url}
						thumbnail={element.thumbnailFile?.url}
						subtitles={subtitles}
						onSelectedSubtitlesChanged={setSelectedSubtitles}
						onTranscriptLanguageChange={setTranscriptsLanguage}
						onSubtitleIndexChanged={setSubtitleIndex}
						disableSpacePlayPause={upsertNoteModalOpen || disableSpacePlayPause}
					/>
				</div>
			)}
			<TabBar renderActiveOnly className={tabBarClass}>
				<TabPanel
					header={t("video_player_element.liveTranscriptTitle")}
					disabled={subtitlesDisabled}
				>
					<div ref={scrollContainerRef} className={transcriptsWrapperClass}>
						{sortedSubtitles?.map((subtitle, index) => renderSubtitle(subtitle, index))}
					</div>
				</TabPanel>
			</TabBar>
		</>
	);
};
