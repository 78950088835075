import { cva } from "@styled-system/css";

export const activeContentWrapperClass = cva({
	variants: {
		active: {
			true: {
				xl: {
					backgroundColor: "shade.0",
					boxShadow: "contentCard",
				},
			},
		},
	},
});
