import { stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	height: "full",
});

export const dataWrapperClass = stack({
	gap: "4",
	p: "16",
	height: "full",
	border: "1px solid",
	borderColor: "shade.20",
	borderRadius: "12",
});

export const headerClass = stack();
