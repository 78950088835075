import { Skeleton } from "@thekeytechnology/epic-ui";
import { ProfileExpandableBox } from "@components/profile-expandable-box";
import { expandableBoxGridClass } from "./certificates-box.styles";

export const CertificatesBoxSkeleton = () => {
	return (
		<ProfileExpandableBox title="Zertifikate" defaultHeightRem={11.875}>
			<div className={expandableBoxGridClass({ columnStyle: "3" })}>
				{Array.from({ length: 3 }).map((_, index) => (
					<Skeleton key={index} width="100%" height="7.875rem" />
				))}
			</div>
		</ProfileExpandableBox>
	);
};
