/**
 * @generated SignedSource<<1909641f27b037fabc1ede5f94a35c82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userCard_UserInAccountWrapperFragment$data = {
  readonly groups: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly id: string;
  readonly isManager: boolean;
  readonly name: string;
  readonly user: {
    readonly user: {
      readonly email: string;
      readonly id: string;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"userAvatar_UserFragment">;
    };
  };
  readonly " $fragmentType": "userCard_UserInAccountWrapperFragment";
};
export type userCard_UserInAccountWrapperFragment$key = {
  readonly " $data"?: userCard_UserInAccountWrapperFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userCard_UserInAccountWrapperFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userCard_UserInAccountWrapperFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isManager",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserInAccountGroup",
      "kind": "LinkedField",
      "name": "groups",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserInAccount",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "userAvatar_UserFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserInAccountWrapper",
  "abstractKey": null
};
})();

(node as any).hash = "44f438e810d734e10d1ae002057467b4";

export default node;
