import styled from "styled-components";

export const ParentWrapper = styled.div<{ width?: number }>`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.spacing16.rem()};
	padding: ${({ theme }) => theme.spacing.spacing24.rem()};
	background-color: ${({ theme }) => theme.colors.shade100.withAlpha(0.9)};
	border-radius: ${({ theme }) => theme.spacing.spacing10.rem()};
	overflow-y: auto;
	width: ${({ width }) => (width !== undefined ? `${width}rem` : "initial")};
`;

export const HeaderWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
`;

export const BackWrapper = styled.div`
	position: absolute;
	left: 0;
	height: 100%;
	display: flex;
	align-items: center;
`;
