import { css } from "@styled-system/css";
import { flex, stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	xl: {
		flex: "1",
	},
});

export const paddingWrapperClass = css({
	paddingTop: "32",
	paddingX: "32",
	paddingBottom: "16",
});

export const headerWrapperClass = stack({
	gap: "24",
});

export const sideMenuWrapperClass = stack({
	gap: "32",
	height: "full",
	overflow: "auto",
	pb: "32",
	scrollbar: "hidden",
});

export const sideMenuHeaderWrapperClass = stack({
	gap: "24",
	paddingX: "24",
});

export const licenseWrapperClass = stack({
	gap: "8",
	paddingX: "24",
});

export const subpathBackgroundClass = css({
	backgroundColor: "shade.0",
	borderTopRadius: "24",
	height: "full",
	paddingTop: "40",
	paddingX: "40",
	borderTop: "7px solid",
	borderTopColor: "[#D3CEE3]",
	_before: {
		content: "''",
		position: "absolute",
		top: "0",
		left: "0",
		right: "0",
		bottom: "0",
		zIndex: "-1",
		backgroundColor: "[rgba(41, 19, 113, 0.11)]",
		borderTopRadius: "24",
		pointerEvents: "none",
	},
});

export const receiverGamificationPointsWrapperClass = flex({
	justifyContent: "center",
	alignItems: "center",
	gap: "8",
	width: "full",
	py: "12",
	bg: "shade.0",
	borderRadius: "12",
});

export const needsLicenseWrapperClass = stack({
	gap: "8",
	mt: "32",
	width: "full",
});
