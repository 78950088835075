/**
 * @generated SignedSource<<aafcda824a617ebc665d54d8b0492a5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type TextWithPagesPageDataKind = "ImagePageData" | "TextPageData";
import { FragmentRefs } from "relay-runtime";
export type textWithPagesLearnElement_TextWithPagesLearnElementFragment$data = {
  readonly id: string;
  readonly pages: ReadonlyArray<{
    readonly data: {
      readonly kind: TextWithPagesPageDataKind;
      readonly " $fragmentSpreads": FragmentRefs<"textWithPagesElementPage_TextWithPagesPageFragment">;
    };
    readonly id: string;
  }>;
  readonly title: string;
  readonly " $fragmentType": "textWithPagesLearnElement_TextWithPagesLearnElementFragment";
};
export type textWithPagesLearnElement_TextWithPagesLearnElementFragment$key = {
  readonly " $data"?: textWithPagesLearnElement_TextWithPagesLearnElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"textWithPagesLearnElement_TextWithPagesLearnElementFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "textWithPagesLearnElement_TextWithPagesLearnElementFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TextWithPagesPage",
      "kind": "LinkedField",
      "name": "pages",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "kind",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "textWithPagesElementPage_TextWithPagesPageFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TextWithPagesLearnElement",
  "abstractKey": null
};
})();

(node as any).hash = "a4fed7591782abcf1b2fd6cf135fc0e3";

export default node;
