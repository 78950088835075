import { css } from "@styled-system/css";
import { EpicH3Typography, EpicP2Typography } from "@thekeytechnology/epic-ui";
import { TabView } from "primereact/tabview";
import styled from "styled-components";

export const StyledTabView = styled(TabView)<{
	$sticky?: boolean;
	$hideHeader?: boolean;
	$disableMaxParentHeight?: boolean;
}>`
	${({ $disableMaxParentHeight }) => ($disableMaxParentHeight ? "" : "max-height: 100%;")}
	${({ $sticky }) =>
		$sticky &&
		`&.p-tabview .p-tabview-nav-container {
		position: sticky;
		top: 0;
		z-index: 1;
	}`}
	display: flex;
	flex-direction: column;
	overflow: hidden;

	&.p-tabview .p-tabview-nav {
		border-bottom: 2px solid ${({ theme }) => theme.colors.shade20};
		gap: 1.5rem;
		background: transparent;
		display: ${({ $hideHeader }) => ($hideHeader ? "none" : "flex")};
	}

	&.p-tabview .p-tabview-nav li .p-tabview-nav-link {
		border-width: 0 0 2px 0;
		border-color: transparent transparent ${({ theme }) => theme.colors.shade20} transparent;
		background: transparent;
		padding: 0.5rem 0;
		color: ${({ theme }) => theme.colors.primary100};
		${EpicP2Typography}
	}

	&.p-tabview .p-tabview-nav li .p-tabview-nav-link .p-tabview-title span::before {
		display: block;
		content: attr(data-label);
		font-weight: bold;
		height: 1px;
		color: transparent;
		overflow: hidden;
		visibility: hidden;
	}

	&.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
		outline: unset;
		outline-offset: unset;
		box-shadow: unset;
	}

	&.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
		border-color: ${({ theme }) => theme.colors.shade20};
		color: ${({ theme }) => theme.colors.shade80};
		background: transparent;
	}

	&.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
		border-color: ${({ theme }) => theme.colors.secondary120} !important;
		color: ${({ theme }) => theme.colors.secondary120} !important;
		${EpicH3Typography}
	}

	&.p-tabview .p-tabview-panels {
		padding: 0;
		background: transparent;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	&.p-tabview .p-tabview-panel:not(.p-hidden) {
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
`;

export const tabBarNavContainerClass = css({
	_after: {
		content: "",
		position: "absolute",
		display: "block",
		width: "[10000%]",
		height: "[2px]",
		background: "shade.20",
		bottom: "0",
		zIndex: "-1",
	},
});
