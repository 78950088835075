import { useAuthContext } from "@thekeytechnology/academies-lib-webapp/hooks/use-auth-context";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useNavigate, useParams } from "react-router-dom";
import { AuthTemplate } from "@components/auth-template";
import { AuthTemplateStep } from "@components/auth-template-step";
import { MultiStepAuthContainer } from "@containers/multi-step-auth-container";
import { useToast } from "@hooks/useToast";
import { Path } from "@router/paths";
import { PasswordResetPasswordStep } from "./parts/password-reset-password-step";
import { PasswordResetSuccessStep } from "./parts/password-reset-success-step";
import { type ResetPasswordValues } from "./reset-password.types";

export const ResetPasswordScreen = () => {
	const { token } = useParams<{ token: string }>();
	const { t } = useTkaTranslation("resetPasswordForm");
	const navigate = useNavigate();
	const { resetPassword } = useAuthContext();
	const { showError } = useToast();

	const handleOnSubmit = (values: ResetPasswordValues, goToNext: () => void) => {
		if (!token) {
			showError({
				summary: t("reset_password_form.errorsResetFailedToastTitle"),
				detail: t("reset_password_form.errorsInvalidResetPasswordToken"),
			});
			return;
		}
		resetPassword(values.password, token).then(goToNext);
	};

	const handleGoToLogin = () => {
		navigate(Path.login.path);
	};

	return (
		<AuthTemplate hideImage>
			<MultiStepAuthContainer<ResetPasswordValues> submitStep={1} onSubmit={handleOnSubmit}>
				{({ onNext }) => (
					<>
						<AuthTemplateStep>
							<PasswordResetPasswordStep
								onNext={onNext}
								onGoToLogin={handleGoToLogin}
							/>
						</AuthTemplateStep>
						<AuthTemplateStep>
							<PasswordResetSuccessStep onGoToLogin={handleGoToLogin} />
						</AuthTemplateStep>
					</>
				)}
			</MultiStepAuthContainer>
		</AuthTemplate>
	);
};
