/**
 * @generated SignedSource<<830b593f8785fe16023830d5cd725401>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type LearnableKind = "Course";
export type LicenseGroupingKind = "Learnable";
import { FragmentRefs } from "relay-runtime";
export type licenseDetails_LicenseGroupingFragment$data = {
  readonly LicenseGrouping: {
    readonly LicenseGrouping: {
      readonly assignmentRules: ReadonlyArray<{
        readonly __typename: string;
        readonly maxLicenseAmount?: number;
        readonly " $fragmentSpreads": FragmentRefs<"licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment" | "userRulesTab_QueryFragment">;
      }>;
      readonly id: string;
      readonly kind: LicenseGroupingKind;
      readonly learnable?: {
        readonly id?: string;
        readonly kind: LearnableKind;
        readonly root?: {
          readonly description: string | null | undefined;
          readonly id: string;
          readonly structureDefinition: {
            readonly title: string;
          };
        } | null | undefined;
      } | null | undefined;
      readonly totalLicenseAmount: number;
      readonly usedLicenseAmount: number;
    };
  };
  readonly " $fragmentType": "licenseDetails_LicenseGroupingFragment";
};
export type licenseDetails_LicenseGroupingFragment$key = {
  readonly " $data"?: licenseDetails_LicenseGroupingFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licenseDetails_LicenseGroupingFragment">;
};

import licenseDetails_RefetchQuery_graphql from './licenseDetails_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxLicenseAmount",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "licenseGroupingId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": licenseDetails_RefetchQuery_graphql
    }
  },
  "name": "licenseDetails_LicenseGroupingFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LicenseGroupingQueries",
      "kind": "LinkedField",
      "name": "LicenseGrouping",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "licenseGroupingId"
            }
          ],
          "concreteType": null,
          "kind": "LinkedField",
          "name": "LicenseGrouping",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "usedLicenseAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalLicenseAmount",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "learnable",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "LearnOpportunityV2",
                          "kind": "LinkedField",
                          "name": "root",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "description",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "structureDefinition",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "title",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "PublishedCourseLearnable",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnableLicenseGrouping",
              "abstractKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "assignmentRules",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "type": "SingleUserAssignmentRule",
                  "abstractKey": null
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "userRulesTab_QueryFragment",
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "userId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "licensesUsedByUser",
                          "storageKey": null
                        },
                        (v2/*: any*/)
                      ],
                      "type": "SingleUserAssignmentRule",
                      "abstractKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "87d8061086f7b2a177bf2f4e60363381";

export default node;
